import React, { useEffect } from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="keywords" content="Ortho-K Lens,contact lens,Daily,Weekly,MBS Premium Concept Store,Takashimaya S.C. Premium Concept Store" />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/ortho-k-lens">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/ortho-k-lens/ogp_1200x630.jpg?1669885165">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css" />
<link href="https://www.owndays.com/web/css/specials-ortho-k-lens.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            Ortho-K Lens                    </li>
        
            </ul>
</div>


<section class="l-ortho-k">
    <div class="l-ortho-k__container">
        <!-- TOP -->
        <section class="top">
            <div class="top__img">
                <picture>
                    <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/ortho-k-lens/main-banner-pc.webp" type="image/webp">
                    <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/ortho-k-lens/main-banner-pc.png" type="image/png">
                    <source media="(max-width:767px)" srcset="https://www.owndays.com/images/specials/products/ortho-k-lens/main-banner-sp.webp" type="image/webp">
                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/main-banner-sp.png" alt="MYOPIA CONTROL FOR KIDS">
                </picture>
                <h1 class="top__title">
                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/main-banner-text.svg" alt="Ortho K Lens">
                </h1>
            </div>
        </section>

        <!-- INTRO -->
        <section class="intro">
            <h2 class="l-ortho-k__title">What is Ortho-k?</h2>
            <p>Ortho-K, also known as orthokeratology, is the use of customised contact lenses that temporarily reshape the cornea to improve vision during treatment. It is a safe and reversible process that allows you to enjoy spectacle-free vision and is effective in slowing down myopia progression for children.</p>
            <img class="intro__img" src="https://www.owndays.com/images/specials/products/ortho-k-lens/children.svg" alt="KIDS">
        </section>

        <!-- VIDEO -->
        <section class="video">
            <div class="l-ortho-k__container--sub">
                <a href="https://youtu.be/CH8DcBcvgNw" data-fancybox>
                    <picture>
                        <source data-srcset="https://www.owndays.com/images/specials/products/ortho-k-lens/video-thumbnail.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/video-thumbnail.jpg" alt="Wake up to spectacle-free vision with Ortho-K lenses 🌞">
                    </picture>
                </a>
            </div>
        </section>

        <!-- HOW IT WORKS -->
        <section class="how-works">
            <h2 class="l-ortho-k__title l-ortho-k__title--center">How It Works</h2>
            <div class="l-ortho-k__container--sub">
                <ul class="how-works__list">
                    <li>
                        <p class="how-works__list-number"></p>
                        <img class="how-works__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/myopic-eye.svg" alt="Myopic eye (short-sighted)" style="max-width: 163px">
                        <h3 class="how-works__list-title">Myopic eye<small>short-sighted</small></h3>
                    </li>
                    <li>
                        <p class="how-works__list-number"></p>
                        <img class="how-works__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/with-ortho-k-lens-fitted.svg" alt="With Ortho-K lens fitted" style="max-width: 130px">
                        <h3 class="how-works__list-title">With Ortho-K lens<br>fitted</h3>
                    </li>
                    <li>
                        <p class="how-works__list-number"></p>
                        <img class="how-works__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/during-overnight-wear.svg" alt="During overnight wear" style="max-width: 130px">
                        <h3 class="how-works__list-title">During<br>overnight wear</h3>
                    </li>
                    <li>
                        <p class="how-works__list-number"></p>
                        <img class="how-works__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/ortho-k-lens-removed.svg" alt="Ortho-K lens removed" style="max-width: 165px">
                        <h3 class="how-works__list-title">Ortho-K lens<br>removed</h3>
                    </li>
                </ul>
                <p class="how-works__des">
                    Ortho-K lenses are gas permeable contact lenses that are specially shaped and fitted accordingly to each individual’s eyes. They are worn at night to reshape the cornea during sleep and then removed during the day.<br>
                    When worn overnight, the Ortho-K lens gently presses the cornea, causing the centre of the cornea to gradually flatten and moulding the cornea to the correct shape that focuses light onto the retina. When removed, the cornea remains flattened and vision is corrected without the need for spectacles during the day.
                </p>
            </div>
        </section>

        <!-- KEY BENEFITS -->
        <section class="key-benefits">
            <h2 class="l-ortho-k__title l-ortho-k__title--center">Key Benefits</h2>
            <ul class="key-benefits__list">
                <li>
                    <div class="key-benefits__list-top">
                        <img class="key-benefits__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/icon-control-myopia-progression.svg" alt="Control myopia progression">
                        <h3 class="key-benefits__list-title">Control<br>myopia progression</h3>
                    </div>
                    <p>Ortho-K offers an effective way to slow down the progression of myopia in children by preventing the elongation of the eyeball which leads to myopia.</p>
                </li>
                <li>
                    <div class="key-benefits__list-top">
                        <img class="key-benefits__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/icon-spectacle-free.svg" alt="Spectacle-free vision all day long">
                        <h3 class="key-benefits__list-title">Spectacle-free vision<br>all day long</h3>
                    </div>
                    <p>With consistent wear, you will be able to see clearly without having to wear spectacles during the day.</p>
                </li>
                <li>
                    <div class="key-benefits__list-top">
                        <img class="key-benefits__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/icon-safe-and-reversible-process.svg" alt="Safe and reversible process">
                        <h3 class="key-benefits__list-title">Safe<br>and reversible process</h3>
                    </div>
                    <p>Ortho-K is a surgery-free treatment that is reversible and can be stopped at any period of time. The cornea will return to its original shape and refractive power will return gradually when you cease wearing the lenses.</p>
                </li>
                <li>
                    <div class="key-benefits__list-top">
                        <img class="key-benefits__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/icon-an-alternative-to-lasik.svg" alt="An alternative to LASIK or refractive surgery">
                        <h3 class="key-benefits__list-title">An alternative to LASIK<br>or refractive surgery</h3>
                    </div>
                    <p>Ortho-K is currently considered one of the most preferred options for myopia management in children since surgical procedures are not recommended until one’s vision becomes stable.</p>
                </li>
            </ul>
        </section>

        <!-- WHO IS ORTHO-K RECOMMENDED FOR? -->
        <section class="recommended">
            <div class="recommended__content">
                <h2 class="l-ortho-k__title">Who is Ortho-K<br>recommended for?</h2>
                <ul class="recommended__list">
                    <li>Children and people who are not good candidates for refractive surgery</li>
                    <li>People who would like to achieve spectacle-free vision without wearing soft contact lenses</li>
                    <li>People who perform recreational and sports activities in which periods without visual correction are beneficial</li>
                    <li>People whose vocation requires unaided visual acuity for certain periods (e.g. the police, firemen, military personnel) or occupations in which refractive surgery may be a cause for exclusion (e.g. deep-sea divers, pilots)</li>
                </ul>
            </div>
            <a href="#stores" class="l-ortho-k__button l-ortho-k__button--down">Which stores is Ortho-K available at?</a>
        </section>

        <!-- TREATMENT PROCESS -->
        <section class="treatment-process">
            <h2 class="l-ortho-k__title l-ortho-k__title--center">Treatment Process</h2>
            <div class="l-ortho-k__container--sub">
                <ul class="treatment-process__list">
                    <li>
                        <div class="treatment-process__list-step active">
                            <p class="treatment-process__list-step-inner"></p>
                        </div>
                        <div class="treatment-process__list-content">
                            <div class="treatment-process__list--question opened">
                                <span>Consultation & Assessment</span>
                                <span class="plus"></span>
                            </div>
                            <div class="treatment-process__list--answer" style="display: block">
                                <div>
                                    <p>Our licensed optometrist will provide a pre-assessment* to find out the customer’s suitability and their expectation on the outcome of Ortho-K.</p>
                                    <span class="l-ortho-k__note">Consultation fee of $50 applies for pre-assessment and will be waived for purchase of Ortho-K lenses made within 1 month of consultation. </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="treatment-process__list-step">
                            <p class="treatment-process__list-step-inner"></p>
                        </div>
                        <div class="treatment-process__list-content">
                            <div class="treatment-process__list--question">
                                <span>Collection of Lenses</span>
                                <span class="plus"></span>
                            </div>
                            <div class="treatment-process__list--answer">
                                <div>
                                    <p>Lenses will be ready for collection in approximately 2 weeks after purchase. During collection, the optometrist will advise the customer on how to properly insert and remove the lenses as well as the cleaning and care techniques.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="treatment-process__list-step">
                            <p class="treatment-process__list-step-inner"></p>
                        </div>
                        <div class="treatment-process__list-content">
                            <div class="treatment-process__list--question">
                                <span>First Overnight Review</span>
                                <span class="plus"></span>
                            </div>
                            <div class="treatment-process__list--answer">
                                <div>
                                    <p>The customer is required to return to the store for a review after the first overnight wear. Appointment should be scheduled on the next day and within two hours of lens removal. The optometrist will determine if the lenses are properly fitted for the customer.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="treatment-process__list-step">
                            <p class="treatment-process__list-step-inner"></p>
                        </div>
                        <div class="treatment-process__list-content">
                            <div class="treatment-process__list--question">
                                <span>One Week Review</span>
                                <span class="plus"></span>
                            </div>
                            <div class="treatment-process__list--answer">
                                <div>
                                    <p>Significant results should be achieved after one week of daily overnight wear. The optometrist will perform another review to ensure that the lenses are well-fitted for the customer. Appointment for this review session should be in the morning.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="treatment-process__list-step">
                            <p class="treatment-process__list-step-inner"></p>
                        </div>
                        <div class="treatment-process__list-content">
                            <div class="treatment-process__list--question">
                                <span>One Month Review</span>
                                <span class="plus"></span>
                            </div>
                            <div class="treatment-process__list--answer">
                                <div>
                                    <p>The customer is required to return to the store for a review after one month of regular wear. Appointment should be scheduled for the evening when the lenses have been removed for 6 to 8 hours. </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="treatment-process__list-step">
                            <p class="treatment-process__list-step-inner"></p>
                        </div>
                        <div class="treatment-process__list-content">
                            <div class="treatment-process__list--question">
                                <span>Three Months Review</span>
                                <span class="plus"></span>
                            </div>
                            <div class="treatment-process__list--answer">
                                <div>
                                    <p>The customer is required to return to the store for a review after three months of regular wear. Appointment should be scheduled for the evening when the lenses have been removed for 6 to 8 hours. The optometrist will also conduct general lens cleaning during this review session.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="treatment-process__list-step">
                            <p class="treatment-process__list-step-inner"></p>
                        </div>
                        <div class="treatment-process__list-content">
                            <div class="treatment-process__list--question">
                                <span>Half Annual Review</span>
                                <span class="plus"></span>
                            </div>
                            <div class="treatment-process__list--answer">
                                <div>
                                    <p>Depending on individual eye condition, the customer should be able to achieve stable, spectacle-free vision after the first 3 to 6 months of consistent daily wear. The customer will need to return for review and general lens cleaning every half annually.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>

        <!-- PRICE-add -->
        <section class="stores u-pb__0">
            <div>
                <h3 class="l-ortho-k__title">Price</h3>
                <p>From $1,688 depending on complexity<br>Includes: 1 set of solutions and 1 pair of spectacles worth up to $198</p>
            </div>
            <div class="u-mt__20">
                <p>Enjoy exclusive discount on repeat purchases:</p>
                <ul class="c-list-condition c-list-condition--dot">
                    <li>50% Off for lenses of the same fitting and the same prescription</li>
                    <li>$200 Off per lens of different fitting and/or different prescription</li>
                </ul>
                <br>
                <p>Repeat Purchase Terms and Conditions</p>
                <ul class="c-list-condition c-list-condition--dot">
                    <li>Offer is limited to lenses used by the same wearer only and is non-transferrable</li>
                    <li>Offer does not include 1 complimentary set of solutions and 1 complimentary pair of spectacles worth up to $198</li>
                    <li>Discount applies to the usual retail price of a pair of Ortho-K lenses priced from $1,688 depending on the complexity of the case</li>
                    <li>Items sold are not refundable nor exchangeable</li>
                    <li>Offer is valid while stocks last</li>
                    <li>Offer cannot be used in conjunction with other discounts, vouchers and/or promotions</li>
                    <li>The management reserves the right to amend the offer and the terms and conditions without prior notice</li>
                </ul>
            </div>
        </section>

   

        <!-- STORES -->
        <section id="stores" class="stores">
            <div>
                <h3 class="l-ortho-k__title">Stores</h3>
                <p>Ortho-K is available exclusively at the following premium concept stores by appointment basis:</p>
            </div>
            <div>
                <ul class="stores__list">
                    <li>
                        <picture>
                            <source data-srcset="https://www.owndays.com/images/specials/products/ortho-k-lens/owndays-mbs-premium-concept-store.webp" type="image/webp">
                            <img class="stores__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/owndays-mbs-premium-concept-store.jpg" alt="OWNDAYS MBS Premium Concept Store">
                        </picture>
                        <h3 class="stores__title">OWNDAYS MBS Premium Concept Store</h3>
                        <a href="https://www.owndays.com/stores/schedule/OD1007" class="l-ortho-k__button l-ortho-k__button--right">Book an appointment</a>
                    </li>
                    <li>
                        <picture>
                            <source data-srcset="https://www.owndays.com/images/specials/products/ortho-k-lens/owndays-takashimaya-sc-premium-concept-store.webp" type="image/webp">
                            <img class="stores__list-img lazyload" src="https://www.owndays.com/images/specials/products/ortho-k-lens/owndays-takashimaya-sc-premium-concept-store.jpg" alt="OWNDAYS Takashimaya S.C. Premium Concept Store">
                        </picture>
                        <h3 class="stores__title">OWNDAYS Takashimaya S.C. Premium Concept Store</h3>
                        <a href="https://www.owndays.com/stores/schedule/OD1020" class="l-ortho-k__button l-ortho-k__button--right">Book an appointment</a>
                    </li>
                </ul>
                <p class="l-ortho-k__note">Appointments are available for online booking only, be it for pre-assessment or simply to meet our qualified optometrists in-store to find out more about Ortho-K. </p>
            </div>
        </section>

        <section class="guide">
            <div class="guide__container">
                <div class="guide__container__title">
                    <h2 class="l-ortho-k__title l-ortho-k__title--center">Beginner’s Guide<br>to Wearing Ortho-K</h2>
                    <h3>[ Inserting & Removing Lenses ]</h3>
                </div>
                <div class="guide__container__frame__slide">
                    <div class="guide__container__slide">
                        <div class="guide__container__row">
                            <div class="guide__container__row__title">
                                <div class="txt-group">
                                    <div class="txt-title">
                                        <h4>Insertion</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="guide__container__row__slide">
                                <div class="swiper">
                                    <ul class="slide-container swiper-wrapper">
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>01</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step01.svg" class="lazyload" alt="STEP01">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Wash hands with soap and dry thoroughly</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>02</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step02.svg" class="lazyload" alt="STEP02">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Rinse lens with saline or multi-purpose solution</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>03</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step03.svg" class="lazyload" alt="STEP03">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Apply a drop of non-preservative eye drop on lens</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>04</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step04.svg" class="lazyload" alt="STEP04">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Place lens in the upright concave shape on the tip of the index finger (master hand)</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>05</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step05.svg" class="lazyload" alt="STEP05">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Hold top eyelid with fingers of the non-master hand and bottom eyelid with the other fingers of the master hand</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>06</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step06.svg" class="lazyload" alt="STEP06">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Insert lens onto the eye while looking directly in the mirror</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="guide__container__row">
                            <div class="guide__container__row__title">
                                <div class="txt-group">
                                    <div class="txt-title">
                                        <h4>Removal</h4>
                                    </div>
                                    <div class="txt-sub-title">
                                        <p>with suction pump</p>
                                    </div>
                                </div>
                            </div>
                            <div class="guide__container__row__slide">
                                <div class="swiper">
                                    <ul class="slide-container swiper-wrapper">
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>01</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step01.svg" class="lazyload" alt="STEP01">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Wash hands with soap and dry thoroughly</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>02</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step08.svg" class="lazyload" alt="STEP02">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Instill 1-2 drops of non-preservative eye drop onto the eye to allow lens to move slightly</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>03</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step07.svg" class="lazyload" alt="STEP03">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Place suction pump at the centre of the lens</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>04</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step06.svg" class="lazyload" alt="STEP04">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Lens will be removed by suction force from the pump</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="guide__container__row">
                            <div class="guide__container__row__title">
                                <div class="txt-group">
                                    <div class="txt-title">
                                        <h4>Removal</h4>
                                    </div>
                                    <div class="txt-sub-title">
                                        <p>with fingers</p>
                                    </div>
                                </div>
                            </div>
                            <div class="guide__container__row__slide">
                                <div class="swiper">
                                    <ul class="slide-container swiper-wrapper">
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>01</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step01.svg" class="lazyload" alt="STEP01">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Wash hands with soap and dry thoroughly</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>02</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step08.svg" class="lazyload" alt="STEP02">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Instill 1-2 drops of non-preservative eye drop onto the eye to allow lens to move slightly</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>03</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step05.svg" class="lazyload" alt="STEP03">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Hold top and bottom eyelid using fingers of different hands</p>
                                            </div>
                                        </li>
                                        <li class="slide-list swiper-slide">
                                            <div class="list-img">
                                                <div class="list-num">
                                                    <p>04</p>
                                                </div>
                                                <div class="list-data-img">
                                                    <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/list-step06.svg" class="lazyload" alt="STEP04">
                                                </div class="list-data-img">
                                            </div>
                                            <div class="list-detail">
                                                <p>Press both eyelids simultaneously to push lens out</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="guide__container__title">
                    <h3>[ Cleaning & Care ]</h3>
                </div>
                <div class="guide__container__clean">
                    <div class="guide__container__clean__row">
                        <ul>
                            <li>
                                <div class="card__clean">
                                    <div class="txt-title-card">
                                        <h4>Daily</h4>
                                    </div>
                                    <div class="img-clean">
                                        <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/hours-circle.svg" class="lazyload" alt="6 hours">
                                    </div>
                                    <div class="txt-detail">
                                        <p>Use multi-purpose solution to rinse and rub lens after each wear and soak lens for minimum 6 hours before the next wear.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card__clean">
                                    <div class="txt-title-card">
                                        <h4>Weekly</h4>
                                    </div>
                                    <div class="img-clean">
                                        <img src="https://www.owndays.com/images/specials/products/ortho-k-lens/hours-circle02.svg" class="lazyload weekly-img" alt="20 to 30 minutes / 6 hours">
                                    </div>
                                    <div class="txt-detail">
                                        <p>Soak lens in protein remover once a week for 20 to 30 minutes. Then use multi-purpose solution to rinse and rub lens before soaking lens for minimum 6 hours before the next wear.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        <!-- FAQ -->
        <section class="faq">
            <h2 class="l-ortho-k__title">FAQ</h2>
            <div class="q-a">
                <div class="q-a__content">
                    <ul class="accordion">
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">Is Ortho-K comfortable to wear?</h3>
                            </div>
                            <div class="accordion__content">
                                <div class="q-a__a-inner">
                                    <p>There may be slight lens awareness and discomfort right after putting on the lenses. However, you will not “feel” the lenses during the overnight wear.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">Is Ortho-K safe?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>Ortho-K is a non-surgical, non-invasive treatment that is safe and reversible. The complications arising from the use of regular contact lenses due to poor hygiene and improper handling such as swimming and showering with the lenses may be minimised in the case of Ortho-K if proper cleaning and care is maintained.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">What is the difference between Ortho-K and LASIK?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>Ortho-K is a non-surgical, non-invasive treatment that is safe and reversible. On the other hand, LASIK is a surgical procedure that is irreversible. Ortho-K is recommended for people who prefer not to undergo LASIK surgery in order to achieve spectacle-free vision or those who are not suitable candidates for LASIK surgery.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">How long should Ortho-K be worn each time?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>Ortho-K lenses should be worn for a minimum of 6 to 8 hours daily during sleep.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">How long does it take to experience the full effects of Ortho-K?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>In general, it takes approximately 10 to 14 days to achieve optimal vision with consistent daily overnight wear. However, results will also depend on individual eye condition.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">Can I wear Ortho-K lenses if I have dry eyes?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>Yes, Ortho-K lenses are suitable for people with mild dry eyes. Dry eye syndrome is usually caused by external factors such as dusty or air-conditioned environment. Since Ortho-K lenses are worn during sleep with the eyes closed, dry eye syndrome may be minimised during Ortho-K treatment.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">Can I walk in to any store to purchase Ortho-K?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>Ortho-K is available exclusively at our premium concept stores in MBS and Takashimaya S.C. You will have to book an appointment for a pre-assessment with one of our qualified optometrists who will determine your suitability for Ortho-K treatment. The pre-assessment will take approximately 45 minutes. Appointments are available for online booking only.<br>
                                        <a class="link-color u-dib" href="/sg/en/shops/1007">Click here</a> to book an appointment at OWNDAYS MBS Premium Concept Store<br>
                                        <a class="link-color u-dib" href="/sg/en/shops/1020">Click here</a> to book an appointment at OWNDAYS Takashimaya S.C. Premium Concept Store
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">Do I have to make an appointment to purchase Ortho-K?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>Yes, you will have to book an appointment for a pre-assessment with one of our qualified optometrists who will determine your suitability for Ortho-K treatment. Do note that Ortho-K is available exclusively at our premium concept stores in MBS and Takashimaya S.C. The pre-assessment will take approximately 45 minutes. Appointments are available for online booking only.<br>
                                        <a class="link-color u-dib" href="/sg/en/shops/1007">Click here</a> to book an appointment at OWNDAYS MBS Premium Concept Store<br>
                                        <a class="link-color u-dib" href="/sg/en/shops/1020">Click here</a> to book an appointment at OWNDAYS Takashimaya S.C. Premium Concept Store
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">Can I wear colour contact lenses after starting Ortho-K treatment?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>No, we do not recommend wearing colour contact lenses after starting Ortho-K treatment. This is because the cornea curvature changes from wearing Ortho-K lenses and this affects the fitting of soft lenses which include colour contact lenses.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">How long can a pair of Ortho-K lenses last?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>Ortho-K lenses are able to last for 1.5 years on average. Depending on your care and handling habits, more frequent replacement may be needed.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="accordion__title">
                                <h3 class="q-a__q-title">Am I suitable for Ortho-K if I have irregular sleeping patterns?</h3>
                            </div>
                            <div class="accordion__content q-a__a-content">
                                <div class="q-a__a-inner">
                                    <p>You should have 6 to 8 hours of consistent closed-eye rest in order to achieve the optimal results. People who have irregular sleeping patterns, such as those who are on rotational shift work, are not suitable candidates for Ortho-K since they are unable to wear Ortho-K lenses at the same time every day.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <!-- SOFT CONTACT LENSES -->
        <section class="contact-lenses">
            <div class="contact-lenses__title">
                <h3>Soft<br>Contact Lenses</h3>
            </div>
            <div class="contact-lenses__list">
                <ul>
                    <li>
                        <a href="/sg/en/news/owndays-contactlens-sg">
                            <picture>
                                <source srcset="https://www.owndays.com/images/common/sg/clear-contact-menu.webp" type="image/webp">
                                <img src="https://www.owndays.com/images/common/sg/clear-contact-menu.jpg" alt="CLEAR CONTACT LENS">
                            </picture>
                        </a>
                    </li>
                    <li>
                        <a href="/sg/en/news/color-contactlens">
                            <picture>
                                <source srcset="https://www.owndays.com/images/common/sg/color-contact-menu.webp" type="image/webp">
                                <img src="https://www.owndays.com/images/common/sg/color-contact-menu.jpg" alt="COLOUR CONTACT LENS">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
        <div>

            <!-- Modal -->
            <div id="terms-conditions-modal" class="modal fade" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                            <h4 class="modal-title">TERMS & CONDITIONS</h4>
                            <ul>
                                <li>Offer is subjected to the wearer’s suitability for Ortho-K treatment</li>
                                <li>Offer is limited to a one-time discount per customer only</li>
                                <li>Items sold are not refundable nor exchangeable</li>
                                <li>Exclusive discounts for repeat purchases will be based on the usual price from $1,688</li>
                                <li>Offer cannot be used in conjunction with other discounts, vouchers and/or promotions</li>
                                <li>The management reserves the right to amend the offer and the terms and conditions without prior notice</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
</section>
        </main>
            </div>

  
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-ortho-k-lens.js"></script>
  
</body>

</html>`;

const OrthoContactLensHTML = () => {
  useEffect(() => {
    if (typeof window.clevertap !== 'undefined') {
      // OD__CLEVERTAP__EVENT (Page View)
      window.clevertap.event.push('Page View', {
        Page: 'Ortho-K Lens Page',
        Title: 'Ortho-K Lens | OWNDAYS Singapore',
        URL: window.location.href,
      });

      // OD__CLEVERTAP__EVENT (Clicked on a Product Category)
      window.clevertap.event.push('Clicked on a Product Category', {
        Page: 'Ortho-K Lens Page',
        Title: 'Ortho-K Lens | OWNDAYS Singapore',
        URL: window.location.href,
      });
    }
  }, []);

  return (
    <>
      <MetaTagsManager
        canonical="/news/ortho-k-lens"
        description="Experience the future of vision correction with our groundbreaking Ortho-K Lens at OWNDAYS Singapore. Achieve clear vision while you sleep!"
        title="Ortho-K Lens | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default OrthoContactLensHTML;
