import React, { useEffect } from 'react';
import './CrossCountryStrip.scss';
import gps from '../../../static/assets/svg/gps.svg';
import { disableBackgroundScroll, UtmObjectCreation } from '../../utils/helper';
import getSetCookie from '../../cookie';
export default function CrossCountryStrip(props) {
  const { country, domain } = props.countryData;
  const {
    localeInfo,
    dataLocale: { OUT_OF_TOWN, YOUR_PRESENT_LOCATION, GO, TO, WEBSITE },
    utmMapping,
  } = props;
  const { domainUrl } = localeInfo;
  let urlQueryUtmString = '';
  let redirectUrl = `https://${domain}?ref=https://${domainUrl}`;
  if (getSetCookie.getCookie('__utmz')) {
    const UtmcookieData = UtmObjectCreation(getSetCookie.getCookie('__utmz').split('|'));
    Object.keys(UtmcookieData).forEach(item => {
      urlQueryUtmString += `${utmMapping[item]}=${UtmcookieData[item]}&`;
    });
    redirectUrl += '&' + urlQueryUtmString.slice(0, urlQueryUtmString.length - 1);
  }
  useEffect(() => {
    disableBackgroundScroll();
  }, []);
  return (
    <div className="cross-country-strip-wrapper">
      <div className="cross-country-strip display-flex align-items-center justify-space-evenly padding-20 pd-20 text-center">
        <img
          alt="logo"
          className="lenskart-logo"
          src="https://static.lenskart.com/media/desktop/img/Banner-lenskart-logo.png"
        />
        <span className="fs30 fsp20">{OUT_OF_TOWN}</span>
        <div className="current-location pd-10 padding-10 display-flex align-items-center justify-content-center">
          <span className="fs16 fsp12">{YOUR_PRESENT_LOCATION}</span>
          <div className="display-flex">
            <img alt="gps" src={gps} />
            <span className="country fs20 fsp16 fw-bold mr-l5 margin-l5">{country}</span>
          </div>
        </div>
        <a className="cta1 text-white fs20 fsp15 fw-bold text-center bora-4" href={redirectUrl}>
          {GO} {TO} {country} {WEBSITE}
        </a>
      </div>
      <div className="dark" />
    </div>
  );
}
