import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/ditto';
import apiClient from '../helpers/apiClient';
import config from 'config';
import { dataURItoBlob } from '../utils/helper';
import sessionStorageHelper from '../utils/sessionStorageHelper';
import getSetCookie from '../cookie';

let _client = null;
function* addNewDitto({ dittoId }) {
  const successAction = { type: actionTypes.ADD_DITTO_SUCCESS };
  const errorAction = { type: actionTypes.ADD_DITTO_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.post(config.apiPath.dittoProfile + dittoId));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* saveDitto({ dittoId }) {
  const successAction = { type: actionTypes.SAVE_UPDATE_DITTO_SUCCESS };
  const errorAction = { type: actionTypes.SAVE_UPDATE_DITTO_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.post(config.apiPath.saveUpdateDitto + dittoId));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getOverlayedDitto({ productId, phNumber, phoneCode, dittoGuestId, clientName }) {
  const successAction = { type: actionTypes.GET_IMAGE_FROM_CYGNUS_SUCCESS };
  const errorAction = { type: actionTypes.GET_IMAGE_FROM_CYGNUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const headers =
      phNumber && phoneCode
        ? {
            'X-Api-Client': clientName,
            'X-Customer-Phone': phNumber,
            'X-Customer-Phone-Code': phoneCode,
          }
        : {
            'X-Api-Client': clientName,
          };
    let dittoGuestId = null;
    if (!(phNumber && phoneCode)) dittoGuestId = getSetCookie.getCookie('dittoGuestId');
    else dittoGuestId = null;

    const response = yield call(async () =>
      _client.get(
        `${config.apiPath.getOverlayedImageFromCygnus}?product_id=${productId}${
          dittoGuestId ? `&guest_id=${dittoGuestId}` : ''
        }&view=json`,
        {
          headers,
        }
      )
    );
    if (response?.overlayed_image_url) {
      successAction.data = response;
      yield put(successAction);
    } else {
      errorAction.error = { message: response?.message ?? 'Some error occured. Please try again.' };
      yield put(errorAction);
    }
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* uploadDitto({ image, phNumber, phoneCode, clientName }) {
  const successAction = { type: actionTypes.UPLOAD_IMAGE_TO_CYGNUS_SUCCESS };
  const errorAction = { type: actionTypes.UPLOAD_IMAGE_TO_CYGNUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  const binaryImage = dataURItoBlob(image);
  const formData = new FormData();
  formData.append('file', binaryImage);
  try {
    const headers =
      phNumber && phoneCode
        ? {
            'X-Api-Client': clientName,
            'X-Customer-Phone': phNumber,
            'X-Customer-Phone-Code': phoneCode,
          }
        : {
            'X-Api-Client': clientName,
          };
    const response = yield call(async () =>
      _client.post(`${config.apiPath.uploadImageToCygnus}`, formData, {
        headers,
      })
    );
    if (response?.id) {
      if (!(phNumber && phoneCode)) getSetCookie.setCookie('dittoGuestId', response?.id);

      successAction.data = { ...response, isGuest: !(phNumber && phoneCode) };
      yield put(successAction);
    } else {
      errorAction.error = { message: response?.message ?? 'Some error occured. Please try again.' };
      yield put(errorAction);
    }
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* updateCustomerCygnus({ cygnusId, clientName }) {
  const successAction = { type: actionTypes.UPDATE_CUSTOMER_CYGNUS_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_CUSTOMER_CYGNUS_FAIL };
  const client = Object.assign({}, { ..._client });
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.put(
        config.apiPath.updateCustomerCygnus,
        {
          cygnusId,
        },
        {
          headers: {
            'X-Api-Client': clientName,
            'X-Session-Token': getSetCookie.getCookie('clientV1'),
          },
        }
      )
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runDittoCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.ADD_DITTO_LOAD, addNewDitto),
    takeLatest(actionTypes.SAVE_UPDATE_DITTO_LOAD, saveDitto),
    takeLatest(actionTypes.UPLOAD_IMAGE_TO_CYGNUS_LOAD, uploadDitto),
    takeLatest(actionTypes.GET_IMAGE_FROM_CYGNUS_LOAD, getOverlayedDitto),
    takeLatest(actionTypes.UPDATE_CUSTOMER_CYGNUS_LOAD, updateCustomerCygnus),
  ]);
}
