import React from 'react';
import './DiscountBanner.scss';
import { withRouter } from 'react-router';

const DiscountBanner = ({history}) => {

  if(history?.location?.pathname?.includes('sunglasses.html')) {
    return null;
  }

  return (
    <div className="discount-banner">
      <p className="discount-banner__title">Double saving, 50% off on your 2nd pair of glasses</p>
    </div>
  );
};

export default withRouter(DiscountBanner);
