import React from 'react';
import './MyopiaControlLens.scss';

const MyopiaControlLens = () => {
  window.location.href = '/sg/en';
  return (
    <div className='myopia-control-lens-page'>
      <h1 className='mclp__heading'>Free Myopia Control Lens Upgrade</h1>
      <img className='mclp__banner' src="https://static.lenskart.com/media/owndays/mobile/img/Free-MCL-Web-Banner-1500x600.webp" alt="myopia" />

      <div className="mclp__content">
        <div className="mclp__blockquote">
          <h3>Duration: 01 - 30 June 2024 </h3>
        </div>

        <p className='mclp__para'>Control myopia progression for your little ones with our new Myopia Control 2 Lenses - clinically proven to slow down myopia progression by up to 50%! For a limited time only, enjoy free upgrade to myopia control lenses (worth $100) for kids aged 14 and below with the purchase of prescription glasses at our stores. </p>

        <div className="mclp__blockquote">
          <h3>Terms & Conditions</h3>
        </div>

        <ul className="mclp__ul">
          <li>Offer is valid with the purchase of prescription glasses only and excludes the lens replacement service</li>
          <li>Offer is valid for customers aged 14 years old and below only</li>
          <li>Children under 8 years old have to book an appointment at selected OWNDAYS stores</li>
          <li>Lens sold will not be covered by the OWNDAYS Customer Guarantees warranty programme</li>
          <li>There will be a one-time exceptional warranty on lens visual performance within 6 months of purchase; lens power based on doctor’s prescription (Dr Rx) will be excluded from this warranty coverage</li>
          <li>Regular top up fee applies for additional lens upgrades in which the standard 1-year warranty on lens visual performance will be valid</li>
          <li>Offer is valid at OWNDAYS retail shops in Singapore only and is subject to stock availability of Myopia Control Lens</li>
          <li>A two-week processing time is required for Myopia Control Lens made during the campaign period</li>
          <li>Offer cannot be used in conjunction with other discounts, vouchers and/ or promotions</li>
          <li>The management reserves the right to amend the offer and the terms and conditions without prior notice</li>
        </ul>

        <div
          className="mclp__bookeyetest_btn"
          onClick={() => {
            if (window?.location?.href?.includes('preproduction')) {
              window.location.href = 'https://preproduction.owndays.com/stores/sg';
            } else {
              window.location.href = 'https://www.owndays.com/stores/sg';
            }
          }}
        >
          <div className="od-font-reg">
            Book Free Eye Test
          </div>
        </div>
      </div>
    </div>

  )
}

export default MyopiaControlLens;