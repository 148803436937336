import React, { useEffect } from 'react';
import AOS from 'aos';

import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import 'aos/dist/aos.css';
import './css/SenichisakuFramesHTML.scss';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

    <link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
    <link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
    <link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
    <link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
    <link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
    <link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
    <link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
    <link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
    <link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
    <link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
    <link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
    <link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
    <link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
    <link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="千一作 | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="Sabae, a city located in the Fukui Prefecture, Japan, is widely regarded as the City of Eyewear. This is where Senichisaku frames are crafted, with every pair carefully put together by hand." />
<meta name="keywords" content="千一作,SENICHISAKU,Sabae,Made in Japan,celluloid,glasses,sunglasses,onlineshop,onlinestore" />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/senichisaku">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/senichisaku/sg/ogp_1200x630.jpg?1658109470">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<script>
    AOS.init({
        duration: 1000,
        once: true,
    });
</script>
<script>
  (function(d) {
    var config = {
      kitId: 'uzs2myr',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);
</script>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400&family=Noto+Serif+TC:wght@300;400;500&family=Kantumruy+Pro:wght@300;400&display=swap" rel="stylesheet">
<link href="https://www.owndays.com/web/css/specials-senichisaku.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            千一作                    </li>
        
            </ul>
</div>


<div class="l-senichisaku">
    <!-- Main -->
    <section class="main">
        <picture>
            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/senichisaku/main_pc.webp">
            <img src="https://www.owndays.com/images/specials/products/senichisaku/main_sp.webp" alt="MIJ/Sabae/handmade/glasses" class="main__bg" data-aos="fade-in">
        </picture>
        <div class="l-senichisaku__container">
            <h1 class="main__title" data-aos="fade-up">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/logo.svg" alt="千一作senichisaku">
                <span>SENICHISAKU</span>
            </h1>
            <p class="main__text" data-aos="fade-up" data-aos-delay="300">The Origin of the Finest <br class="u-sp-only">Japanese Craftsmanship</p>
        </div>
    </section><!-- Main -->
    <!-- Brand Story -->
    <section class="bs">
        <div class="l-senichisaku__container">
            <h2 class="bs__title" data-aos="fade-up">Brand Story<small>The Making of Senichisaku</small></h2>
        </div>
        <div class="l-senichisaku__container l-senichisaku__container--sm">
            <div class="bs__img bs__img--glasses" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/bs-01.webp" alt="the making of senichisaku"></div>
            <h5 class="bs__subtitle" data-aos="fade-up">Handcrafted in Sabae<br>The Origin of the Finest Japanese Craftsmanship</h5>
            <p class="bs__text" data-aos="fade-up">Sabae, a city located in the Fukui Prefecture, Japan, is widely regarded as the City of Eyewear. This is where Senichisaku frames are crafted, with every pair carefully put together by hand. Senichisaku frames are made of a scarce celluloid material that has excellent durability and a unique, rich colour that adds lustre to the frames. Like a prized gem that only gets better with age, the colour of the frame becomes deeper the more you use it and that is when you can truly revel in the beauty of a Senichisaku frame.</p>
        </div>
        <div class="bs__slider">
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-01-01.webp" alt="千一作 feature①">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-01-02.webp" alt="千一作 feature②">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-01-03.webp" alt="千一作 feature③">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-01-04.webp" alt="千一作 feature④">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-01-05.webp" alt="千一作 feature⑤">
            </div>
        </div>
        <div class="l-senichisaku__container">
            <div class="l-senichisaku__btn-group" data-aos="fade-up">
                <a href="/sg/en/search?q=SENICHI33" class="l-senichisaku__btn"><span>See all Senichisaku frames</span></a>
                <a href="/stores/sg" class="l-senichisaku__btn"><span>Search for nearest OWNDAYS stores</span></a>
            </div>
            <h5 class="bs__subtitle" data-aos="fade-up">Sabae – the City of Eyewear</h5>
            <div class="bs__img bs__img--town" data-aos="fade-up">
                <picture>
                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/senichisaku/bs-02_pc.webp">
                    <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-02_sp.webp" alt="sabae" >
                </picture>
            </div>
        </div>
        <div class="l-senichisaku__container l-senichisaku__container--sm">
            <p class="bs__text" data-aos="fade-up">Situated in the Heavy Snow Area of Japan, the Hokuriku region has long been plagued by heavy snowfalls. During the winter, rice fields and farms are covered by snow, making it difficult to grow crops or even head outdoors. Since agriculture is impracticable, people have instead turned to traditional craft-making works such as decorating lacquerware. During the Meiji era, manufacturing flourished with the adoption of techniques derived from such craft-making. </p>
        </div>
        <div class="bs__slider">
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-02-01.webp" alt="sabae①">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-02-02.webp" alt="sabae②">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-02-03.webp" alt="sabae③">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-02-04.webp" alt="sabae④">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-02-05.webp" alt="sabae⑤">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-02-06.webp" alt="sabae⑥">
            </div>
            <div class="bs__slider-item">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/bs-slide-02-07.webp" alt="sabae⑦">
            </div>
        </div>
        <div class="l-senichisaku__container l-senichisaku__container--sm">
            <p class="bs__text" data-aos="fade-up">Sabae, which is located within the Hokuriku region, is believed to have started eyewear manufacturing in 1905 and becoming the largest manufacturer of eyewear in Japan in 1935. The materials used for making spectacle frames at that time were red copper and brass. Celluloid and nickel alloys then became the most common frame materials in the Showa era. During the period of economic boom in Japan, sales of eyewear increased, leading to rapid growth in the eyewear industry in Sabae. <br><br>Today, Sabae is widely regarded as the City of Eyewear in Japan where there is a proliferation of eyewear workshops and artisans. One can even find symbols in the spectacle shape all over the streets.</p>
        </div>
        <div class="l-senichisaku__container l-senichisaku__container--lg">
            <div class="bs__img bs__img--frame" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/bs-04.webp" alt="千一作 senichisaku glasses"></div>
        </div>
        <div class="l-senichisaku__container l-senichisaku__container--sm">
            <h5 class="bs__subtitle" data-aos="fade-up">Celluloid that has a brilliant lustre<br>In a beautiful colour that intensifies with use</h5>
            <p class="bs__text" data-aos="fade-up">Celluloid is one of the most long-standing materials used for making spectacle frames. A large celluloid sheet is usually cut and then transformed into spectacle frames. Celluloid is elastic, impact resistant, durable and is able to retain its shape. <br><br>However, the manufacturing process to turn celluloid into spectacle frames is tedious and requiring many steps. This is why most of the plastic frames available in the market are made of another material called acetate. Unlike acetate, celluloid has a unique, rich colour that gives it a brilliant lustre. It is beautiful, luxury and even considered to be a precious gem at times.</p>
            <div class="bs__img bs__img--room" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/bs-05.webp" alt="celluloid/glasses"></div>
        </div>
    </section><!-- Brand Story -->
    <!-- Craftsmen -->
    <section class="craftsmen">
        <div class="craftsmen__top">
            <picture>
                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/senichisaku/pc_12pics.webp">
                <img src="https://www.owndays.com/images/specials/products/senichisaku/sp_12pics.webp" alt="craftsman in sabae" class="craftsmen__img " data-aos="fade-up">
            </picture>
            <div class="l-senichisaku__container l-senichisaku__container--sm">
                <h5 class="craftsmen__title" data-aos="fade-up">Bringing spectacle frames to life<br>With traditional techniques from the Sabae artisans</h5>
                <p class="craftsmen__text" data-aos="fade-up">In Sabae, spectacle frames are made through a division of labour. From die-cutting a frame from the celluloid sheet, polishing and attaching metal parts to finally completing a spectacle frame, each step along the way is handled by an artisan who is specialised in that particular process.</p>
            </div>
        </div>
        <div class="l-senichisaku__container l-senichisaku__container--lg">
            <div class="craftsmen__block craftsmen__block--odd">
                <div class="craftsmen__block-img" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/bs-06.webp" alt="polishing"></div>
                <div class="craftsmen__block-details">
                    <h5 class="craftsmen__block-title" data-aos="fade-up">Polishing</h5>
                    <p class="craftsmen__block-text" data-aos="fade-up">Polishing is an important step in creating a beautiful finishing on a frame. The entire polishing process is highly dependent on the experience of the artisan, requiring skills and precise intuition on the angle, pressure and length of time a frame is applied to the polishing wheel that will give it an exquisite shine. <br><br>Each Senichisaku frame is delicately hand polished by artisans from a family-run workshop specialised in making celluloid frames. Founded in 1947, the workshop is currently run by its 2nd generation owner whose two sons and their families are also working in the family business. Every one of them is highly skilled in every step of the polishing process, producing the finest eyewear that are filled with their heart and soul.</p>
                </div>
            </div>
            <div class="craftsmen__block craftsmen__block--even">
                <div class="craftsmen__block-img" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/bs-07.webp" alt="metalwork" ></div>
                <div class="craftsmen__block-details">
                    <h5 class="craftsmen__block-title" data-aos="fade-up">Metalwork</h5>
                    <p class="craftsmen__block-text" data-aos="fade-up">In a workshop founded in 1972 when its artisan-owner was 21 years old, a 3-person team made up of the owner, his wife and an employee work on metalwork such as attaching hinges that connect the front of the frame to the temples. In the past, they used to work mainly on metal spectacle frames decorated with jewels but had subsequently made a bold decision to switch their focus to celluloid frames when demand for bejewelled metal frames decreased over time. Their expertise in handling delicate metalwork such as attaching decorative jewels has made them much admired by other artisans in the field. The workshop celebrates its 50th anniversary in 2022. </p>
                </div>
            </div>
        </div>
    </section><!-- Craftsmen -->
    <!-- Process -->
    <section class="process">
        <picture>
            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/senichisaku/process-cover_pc.webp">
            <img src="https://www.owndays.com/images/specials/products/senichisaku/process-cover_sp.webp" alt="process" class="process__cover " data-aos="fade-up">
        </picture>
        <div class="l-senichisaku__container">
            <h2 class="process__title" data-aos="fade-up">Manufacturing Process</h2>
            <div class="process__block process__block--odd process__block--sm">
                <div class="process__block-media" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/process-01.webp" alt="material selection"></div>
                <div class="process__block-details" data-aos="fade-up">
                    <h5 class="process__block-title">1. Material Selection </h5>
                    <p class="process__block-text">A celluloid with the most suitable colour and thickness is selected based on the frame design and the latest trends in eyewear fashion.</p>
                    <h5 class="process__block-title">2. Bending Celluloid Sheet</h5>
                    <p class="process__block-text">The celluloid sheet is bent according to the size of the frame to create a gentle curvature for the frame front.</p>
                </div>
            </div>
            <div class="process__block process__block--even process__block--lg">
                <div class="process__block-media"  data-aos="fade-up">
                    <video width="100%" playsinline autoplay loop muted>
                        <source src="https://www.owndays.com/images/specials/products/senichisaku/C0008.MP4" type="video/mp4">
                    </video>
                </div>
                <div class="process__block-details" data-aos="fade-up">
                    <h5 class="process__block-title">3. Die-Cutting</h5>
                    <p class="process__block-text">The celluloid sheet is die-cut to the required shape and size of the frame.</p>
                </div>
            </div>
            <div class="process__block process__block--odd process__block--md">
                <div class="process__block-media" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/process-02.webp" alt="drum polishing"></div>
                <div class="process__block-details" data-aos="fade-up">
                    <h5 class="process__block-title">4. Drum Polishing</h5>
                    <p class="process__block-text">The frame is placed in a large polishing drum with abrasives and polishing chips and spun continuously to smooth out the edges.</p>
                    <h5 class="process__block-title">5.  Buffing and Attaching Hinges</h5>
                    <p class="process__block-text">A clay-like abrasive is used to polish the frame until it shines. Small slots are cut for the hinges which are embedded into the frame manually using a machine that generates heat to set the hinges in place.</p>
                </div>
            </div>
            <div class="process__block process__block--even process__block--sm2">
                <div class="process__block-media" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/process-03.webp" alt="attaching pad arms"></div>
                <div class="process__block-details" data-aos="fade-up">
                    <h5 class="process__block-title">6. Attaching Pad Arms</h5>
                    <p class="process__block-text">Holes are created to embed the pad arms into the frame.</p>
                    <h5 class="process__block-title">7. Temples and Hinges</h5>
                    <p class="process__block-text">Hinges are fastened onto the temples. The screws are then tightened and the frame is adjusted to ensure that it is at the correct pantoscopic angle.</p>
                </div>
            </div>
            <div class="process__block process__block--odd process__block--md">
                <div class="process__block-media" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/senichisaku/process-04.webp" alt="hand polishing"></div>
                <div class="process__block-details" data-aos="fade-up">
                    <h5 class="process__block-title">8. Hand Polishing</h5>
                    <p class="process__block-text">The frame is polished using different buffing wheels in sequence. This is the step that gives rise to the brilliant lustre and beautiful colour gradient on the celluloid.</p>
                    <h5 class="process__block-title">9.  Completion</h5>
                    <p class="process__block-text">A Senichisaku mark is engraved onto the frame after it clears quality inspection.</p>
                </div>
            </div>
        </div>
    </section><!-- Process -->
    <!-- Last -->
    <section class="last">
        <picture>
            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/senichisaku/owndays-store_pc.webp">
            <img src="https://www.owndays.com/images/specials/products/senichisaku/owndays-store_sp.webp" alt="OWNDAYS stores" class="last__img " data-aos="fade-up">
        </picture>
        <div class="l-senichisaku__container l-senichisaku__container--sm">
            <h2 class="last__title" data-aos="fade-up">Eyewear that stands the test of time</h2>
            <p class="last__text" data-aos="fade-up">With increasing global interest towards building a sustainable society guided by the SDGs, people begin moving away from mass production and mass consumption. Consumers are starting to embrace the concept of ethical consumption and “good and cheap” products are no longer attractive to them. More consumers perceive buying one pair of spectacles at $280 and using it for 2 years to have less environmental impact compared to buying four pairs of spectacles worth $70 each in 2 years.<br><br>In view of this, OWNDAYS then finetuned its business policies 6 years ago, creating eyewear of the highest standard in the optical industry without lowering prices. With products that are more durable, re-purchase cycle is longer and thus contributing to a more sustainable economy.<br><br>Senichisaku frames are the true epitome of Japanese craftsmanship. Made using the finest materials, every pair is carefully crafted to stand the test of time. Even though affordable spectacles are readily available nowadays, we hope you are able to find a perfect pair that can last you for the longest time.</p>
        </div>
        <div class="l-senichisaku__container ">
            <div class="l-senichisaku__btn-group" data-aos="fade-up">
                <a href="/sg/en/search?q=SENICHI33" class="l-senichisaku__btn"><span>See all Senichisaku frames</span></a>
                <a href="/stores/sg" class="l-senichisaku__btn"><span>Search for nearest OWNDAYS stores</span></a>
            </div>
        </div>
    </section><!-- Last -->
</div>

        </main>
            </div>


    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-senichisaku.js"></script>
 
</body>

</html>`;

const SenichisakuFramesHTML = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <MetaTagsManager
        description="Discover the art of Senichisaku frames, meticulously handcrafted in the City of Eyewear, Sabae. Experience the unique, enriching color and unmatched durability."
        title="Handcrafted Senichisaku Frames | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default SenichisakuFramesHTML;
