import React from 'react';
import './Packages.scss';
import Collapse from './Collapse';
import { useSelector } from 'react-redux';
import CheckedIcon from '../../../../../static/assets/svg/od-filled-circle.svg';
import UnCheckedIcon from '../../../../../static/assets/svg/od-circle.svg';
import { getProductPrice } from '../../../helper';

const Packages = ({ packages, selectedCoating, setSelectedCoating, setSelectedPackage }) => {
  const { CURRENCY_SYMBOL, CURRENCY_CODE } = useSelector(state => state?.locale);

  if (packages.length > 0) {
    return (
      <div className="odslv-p">
        {packages.map(pack => (
          <Collapse
            key={pack?.id}
            isToggleOnHeaderClick
            description={pack?.subtitle}
            icon={pack?.brand_image_url}
            title={pack?.label}
            onSelect
          >
            <div className="odslv-p__addons">
              {pack?.addons?.map((addon, index) => (
                <div
                  key={index}
                  className={`odslv-p__addon ${
                    selectedCoating?.id === addon?.id ? 'odslv-p__addon__selected' : ''
                  }`}
                  onClick={() => {
                    setSelectedCoating(addon);
                    setSelectedPackage(pack);
                  }}
                >
                  <div className="odslv-p__addon__left">
                    <div className="odslv-p__addon-img">
                      <img alt={addon?.title} src={addon?.imageUrl} />
                    </div>
                    <div className="odslv-p__addon-des">
                      <div className="odslv-p__addon-des-t">{addon?.title}</div>
                      <div className="odslv-p__addon-des-p">
                        <strong>
                          +{`${CURRENCY_CODE}${CURRENCY_SYMBOL}${getProductPrice(addon)}`}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="odslv-p__addon__right">
                    <img
                      alt="unchecked"
                      src={selectedCoating?.id === addon?.id ? CheckedIcon : UnCheckedIcon}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Collapse>
        ))}
      </div>
    );
  }

  return <div>No Packages found</div>;
};

export default Packages;
