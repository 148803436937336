import React, { useEffect } from 'react';
import './Gundam5Zeon.scss';
import Lineup from './Lineup/Lineup';
import { LineupScript } from './Lineup/LineupScript';
import Breadcrumb from '../../../../desktop/components/Common/Breadcrumb';

const baseImgUrl = 'https://www.owndays.com/images/specials/products/gundam5-zeon/';
const Gundam = () => {
  const baseUrl = 'https://www.owndays.com/sg/en/';
  const currentUrl = window.location.href;
  const pageName = currentUrl.split('/').pop();
  const storesNotes = [
    'Not valid in conjunction with other discounts, promotions and/or privileges',
    'No cancellation, refund, exchange or change of order details once an order is placed',
    'Resale or transfer is strictly prohibited',
    'Delivery within Malaysia only',
    'Certain prescription cannot be added to the lenses due to constraints in the lens power',
    'Lenses of the sunglasses cannot be replaced, whether they are prescription or not',
    'Upgrade to optional lenses is available at additional charges',
    'There is no product warranty except for warranty on lens visual performance if lenses have been replaced',
  ];

  const socialLinks = [
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}${pageName}`,
      class: 'fa-facebook',
      label: 'Share on Facebook',
    },
    {
      href: `https://twitter.com/intent/tweet?url=${baseUrl}${pageName}`,
      class: 'fa-x-twitter',
      label: 'Share on Twitter',
    },
    {
      href: `https://social-plugins.line.me/lineit/share?url=${baseUrl}${pageName}`,
      class: 'fa-line',
      label: 'Share on Line',
    },
  ];
    
  const createMarkup = htmlString => ({ __html: htmlString });

  useEffect(() => {
    const addScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = onLoad;
      script.onerror = () => {
        console.error(`Failed to load script: ${src}`);
      };
      document.head.appendChild(script);
      return script;
    };

    const addStylesheet = href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      document.head.appendChild(link);
      return link;
    };

    const scripts = [
      { src: 'https://cdn-script.com/ajax/libs/jquery/3.7.1/jquery.js', onLoad: null },
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js', onLoad: null },
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/rellax/1.12.1/rellax.min.js', onLoad: null },
    ];

    const stylesheets = [
      'https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.css',
      'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css',
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css',
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css',
    ];

    const scriptElements = scripts.map(({ src, onLoad }) => addScript(src, onLoad));
    const styleElements = stylesheets.map(href => addStylesheet(href));

    scriptElements[0].onload = () => {
      $('body').addClass('gundam-zeon').append(`
        <script src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js"></script>
        <script>
          var rellax = new Rellax('.rellax');
          const $lHeader = $('.header-placeholder').length > 0 ? $('.header-placeholder') : $('.od-header__panel');
          const $lHeaderHeight = $lHeader.outerHeight();

          $.fn.hasAttr = function (name) {
            return this.attr(name) !== undefined;
          };

          $(".l-zeon a[href^='#']").on('click', function (e) {
            const $this = $(this);
            const $targetId = $this.attr('href');
            const $target = $($targetId);
            const $duration = $this.hasAttr('data-scroll-duration')
              ? $this.attr('data-scroll-duration')
              : 1000;
            const $offset = $this.hasAttr('data-scroll-offset') ? $this.attr('data-scroll-offset') : 125;
            const $scrollPosition = $target.offset().top - $lHeaderHeight - $offset;

            if ($target.length === 0) {
              return;
            }

            e.preventDefault();

            $('html, body').animate(
              {
                scrollTop: $scrollPosition,
              },
              {
                duration: parseInt($duration),
                step: (now, fx) => {
                  const $realPos = $target.offset().top - $lHeaderHeight - $offset;
                  if (fx.end !== $realPos) {
                    fx.end = $realPos;
                  }
                },
              }
            );
          });
        </script>
      `);
    };

    window.onload = () => {
      AOS.init({
        once: true,
      });

      function handleScroll(targetSelector, actionCallback) {
        $(targetSelector).each(function () {
          const $target = $(this);

          if ($target.hasClass('aos-animate') && !$target.hasClass('is-shown')) {
            $target.addClass('is-shown');
            actionCallback($target);
          }
        });
      }

      LineupScript();

      $(window).on('scroll', function () {
        handleScroll('.concept__movie', function ($target) {
          const setAutoPlay = $target
            .find('iframe')
            .attr('src')
            .replace('autoplay=0', 'autoplay=1');
          $target.find('iframe').attr('src', setAutoPlay);
        });
      });
    };

    return () => {
      scriptElements.forEach(script => document.head.removeChild(script));
      styleElements.forEach(link => document.head.removeChild(link));
    };
  }, []);
  return (
    <>
      <Breadcrumb
        breadcrumbData={[
          {
            label: 'Homepage',
            link: 'www.lenskart.com',
          },
          {
            label: 'News',
            link: 'sg/en/news',
          },
          {
            label: 'ZEON EXCLUSIVE EYEGLASSES & SUNGLASSES',
          },
        ]}
        dataLocale={{
          SYMBOL_GREATER: '>',
        }}
      />
      <div className="l-zeon">
        {/* Main */}
        <section className="main">
          <div className="main__fg main__fg--1">
            <picture>
              <source media="(min-width: 768px)" srcset={`${baseImgUrl}main_fg-1-pc.webp`} />
              <img src={`${baseImgUrl}main_fg-1-sp.webp`} alt="Background-1" />
            </picture>
          </div>
          <div className="main__char">
            <div className="rellax" data-rellax-speed="-1">
              <picture>
                <source media="(min-width: 768px)" srcset={`${baseImgUrl}main_char-pc.webp`} />
                <img src={`${baseImgUrl}main_char-sp.webp`} alt="Char Aznable" />
              </picture>
            </div>
          </div>
          <div className="main__fg main__fg--2">
            <picture>
              <source media="(min-width: 768px)" srcset={`${baseImgUrl}main_fg-2-pc.webp`} />
              <img src={`${baseImgUrl}main_fg-2-sp.webp`} alt="Background-2" />
            </picture>
          </div>
          <div className="l-zeon__container">
            <div className="main__zeon-logo">
              <img src={`${baseImgUrl}zeon-logo.svg`} alt="ZEON" />
            </div>
            <div className="main__heading">
              <h1 className="rellax" data-rellax-speed="1">
                <img
                  src={`${baseImgUrl}logo.svg`}
                  alt="Principality of ZEON × OWNDAYS / ZEON EXCLUSIVE SPECTACLES & SUNGLASSES"
                />
                <span>ZEON EXCLUSIVE EYEGLASSES &amp; SUNGLASSES</span>
              </h1>
            </div>
            <div className="main__scroll-down">
              <a href="#concept">
                <i>SCROLL DOWN</i>
              </a>
            </div>
          </div>
        </section>

        {/* Concept */}
        <section id="concept" className="concept">
          <div className="l-zeon__container">
            <h2 className="l-zeon__heading l-zeon__heading--border l-zeon__heading--pc-center">
              <span>CONCEPT</span>
            </h2>
            <p className="concept__text">
              From Mobile Suit Gundam, one of the most iconic Japanese anime series that has
              remained highly popular across generations, comes an eyewear collaboration focusing on
              the Principality of Zeon. The lineup consists of a spectacle model and a sunglass
              model. The Zeon exclusive spectacles come in a red option that is inspired by Ace
              Pilot Char Aznable who has led the Principality of Zeon Armed Forces to numerous
              triumphant battles and a second option in khaki, replicating the colour of the uniform
              worn by soldiers of the Zeon armed forces. The Zeon exclusive sunglasses project a
              tough, resilient aura created by its metallic texture. This design treatment is
              inspired by Zaku II, also commonly known as Zaku, which plays a crucial role in the
              long-standing conflict against the Earth Federation. The sunglasses come in red and
              khaki, denoting Char’s custom Zaku and the Mass Production Type Zaku respectively.
            </p>
            <div className="concept__movie-wrap">
              <a
                className="concept__movie"
                href="https://youtu.be/nddglOH6pu0"
                data-fancybox
                data-aos
                data-aos-offset="-300"
              >
                <iframe
                  src="https://www.youtube.com/embed/XCRrx-fIqlQ?autoplay=0&mute=1&loop=1&controls=0&showinfo=0&playlist=XCRrx-fIqlQ"
                  allow="autoplay"
                  width="100%"
                  height="100%"
                ></iframe>
              </a>
            </div>
          </div>
        </section>
        {/* Lineup */}
        <Lineup baseImgUrl={baseImgUrl} />
        {/* Accessories */}
        <section className="accessories">
          <div className="l-zeon__container">
            <div className="accessories__inner">
              <h2 className="l-zeon__heading l-zeon__heading--center">
                <span>ACCESSORIES</span>
              </h2>
              <div className="accessories__grid">
                <div className="accessories__img">
                  <picture>
                    <source
                      media="(min-width: 768px)"
                      srcset={`${baseImgUrl}accessories_img-pc.webp`}
                      alt=""
                    />
                    <img
                      src={`${baseImgUrl}accessories_img-sp.webp`}
                      alt="Original Case & Cleaning Cloth Set"
                    />
                  </picture>
                </div>
                <p className="accessories__text">
                  Featuring the emblem of the Principality of Zeon gleaming against a luxe black
                  faux-leather material, this exclusive spectacle case is designed to befit the
                  soldiers of the Principality of Zeon. The spectacle case along with a spectacle
                  cloth in similar design are included with every purchase of the collaboration
                  spectacles or sunglasses.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Stores */}
        <section className="stores">
          <div className="l-zeon__container">
            <div className="stores__action">
              <a href="https://www.owndays.com/stores/sg" className="l-zeon__btn">
                Search for nearest OWNDAYS stores
              </a>
            </div>
            <ul className="stores__notes">
              {storesNotes.map((note, index) => (
                <li key={index} dangerouslySetInnerHTML={createMarkup(note)} />
              ))}
            </ul>
          </div>
        </section>
        {/* Case */}
        <section className="case">
          <div className="case__img">
            <picture>
              <source media="(min-width: 768px)" srcset={`${baseImgUrl}zaku-head-case-pc.webp`} />
              <img src={`${baseImgUrl}zaku-head-case-sp.webp`} alt="ZAKU HEAD CASE" />
            </picture>
          </div>
          <div className="l-zeon__container">
            <div className="case__content">
              <span className="case__badge">Simultaneous Release</span>
              <h2 className="case__heading">
                <small>MS-06S CHAR’S ZAKUII</small>ZAKU HEAD CASE
              </h2>
              <div className="case__zeon-owndays">
                <h3>
                  <img src={`${baseImgUrl}logo.svg`} alt="Principality of ZEON × OWNDAYS" />
                </h3>
                <p>
                  Limited to <strong>2,500</strong> units worldwide
                </p>
              </div>
              <div className="case__action">
                <a href="/sg/en/news/gundam5-zakuhead" className="l-zeon__btn l-zeon__btn--red">
                  View More
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* share */}
        <section className="share">
          <div className="share__inner">
            <div className="l-zeon__container">
              <h2 className="l-zeon__heading l-zeon__heading--center l-zeon__heading--bold">
                <span>SHARE</span>
              </h2>
              <ul className="share__list">
                {socialLinks.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={link.label}
                    >
                      <i className={`fa-brands ${link.class}`}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Gundam;
