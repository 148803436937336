import React, { useState } from 'react';
import './CartAuthModalSignIn.scss';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import OdFacebookIcon from '../../../../../static/assets/svg/od-facebook-icon.svg';
import OdGoogleIcon from '../../../../../static/assets/svg/od-google-icon.svg';
import OdEyeIcon from '../../../../../static/assets/svg/od-eye-icon.svg';

const CartAuthModalSignIn = ({}) => {
  const dataLocale = useSelector(state => state.locale);

  const {
    CART_AUTH_SIGN_IN_TITLE,
    SIGN_IN,
    FORGOT_PASSWORD,
    LOGIN,
    OR,
    NEED_AN_ACCOUNT,
    SIGN_UP,
    PHONE_NO_EMAIL_ID,
    TOGGLE,
    PASSWORD,
  } = dataLocale;

  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showUserPassword, setShowUserPassword] = useState('');

  return (
    <div className="od-cart-auth-modal-sign-in">
      <div className="od-cart-auth-modal-sign-in__header od-font-reg">
        {CART_AUTH_SIGN_IN_TITLE}
      </div>

      <div className="od-cart-auth-modal-sign-in__form">
        <div className="od-cart-auth-modal-sign-in__title od-font-bold">
          {capitalizeFirstLetter(SIGN_IN.toLowerCase())}
        </div>

        <div className="od-cart-auth-modal-sign-in-field od-font-reg">
          <input
            autoComplete="emailOrPhone"
            className="od-cart-auth-modal-sign-in-field__input od-font-reg"
            name="emailOrPhone"
            placeholder={PHONE_NO_EMAIL_ID}
            value={emailOrPhone}
            onInput={$event => setEmailOrPhone($event.target.value)}
          />
        </div>

        <div className="od-cart-auth-modal-sign-in-field od-cart-auth-modal-sign-in-field--post-icon od-font-reg">
          <input
            autoComplete="password"
            className="od-cart-auth-modal-sign-in-field__input od-font-reg"
            name="password"
            placeholder={PASSWORD}
            type={showUserPassword ? 'text' : 'password'}
            value={password}
            onInput={$event => setPassword($event.target.value)}
          />
          <div
            className={`od-cart-auth-modal-sign-in-field__password-eye ${
              showUserPassword && 'od-cart-auth-modal-sign-in-field__password-eye--visible'
            }`}
            onClick={() => setShowUserPassword(!showUserPassword)}
          >
            <img
              alt={TOGGLE}
              className="od-cart-auth-modal-sign-in-field__password-eye-icon"
              src={OdEyeIcon}
            />
          </div>
        </div>

        <div className="od-cart-auth-modal-sign-in__forgot-password od-font-bold">
          {capitalizeFirstLetter(FORGOT_PASSWORD.toLowerCase())}
        </div>

        <div className="od-cart-auth-modal-sign-in__submit-button od-font-reg">{LOGIN}</div>

        <div className="od-cart-auth-modal-sign-in-delimiter">
          <div className="od-cart-auth-modal-sign-in-delimiter__line" />
          <div className="od-cart-auth-modal-sign-in-delimiter__text od-font-bold">
            {OR.toLowerCase()}
          </div>
          <div className="od-cart-auth-modal-sign-in-delimiter__line" />
        </div>

        <div className="od-cart-auth-modal-sign-in__social-login">
          <div className="od-cart-auth-modal-sign-in__social-login-button od-font-reg">
            <img
              alt="Facebook"
              className="od-cart-auth-modal-sign-in__social-login-icon"
              src={OdFacebookIcon}
            />
            Facebook
          </div>
          <div className="od-cart-auth-modal-sign-in__social-login-button od-font-reg">
            <img
              alt="Google"
              className="od-cart-auth-modal-sign-in__social-login-icon"
              src={OdGoogleIcon}
            />
            Google
          </div>
        </div>

        <div className="od-cart-auth-modal-sign-in-delimiter">
          <div className="od-cart-auth-modal-sign-in-delimiter__line" />
          <div className="od-cart-auth-modal-sign-in-delimiter__text od-font-bold">
            {OR.toLowerCase()}
          </div>
          <div className="od-cart-auth-modal-sign-in-delimiter__line" />
        </div>

        <div className="od-cart-auth-modal-sign-in__sign-up-wrapper od-font-reg">
          <span className="od-cart-auth-modal-sign-in__sign-up-question od-font-reg">
            {NEED_AN_ACCOUNT}?{' '}
          </span>
          <span className="od-cart-auth-modal-sign-in__sign-up od-font-bold">
            {capitalizeFirstLetter(SIGN_UP.toLowerCase())}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CartAuthModalSignIn;
