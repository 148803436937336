import React from 'react';
import './GundamDescription.scss';

const GundamDescription = ({ gundamDescriptionRef, onClick }) => {
  return (
    <div ref={gundamDescriptionRef && gundamDescriptionRef} className="gundam-description">
      <div className="gundam-description-main">
        <div className="gundam-description__info od-font-reg">
          From Mobile Suit Gundam, one of the most iconic Japanese anime series that has remained
          highly popular across generations, comes an eyewear collaboration focusing on the
          Principality of Zeon. The lineup consists of exclusive Zaku Head Case, Spectacles, and
          Sunglasses which are inspired by the Principality of Zeon.
          <p>
            Come experience this special collaboration with your own eyes while remembering the
            people of the Principality of Zeon who have lived through turbulent times.
          </p>
        </div>
        <div className="gundam-description__action od-font-bold" onClick={onClick}>
          <span>View products and collections</span>
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 9L12 15L18 9" stroke="white" strokeLinecap="square" strokeWidth="2" />
          </svg>
        </div>
      </div>
      <img
        alt="main_char-pc"
        className="gundam-description__img"
        src="https://static.lenskart.com/media/owndays/mobile/img/gundum/main_char-pc.png"
      />
    </div>
  );
};

export default GundamDescription;
