import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapComponent = ({ lat, long }) => {
  const [containerStyle, setContainerStyle] = useState({
    width: '100%',
    height: '500px', // Default to desktop height
  });
  const center = {
    lat: lat, // Using the props
    lng: long, // Using the props
  };
  const options = {
    streetViewControl: false,
    zoomControl: true,
    mapTypeControl: false,
    fullscreenControl: false,
  };
  const icon = {
    url: 'https://static.lenskart.com/media/owndays/img/pin.svg',
  };

  useEffect(() => {
    const updateContainerStyle = () => {
      if (window.innerWidth <= 767) {
        setContainerStyle({
          width: '100%',
          height: '240px', // Mobile height
        });
      } else {
        setContainerStyle({
          width: '100%',
          height: '450px', // Desktop height
        });
      }
    };

    // Initial check
    updateContainerStyle();

    // Add resize event listener
    window.addEventListener('resize', updateContainerStyle);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateContainerStyle);
    };
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBH3SwkuwI4c_eJLKM00WeO7BRTuEj8V6A&hl=ja">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17} options={options}>
        <Marker position={center} icon={icon} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
