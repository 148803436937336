import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="keywords" content="Pokémon,Pikachu,Bulbasaur,Squirtle,Charmander,Eevee,Snorlax,Jigglypuff,Mew,collection,glasses,character,OWNDAYS" />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/pokemon">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/pokemon/ogp_1200x630.jpg?1661951802">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://use.typekit.net/mvr0plz.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css">
<link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Dangrek&display=swap" rel="stylesheet">
<link href="https://www.owndays.com/web/css/specials-pokemon.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            Pokémon Frame Collection                    </li>
        
            </ul>
</div>


<div class="l-pokemon">
    <div class="side-text"></div>
    <!-- Main -->
    <section class="main">
        <h1 data-aos="fade-up"><img class="main__logo" src="https://www.owndays.com/images/specials/products/pokemon/logo.svg" alt="Pokémon Frame Collection"></h1>
        <div class="main__slider" data-aos="fade-up" data-aos-delay="200">
            <picture>
                <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/pokemon/main-pc.webp">
                <img class="main__slider-img" src="https://www.owndays.com/images/specials/products/pokemon/main-sp.webp" alt="Pokémon Frame Collection - Main visual">
            </picture>
            <img class="main__slider-text" src="https://www.owndays.com/images/specials/products/pokemon/main-text.svg" alt="Choose your Pokémon Choose your Fit.">
        </div>
        <h3 class="main__title" data-aos="fade-up">Now Available In-store and Online</h3>
    </section>
    <!-- collection -->
    <section class="collection">
        <div class="l-pokemon__container">
            <img class="collection__img" src="https://www.owndays.com/images/specials/products/pokemon/pikachu-back.svg" alt="pikachu silhouette" data-aos="fade-up">
            <h2 class="collection__title" data-aos="fade-up">Pokémon <i class="l-pokemon__monster-ball"></i> Frame Collection</h2>
            <p data-aos="fade-up">An eyewear collection inspired by 8 Pokémon, <br class="u-pc-only">featuring 9 spectacle frames in an assortment of colours and shapes <br class="u-pc-only">that would bring you equal quotient of style and functionality.</p>
            <p data-aos="fade-up">Choose your Pokémon and embark on a chic escapade!</p>
            <div class="collection__vdo" data-aos="fade-up">
                <a data-fancybox href="https://youtu.be/UJ46HLuoNog">
                    <img class="collection__vdo-img" src="https://www.owndays.com/images/specials/products/pokemon/video.webp" alt="Pokémon Frame Collection PV">
                </a>
            </div>
        </div>
    </section>
    <!-- Lineup top -->
    <section class="lineup__top">
        <div class="l-pokemon__container">
            <h2 class="l-pokemon__title" data-aos="fade-up">LINEUP</h2>
            <h3 class="lineup__top-title" data-aos="fade-up" data-aos-delay="100">9 types / S$198</h3>
            <ul class="lineup__top-list">
                <li data-aos="fade-up">
                    <a class="lineup__top-list-inner" href="#pikachu">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-pikachu.svg" alt="Monster ball">
                        <img class="lineup__top-list-character left-space" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-pikachu-1.svg" alt="PIKACHU" style="--w-sp:65%; --w-pc:87%; --w-pc-lg:139px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #FFCC01">PIKACHU</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-1.svg" alt="Type">
                        </div>
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="100">
                    <a class="lineup__top-list-inner" href="#pikachu-snap">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-pikachu-2.svg" alt="Monster ball">
                        <img class="lineup__top-list-character" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-pikachu-2.svg" alt="PIKACHU" style="--w-sp:50%; --w-pc:67%; --w-pc-lg:106px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #E5B255">PIKACHU</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-2.svg" alt="Type">
                        </div>
                        <img class="lineup__top-list-snap" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-snap-1.svg" alt="SNAP LENS">
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="200">
                    <a class="lineup__top-list-inner" href="#bulbasaur">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-bulbasaur.svg" alt="Monster ball">
                        <img class="lineup__top-list-character" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-bulbasaur.svg" alt="BULBASAUR" style="--w-sp:49%; --w-pc:65%; --w-pc-lg:104px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #80B352">BULBASAUR</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-3.svg" alt="Type">
                        </div>
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="300">
                    <a class="lineup__top-list-inner" href="#squirtle">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-squirtle.svg" alt="Monster ball">
                        <img class="lineup__top-list-character" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-squirtle.svg" alt="SQUIRTLE" style="--w-sp:58%; --w-pc:75%; --w-pc-lg:120px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #7BC2CB">SQUIRTLE</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-4.svg" alt="Type">
                        </div>
                        <img class="lineup__top-list-snap" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-snap-2.svg" alt="SNAP LENS">
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="400">
                    <a class="lineup__top-list-inner" href="#charmander">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-charmander.svg" alt="Monster ball">
                        <img class="lineup__top-list-character left-space" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-charmander.svg" alt="CHARMANDER" style="--w-sp:67%; --w-pc:87%; --w-pc-lg:139px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #FE9929">CHARMANDER</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-5.svg" alt="Type">
                        </div>
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                    <a class="lineup__top-list-inner" href="#eevee">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-eevee.svg" alt="Monster ball">
                        <img class="lineup__top-list-character" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-eevee.svg" alt="EEVEE" style="--w-sp:65%; --w-pc:85%; --w-pc-lg:136px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #CB802B">EEVEE</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-6.svg" alt="Type">
                        </div>
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="600">
                    <a class="lineup__top-list-inner" href="#snorlax">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-snorlax.svg" alt="Monster ball">
                        <img class="lineup__top-list-character" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-snorlax.svg" alt="SNORLAX" style="--w-sp:76%; --w-pc:99%; --w-pc-lg:158px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #6B95AC">SNORLAX</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-7.svg" alt="Type">
                        </div>
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="700">
                    <a class="lineup__top-list-inner" href="#jigglypuff">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-jigglypuff.svg" alt="Monster ball">
                        <img class="lineup__top-list-character" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-jigglypuff.svg" alt="JIGGLYPUFF" style="--w-sp:52%; --w-pc:68%; --w-pc-lg:108px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #FA9AAD">JIGGLYPUFF</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-8.svg" alt="Type">
                        </div>
                        <img class="lineup__top-list-snap" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-snap-3.svg" alt="SNAP LENS">
                    </a>
                </li>
                <li data-aos="fade-up" data-aos-delay="800">
                    <a class="lineup__top-list-inner" href="#mew">
                        <img src="https://www.owndays.com/images/specials/products/pokemon/monster-ball-bg-mew.svg" alt="Monster ball">
                        <img class="lineup__top-list-character" src="https://www.owndays.com/images/specials/products/pokemon/lineup-top-mew.svg" alt="MEW" style="--w-sp:47%; --w-pc:61%; --w-pc-lg:97px">
                        <div class="lineup__top-list-detail">
                            <h4 class="lineup__top-list-title" style="color: #BB9EC6">MEW</h4>
                            <img class="lineup__top-list-pokemon-type" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-type-9.svg" alt="Type">
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </section>
    <!-- Line up -->
    <section class="l-pokemon__lineup">
        <ul class="l-pokemon__lineup-list">
            <!-- PIKACHU -->
            <li id="pikachu" class="l-pokemon__lineup-item l-pokemon__lineup-item--pikachu">
                <img src="https://www.owndays.com/images/specials/products/pokemon/PIKACHU_Shadow.png" alt="PIKACHU" class="l-pokemon__lineup-shadow" style="--w: 177px; --w-lg: 447px;">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.025</span>
                                                <strong>PIKACHU</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Pikachu is an Electric-type Pokémon. Pikachu that can generate powerful electricity have cheek sacs that are extra soft and super stretchy.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/PIKACHU_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK025B-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/PIKACHU.svg" alt="PIKACHU" class="l-pokemon__lineup-model-character" style="--w: 172px; --w-lg: 296px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1.webp" alt="PK025B-2S C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Polygonal frame in matte gold with subtle touches of brown to accentuate the overall design</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Temples feature a zigzag, lightning bolt design as a reference to Pikachu’s Electric-type move</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>A light colour palette is chosen for the temple tips to soften the overall look with an emblem in Pikachu’s silhouette featured on the reverse side of the left temple tip</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1_01.jpg" alt="PK025B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1_02.jpg" alt="PK025B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1_03.jpg" alt="PK025B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1_04.jpg" alt="PK025B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1_05.jpg" alt="PK025B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1_06.jpg" alt="PK025B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025B-2A/PK025B-2S-C1_07.jpg" alt="PK025B-2S C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                    <a href="/sg/en/products/PK025B-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                </div>
            </li>
            <!-- PIKACHU SNAP -->
            <li id="pikachu-snap" class="l-pokemon__lineup-item l-pokemon__lineup-item--pikachu-snap">
                <img src="https://www.owndays.com/images/specials/products/pokemon/PIKACHU_SNAP_Shadow.png" alt="PIKACHU" class="l-pokemon__lineup-shadow" style="--w: 177px; --w-lg: 448px;">
                <img src="https://www.owndays.com/images/specials/products/pokemon/SNAP_LENS.png" alt="SNAP LENS" class="l-pokemon__lineup-snap">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.025</span>
                                                <strong>PIKACHU</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Pikachu is an Electric-type Pokémon. Pikachu that can generate powerful electricity have cheek sacs that are extra soft and super stretchy.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/PIKACHU_SNAP_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK025N-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/PIKACHU_SNAP.svg" alt="PIKACHU" class="l-pokemon__lineup-model-character" style="--w: 146px; --w-lg: 273px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1.webp" alt="PK025N-2A C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-4"><span>04</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Polygonal frame in gold with black accents referencing colours symbolic of Pikachu</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Front of the temples features a wavy structure that breaks away from the usual linear conformity</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Temple tips are shaped like a lightning bolt similar to Pikachu’s tail with an emblem in Pikachu’s silhouette featured on the reverse side of the left temple tip</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 04</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/feature-04.webp" alt="FEATURE 04">
                                        <p>Comes with grey tinted SNAP LENS that converts the frame into sunglasses in a split second</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_01.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_02.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_03.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_04.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_05.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_06.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_07.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_08.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_011.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_012.jpg" alt="PK025N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK025N-2A/PK025N-2A-C1_013.jpg" alt="PK025N-2A C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                    <a href="/sg/en/products/PK025N-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                </div>
            </li>
            <!-- BULBASAUR -->
            <li id="bulbasaur" class="l-pokemon__lineup-item l-pokemon__lineup-item--bulbasaur">
                <img src="https://www.owndays.com/images/specials/products/pokemon/BULBASAUR_Shadow.png" alt="BULBASAUR" class="l-pokemon__lineup-shadow" style="--w: 177px; --w-lg: 419px;">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.001</span>
                                                <strong>BULBASAUR</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Bulbasaur is a Grass-and-Poison-type Pokémon. There is a seed on its back. By soaking up the sun’s rays, the seed grows progressively larger.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/BULBASAUR_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK001B-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/BULBASAUR.svg" alt="BULBASAUR" class="l-pokemon__lineup-model-character" style="--w: 160px; --w-lg: 271px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1.webp" alt="PK001B-2A C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Wellington frame featuring elements inspired by Bulbasaur’s Grass-type move, Vine Whip</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Rim comes in a see-through green that will show up in a distinct shade according to the skin tone of the wearer</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Left temple tip features an emblem in Bulbasaur’s silhouette on its reverse side</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1_01.jpg" alt="PK001B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1_02.jpg" alt="PK001B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1_03.jpg" alt="PK001B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1_04.jpg" alt="PK001B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1_05.jpg" alt="PK001B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1_06.jpg" alt="PK001B-2S C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK001B-2A/PK001B-2S-C1_07.jpg" alt="PK001B-2S C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                    <a href="/sg/en/products/PK001B-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                </div>
            </li>
            <!-- SQUIRTLE -->
            <li id="squirtle" class="l-pokemon__lineup-item l-pokemon__lineup-item--squirtle">
                <img src="https://www.owndays.com/images/specials/products/pokemon/SQUIRTLE_Shadow.png" alt="SQUIRTLE" class="l-pokemon__lineup-shadow" style="--w: 177px; --w-lg: 371px;">
                <img src="https://www.owndays.com/images/specials/products/pokemon/SNAP_LENS.png" alt="SNAP LENS" class="l-pokemon__lineup-snap">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.007</span>
                                                <strong>SQUIRTLE</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Squirtle is a Water-type Pokémon. The shell’s rounded shape and the grooves on its surface help minimise resistance in water, enabling this Pokémon to swim at high speeds.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/SQUIRTLE_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK007N-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/SQUIRTLE.svg" alt="SQUIRTLE" class="l-pokemon__lineup-model-character" style="--w: 140px; --w-lg: 268px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1.webp" alt="PK007N-2A C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Polygonal frame in blue and gunmetal with 2 enamel turtle-shell tessellations on each temple</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Temple tips are designed to resemble Squirtle’s tail with an emblem in its silhouette featured on the reverse side of the left temple tip</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Comes with blue tinted SNAP LENS that converts the frame into sunglasses in a split second</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_01.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_02.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_03.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_04.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_05.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_06.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_07.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_08.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_011.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_012.jpg" alt="PK007N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK007N-2A/PK007N-2A-C1_013.jpg" alt="PK007N-2A C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                    <a href="/sg/en/products/PK007N-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                </div>
            </li>
            <!-- CHARMANDER -->
            <li id="charmander" class="l-pokemon__lineup-item l-pokemon__lineup-item--charmander">
                <img src="https://www.owndays.com/images/specials/products/pokemon/CHARMANDER_Shadow.png" alt="CHARMANDER" class="l-pokemon__lineup-shadow" style="--w: 161px; --w-lg: 365px;">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.004</span>
                                                <strong>CHARMANDER</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Charmander is a Fire-type Pokémon. <br>It has a preference for hot things. When it rains, steam is said to spout from the tip of its tail.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/CHARMANDER_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK004B-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/CHARMANDER.svg" alt="CHARMANDER" class="l-pokemon__lineup-model-character" style="--w: 140px; --w-lg: 260px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1.webp" alt="PK004B-2S C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Gold-colour Boston frame with a flame pattern engraved on the end pieces</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Rim features a combo finishing with the top half in shiny gold while the bottom half comes in a matte treatment</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Left temple tip features an emblem in Charmander’s silhouette on its reverse side</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1_01.jpg" alt="PK004B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1_02.jpg" alt="PK004B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1_03.jpg" alt="PK004B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1_04.jpg" alt="PK004B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1_05.jpg" alt="PK004B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1_06.jpg" alt="PK004B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK004B-2A/PK004B-2S-C1_07.jpg" alt="PK004B-2A C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                    <a href="/sg/en/products/PK004B-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                </div>
            </li>
            <!-- EEVEE -->
            <li id="eevee" class="l-pokemon__lineup-item l-pokemon__lineup-item--eevee">
                <img src="https://www.owndays.com/images/specials/products/pokemon/EEVEE_Shadow.png" alt="EEVEE" class="l-pokemon__lineup-shadow" style="--w: 191px; --w-lg: 433px;">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.133</span>
                                                <strong>EEVEE</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Eevee is a Normal-type Pokémon.<br>It has the ability to alter the composition of its body to suit its surrounding environment.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/EEVEE_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK133B-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/EEVEE.svg" alt="EEVEE" class="l-pokemon__lineup-model-character" style="--w: 148px; --w-lg: 284px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1.webp" alt="PK133B-2S C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Dual-material frame in Boston style made of clear plastic in beige colour with metal components in matte gold</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Features design elements drawing reference from Eevee’s ears, the fluffy ruff around its neck and its 2-toned tail</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Left temple tip features an emblem in Eevee’s silhouette on its reverse side</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1_01.jpg" alt="PK133B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1_02.jpg" alt="PK133B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1_03.jpg" alt="PK133B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1_04.jpg" alt="PK133B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1_05.jpg" alt="PK133B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1_06.jpg" alt="PK133B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK133B-2A/PK133B-2S-C1_07.jpg" alt="PK133B-2A C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                    <a href="/sg/en/products/PK133B-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                </div>
            </li>
            <!-- SNORLAX -->
            <li id="snorlax" class="l-pokemon__lineup-item l-pokemon__lineup-item--snorlax">
                <img src="https://www.owndays.com/images/specials/products/pokemon/SNORLAX_Shadow.png" alt="SNORLAX" class="l-pokemon__lineup-shadow" style="--w: 224px; --w-lg: 468px;">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.143</span>
                                                <strong>SNORLAX</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Snorlax is a Normal-type Pokémon. It is not satisfied unless it eats over 880 pounds of food every day. When it is done eating, it goes promptly to sleep.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/SNORLAX_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK143B-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/SNORLAX.svg" alt="SNORLAX" class="l-pokemon__lineup-model-character" style="--w: 198px; --w-lg: 354px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1.webp" alt="PK143B-2S C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Oversized, transparent frame with metal wire cores decorated with engravings on both sides of the temples</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Features a bold structure as a reference to Snorlax’s huge body size</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Temple tips are designed to resemble the shape of Snorlax’s head with an emblem in its silhouette featured on the reverse side of the left temple tip</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1_01.jpg" alt="PK143B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1_02.jpg" alt="PK143B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1_03.jpg" alt="PK143B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1_04.jpg" alt="PK143B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1_05.jpg" alt="PK143B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1_06.jpg" alt="PK143B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK143B-2A/PK143B-2S-C1_07.jpg" alt="PK143B-2A C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                                        <a href="/sg/en/products/PK143B-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                                    </div>
            </li>
            <!-- JIGGLYPUFF -->
            <li id="jigglypuff" class="l-pokemon__lineup-item l-pokemon__lineup-item--jigglypuff">
                <img src="https://www.owndays.com/images/specials/products/pokemon/JIGGLYPUFF_Shadow.png" alt="JIGGLYPUFF" class="l-pokemon__lineup-shadow" style="--w: 158px; --w-lg: 339px;">
                <img src="https://www.owndays.com/images/specials/products/pokemon/SNAP_LENS.png" alt="SNAP LENS" class="l-pokemon__lineup-snap">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.039</span>
                                                <strong>JIGGLYPUFF</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Jigglypuff is a Normal-and-Fairy-type Pokémon. Recordings of Jigglypuff’s strange lullabies can be purchased from department stores. These CDs can be found near the bedding area.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/JIGGLYPUFF_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK039N-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/JIGGLYPUFF.svg" alt="JIGGLYPUFF" class="l-pokemon__lineup-model-character" style="--w: 107px; --w-lg: 228px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1.webp" alt="PK039N-2A C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-4"><span>04</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Round frame in Jigglypuff’s iconic pink body colour with accents in rose gold</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Jigglypuff’s tuft of fur on its forehead has inspired the design of the temple tips and the spiral features found on the rim and the temples</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Left temple tip features an emblem in Jigglypuff’s silhouette on its reverse side</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 04</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/feature-04.webp" alt="FEATURE 04">
                                        <p>Comes with pink tinted SNAP LENS that converts the frame into sunglasses in a split second</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_01.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_02.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_03.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_04.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_05.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_06.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_07.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_08.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_011.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_012.jpg" alt="PK039N-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK039N-2A/PK039N-2A-C1_013.jpg" alt="PK039N-2A C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                    <a href="/sg/en/products/PK039N-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                </div>
            </li>
            <!-- MEW -->
            <li id="mew" class="l-pokemon__lineup-item l-pokemon__lineup-item--mew">
                <img src="https://www.owndays.com/images/specials/products/pokemon/MEW_Shadow.png" alt="MEW" class="l-pokemon__lineup-shadow" style="--w: 132px; --w-lg: 312px;">
                <div class="l-pokemon__lineup-inner">
                    <h2 class="l-pokemon__lineup-title">
                        <span>No.151</span>
                                                <strong>MEW</strong>
                        <small>Model</small>
                    </h2>
                    <p class="l-pokemon__lineup-des">Mew is a Psychic-type Pokémon. It is capable of making itself invisible at will, so it entirely avoids notice even if it approaches people.</p>
                    <div class="l-pokemon__lineup-grid">
                        <div class="l-pokemon__lineup-model">
                            <img src="https://www.owndays.com/images/specials/products/pokemon/MEW_Model.webp" alt="Wearing Image" class="l-pokemon__lineup-model-img">
                            <p>PK151B-2A C1 / S$198</p>
                            <img src="https://www.owndays.com/images/specials/products/pokemon/MEW.svg" alt="MEW" class="l-pokemon__lineup-model-character" style="--w: 155px; --w-lg: 271px;">
                        </div>
                        <div class="l-pokemon__lineup-frame">
                            <div class="l-pokemon__lineup-frame-img">
                                <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1.webp" alt="PK151B-2S C1">
                                <div class="l-pokemon__lineup-frame-nav">
                                    <div class="l-pokemon__lineup-frame-nav-item nav-1"><span>01</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-2"><span>02</span></div>
                                    <div class="l-pokemon__lineup-frame-nav-item nav-3"><span>03</span></div>
                                </div>
                            </div>
                            <div class="l-pokemon__lineup-frame-for">
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 01</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/feature-01.webp" alt="FEATURE 01">
                                        <p>Round frame in a shiny metallic pink tone similar to the colour of Mew</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 02</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/feature-02.webp" alt="FEATURE 02">
                                        <p>Features a flamboyant design in curvy form as a connotation to Mew’s long, thin tail</p>
                                    </div>
                                </div>
                                <div class="l-pokemon__lineup-frame-item">
                                    <div class="l-pokemon__lineup-frame-details">
                                        <h5>FEATURE 03</h5>
                                        <img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/feature-03.webp" alt="FEATURE 03">
                                        <p>Left temple tip features an emblem in Mew’s silhouette on its reverse side</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-pokemon__lineup-slider">
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1_01.jpg" alt="PK151B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1_02.jpg" alt="PK151B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1_03.jpg" alt="PK151B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1_04.jpg" alt="PK151B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1_05.jpg" alt="PK151B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1_06.jpg" alt="PK151B-2A C1"></div>
                    <div class="l-pokemon__lineup-slider-item"><img src="https://www.owndays.com/images/specials/products/pokemon/products/PK151B-2A/PK151B-2S-C1_07.jpg" alt="PK151B-2A C1"></div>
                </div>
                <div class="l-pokemon__lineup-inner">
                                        <a href="/sg/en/products/PK151B-2A" class="l-pokemon__lineup-btn">MORE DETAILS</a>
                                    </div>
            </li>

        </ul>
    </section>
    <!-- Merchandise -->
    <section class="merchandise">
        <div class="l-pokemon__container">
            <img class="merchandise__pokemon-logo" src="https://www.owndays.com/images/specials/products/pokemon/pokemon-logo.svg" alt="Pokémon" data-aos="fade-up">
            <h2 class="l-pokemon__title" data-aos="fade-up" data-aos-delay="100">Merchandise Set</h2>
            <div class="merchandise__label" data-aos="fade-up">
                <img class="merchandise__label-img" src="https://www.owndays.com/images/specials/products/pokemon/merchandise-pikachu-black.svg" alt="Pikachu">
                <p>Available with every purchase of Pokémon frame</p>
            </div>
            <div class="merchandise__main">
                <picture data-aos="fade-up">
                    <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/pokemon/merchandise-set-pc.webp">
                    <img class="merchandise__main-img" src="https://www.owndays.com/images/specials/products/pokemon/merchandise-set-sp.webp" alt="Pokémon Merchandise Set">
                </picture>
                <h5 class="merchandise__main-text merchandise__main-text--1" data-aos="fade-up" data-aos-delay="100"><span>Reusable Bag</span></h5>
                <h5 class="merchandise__main-text merchandise__main-text--2" data-aos="fade-up" data-aos-delay="200"><span>Crossbody</span><br><span>Spectacle Pouch</span></h5>
                <h5 class="merchandise__main-text merchandise__main-text--3" data-aos="fade-up"><span>Spectacle Cloth</span></h5>
                <img class="merchandise__main-character merchandise__main-character--1" src="https://www.owndays.com/images/specials/products/pokemon/merchandise-pikachu-1.svg" alt="Pikachu" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="800">
                <img class="merchandise__main-character merchandise__main-character--2" src="https://www.owndays.com/images/specials/products/pokemon/merchandise-pikachu-2.svg" alt="Pikachu" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="800">
                <!-- <div class="circle"></div> -->
            </div>
            <div class="merchandise__button-group" style="--cl:#ffe501" data-aos="fade-up">
                <a href="/sg/en/products?productLines[]=46" class="l-pokemon__button" style="--mw: 377px;">SEE ALL ITEMS</a>
                <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-pokemon__button l-pokemon__button--shop" style="--mw: 424px;">SEARCH FOR NEARBY STORES</a>
            </div>
        </div>
    </section>
    <!-- Available shop -->
        <section class="l-pokemon__shop">
        <div class="l-pokemon__container">
            <h2 class="l-pokemon__title" data-aos="fade-up">Available shop</h2>
            <ul class="l-pokemon__shop-list l-content--inner c-list-decoration c-list-decoration--dot">
                <li><a href="/sg/en/shops/905">OWNDAYS Plaza Singapura</a></li>
                <li><a href="/sg/en/shops/908">OWNDAYS Bedok Mall</a></li>
                <li><a href="/sg/en/shops/909">OWNDAYS nex</a></li>
                <li><a href="/sg/en/shops/924">OWNDAYS Suntec City</a></li>
                <li><a href="/sg/en/shops/928">OWNDAYS Jurong Point</a></li>
                <li><a href="/sg/en/shops/931">OWNDAYS Vivo City</a></li>
                <li><a href="/sg/en/shops/933">OWNDAYS Waterway Point</a></li>
                <li><a href="/sg/en/shops/968">OWNDAYS Northpoint City</a></li>
                <li><a href="/sg/en/shops/1007">OWNDAYS MBS Premium Concept Store</a></li>
            </ul>
        </div>
    </section>
        <!-- Share -->
    <section class="share">
        <div class="l-pokemon__container">
            <h3 class="l-pokemon__title" data-aos="fade-up">SHARE</h3>
            <ul class="share__social" data-aos="fade-up" data-aos-delay="70">
                <li class="share__social-item share__social-item--fb">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/pokemon" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">facebook</a>
                </li>
                <li class="share__social-item share__social-item--tw">
                    <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/pokemon" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">twitter</a>
                </li>
                <li class="share__social-item share__social-item--line">
                    <a href="https://social-plugins.line.me/lineit/share?url=https://www.owndays.com/sg/en/news/pokemon" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">line</a>
                </li>
            </ul>
        </div>
    </section>
    <!-- Footer -->
    <section class="footer">
        <div class="l-pokemon__container">
            <p>©Nintendo, Creatures, GAME FREAK, <br class="u-sp-only">
                TV Tokyo, ShoPro, JR Kikaku. ©Pokémon.<br>
                TM and Ⓡ are trademarks of Nintendo. <br class="u-sp-only">
                For sale in SG, TW, TH, PH, VN, HK & KH only.
            </p>
        </div>
    </section>
</div>

        </main>
            </div>

    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js"></script>
<script src="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js"></script>
<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/scrollReveal.js/4.0.9/scrollreveal.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js"></script>
<script src="https://www.owndays.com/web/min/specials-pokemon.min.js" defer></script>
 
</body>

</html>`;

const PokemonCollectionsHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Explore an eyewear collection inspired by 8 Pokémon. Choose from 9 diverse frames in various colors and shapes, blending style and functionality seamlessly."
        title="Pokémon Frame Collections | OWNDAYS Singapore Online Store"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default PokemonCollectionsHTML;
