import React from 'react';
import './CartAuthModal.scss';
import { useSelector } from 'react-redux';
import CartAuthModalSignIn from './CartAuthModalSignIn/CartAuthModalSignIn';
import CartAuthModalSignUp from './CartAuthModalSignUp/CartAuthModalSignUp';
import OdPointWhiteIcon from '../../../../static/assets/svg/od-point-white-icon.svg';
import OdGiftWhiteIcon from '../../../../static/assets/svg/od-gift-white-icon.svg';
import OdSaleWhiteIcon from '../../../../static/assets/svg/od-sale-white-icon.svg';

const CartAuthModal = ({ openTab, handleModalClose }) => {
  const dataLocale = useSelector(state => state.locale);

  const {
    WELCOME_TO,
    EARN_MEMBERSHIP_POINTS,
    GET_EXCLUSIVE_OFFERS,
    EARLY_ACCESS_SALES,
    CART_AUTH_TERMS,
  } = dataLocale;

  return (
    <div className="od-cart-auth-modal">
      <div className="od-cart-auth-modal__sidebar">
        <div className="od-cart-auth-modal__subtitle od-font-reg">{WELCOME_TO}</div>
        <div className="od-cart-auth-modal__title od-font-bold">OWNDAYS</div>
        <div className="od-cart-auth-modal__features">
          <div className="od-cart-auth-modal__feature">
            <img
              alt={EARN_MEMBERSHIP_POINTS}
              className="od-cart-auth-modal__feature-icon"
              src={OdPointWhiteIcon}
            />
            <div className="od-cart-auth-modal__feature-text od-font-reg">
              {EARN_MEMBERSHIP_POINTS}
            </div>
          </div>
          <div className="od-cart-auth-modal__feature">
            <img
              alt={GET_EXCLUSIVE_OFFERS}
              className="od-cart-auth-modal__feature-icon"
              src={OdGiftWhiteIcon}
            />
            <div className="od-cart-auth-modal__feature-text od-font-reg">
              {GET_EXCLUSIVE_OFFERS}
            </div>
          </div>
          <div className="od-cart-auth-modal__feature">
            <img
              alt={EARLY_ACCESS_SALES}
              className="od-cart-auth-modal__feature-icon"
              src={OdSaleWhiteIcon}
            />
            <div className="od-cart-auth-modal__feature-text od-font-reg">{EARLY_ACCESS_SALES}</div>
          </div>
        </div>
        <div className="od-cart-auth-modal__terms od-font-reg">{CART_AUTH_TERMS}</div>
      </div>

      <div className="od-cart-auth-modal__content">
        {/* <CartAuthModalSignIn /> */}
        {/* <CartAuthModalSignUp /> */}
        <CartAuthModalSignUp isFinishSignUp />
      </div>
    </div>
  );
};

export default CartAuthModal;
