import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
                                                    <meta property="og:title" content="Lens Replacement | OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                                                    <meta name="description" content="In OWNDAYS, we offer lens replacement service even for spectacles bought at other shops. Prices start from $98 for standard high index aspheric lenses. You can opt for lens upgrades at simple price too. Our optional lenses include progressive lenses, PC lenses, colour lenses, transitions lenses and polarised lenses.">
                                            <meta name="keywords" content="Lens Replacement,glasses,spectacles,optical shop,eyeglasses,OWNDAYS">
                                                                                                        <meta property="og:description" content="In OWNDAYS, we offer lens replacement service even for spectacles bought at other shops. Prices start from $98 for standard high index aspheric lenses. You can opt for lens upgrades at simple price too. Our optional lenses include progressive lenses, PC lenses, colour lenses, transitions lenses and polarised lenses.">
                                            <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/services/lens-replacement">
                <meta property="og:image" content="https://www.owndays.com/images/services/lens-replacement/ogp_1200x630.jpg?1553871975">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="preload" as="style" href="https://www.owndays.com/web/css/services-top.css">
<link href="https://www.owndays.com/web/css/services-top.css" rel="stylesheet">
<link rel="preload" as="style" href="https://www.owndays.com/web/css/services-lens-replacement.css?v=1698736546">
<link href="https://www.owndays.com/web/css/services-lens-replacement.css?v=1698736546" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">    Lens Replacement | OWNDAYS ONLINE STORE - OPTICAL SHOP
</title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/services">Services</a>
                                                                                </li>
                <li>
                            Lens Replacement                    </li>
        
            </ul>
</div>


<div class="l-services-lens-replacement">
    <div class="l-services__submenu">
        <ul class="l-container">
            <li><a href="/sg/en/services">Services</a></li>
                        <li><a href="/sg/en/services/price">Retail Price</a></li>
                        <li><a href="/sg/en/services/lens">Lens</a></li>
            <li class="active"><a href="/sg/en/services/lens-replacement">Lens Replacement</a></li>
            <li><a href="/sg/en/services/warranty">Customer Guarantees</a></li>
                        <li><a href="/sg/en/services/staff">Staff</a></li>
        </ul>
    </div>

    <!-- MAIN AREA -->
    <section class="p-main">
        <div class="p-main__bg">
            <picture>
                <source media="(min-width:768px)" srcset="https://www.owndays.com/images/services/lens-replacement/main-1536x480.webp">
                <source srcset="https://www.owndays.com/images/services/lens-replacement/main-1534x1023.webp">
                <img src="https://www.owndays.com/images/services/lens-replacement/main-768x500.webp" alt="">
            </picture>
        </div>
        <div class="p-main__title">
            <h1 class="p-main__title-default">Lens Replacement</h1>
            <p class="p-main__title-description">Extend the lifespan of spectacles that you cherish with regular lens replacement</p>
        </div>
    </section>

    <!-- CONTENTS AREA -->
    <div class="l-container">
        
        <section class="l-base-price l-content--inner">
            <h2>Basic Charge</h2>
            <div class="l-base-price__content">
                <table class="l-set">
                    <tr>
                        <td class="l-set__services">
                            <picture>
                                <img src="https://www.owndays.com/images/services/lens-replacement/eye-test.svg" alt="eye-test">
                            </picture>
                            <p>Eyesight<br>Measurement</p>
                        </td>
                        <td class="l-set__services">
                            <picture>
                                <img src="https://www.owndays.com/images/services/lens-replacement/lens-edit.svg" alt="lens-edit">
                            </picture>
                            <p>Lens<br>Processing</p>
                        </td>
                        <td class="l-set__services">
                            <picture>
                                <img src="https://www.owndays.com/images/services/lens-replacement/lens.svg" alt="lens">
                            </picture>
                            <p>Lens</p>
                        </td>
                        <td class="l-set__services -last">
                            <picture>
                                <img src="https://www.owndays.com/images/services/lens-replacement/cleaning.svg" alt="cleaning">
                            </picture>
                            <p>Frame<br>Cleaning</p>
                        </td>
                        <td class="l-set__services -price u-pc-only">
                            <p>S$98<small></small></p>
                        </td>
                    </tr>
                </table>
                <div class="l-set__services-price-sp u-sp-only">
                    <p>S$98 <small></small></p>
                </div>
                <p>Let us help you extend the lifespan of spectacles that you cherish by replacing the lenses. Simply bring the spectacles that you can no longer use due to changes in your prescription or damage to the lenses to any OWNDAYS store near you. We are happy to replace the lenses of spectacles purchased from other shops too.</p>
                <p class="c-attention"><i>Price includes eye test, frame fitting, lens processing and frame cleaning.</i></p>
                <p class="c-attention"><i>Service may not be available for some spectacles due to constraints in frame condition and shape.</i></p>

                            </div>
        </section>

        
        <section class="l-lens-list l-content--inner">
            <h2>Option Lenses</h2>
            <ul>
                <li class="l-lens-list-inner">
                    <div class="l-lens-name">
                        <h3>Lens High Index Aspheric</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                            <p class="c-tag-price au-en--none">+S$0</p>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67, 1.74</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Features</th>
                            <td>
                                <ul class="">
                                    <li>
                                        <p>99% UV Protection</p>
                                    </li>
                                    <li>
                                        <p>Anti-Reflection</p>
                                    </li>
                                    <li>
                                        <p>Scratch Resistant</p>
                                    </li>
                                    <li>
                                        <p>Dust-Repellent</p>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </li>

                
                <li class="l-lens-list-inner progressive">
                    <div class="l-lens-name">
                        <h3>Enhanced Progressive Lenses</h3>
                        <div class="l-lens-name__price">
                                                        <p class="c-tag-price">+ S$100</p>
                                                    </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67</p>
                            </td>
                        </tr>
                    </table>
                                    </li>

                <li class="l-lens-list-inner progressive vn-none kh--none zh-tw--none">
                    <div class="l-lens-name">
                        <h3>Prestige Progressive</h3>
                        <div class="l-lens-name__price">
                                                        <p class="c-tag-price">+ S$200</p>
                                                        
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67, ^Upgrade to 1.74 lenses at additional S$100</p>
                            </td>
                        </tr>
                    </table>
                                    </li>

                
                                <li class="l-lens-list-inner progressive">
                    <div class="l-lens-name">
                        <h3>Prestige Gold</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription th-none"><span>With Prescription</span></p>
                            <p class="c-tag-price">+ S$400</p>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67, ^Upgrade to 1.74 lenses at additional S$100</p>
                            </td>
                        </tr>
                    </table>
                </li>
                
                <li class="l-lens-list-inner pc">
                    <div class="l-lens-name">
                        <h3>Blue Light Lenses</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                                                        <p class="c-tag-price">+ S$100</p>
                                                    </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Features</th>
                            <td>
                                <p>Blue Light Reduction</p>
                            </td>
                        </tr>
                    </table>
                </li>

                
                
                                <li class="l-lens-list-inner pc420">
                    <div class="l-lens-name">
                        <h3>PC420</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                            <p class="c-tag-price">+ S$100</p>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67, 1.74</p>
                            </td>
                        </tr>
                    </table>
                </li>
                
                <li class="l-lens-list-inner transitions au-none hk-none sg-none">
                    <div class="l-lens-name">
                        <h3>Transitions Lenses</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                                                        <p class="c-tag-price">+ S$100</p>
                                                    </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Features</th>
                            <td>
                                <p>Lens colour changes according to the amount of UV</p>
                            </td>
                        </tr>
                    </table>
                                        <p class="u-text-comment u-pt__10"><i>Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.</i></p>
                                    </li>

                                <li class="l-lens-list-inner transitions transitions-light">
                    <div class="l-lens-name">
                        <h3>Transitions® Light Intelligent Lenses</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                                                        <p class="c-tag-price au-en--none">+ S$200</p>
                                                    </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Features</th>
                            <td>
                                <p>Lens colour changes according to the amount of UV</p>
                            </td>
                        </tr>
                    </table>
                    <p class="u-text-comment u-pt__10"><i>Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.</i></p>
                </li>
                
                                <li class="l-lens-list-inner transitions transitions-generation">
                    <div class="l-lens-name">
                        <h3>Transitions® XTRActive® New Generation</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                            <p class="c-tag-price au-en--none">+ S$200</p>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Features</th>
                            <td>
                                <p>Lens colour changes according to the amount of UV</p>
                            </td>
                        </tr>
                    </table>
                    <p class="u-text-comment u-pt__10"><i>Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.</i></p>
                </li>
                <li class="l-lens-list-inner transitions transitions-polarized">
                    <div class="l-lens-name">
                        <h3>Transitions® XTRActive® Polarized™</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                            <p class="c-tag-price au-en--none">+ S$300</p>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Features</th>
                            <td>
                                <p>Lens colour changes according to the amount of UV</p>
                            </td>
                        </tr>
                    </table>
                    <p class="u-text-comment u-pt__10"><i>Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.</i></p>
                </li>
                
                
                <li class="l-lens-list-inner polarised ph-en--none my-none vn-none id-none kh--none">
                    <div class="l-lens-name">
                        <h3>Polarised Lenses</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                                                        <p class="c-tag-price">+ S$100</p>
                                                    </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Features</th>
                            <td>
                                <p>UV Protection / Anti-Reflection</p>
                            </td>
                        </tr>
                                            </table>
                </li>

                <li class="l-lens-list-inner color">
                    <div class="l-lens-name">
                        <h3>Colour Lenses</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                                                        <p class="c-tag-price au-en--none">+ S$100 <small></small></p>
                                                    </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Color</th>
                            <td>
                                <p>Grey / Brown / Green / Blue</p>
                            </td>
                        </tr>
                    </table>
                </li>

                
                
                <li class="l-lens-list-inner antifog au-none vn-none my-none au-en--none kh--none hk-none ph-none">
                    <div class="l-lens-name">
                        <h3>Anti-Fog Lenses</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                                                        <p class="c-tag-price">+ S$100</p>
                                                    </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67</p>
                            </td>
                        </tr>
                    </table>
                </li>
                                <li class="l-lens-list-inner myokid">
                    <div class="l-lens-name">
                        <h3>Myopia Control Lenses</h3>
                        <div class="l-lens-name__price">
                            <p class="c-tag-prescription"><span>With Prescription</span></p>
                            <p class="c-tag-no-prescription"><span>Without Prescription</span></p>
                            <p class="c-tag-price">+ S$100</p>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>Refractive Index<a class="p-details-view" data-toggle="tooltip" data-placement="bottom" data-html="true" title="<p>The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.<br>Do note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.</p>"><img src="https://www.owndays.com/images/common/help-1.png" alt=""></a></th>
                            <td>
                                <p>1.60, 1.67</p>
                            </td>
                        </tr>
                    </table>
                </li>
                
                            </ul>
            <div class="l-base-price__content">
                <ul class="p-attention-list">
                    <li>
                        <p>Prices shown are for the replacement of a pair of lenses.</p>
                    </li>
                    <li>
                        <p>OWNDAYS reserves the right to change the prices without prior notice.</p>
                    </li>
                    <li>
                        <p>If special processing is required (such as for rimless frames), an additional cost may apply.</p>
                    </li>
                    <li class="kh--none th-none">
                        <p>Lens replacement service cannot be provided for some frames due to the condition and shape.</p>
                    </li>
                </ul>
                <div class="c-btn c-btn--primary c-btn--primary-center">
                    <a href="/sg/en/services/lens">
                        Click here for Lenses details                    </a>
                </div>
            </div>
                    </section>

        
                <section class="l-steps l-content--inner">
            <h2>Lens Replacement Steps</h2>
            <ul>
                <li class="l-steps-inner -step1">
                    <h3>
                        <span>STEP1</span> Bring your glasses to the nearest OWNDAYS shop                    </h3>
                    <p>Bring your glasses to any OWNDAYS shop. The staff would first assess the condition your glasses and recommend you the most suitable solution which may include replacing the frame, cleaning the lenses or adjusting the fitting.</p>
                </li>
                <li class="l-steps-inner -step2">
                    <h3>
                        <span>STEP2</span> Select Types of Lenses                    </h3>
                    <p>OWNDAYS offers complimentary eye test. Besides the standard lenses, you can choose to upgrade to PC lenses, color lenses or other option lenses.<br><i>Processing time of 7 working days is required for option lenses and lenses of certain prescriptions.</i><br><i>Under Not available in online store.</i></p>
                                    </li>
                <li class="l-steps-inner -step3">
                    <h3><span>STEP3</span> Collection</h3>
                    <p>You can collect your glasses in as little as 20 minutes after payment is made. You will also enjoy 1-year warranty on lens visual performance under the OWNDAYS Customer Guarantees programme.</p>
                    <p><a href="/sg/en/services/warranty">Click here for Customer Guarantees </a></p>
                </li>
            </ul>
                    </section>

        
        
        
        <section class="l-qa l-content--inner">
            <h2>Lens Replacement Q&A</h2>
            <ul class="l-accordion">
                                <li class="l-accordion__item">
                    <div class="l-accordion__item-q">
                        <h3> Can you replace the lenses of the glasses from another shop?</h3>
                        <span class="c-open-navigate">open</span>
                    </div>
                    <div class="l-accordion__item-a">
                        <p>Yes, we can replace them. However, we are unable to do replacement for some glasses due to the shape and parts as well as the condition of the frame. please let our staff see the frame first.</p>
                    </div>
                </li>
                <li class="l-accordion__item">
                    <div class="l-accordion__item-q">
                        <h3>Can you make lenses with the same prescription as the ones I’m currently using?</h3>
                        <span class="c-open-navigate">open</span>
                    </div>
                    <div class="l-accordion__item-a">
                        <p>Yes, we can. Please bring your glasses or any record stating your strength to any of our shops.                                                    </p>
                    </div>
                </li>
                <li class="l-accordion__item">
                    <div class="l-accordion__item-q">
                        <h3>What kind of lenses do you use?</h3>
                        <span class="c-open-navigate">open</span>
                    </div>
                    <div class="l-accordion__item-a">
                        <p>All OWNDAYS lenses are produced by major Japanese manufacturers and have UV protection and anti-reflective coating.</p>
                    </div>
                </li>
                <li class="l-accordion__item">
                    <div class="l-accordion__item-q">
                        <h3>Can you replace the lenses of rimless frames?</h3>
                        <span class="c-open-navigate">open</span>
                    </div>
                    <div class="l-accordion__item-a">
                        <p>Yes, we can.However, as a rimless frame requires precision edging, it might take a few days to be processed.                        </p>
                    </div>
                </li>
                <li class="l-accordion__item">
                    <div class="l-accordion__item-q">
                        <h3>How long does it take to complete my glasses?</h3>
                        <span class="c-open-navigate">open</span>
                    </div>
                    <div class="l-accordion__item-a">
                        <p>Your glasses can be ready in as little as 20 minutes when we have the lenses in stock at the shop. For some lenses such as color lenses and progressive lenses, it takes about 2 weeks since they're custom-made.<span class="u-text-comment u-db"><i>Processing time of 7 working days is required for option lenses and lenses of certain prescriptions.</i></span></p>
                    </div>
                </li>
                                                <li class="l-accordion__item">
                    <div class="l-accordion__item-q">
                        <h3>I don't think there is any charge for special processing for rimless frames?</h3>
                        <span class="c-open-navigate">open</span>
                    </div>
                    <div class="l-accordion__item-a">
                        <p>If special processing is required (such as for rimless frames), an additional cost may apply.</p>
                    </div>
                </li>
                            </ul>
        </section>

        
        <div class="l-services__submenu-sp l-content--inner">
            <ul>
                <li><a href="/sg/en/services">Services</a></li>
                <li><a href="/sg/en/services/lens">Lens</a></li>
                <li><a href="/sg/en/services/lens-replacement">Lens Replacement</a></li>
                <li><a href="/sg/en/services/warranty">Customer Guarantees</a></li>
                <li><a href="/sg/en/services/staff">Staff</a></li>
            </ul>
        </div>
    </div>
</div>

        </main>
            </div>

 
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://www.owndays.com/web/js/jp/common.js" defer></script>
<script src="https://www.owndays.com/web/min/services-lens-replacement.min.js" defer></script>
 
</body>

</html>`;

const LensReplacementHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Get lens replacements even for specs bought elsewhere at OWNDAYS. Choose from a variety of lens options including progressive, PC, and polarized lenses."
        title="Lens Replacement | OWNDAYS Online Optical Store"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default LensReplacementHTML;
