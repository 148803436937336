import React from 'react';

const Merchandise = ({ baseImgUrl, isSP }) => {
  const merchandiseSlides = [
    {
      imgPc: `${baseImgUrl}ems-slider-1.webp`,
      imgSp: `${baseImgUrl}ems-slider-1-sp.webp`,
      alt: 'Original Case & Cleaning Case',
    },
    {
      imgPc: `${baseImgUrl}ems-slider-2.webp`,
      imgSp: `${baseImgUrl}ems-slider-2-sp.webp`,
      alt: 'Original Case & Cleaning Cloth',
    },
  ];

  const merchandiseOtherSlides = [
    { src: `${baseImgUrl}Exclusive-Merchandise-Set/other_1.webp`, alt: 'Harry Potter × OWNDAYS Original Glasses Case' },
    { src: `${baseImgUrl}Exclusive-Merchandise-Set/other_3.webp`, alt: 'Harry Potter × OWNDAYS Original Glasses Case' },
    { src: `${baseImgUrl}Exclusive-Merchandise-Set/other_5.webp`, alt: 'Harry Potter × OWNDAYS Original Glasses Cloth' },
    { src: `${baseImgUrl}Exclusive-Merchandise-Set/other_6.webp`, alt: 'Harry Potter × OWNDAYS Original Glasses Cloth' },
    { src: `${baseImgUrl}Exclusive-Merchandise-Set/other_7.webp`, alt: 'Harry Potter × OWNDAYS Original Glasses Cloth' },
  ];

  const merchandiseTermsConditions = [
    'Not valid in conjunction with other discounts, promotions and/or privileges',
    'No cancellation, refund, exchange or change of order details once an order is placed',
    'Delivery within Singapore only',
    'Upgrade to optional lenses is available at additional charges',
  ];
  return (
    <section className="merchandise">
      <div className="l-hp__container">
        <h2
          className="l-hp__title l-hp__title--sm l-hp__title--sp text-effect"
          data-aos="fade-up"
        >
          <span>
            Original Case <br/>& Cleaning Cloth Set
          </span>
        </h2>
      </div>
      <div className="merchandise__main" data-aos>
        <div className="merchandise__main-slider swiper">
          <div className="swiper-wrapper">
            {merchandiseSlides.map((slide, index) => (
              <div className="swiper-slide" key={index}>
                <picture>
                  {!isSP && <source
                    media="(min-width:768px)"
                    srcSet={slide.imgPc}
                    type="image/webp"
                  />}
                  <img src={slide.imgSp} alt={slide.alt} />
                </picture>
              </div>
            ))}
          </div>
          <div className="merchandise__main-slider-pagination"></div>
        </div>
        <div className="merchandise__main-text">
          <div className="l-hp__container">
            <h2
              className="l-hp__title l-hp__title--sm l-hp__title--pc text-effect"
              data-aos="fade-up"
            >
              <span>
                Original Case
                <br/>& Cleaning Cloth
              </span>
            </h2>
            <p
              className="merchandise__main-text-inner"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              Receive a spectacle case and a lens cleaning cloth with every purchase of the
              Harry Potter x OWNDAYS frame. Both items are exclusively designed for this
              limited-edition collection and feature a medley of magical artifacts symbolic to
              the famed Harry Potter series.
            </p>
          </div>
        </div>
      </div>
      <div className="merchandise__other">
        <div className="merchandise__other-slider swiper">
          <div className="swiper-wrapper">
            {merchandiseOtherSlides.map((slide, index) => (
              <div className="swiper-slide" key={index}>
                <img src={slide.src} alt={slide.alt} />
              </div>
            ))}
          </div>
          <div className="merchandise__other-slider-pagination lineup__default-pagination"></div>
        </div>
      </div>
      <div className="merchandise__notes">
        <div className="l-hp__container">
          <ul>
          {merchandiseTermsConditions.map((term, index) => (
            <li key={index}>{term}</li>
          ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Merchandise;