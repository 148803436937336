import React, { useState } from 'react';
import BottomSheet from '../BottomSheet/BottomSheet';
import Carousel from '../Carousel/Carousel';
import config from '../../../config';

function PFUViewDetail(props) {
  const { dataLocale, pdImageFileName, imageFileName, prescription, view, isBorderallowed } = props;
  const {
    VIEW_DETAILS,
    VIEW_PRESCRIPTION,
    PD_UPLOADED,
    POWER_DETAILS_UPLOADED,
    PD_UPLOADED_WILL_ASSIST_YOU,
    POWER_PRESCRIPTION,
    PD_PRESCRIPTION_UPLOADED,
    POWER_PRESCRIPTION_UPLOADED,
    PUPILLARY_DISTANCE_UPLOADED,
    PD_IMAGE,
  } = dataLocale;
  const [isShow, setIsShow] = useState(false);
  const handleShow = () => {
    setIsShow(true);
  };
  let filterData;
  let isShowPowerImage;
  let isShowPdImage;
  let message;
  if (pdImageFileName && !imageFileName) {
    message =
      prescription?.left &&
        prescription.left.pd &&
        !isNaN(prescription.left.pd) &&
        prescription.left.pd.length !== 0
        ? view === 'desktop' ? PD_PRESCRIPTION_UPLOADED : PUPILLARY_DISTANCE_UPLOADED
        : `${PD_UPLOADED} ${PD_UPLOADED_WILL_ASSIST_YOU}`;
    isShowPdImage = true;
    isShowPowerImage = false;
  } else if (pdImageFileName && imageFileName) {
    const pdValue =
      prescription?.left &&
      prescription.left.pd &&
      !isNaN(prescription.left.pd) &&
      prescription.left.pd.length !== 0;
    const powerValue =
      prescription.left.sph && !isNaN(prescription.left.sph) && prescription.left.sph.length !== 0;
    isShowPdImage = true;
    isShowPowerImage = true;
    message =
      pdValue && powerValue
        ? view === 'desktop' ? POWER_PRESCRIPTION_UPLOADED : POWER_DETAILS_UPLOADED
        : `${POWER_DETAILS_UPLOADED} ${PD_UPLOADED_WILL_ASSIST_YOU}`;
  }
  // if (prescription?.left && prescription.left.pd && !isNaN(prescription.left.pd) && prescription.left.pd.length !== 0 && prescription.left.sph && !isNaN(prescription.left.sph) && prescription.left.sph.length !== 0) {
  //     isShowPdImage = false;
  //     isShowPowerImage = false;
  // } else if (prescription?.left && (!prescription.left.pd || isNaN(prescription.left.pd) && prescription.left.pd.length !== 0) && prescription.left.sph && !isNaN(prescription.left.sph) && prescription.left.sph.length !== 0) {
  //     isShowPowerImage = false;
  //     isShowPdImage = true;
  //     message = PD_UPLOADED;
  // } else if (prescription?.left && (!prescription.left.pd || !isNaN(prescription.left.pd) && prescription.left.pd.length !== 0) && (!prescription.left.sph || !isNaN(prescription.left.sph) && prescription.left.sph.length !== 0)) {
  //     isShowPowerImage = true;
  //     isShowPdImage = true;
  //     message = POWER_DETAILS_UPLOADED;
  // }
  if (isShowPdImage && isShowPowerImage) {
    filterData = [
      {
        imageURL: imageFileName,
        description: POWER_PRESCRIPTION,
      },
      {
        imageURL: pdImageFileName,
        description: PD_IMAGE,
      },
    ];
  } else if (isShowPdImage) {
    filterData = [
      {
        imageURL: pdImageFileName,
        description: PD_IMAGE,
      },
    ];
  }
  return (
    <>
      {!isShow ? (
        <>
          {view !== 'desktop' && isBorderallowed && <div className="border-bottom_grey mr-l15 mr-r15"></div>}
          <div className="addPower power-status-message display-flex justify-space-between pd-t10 pd-l10 pd-r10 mr-auto bora-4 mr-b10">
            <div
              className="status-message fsp12 mr-b10"
            ><span>{message}</span>
              <span className="fsp12 text-color_link_blue text-uppercase cursor-pointer fw400 mr-l10"
                onClick={handleShow}>{view === 'desktop' ? VIEW_DETAILS : VIEW_PRESCRIPTION}</span>
            </div>
          </div>
        </>
      ) : (
        <BottomSheet expanded backdrop onClickBackdrop={() => setIsShow(false)}>
          <div
            style={{ height: '34px', display: 'flex', alignItems: 'end', marginBottom: '-6px' }}
            onClick={() => setIsShow(false)}
          >
            <span className="text-topaz fsp14 pd-5 pos-abs cross" style={{ right: '0' }}>
              &#10005;
            </span>
          </div>
          <div style={{ background: '#f7f8f9' }}>
            <Carousel aspectRatio="2" autoPlay={15000} dataLocale={dataLocale} isPfu>
              {filterData &&
                filterData.map((unit, i) => {
                  return (
                    <div
                      key={`carousel-child-${i}`}
                      className="w100 h100 home-page"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <div>
                        <img
                          src={`${config.apiPath.downloadUploadedPrescription}${unit.imageURL}`}
                          alt={unit.description}
                          width="330px"
                        />
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '17px',
                          fontWeight: '700',
                          marginTop: '10px',
                        }}
                      >
                        {unit.description}
                      </div>
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </BottomSheet>
      )}
    </>
  );
}

export default PFUViewDetail;
