export const gundamEyeglasses = {
  modelNumber: 'GDM2003B-3A',
  name: 'ZEON EXCLUSIVE SPECTACLES',
  colors: [
    {
      id: 221922,
      url: `gundam-gdm2003b-3a-c2-c2-eyeglasses.html`,
      color: '#434021',
      images: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c2-c2-eyeglasses_gdm2003b-3a_c2_01.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c2-c2-eyeglasses_gdm2003b-3a_c2_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c2-c2-eyeglasses_gdm2003b-3a_c2_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c2-c2-eyeglasses_gdm2003b-3a_c2_04.jpg',
      ],
      altImages: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c2-c2-eyeglasses_gdm2003b-3a_c2_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c2-c2-eyeglasses_gdm2003b-3a_c2_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c2-c2-eyeglasses_gdm2003b-3a_c2_04.jpg',
      ],
    },
    {
      id: 221921,
      url: 'gundam-gdm2003b-3a-c1-c1-eyeglasses.html',
      color: '#bf1a21',
      images: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c1-c1-eyeglasses_gdm2003b-3a_c1_01.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c1-c1-eyeglasses_gdm2003b-3a_c1_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c1-c1-eyeglasses_gdm2003b-3a_c1_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c1-c1-eyeglasses_gdm2003b-3a_c1_04.jpg',
      ],
      altImages: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c1-c1-eyeglasses_gdm2003b-3a_c1_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c1-c1-eyeglasses_gdm2003b-3a_c1_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm2003b-3a-c1-c1-eyeglasses_gdm2003b-3a_c1_04.jpg',
      ],
    },
  ],
};

export const gundamSunglasses = {
  modelNumber: 'GDM1004B-3A',
  name: 'ZEON EXCLUSIVE SUNGLASSES',
  colors: [
    {
      id: 221924,
      url: `gundam-gdm1004b-3a-c2-c2-sunglasses.html`,
      color: '#434021',
      images: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c2-c2-sunglasses_gdm1004b-3a_c2_01.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c2-c2-sunglasses_gdm1004b-3a_c2_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c2-c2-sunglasses_gdm1004b-3a_c2_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c2-c2-sunglasses_gdm1004b-3a_c2_04.jpg',
      ],
      altImages: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c2-c2-sunglasses_gdm1004b-3a_c2_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c2-c2-sunglasses_gdm1004b-3a_c2_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c2-c2-sunglasses_gdm1004b-3a_c2_04.jpg',
      ],
    },
    {
      id: 221923,
      url: 'gundam-gdm1004b-3a-c1-c1-sunglasses.html',
      color: '#dd342e',
      images: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c1-c1-sunglasses_gdm1004b-3a_c1_01.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c1-c1-sunglasses_gdm1004b-3a_c1_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c1-c1-sunglasses_gdm1004b-3a_c1_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c1-c1-sunglasses_gdm1004b-3a_c1_04.jpg',
      ],
      altImages: [
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c1-c1-sunglasses_gdm1004b-3a_c1_02.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c1-c1-sunglasses_gdm1004b-3a_c1_03.jpg',
        'https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/628x301/9df78eab33525d08d6e5fb8d27136e95//g/i/gundam-gdm1004b-3a-c1-c1-sunglasses_gdm1004b-3a_c1_04.jpg',
      ],
    },
  ],
};

export const gundamCase = {
  id: 221925,
  url: 'gundam-zaku-case3a.html',
  name: 'ZEON EXCLUSIVE SPECTACLE CASE',
  modelNumber: 'ZAKU-CASE3A',
  images: [
    'https://static.lenskart.com/media/owndays/desktop/img/gundum/gallery-01.png',
    'https://static.lenskart.com/media/owndays/desktop/img/gundum/gallery-2.webp',
    'https://static.lenskart.com/media/owndays/desktop/img/gundum/gallery-3.webp',
    'https://static.lenskart.com/media/owndays/desktop/img/gundum/gallery-4.webp',
  ],
  altImages: [
    'https://static.lenskart.com/media/owndays/desktop/img/gundum/gallery-2.webp',
    'https://static.lenskart.com/media/owndays/desktop/img/gundum/gallery-3.webp',
    'https://static.lenskart.com/media/owndays/desktop/img/gundum/gallery-4.webp',
  ],
};
