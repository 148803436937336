import React, { useEffect } from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">
    <head>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
        <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>
        <script>
            if (window.location.href.indexOf('jp.owndays.com') != -1) {
                window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
            }
        </script>
        <!-- Rtoaster -->
        <!-- /Rtoaster -->
        <!-- CleverTap -->
        <!-- /CleverTap -->
        
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
        <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
        <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
        <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">
        <link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
        <link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
        <link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
        <link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
        <link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
        <link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
        <link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
        <link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
        <link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
        <link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
        <link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
        <link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
        <link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
        <link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
        <link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
        <link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
        <link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">
        <!-- CSRF Token -->
        <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
        <meta property="og:locale" content="en_SG"/>
        <meta name="locale-country" content="sg"/>
        <meta name="locale-language" content="en"/>
        <meta property="og:title" content="Collections | OWNDAYS ONLINE STORE - OPTICAL SHOP">
        <meta name="description" content="Browse the extensive collection of eyewear at OWNDAYS. Each collection is built upon a unique design concept that is catered a specific audience in mind. Be it fashion-forward, basic or functional, we’re sure you’ll find eyewear that complements your personality and style!">
        <meta name="keywords" content="Collections,glasses,spectacles,optical shop,online store,eyeglasses,OWNDAYS,frame,sunglasses,pc lenses">
        <meta property="og:description" content="Browse the extensive collection of eyewear at OWNDAYS. Each collection is built upon a unique design concept that is catered a specific audience in mind. Be it fashion-forward, basic or functional, we’re sure you’ll find eyewear that complements your personality and style!">
        <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/brands">
        <meta property="og:image" content="https://www.owndays.com/images/common/ogp_1200x630.jpg?1690859228">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
        <link rel="preconnect" href="https://ka-f.fontawesome.com">
        <link rel="preconnect" href="https://use.typekit.net">
        <link rel="preconnect" href="https://cdn.jsdelivr.net">
        <!-- Fonts -->
        <link rel="dns-prefetch" href="https://fonts.gstatic.com">
        <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
        <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
        <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
        <!-- Images -->
        <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
        <!-- Styles -->
        <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
        <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
        <link rel="preload" as="style" href="https://www.owndays.com/web/css/owl.carousel.min.css">
        <link href="https://www.owndays.com/web/css/owl.carousel.min.css" rel="stylesheet">
        <link rel="preload" as="style" href="https://www.owndays.com/web/css/owl.theme.default.css">
        <link href="https://www.owndays.com/web/css/owl.theme.default.css" rel="stylesheet">
        <link rel="preload" as="style" href="https://www.owndays.com/web/css/collections.css?v=1.0.3">
        <link href="https://www.owndays.com/web/css/collections.css?v=1.0.3" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
        <!-- fontawesome 5.x -->
        <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
        <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
        <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
        <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
        <title id="title">Collections | OWNDAYS ONLINE STORE - OPTICAL SHOP
</title>
    </head>
    <!-- If open with LINE add class ".is-line-mini" to body tag -->
    <body id="main-body" style="display: block" class="is-sale-time">
        <div id="app" data-nosnippet>


            <!-- header - SP電話モーダル中身 -->
            <modal class="l-header__modal" id="contact" style="display: none;">
                <p class="l-header__modal-title">Contact Us</p>
                <div class="contact-information">
                    <a id="header_modal_contact" href="tel:6222-0588">
                        <span class="icon-tel"></span>
                        6222-0588
                    </a>
                </div>
                <p>
                    <span>Operating Hours</span>
                    <span>10:00 - 19:00</span>
                </p>
                <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
                    <a href="/sg/en/faq">FAQs</a>
                </div>
                <p class="c-attention"></p>
            </modal>
            <main class="l-content
        
        
        
        
        
        ">
                <div class="od-c-breadcrumb">
                    <ul id="breadcrumb-list">
                        <li>
                            <a href="/sg/en">Homepage</a>
                        </li>
                        <li>
                            <a href="/sg/en/brands">Collections</a>
                        </li>
                    </ul>
                </div>
                <section class="l-collections" id="collections-wrapper">
                    <div class="c-title c-title-group c-title-group--headline">
                        <h1 class="c-title c-title--small-sp">COLLECTIONS</h1>
                    </div>
                    <div class="l-content--inner">
                        <div class="l-collections__list archive-list clearfix">
                            <div class="column" id="shingo-aiba">
                                <a href="#shingo-aiba" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/40d62a2f-d3fa-440d-896b-9036fde4890d.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/ec542555-58db-4fc9-b30b-78f2f78174ab.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/4034fd23-35b4-416f-97e8-827fe3c42a54.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/a2fa45c9-6b36-491d-9cd6-4d10718f678a.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=54">
                                                                <img data-src="https://www.owndays.com/storage/productLines/62358850-45d9-4b2b-9c96-2f71670f297b.webp" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris. Reflecting a neoclassic perspective that is not confined by trends, the collection brings to life spectacle frames that are reminiscent of the classics with its emphasis on icons from the 70s while being elevated with modern materials and colours. Every piece in this Made-in-Japan collection is crafted to perfection through the superb skills of eyewear artisans from Sabae – also known as the City of Eyewear in Japan – who boast over 50 years of experience under their belt. Combined with the luxury of state-of-the-art technology, this is decidedly a collection that is made to last for years to come.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=54">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/shingo-aiba">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="kuromi">
                                <a href="#kuromi" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/68ecfb86-4082-4f9b-bbae-bf478581550e.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/c3f14887-066a-4f83-a511-502d7c3fcc97.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/b1ac9ee4-e9bd-4a16-9def-170013f367b9.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/89ba3c75-b8f9-4191-8d85-9eb12db94696.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=48">
                                                                <img data-src="https://www.owndays.com/storage/productLines/0d6a7b23-b330-41fd-abbf-4e8d8b0df6f2.webp" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">An OWNDAYS x Kuromi crossover in support of the #KUROMIfy the World project. Transform into the best self you can ever be with these cute, accessory-like eyewear that are inspired by Kuromi and Baku!</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=48">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/kuromi">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="Pokemon">
                                <a href="#Pokemon" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/e61c6d69-e796-4a98-bf24-049aa19e51c9.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/d5b2026b-8a3c-4f52-a294-7e8885ada045.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/79b9cb67-026b-4ccc-b045-ca5bded1233f.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/00d38f48-b3c6-4876-b01d-ece192c84ed1.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/04ccfbd1-723b-4eb4-817f-1667d4a5bc2e.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=46">
                                                                <img data-src="https://www.owndays.com/storage/productLines/7870f1d9-0873-4080-a2c0-e54cff64a033.webp" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">An eyewear collection inspired by 8 Pokémon, featuring 9 spectacle frames in an assortment of colours and shapes that would bring you equal quotient of style and functionality.  Choose your Pokémon and embark on a chic escapade!</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=46">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/pokemon">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="huawei">
                                <a href="#huawei" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/ed9fd74f-4ba7-4612-b2f2-bdb11bfafa5c.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/0a52e227-04ac-4970-8dff-442160e89afd.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/64e2ef51-0cff-4342-b6b9-f9e446e74f4f.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/c3a81135-73b2-49ff-8e88-f894985db711.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/2f5f21bb-74ad-48ce-bdfa-801033429449.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/10e13c69-7435-4c41-a353-20c9b7949ff8.jpeg" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=45">
                                                                <img data-src="https://www.owndays.com/storage/productLines/5cb478ca-6e74-4f1f-b4a4-5baa87fbf5ae.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A smart wearable designed to bring you an unparalleled visual and auditory experience, featuring an open-ear design that allows you to stay aware of your surroundings while listening to music on the go. Complete with built-in directional speakers to minimise sound leakage, these sleek, minimalist glasses are intriguingly stylish and work-appropriate at the same time.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=45">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/huawei">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="owndays-plus">
                                <a href="#owndays-plus" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/41584d40-e600-417b-8817-82dfd58c444f.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/ce9846a3-8ef0-4c50-aff0-11541eef2d9e.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/ab959496-b79f-4ec1-8f2d-2c292ae40086.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/db0f3e4c-c65a-4a40-b16d-3c64149d4eab.jpeg" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/Owndaysplus.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/a8d77da2-a295-4e92-8ad9-a384a07252a8.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">OWNDAYS aspires to present eyewear that is modest and a true reflection of　the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/Owndaysplus.html">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/owndays-plus">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="airultem">
                                <a href="#airultem" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/4c2b9a2c-a458-41bf-9336-392a088fe5d8.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/4f3440a6-01be-4ca8-a91b-da40ae8954f8.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/4b6c3746-f046-479b-8c19-bbc974357ce5.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/10601e22-3afb-4634-8999-59d0a055a0ce.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/AIRUltem.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/KxY4P8eKPa3SgXB7wC0EcSTomsjhAAU2kViSyChd.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A collection of frames made of ultem, an ultra-flexible material that is  amazingly light and durable. AIR Ultem has passed inspections of endurance and bridge distortion based on Japanese Industrial Standards (JIS B 7285:2008). In addition, a test conducted by the Japan Spectacles and Popular Optics Inspection Institute has shown that the temples are able to retain their shape without distortion despite being twisted over 10,000 times. These unique properties make AIR Ultem a highly functional collection that is perfect for day-to-day use.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/AIRUltem.html">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/airultem">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="owndayssnap">
                                <a href="#owndayssnap" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/182cacbf-7d9e-475e-b218-5eda1bfaf0f2.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/1df1074f-0ea3-4fea-aff2-f08e1247cd95.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/e90abce8-b495-4d82-bb9a-b539130646e7.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/50fe11f8-e3f2-4cb1-88b3-03517850e01f.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/snap.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/vTLvgOV3uPsJ4TiEiIKvQsrZcKfefbI02nvFhvLh.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">Turn your spectacles into sunglasses in an instant through the use of built-in magnets that makes attaching the coloured SNAP LENS onto the frame so effortless. SNAP LENS is fitted with polarised lenses that can reduce glare from shiny surfaces such as the road and water surface to give you a more comfortable vision. This makes OWNDAYS SNAP a functional eyewear that is great for everyday use and perfect when you are on the go. 
*Comes in a bundled set consisting of a frame and a SNAP LENS
*Changing of SNAP LENS colour is not available for bundled set</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/snap.html">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/owndays-snap">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="memorymetal">
                                <a href="#memorymetal" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/ecb2efd0-e7c1-4387-a955-41bfa587415a.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/625073ef-749c-4199-a425-88191dd4d064.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/e9be3a39-6e3b-47b5-8175-d2588d6604b9.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/e93c20cc-834d-427d-ac62-41a1ea0131cc.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/MemoryMetal.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/fa352b86-0a2a-4c4e-97c8-248144a2460e.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A collection featuring Nitinol (NiTi) frames that are made using the latest ion plating (IP) process. Nitinol is a shape memory alloy consisting of nickel and titanium. The material exhibits great elasticity and durability. It is able to retain its shape despite being bent by force. Ion plating is applied on the frame surface to enhance durability and resistance to rust. This also prevents the frame coating from peeling or scratching while acting as a protective layer for people with metal allergies.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/MemoryMetal.html">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/memorymetal">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="johndillinger">
                                <a href="#johndillinger" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/7e02e23c-7991-4858-8c7d-0be619118481.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/4de7aa62-7172-4810-b444-c06a29436041.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/68cd4e69-0804-449b-beca-03a547f19a1d.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/6b1a0884-cd6a-4df5-9c81-bd1100df7b52.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/JohnDillinger.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/xhRSpgZbcV98CPf3bj8KCmQkjE49ui7EigR8VFbB.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">In the early 1930s, John Dillinger was highly supported by the citizens as a benevolent thief in the Midwestern United States. We revived his period and history to the present and made neo-classic collection. From the standard designs to the unique designs, each frames has their own name with motif of 1960’s famous people.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/JohnDillinger.html">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="graphbelle">
                                <a href="#graphbelle" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/985c4202-9e0f-4d83-9193-47ab7015b7fa.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/d88238a9-fe74-40f5-ae02-210cfef316a0.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/82d1a234-7843-4725-a5c3-ac086cbadc55.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/6e7f7a7c-40b0-4f8c-84a8-330567043fb1.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/graphbelle.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/VBqxYXcfQ7jR0kiARXjQfNQ21bNXiRdQ1pfungAV.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A stylish and feminine line for the strong and beautiful woman living in today &#039;s world. We &#039;ve given these frames a suppleness befitting a woman and a style that hints at independence.  Detailed accents adorn the simple and composed design, invoking a sense of playfulness from any angle.  This line has honed in on a mode style—elegant yet adorable, with a touch of intelligence.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/graphbelle.html">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="fuwacellu">
                                <a href="#fuwacellu" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/eb15724e-0172-4731-bf3f-48e855bb7b13.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/7e59c6ea-0fef-41ca-b8f6-fe725a4b5c51.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/5e175b6f-4d06-4310-b565-51ff93409701.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/8c22c9f0-9750-48c3-8b32-49659fc34f22.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=13">
                                                                <img data-src="https://www.owndays.com/storage/productLines/HuCKu7jUViZoohrV4CSt5Tu4LEV8pS3fKoSm6Bsl.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">Light as can be and easy on your skin. The FUWA CELLU brand line uses Grilamid TR-90 for its frames, which is recently referred to as the material of the 21st century. Safe and skin-friendly, TR-90 is recommended for those who suffer from metal allergies. In addition, their lightweight and excellent elasticity makes them comfortable to wear—perfect for those who are not used to wearing glasses.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=13">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="lillybell">
                                <a href="#lillybell" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/648139a9-aa5a-4665-a414-42b19777dd90.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/35027e00-fa8c-4717-b848-9dbc471ca623.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/6b748cc5-9622-4995-a930-9d7453a9076c.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/fe78ba62-fe46-4771-9678-6af14e63997a.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=30">
                                                                <img data-src="https://www.owndays.com/storage/productLines/ZP0uFJIFQmVzk0nkCZ1BMone2Fdh75Bu8MDObijA.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A collection that draws on the notion of enjoying the present, offering options that are on-trend and with elements of both elegance and edge. Designed for the sassy, buoyant sweethearts who enjoy a blend of street-style edge and delicate appeal, while basking in the pursuit of finesse and maturity as they transform into refined young ladies. With a rich lineup from acetate to metal frames that will elevate any style, here’s a collection perfect for the fashionista in you.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=30">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="owndayspc">
                                <a href="#owndayspc" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/1a1ed744-f1a6-4526-9ce2-42b96649d67c.jpeg" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/3d427ac1-6cc2-45aa-8afa-9717ea83ce58.jpeg" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=15">
                                                                <img data-src="https://www.owndays.com/storage/productLines/6c055a8a-5d4d-47d3-8ade-9a43662b585e.jpeg" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN・KIDS
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">OWNDAYS PC was developed for the people who tend to stare displays of computer, TV, mobile phone and video game today. They can reduce stresses on your eyes by cutting brightness and glares of displays down. PC glasses are strongly recommended to people today who are exhausting their eyes.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=15">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/owndayspc">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="kmoriyama">
                                <a href="#kmoriyama" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/43a0ed9c-742e-4efc-bd50-d1daa234d3b7.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/b47e1a01-49ca-47ab-89c9-e1c85ba5d3f7.png" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/712597b6-6510-47ee-8bae-4538c49d0742.png" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=6">
                                                                <img data-src="https://www.owndays.com/storage/productLines/75aKQETLxFH2cWDERNGQ5ANW4MccdbNQcfiNLsT3.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A collection of metal frames synonymous with sophistication and elegance. The use of metallic components is an intentional approach to create a lustrous shine that bounces off the frame upon changes in movements and lights. With an emphasis on both simplicity and attention to details, each pair in the collection is a versatile piece that could work multiple occasions, be it formal or casual. This is a collection designed to lend you a timeless, understated charm.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=6">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="niche">
                                <a href="#niche" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/bc0169bb-dcfe-4085-b653-1c75c9a628ed.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/6099d0b6-b3a1-4ceb-88b8-46fffd2b8768.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/a70c445a-0f6b-4aa2-ab13-eb50b2db5363.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/67b17b26-36f5-4290-a57d-e5c6bba7e5dd.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=28">
                                                                <img data-src="https://www.owndays.com/storage/productLines/t4o7t10iOOiUAllUHoDWUnhlspAw0dIRyrQMpc1O.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">NICHE: The Undiscovered Dimensions – a collection of avant-garde eyewear born out of an undiscovered outlook in material and design. With the idea of being differentiated, OWNDAYS has re-invented the intrinsic charm in each material and in the process, uncovered new dimensions unknown to the world.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=28">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="eco2xy">
                                <a href="#eco2xy" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/fbf317f5-ad06-46a0-bdee-9b77c77942c1.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/840f0b08-b6a6-4701-ba9f-023d225fe7b6.png" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/381a2612-b1e5-4908-8961-fde46d37998f.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/120abc7e-cbf6-4bf8-ad6a-5b18b7d02d64.jpeg" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/eco2xy.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/I36kgkQFbFsi90FgkTuc3gR2xhNQ8tCTjzfklfIc.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN・KIDS
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A collection of eco-friendly spectacle frames made using biomass resources derived from castor plant. Castor plant is a renewable raw material that grows in wastelands. It is a perennial plant that does not cause deforestation. eco²xy frames are lightweight with no exposed metal components, making them safe for people with metal allergies.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/eco2xy.html">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/eco2xy">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="airfit">
                                <a href="#airfit" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/d65bf698-1c08-49de-ac04-74112f0916e3.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/dbb5a4e1-e95f-45d2-bc93-c0a1e979ae6c.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/2c6fbe8d-e927-41b0-8352-77b4c8e9ad51.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/73055cf7-f688-4ec1-b20c-cb34c2737ec4.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/AIRFIT.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/rvQD61QpU4v1J5hsnL4j4KDjV1BoUCnLPywqoAz0.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">So comfortable you feel like you’re not wearing glasses ― this is the idea which has inspired us to create a collection featuring frames made of a material that is both lightweight, flexible and corrosion-resistant. Besides comfort, the clean-cut design of the frames gives a sharp, impeccable look that is perfect for business and other formal occasions.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/AIRFIT.html">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="senichi">
                                <a href="#senichi" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/09a8e571-bc58-46af-8442-19d7969cda0b.jpeg" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/ee293b0c-2576-4226-a328-93f7a29acc44.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/8a700a61-dc48-4fca-b80a-118747e6068a.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/a268271a-9b90-4eac-af1f-1834bf2f95ba.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/24d4a03d-9f2b-496b-b58f-5ed5ac249ca2.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/97e7315e-9b22-4d26-88ed-c69475d5adfc.jpeg" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/4aedd455-a0d4-40b9-897a-ad1d3a9f7505.jpeg" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=2">
                                                                <img data-src="https://www.owndays.com/storage/productLines/yG08buxYCJlCEM9X4ZMu7yZcMTQRCuY5Snh384pw.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">Sabae, a city located in the Fukui Prefecture, Japan, is widely regarded as the City of Eyewear. This is where Senichisaku frames are crafted, with every pair carefully put together by hand. Senichisaku frames are made of a scarce celluloid material that has excellent durability and a unique, rich colour that adds lustre to the frames. Like a prized gem that only gets better with age, the colour of the frame becomes deeper the more you use it and that is when you can truly revel in the beauty of a Senichisaku frame.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=2">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/senichisaku">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="junni">
                                <a href="#junni" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/1c3900fd-0882-498e-8a34-634e18c6a12c.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/6c7ef2b7-4aeb-4cfb-bdcd-7986cb6d4a98.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/4aceb28f-fec5-4f39-9bf7-970cd001c7cb.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/91c7aa02-84ae-43e8-ab52-7f437a7c5235.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/eyeglasses/brands/Junni.html">
                                                                <img data-src="https://www.owndays.com/storage/productLines/a0921c26-50cc-4cd9-b179-f724b25c5eb1.webp" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : WOMEN・KIDS
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">Fashion is not just for the adults – this is the concept behind Junni, a collection created for the children of today, featuring stylish frames even adults would love. With an emphasis on perfect frame fitting, Junni frames cater for both everyday use and children who are first-time eyewear users. It is a collection that pairs functionality with a grown-up aesthetic ideal for the little fashionistas.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/eyeglasses/brands/Junni.html">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="based">
                                <a href="#based" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/29f30f8c-66c9-4300-99ee-76dfc4c960e1.webp" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/09766498-263f-4689-935a-0390ef5c812c.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/fcef4665-6f30-4717-a8df-5fc7ab164276.webp" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/bb994eb1-3c60-4663-a298-15cc3a5d0acb.webp" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=3">
                                                                <img data-src="https://www.owndays.com/storage/productLines/pUvDG6lDtlXoYWOCr9UDBbUtNtw7UQjHU1HFGWch.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A brand for sophisticated men with gentle atmosphere. This name &quot;Based &quot;represents men who are living based on their experience and has depth in life. A life with stability and profundity should be handed down to the next generation; that we think glasses should be like so.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=3">See All Products</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="kimetsu">
                                <a href="#kimetsu" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/1ea14171-976f-47f6-aa56-cfd41f5fea89.png" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/a553a557-d895-4103-a9e8-0e34f14a941f.png" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/3d905a85-e9e3-44be-94f4-f1f2bb3dc299.png" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=35">
                                                                <img data-src="https://www.owndays.com/storage/productLines/e1e0ad8a-b80b-4dbe-9989-9cf36bb5b3fb.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : MEN・WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">A collection featuring six spectacle frames inspired by the six characters, namely Tanjiro, Nezuko, Zenitsu, Inosuke, Giyu and Shinobu, from the anime television series Demon Slayer: Kimetsu no Yaiba.
Each frame is a distinct representation of each of the six characters, with frame temples decorated in motifs depicting iconic patterns found on both the Nichirin Sword and the outfit of the respective character.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=35">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/kimetsu">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column" id="mi-mollet">
                                <a href="#mi-mollet" class="column-inner">
                                    <div class="filter">
                                        <img data-src="https://www.owndays.com/storage/productLines/ef661fd3-bb82-4e5f-affc-4971f4157bb2.png" class="lazyload">
                                    </div>
                                    <span class="icon-search"></span>
                                </a>
                                <div class="column-content">
                                    <div class="column-detail">
                                        <div class="archive-drawarea">
                                            <div class="archive-body">
                                                <div class="column-description">
                                                    <div class="l-collections__slider">
                                                        <ul class="owl-carousel owl-theme">
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/3b4923d8-5b8f-4f1b-9aa5-94d476de2db0.png" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/ded338b1-ba31-4cb0-ad5b-142500e7f99a.png" alt="" class="lazyload">
                                                            </li>
                                                            <li>
                                                                <img data-src="https://www.owndays.com/storage/productLines/629535ba-7f0f-4b6b-8eb6-223db65c9e60.png" alt="" class="lazyload">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="l-collections__detail">
                                                        <h2>
                                                            <a href="/sg/en/products?productLines[]=37">
                                                                <img data-src="https://www.owndays.com/storage/productLines/2e44638f-676f-4290-9fd9-aca2c99204de.png" alt="" class="lazyload">
                                                            </a>
                                                        </h2>
                                                        <p class="l-collections__recommend">Recommended for : WOMEN
                                                    </p>
                                                        <div class="l-collections__inner">
                                                            <p class="l-collections__concept">Naoko Okusa started her career as an editor and is currently one of the most prominent fashion stylists in Japan. This collaboration with web magazine “mi-mollet”, where Naoko serves as its concept director, features a series of cute “adult glasses” designed for different occasions. Wear it as an accessory or as a pair of reading glasses and let it sparkle on your face like a prized jewellery.</p>
                                                            <div class="l-collections__buttons">
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="/sg/en/products?productLines[]=37">See All Products</a>
                                                                </div>
                                                                <div class="c-btn c-btn--md-fullwidth c-btn--primary c-btn--secondary-center">
                                                                    <a href="https://www.owndays.com/sg/en/news/mi-mollet">More Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="close-archive" href="#">
                                            <span class="close-archive-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>

        <!-- /top-return -->
        <div id="top-btn" class="c-top-btn">
            <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
            <p>TOP</p>
        </div>

        <!-- Modal / Country & Language -->
        <div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                        </button>
                        <p class="modal-title">Choose your country</p>
                        <div id="accordionCountryLang" class="modal-accordion accordion">
                            <!-- For Mobile -->
                            <ul class="modal-list u-sp-only">
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                        </picture>
                                        日本
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/jp/ja" class="">日本語
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/jp/en" class="">English
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/jp/zh_cn" class="">中文 (简体)
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/jp/zh_tw" class="">中文 (繁體)
                                    </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                        </picture>
                                        SINGAPORE
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/sg/en" class="active">English
                                    </a>
                                        </li>
                                        
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                        </picture>
                                        台灣
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                        </picture>
                                        ไทย
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/th/th" class="">ภาษาไทย
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/th/en" class="">English
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/th/ja" class="">日本語
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/th/zh_cn" class="">中文 (简体)
                                    </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                        </picture>
                                        ព្រះរាជាណាចក្រ
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                        </picture>
                                        PHILIPPINES
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                        </picture>
                                        AUSTRALIA
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                        </picture>
                                        MALAYSIA
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                        </picture>
                                        Việt Nam
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/vn/vi" class="">Tiếng Việt
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/vn/en" class="">English
                                    </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                        </picture>
                                        INDONESIA
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                        </picture>
                                        香港
                            
                                    </a>
                                </li>
                            </ul>
                            <!-- For Desktop -->
                            <ul class="modal-list u-pc-only">
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                        </picture>
                                        日本
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/jp/ja" class="">日本語
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/jp/en" class="">English
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/jp/zh_cn" class="">中文 (简体)
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/jp/zh_tw" class="">中文 (繁體)
                                    </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                        </picture>
                                        台灣
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                        </picture>
                                        ព្រះរាជាណាចក្រ
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                        </picture>
                                        AUSTRALIA
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                        </picture>
                                        Việt Nam
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/vn/vi" class="">Tiếng Việt
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/vn/en" class="">English
                                    </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                        </picture>
                                        香港
                            
                                    </a>
                                </li>
                            </ul>
                            <ul class="modal-list u-pc-only">
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                        </picture>
                                        SINGAPORE
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/sg/en" class="active">English
                                    </a>
                                        </li>
                                        
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                        </picture>
                                        ไทย
                            
                                    </button>
                                    <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                        <li>
                                            <a href="https://www.owndays.com/th/th" class="">ภาษาไทย
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/th/en" class="">English
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/th/ja" class="">日本語
                                    </a>
                                        </li>
                                        <li>
                                            <a href="https://www.owndays.com/th/zh_cn" class="">中文 (简体)
                                    </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                        </picture>
                                        PHILIPPINES
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                        </picture>
                                        MALAYSIA
                            
                                    </a>
                                </li>
                                <li class="modal-list-item">
                                    <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                        <picture>
                                            <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                            <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                        </picture>
                                        INDONESIA
                            
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Scripts -->
        <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
        <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
        <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
            lazySizes.init();
            const baseUrl = "https://www.owndays.com"

            //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

            if (!Object.entries) {
                Object.entries = function(obj) {
                    var ownProps = Object.keys(obj)
                      , i = ownProps.length
                      , resArray = new Array(i);
                    // preallocate the Array
                    while (i--)
                        resArray[i] = [ownProps[i], obj[ownProps[i]]];

                    return resArray;
                }
                ;
            }
            if (!Array.prototype.find) {
                Object.defineProperty(Array.prototype, 'find', {
                    value: function(predicate) {
                        // 1. Let O be ? ToObject(this value).
                        if (this == null) {
                            throw new TypeError('"this" is null or not defined');
                        }

                        var o = Object(this);

                        // 2. Let len be ? ToLength(? Get(O, "length")).
                        var len = o.length >>> 0;

                        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                        if (typeof predicate !== 'function') {
                            throw new TypeError('predicate must be a function');
                        }

                        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                        var thisArg = arguments[1];

                        // 5. Let k be 0.
                        var k = 0;

                        // 6. Repeat, while k < len
                        while (k < len) {
                            // a. Let Pk be ! ToString(k).
                            // b. Let kValue be ? Get(O, Pk).
                            // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                            // d. If testResult is true, return kValue.
                            var kValue = o[k];
                            if (predicate.call(thisArg, kValue, k, o)) {
                                return kValue;
                            }
                            // e. Increase k by 1.
                            k++;
                        }

                        // 7. Return undefined.
                        return undefined;
                    },
                    configurable: true,
                    writable: true
                });
            }

            window.site_url = ''
        </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
        <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
        <script src="https://www.owndays.com/web/js/helper.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
        <script src="https://cdn.xapping.com/libs.js"></script>
        <script>
            var uri = window.location.toString();
            if (uri.indexOf("#") > 0) {
                var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
                window.history.replaceState({}, document.title, clean_uri);
            }

            $('#main-body').show();

            function openMenu(evt, menuName) {
                var i, tabcontent, tablinks;
                tabcontent = document.getElementsByClassName("tab__content");
                for (i = 0; i < tabcontent.length; i++) {
                    tabcontent[i].style.display = "none";
                }
                tablinks = document.getElementsByClassName("tab__link");
                for (i = 0; i < tablinks.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" active", "");
                }
                document.getElementById(menuName).style.display = "flex";
                evt.currentTarget.className += " active";
            }

            function supportWebP() {
                var rv = $.Deferred()
                  , img = new Image();
                img.onload = function() {
                    rv.resolve();
                }
                ;
                img.onerror = function() {
                    rv.reject();
                }
                ;
                img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
                return rv.promise();
            }

            supportWebP().then(function() {
                $('body').addClass('webp');
            }, function() {
                $('body').addClass('no-webp');
            });
        </script>
        <script>
            // handle suffix breadcrumb in profile page
            appendBreadcrumb($('.is-active'))

            $('.profile-nav').on('click', function() {
                appendBreadcrumb(this)
            })
            function appendBreadcrumb(element) {
                var breadcrumb = $(element).data('breadcrumb')
                $('#suffix-breadcrumb').remove();
                if (breadcrumb) {
                    $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>" + breadcrumb + "</li>")
                }
            }
        </script>
        <script src="https://www.owndays.com/web/js/owl.carousel.min.js"></script>
        <script src="https://www.owndays.com/web/js/view-box_collections.js"></script>
        <script>
            const localStorageName = "localProductLineFavorites";
            let brandFavorites = [];
            setHeartColor();

            function setHeartColor() {
                let allFavorites = $('.favorite');
                let localBrandFavorites = getLocalProductLineFavorites();
                if (localBrandFavorites) {
                    let concatBrands = localBrandFavorites.concat(brandFavorites);
                    allFavorites.each(item=>{
                        let brandId = $(allFavorites[item]).data('brand_id');
                        let haveInLocal = concatBrands.filter(item=>brandId == item);
                        if (haveInLocal.length) {
                            $(allFavorites[item]).addClass('favorited')
                        } else {
                            $(allFavorites[item]).removeClass('favorited')
                        }
                    }
                    )
                }
            }

            function togleBrandFavorite(event, target) {
                let user = null;
                const mainUrl = 'https://jp.owndays.com';
                const heart = $(event.target);
                const heartClassses = heart.attr('class').split(/\s+/);
                const isFavorited = heartClassses.filter(item=>item === 'favorited');

                if (target) {
                    if (user) {

                        $.ajax({
                            url: "https://jp.owndays.com/api/product-line/favorites",
                            type: "POST",
                            dataType: "json",
                            data: {
                                user_id: user.id,
                                product_line_id: target,
                            },
                            success: function(data) {
                                let hasFavorite = data.filter(item=>target == item.product_line_id);
                                if (hasFavorite.length) {
                                    heart.addClass('favorited')
                                    brandFavorites.push(target)
                                } else {
                                    heart.removeClass('favorited')
                                    brandFavorites.remove(target);
                                }
                            },
                        });
                    } else {
                        toggleLocalProductLineFavorite(target);
                        const productLineFavorites = getLocalProductLineFavorites();
                        const isLocalFavorited = productLineFavorites.filter(item=>item == target);

                        if (isLocalFavorited.length) {
                            heart.addClass('favorited')
                        } else {
                            heart.removeClass('favorited')
                        }
                    }
                }
            }

            Array.prototype.remove = function() {
                var what, a = arguments, L = a.length, ax;
                while (L && this.length) {
                    what = a[--L];
                    while ((ax = this.indexOf(what)) !== -1) {
                        this.splice(ax, 1);
                    }
                }
                return this;
            }
            ;

            function toggleLocalProductLineFavorite(productLineId, type) {
                const productLineFavorites = getLocalProductLineFavorites();
                const matchedProductLine = productLineFavorites.find((favorite)=>favorite === productLineId);
                if (matchedProductLine) {
                    removeLocalProductLineFavorite(matchedProductLine);
                } else {
                    addLocalProductLineFavorite(productLineId);
                }
            }

            function getLocalProductLineFavorites() {
                const productLines = JSON.parse(localStorage.getItem(localStorageName)) || [];
                const filteredProductLines = filterNotExpired(productLines);
                setLocalProductLineFavorites(filteredProductLines);
                return filteredProductLines;
            }

            function filterNotExpired(productLines) {
                const expiredDay = moment().subtract(180, "days");
                const notExpired = (tryOn)=>moment(tryOn.date) > expiredDay;
                return productLines.filter(notExpired);
            }

            function setLocalProductLineFavorites(productLines) {
                localStorage.setItem(localStorageName, JSON.stringify(productLines));
            }

            function removeLocalProductLineFavorite(productLineId) {
                const productLineFavorites = getLocalProductLineFavorites();
                if (productLineId) {
                    setLocalProductLineFavorites(productLineFavorites.filter((favorite)=>favorite !== productLineId));
                }
            }

            function addLocalProductLineFavorite(productLineId) {
                const productLineFavorites = getLocalProductLineFavorites();
                if (productLineFavorites.length >= 50) {
                    return alert("You've reached product's favorite maximum.");
                }

                const isDuplicated = productLineFavorites.find((favorite)=>favorite === productLineId);
                if (!isDuplicated) {
                    setLocalProductLineFavorites([...productLineFavorites, productLineId]);
                }
            }

            function setLocalProductLineFavorites(productLines) {
                localStorage.setItem(localStorageName, JSON.stringify(productLines))
            }
        </script>
      
    </body>
</html>
`;

const CollectionsHTML = () => {
  useEffect(() => {
    const loadScripts = async () => {
      const scriptUrls = [
        'https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap',
        'https://www.owndays.com/web/js/active-nav-menu.js?v=1.1',
        'https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3',
        'https://www.owndays.com/web/js/lazysizes.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js',
        'https://www.owndays.com/web/js/jquery.easing.1.3.js',
        'https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js',
        'https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222',
        'https://www.owndays.com/web/js/helper.js',
        'https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js',
        'https://cdn.xapping.com/libs.js',
        'https://www.owndays.com/web/js/owl.carousel.min.js',
        'https://www.owndays.com/web/js/view-box_collections.js',
      ];

      for (const url of scriptUrls) {
        await loadScript(url);
      }
    };

    const loadScript = url => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    loadScripts();

    const productFavoritesScript = document.createElement('script');
    productFavoritesScript.innerHTML = `
            const localStorageName = "localProductLineFavorites";
            let brandFavorites = [];
            setHeartColor();
    
            function setHeartColor() {
                let allFavorites = $('.favorite');
                let localBrandFavorites = getLocalProductLineFavorites();
                if (localBrandFavorites) {
                    let concatBrands = localBrandFavorites.concat(brandFavorites);
                    allFavorites.each(item=>{
                        let brandId = $(allFavorites[item]).data('brand_id');
                        let haveInLocal = concatBrands.filter(item=>brandId == item);
                        if (haveInLocal.length) {
                            $(allFavorites[item]).addClass('favorited')
                        } else {
                            $(allFavorites[item]).removeClass('favorited')
                        }
                    }
                    )
                }
            }
    
            function togleBrandFavorite(event, target) {
                let user = null;
                const mainUrl = 'https://jp.owndays.com';
                const heart = $(event.target);
                const heartClassses = heart.attr('class').split(/\s+/);
                const isFavorited = heartClassses.filter(item=>item === 'favorited');
    
                if (target) {
                    if (user) {
    
                        $.ajax({
                            url: "https://jp.owndays.com/api/product-line/favorites",
                            type: "POST",
                            dataType: "json",
                            data: {
                                user_id: user.id,
                                product_line_id: target,
                            },
                            success: function(data) {
                                let hasFavorite = data.filter(item=>target == item.product_line_id);
                                if (hasFavorite.length) {
                                    heart.addClass('favorited')
                                    brandFavorites.push(target)
                                } else {
                                    heart.removeClass('favorited')
                                    brandFavorites.remove(target);
                                }
                            },
                        });
                    } else {
                        toggleLocalProductLineFavorite(target);
                        const productLineFavorites = getLocalProductLineFavorites();
                        const isLocalFavorited = productLineFavorites.filter(item=>item == target);
    
                        if (isLocalFavorited.length) {
                            heart.addClass('favorited')
                        } else {
                            heart.removeClass('favorited')
                        }
                    }
                }
            }
    
            Array.prototype.remove = function() {
                var what, a = arguments, L = a.length, ax;
                while (L && this.length) {
                    what = a[--L];
                    while ((ax = this.indexOf(what)) !== -1) {
                        this.splice(ax, 1);
                    }
                }
                return this;
            }
            ;
    
            function toggleLocalProductLineFavorite(productLineId, type) {
                const productLineFavorites = getLocalProductLineFavorites();
                const matchedProductLine = productLineFavorites.find((favorite)=>favorite === productLineId);
                if (matchedProductLine) {
                    removeLocalProductLineFavorite(matchedProductLine);
                } else {
                    addLocalProductLineFavorite(productLineId);
                }
            }
    
            function getLocalProductLineFavorites() {
                const productLines = JSON.parse(localStorage.getItem(localStorageName)) || [];
                const filteredProductLines = filterNotExpired(productLines);
                setLocalProductLineFavorites(filteredProductLines);
                return filteredProductLines;
            }
    
            function filterNotExpired(productLines) {
                const expiredDay = moment().subtract(180, "days");
                const notExpired = (tryOn)=>moment(tryOn.date) > expiredDay;
                return productLines.filter(notExpired);
            }
    
            function setLocalProductLineFavorites(productLines) {
                localStorage.setItem(localStorageName, JSON.stringify(productLines));
            }
    
            function removeLocalProductLineFavorite(productLineId) {
                const productLineFavorites = getLocalProductLineFavorites();
                if (productLineId) {
                    setLocalProductLineFavorites(productLineFavorites.filter((favorite)=>favorite !== productLineId));
                }
            }
    
            function addLocalProductLineFavorite(productLineId) {
                const productLineFavorites = getLocalProductLineFavorites();
                if (productLineFavorites.length >= 50) {
                    return alert("You've reached product's favorite maximum.");
                }
    
                const isDuplicated = productLineFavorites.find((favorite)=>favorite === productLineId);
                if (!isDuplicated) {
                    setLocalProductLineFavorites([...productLineFavorites, productLineId]);
                }
            }
    
            function setLocalProductLineFavorites(productLines) {
                localStorage.setItem(localStorageName, JSON.stringify(productLines))
            }
        `;

    document.body.appendChild(productFavoritesScript);

    return () => {
      document.body.removeChild(productFavoritesScript);
    };
  }, []);

  return (
    <>
      <MetaTagsManager
        description="Discover OWNDAYS' vast eyewear range, each tailored with distinct designs for specific tastes. From trendy to functional, find your perfect style match."
        title="Eyewear Collections | OWNDAYS Singapore Brands Collections"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default CollectionsHTML;
