export function validateSingaporeMobileNumber(number) {
  // Singapore mobile numbers start with 8 or 9 and are followed by 7 digits
  // const regex = /^[89]\d{7}$/;
  const regex = /^\d{8}$/;

  return regex.test(number);
}

function validateEmail(email) {
  // Regular expression for basic email validation
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const validateLoginCreds = (username, password) => {
  let validBlock = null;

  if(username.trim().length === 0) {
    return {isValid: false, msg: "Username is required. Please provide a username."};
  }
  
  if(password.trim().length === 0 && !validateSingaporeMobileNumber(username)) {
    return {isValid: false, msg: "Password is required. Please provide a password."};
  }

  if (validateSingaporeMobileNumber(username)) {
    validBlock = "mobile";
  }

  if (validateEmail(username)) {
    validBlock = "email"
  }

  if(validBlock) {
    return ({isMobileUserOnly: validBlock === "mobile", validBlock, isValid: true, msg: ""});
  }

  return {isValid: false, msg: "The username provided is not valid. Please enter a valid username."};
}