import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">
    <link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
    <link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
    <link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
    <link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
    <link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
    <link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
    <link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
    <link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
    <link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
    <link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
    <link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
    <link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
    <link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
    <link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="keywords" content="Cinnamoroll,Poron,Corune,Milk,Sanrio,collection,glasses,character,OWNDAYS" />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/cinnamoroll">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/cinnamoroll/ogp_1200x630.jpg?1631198669">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Dangrek&display=swap" rel="stylesheet">
<link href="https://www.owndays.com/web/css/specials-cinnamoroll.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">Cinnamoroll × OWNDAYS | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            Cinnamoroll × OWNDAYS                    </li>
        
            </ul>
</div>


<section class="l-cinnamoroll">
    <div class="loading">
        <div class="loading__img">
            <div class="img"></div>
            <p>Loading...</p>
        </div>
    </div>
    <!-- Top -->
    <div class="top">
        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/top-bg.webp" alt="" class="top__bg rellax">
        <div class="l-cinnamoroll__container">
            <h1 class="top__title text-hide" data-aos="zoom-out">Cinnamoroll × OWNDAYS<img src="https://www.owndays.com/images/specials/products/cinnamoroll/CinnamorollxOWNDAYS.svg" alt="Cinnamoroll × OWNDAYS" class=""></h1>
            <img src="https://www.owndays.com/images/specials/products/cinnamoroll/top-img.svg" alt="An eyewear collection inspired by Cinnamoroll！" class="top__img " data-aos="zoom-out">
            <div class="top__details" data-aos="zoom-out">
                <p class="top__details__release"><span><strong>15.10.2021</strong><img src="https://www.owndays.com/images/specials/products/cinnamoroll/star.svg" alt="" class="">Fri</span></p>
                <p class="top__details__note">Same Day Release・Online and In-Store</p>
            </div>
        </div>
        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/top-cloud.svg" alt="" class="top__cloud ">
    </div>
    <div class="l-cinnamoroll__content">
        <!-- Concept -->
        <div class="concept">
            <div class="l-cinnamoroll__container">
                <h2 class="l-cinnamoroll__title text-hide">CONCEPT<img src="https://www.owndays.com/images/specials/products/cinnamoroll/concept-title.svg" alt="CONCEPT" class=""></h2>
                <div class="concept__sticker-character">
                    <p class="concept__sticker text-hide"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/sg/concept-sticker.svg" alt="2021 Sanrio Character Ranking No. 1" class=""></p>
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-1.svg" alt="cinnamoroll" class="concept__character concept__character--1 " data-aos="shake-lr">
                </div>
                <h3 class="concept__subtitle text-hide" data-aos="zoom-in"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/sg/concept.svg" alt="An eyewear collection inspired by Cinnamoroll！" class=""></h3>
                <p class="concept__text">
                    An eyewear collection inspired by Cinnamoroll,<br>
        a fluffy white male puppy,<br class="u-sp-only">
        and his friends.<br class="u-sp-only"><br>
        Featuring frames modelled after<br>
        <span>Cinnamoroll, Milk<i class="u-pc-only">,</i></span><br class="u-sp-only"><span>Corune and Poron</span><br>
        with carefully thought-out accents iconic to each character.<br class="u-sp-only"><br>
        These highly adorable frames are chic,versatile and<br>will certainly bring delight to your style!                </p>
                <div class="concept__cloud-character">
                    <div class="concept__cloud-character__wrapper">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-1.svg" alt="" class="concept__cloud concept__cloud--1 ">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-2.png" alt="Poron" class="concept__character concept__character--2 " data-aos="shake-lr">
                    </div>
                </div>
                <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-1.svg" alt="" class="concept__cloud concept__cloud--2 " data-aos="cloud">
            </div>
        </div>
        <!-- Line up -->
        <div class="lineup">
            <div class="l-cinnamoroll__container">
                <h2 class="l-cinnamoroll__title text-hide">LINE UP<img src="https://www.owndays.com/images/specials/products/cinnamoroll/lineup-title.svg" alt="LINE UP" class=""></h2>
                <h3 class="lineup__subtitle">
                    <span>Also <i>available in kids sizing</i> so you can pair up with your little one!</span>                </h3>
                <h4 class="lineup__price"><span>8 types in 4 styles / <strong>S$178</strong></span></h4>
                <ul class="lineup__nav">
                    <li class="lineup__nav__item lineup__nav__item--1">
                        <a data-scroll href="#lineup1" class="content">
                            <div class="lineup__nav__character"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-3.svg" alt="cinnamoroll" class=""></div>
                            <h5>Cinnamoroll</h5>
                        </a>
                    </li>
                    <li class="lineup__nav__item lineup__nav__item--2">
                        <a data-scroll href="#lineup2" class="content">
                            <div class="lineup__nav__character"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-4.svg" alt="Milk" class=""></div>
                            <h5>Milk</h5>
                        </a>
                    </li>
                    <li class="lineup__nav__item lineup__nav__item--3">
                        <a data-scroll href="#lineup3" class="content">
                            <div class="lineup__nav__character"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-5.svg" alt="Corune" class=""></div>
                            <h5>Corune</h5>
                        </a>
                    </li>
                    <li class="lineup__nav__item lineup__nav__item--4">
                        <a data-scroll href="#lineup4" class="content">
                            <div class="lineup__nav__character"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-6.png" alt="Poron" class=""></div>
                            <h5>Poron</h5>
                        </a>
                    </li>
                </ul>
                <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-2.svg" alt="" class="lineup__cloud ">
                <!-- Line up 1 -->
                <div class="lineup__item lineup__item--1" id="lineup1">
                    <div class="lineup__item__character">
                        <div class="lineup__item__character__word">
                            <p>Gentle &<br>easy-going</p>                        </div>
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-3.svg" alt="cinnamoroll" data-aos="bounce-up-down">
                    </div>
                    <h3 class="lineup__item__title text-hide"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/sg/lineup-item-title-1.svg" alt="cinnamoroll" class=""></h3>
                    <div class="lineup__item__slider-block">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1001B-1A_C2_02.jpg" alt="SR1001B-1A C2" class="lineup__item__img ">
                        <div class="lineup__item__slider">
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1001B-1A_C2_01.jpg" alt="Cinnamoroll model point①" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 01<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-01.svg" alt="POINT 01" class=""></h5>
                                    <p>An oversized polygon-shaped frame that<br class="u-sp-only"> is full of personality yet allowing<br class="u-sp-only"> one to still look ultra-gentle and friendly.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1001B-1A_C2_05.jpg" alt="Cinnamoroll model point②" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 02<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-02.svg" alt="POINT 02" class=""></h5>
                                    <p>The temple wire cores feature engravings in the design of Cinnamoroll’s face and tail.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1001B-1A_C2_06.jpg" alt="Cinnamoroll model point③" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 03<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-03.svg" alt="POINT 03" class=""></h5>
                                    <p>The temple tips are designed to resemble the huge ears that Cinnamoroll has.</p>                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__item__line"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/star-lineup-1.svg" alt="" class=""></div>
                    <ul class="lineup__item__list">
                        <li class="lineup__item__list-item" data-aos="fade-up">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1001B-1A_C1_04.jpg" alt="SR1001B-1A_C1_04" class="">
                                    <h5>SR1001B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1001B-1A?sku=5562" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-1">
                                        CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1001B-1A_C2_04.jpg" alt="SR1001B-1A_C1_04" class="">
                                    <h5>SR1001B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1001B-1A?sku=5563" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-1">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-1.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-2.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                        <!-- SRK1001B-1A C1 -->
                                                <li class="lineup__item__list-item lineup__item__list-item--kids" data-aos="fade-up" data-aos-delay="100">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1001B-1A_C1_04.jpg" alt="SR1001B-1A C1" class="">
                                    <h5>SRK1001B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1001B-1A?sku=5564" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-1">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1001B-1A_C2_04.jpg" alt="SRK1001B-1A C1" class="">
                                    <h5>SRK1001B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1001B-1A?sku=5565" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-1">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-1.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-2.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                                                <!-- SRK1001B-1A C1 -->
                    </ul>
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-1.svg" alt="" class="lineup__item__cloud lineup__item__cloud--1 " data-aos="slide-left">
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-1.svg" alt="" class="lineup__item__cloud lineup__item__cloud--2 " data-aos="slide-right">
                </div>
                <!-- Line up 2 -->
                <div class="lineup__item lineup__item--2" id="lineup2">
                    <div class="lineup__item__character">
                        <div class="lineup__item__character__word">
                            <p>Longing to fly<br>in the air<br>some day</p>                        </div>
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-4.svg" alt="Milk" class="" data-aos="bounce-up-down">
                    </div>
                    <h3 class="lineup__item__title text-hide"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/sg/lineup-item-title-2.svg" alt="Milk" class=""></h3>
                    <div class="lineup__item__slider-block">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1002B-1A_C2_02.jpg" alt="SR1002B-1A C2" class="lineup__item__img ">
                        <div class="lineup__item__slider">
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1002B-1A_C2_07.jpg" alt="Milk model point①" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 01<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-01.svg" alt="POINT 01" class=""></h5>
                                    <p>The temple tips are designed<br>to resemble Milk’s favourite pacifier.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1002B-1A_C2_01.jpg" alt="Milk model point②" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 02<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-02.svg" alt="POINT 02" class=""></h5>
                                    <p>The frame features milky subdued hues that make it appear soothing and gentle.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1002B-1A_C2_05.jpg" alt="Milk model point③" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 03<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-03.svg" alt="POINT 03" class=""></h5>
                                    <p>The temples feature motifs in the design of an iconic curl of baby hair that is seen on the top of Milk’s head.</p>                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__item__line"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/star-lineup-2.svg" alt="" class=""></div>
                    <ul class="lineup__item__list">
                        <li class="lineup__item__list-item" data-aos="fade-up">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1002B-1A_C1_04.jpg" alt="SR1002B-1A_C1_04" class="">
                                    <h5>SR1002B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1002B-1A?sku=5566" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-2">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1002B-1A_C2_04.jpg" alt="SR1002B-1A_C1_04" class="">
                                    <h5>SR1002B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1002B-1A?sku=5567" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-2">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-3.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-2.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                                                <!-- SRK1002B-1A C1 -->
                        <li class="lineup__item__list-item lineup__item__list-item--kids" data-aos="fade-up" data-aos-delay="100">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1002B-1A_C1_04.jpg" alt="SRK1002B-1A_C1_04" class="">
                                    <h5>SRK1002B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1002B-1Asku=5568" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-2">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1002B-1A_C2_04.jpg" alt="SRK1002B-1A_C1_04" class="">
                                    <h5>SRK1002B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1002B-1Asku=5569" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-2">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-3.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-2.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                        <!-- SRK1002B-1A C1 -->
                                            </ul>
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-2.svg" alt="" class="lineup__item__cloud lineup__item__cloud--1 " data-aos="slide-left">
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-2.svg" alt="" class="lineup__item__cloud lineup__item__cloud--2 " data-aos="slide-right">
                </div>
                <!-- Line up 3 -->
                <div class="lineup__item lineup__item--3" id="lineup3">
                    <div class="lineup__item__character">
                        <div class="lineup__item__character__word">
                            <p>Time<br>traveller</p>                        </div>
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-5.svg" alt="Corune" class="" data-aos="bounce-up-down">
                    </div>
                    <h3 class="lineup__item__title text-hide"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/sg/lineup-item-title-3.svg" alt="Corune" class=""></h3>
                    <div class="lineup__item__slider-block">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1003B-1A_C2_02.jpg" alt="SR1003B-1A C2" class="lineup__item__img ">
                        <div class="lineup__item__slider">
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1003B-1A_C2_01.jpg" alt="Corune model point①" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 01<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-01.svg" alt="POINT 01" class=""></h5>
                                    <p>The frame comes in a beautiful,<br>vivid colourway inspired by Corune.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1003B-1A_C2_06.jpg" alt="Corune model point②" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 02<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-02.svg" alt="POINT 02" class=""></h5>
                                    <p>The temples feature a twist design akin to a choco cornet.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1003B-1A_C2_07.jpg" alt="Corune model point③" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 03<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-03.svg" alt="POINT 03" class=""></h5>
                                    <p>The temple tips are designed to resemble Corune’s tail.</p>                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__item__line"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/star-lineup-3.svg" alt="" class=""></div>
                    <ul class="lineup__item__list">
                        <li class="lineup__item__list-item" data-aos="fade-up">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1003B-1A_C1_04.jpg" alt="SR1003B-1A_C1_04" class="">
                                    <h5>SR1003B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1003B-1A" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-3">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1003B-1A_C2_04.jpg" alt="SR1003B-1A_C1_04" class="">
                                    <h5>SR1003B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1003B-1A" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-3">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-4.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-5.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                                                <!-- SRK1003B-1A C1 -->
                        <li class="lineup__item__list-item lineup__item__list-item--kids" data-aos="fade-up" data-aos-delay="100">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1003B-1A_C1_04.jpg" alt="SRK1003B-1A_C1_04" class="">
                                    <h5>SRK1003B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1003B-1A?sku=5572" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-3">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1003B-1A_C2_04.jpg" alt="SRK1003B-1A_C1_04" class="">
                                    <h5>SRK1003B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1003B-1A?sku=5573" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-3">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-4.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-5.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                        <!-- SRK1003B-1A C1 -->
                                            </ul>
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-3.svg" alt="" class="lineup__item__cloud lineup__item__cloud--1 " data-aos="slide-left">
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-3.svg" alt="" class="lineup__item__cloud lineup__item__cloud--2 " data-aos="slide-right">
                </div>
                <!-- Line up 4 -->
                <div class="lineup__item lineup__item--4" id="lineup4">
                    <div class="lineup__item__character">
                        <div class="lineup__item__character__word">
                            <p>With ears<br>that are<br>fluffy like clouds</p>                        </div>
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-6.png" alt="Poron" class="" data-aos="bounce-up-down">
                    </div>
                    <h3 class="lineup__item__title text-hide"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/sg/lineup-item-title-4.svg" alt="" class="Poron"></h3>
                    <div class="lineup__item__slider-block">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1004B-1A_C2_02.jpg" alt="SR1004B-1A C2" class="lineup__item__img ">
                        <div class="lineup__item__slider">
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1004B-1A_C2_06.jpg" alt="Poron model point①" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 01<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-01.svg" alt="POINT 01" class=""></h5>
                                    <p>A girlish design inspired by the blue ribbon Poron wears on her head.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1004B-1A_C2_05.jpg" alt="Poron model point②" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 02<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-02.svg" alt="POINT 02" class=""></h5>
                                    <p>The temple wire cores feature engravings in the design of Poron’s key.</p>                                </div>
                            </div>
                            <div class="lineup__item__slider-item">
                                <div class="lineup__item__slider-item__img">
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1004B-1A_C2_07.jpg" alt="Poron model point③" class="">
                                </div>
                                <div class="lineup__item__slider-item__details">
                                    <h5 class="text-hide">POINT 03<img src="https://www.owndays.com/images/specials/products/cinnamoroll/point-03.svg" alt="POINT 03" class=""></h5>
                                    <p>The left temple tip features an emblem in the design of Poron’s magical time compass. </p>                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__item__line"><img src="https://www.owndays.com/images/specials/products/cinnamoroll/star-lineup-4.svg" alt="" class=""></div>
                    <ul class="lineup__item__list">
                        <li class="lineup__item__list-item" data-aos="fade-up">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1004B-1A_C1_04.jpg" alt="SR1004B-1A_C1_04" class="">
                                    <h5>SR1004B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1004B-1A?sku=5574" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-4">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SR1004B-1A_C2_04.jpg" alt="SR1004B-1A_C1_04" class="">
                                    <h5>SR1004B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SR1004B-1A?sku=5575" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-4">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>

                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-6.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-7.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                                                <!-- SRK1004B-1A C1 -->
                        <li class="lineup__item__list-item lineup__item__list-item--kids" data-aos="fade-up" data-aos-delay="100">
                            <div class="lineup__item__product-slider lineup__item__product-slider--for">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1004B-1A_C1_04.jpg" alt="SRK1004B-1A_C1_04" class="">
                                    <h5>SRK1004B-1A C1<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1004B-1A?sku=5576" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-4">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/SRK1004B-1A_C2_04.jpg" alt="SRK1004B-1A_C1_04" class="">
                                    <h5>SRK1004B-1A C2<br>S$178</h5>
                                                                        <a href="/sg/en/products/SRK1004B-1A?sku=5577" class="l-cinnamoroll__btn l-cinnamoroll__btn--lineup-4">CHECK ITEM
                                        <span class="l-cinnamoroll__btn__inner">
                                            <span class="l-cinnamoroll__btn__blobs">
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                                <span class="l-cinnamoroll__btn__blob"></span>
                                            </span>
                                        </span>
                                    </a>
                                                                    </div>
                            </div>
                            <div class="lineup__item__product-slider lineup__item__product-slider--nav">
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-6.png" alt="" class=""></span>
                                </div>
                                <div>
                                    <span><img src="https://www.owndays.com/images/specials/products/cinnamoroll/color-7.png" alt="" class=""></span>
                                </div>
                            </div>
                        </li>
                        <!-- SRK1004B-1A C1 -->
                                            </ul>
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-4.svg" alt="" class="lineup__item__cloud lineup__item__cloud--1 " data-aos="slide-left">
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/cloud-lineup-4.svg" alt="" class="lineup__item__cloud lineup__item__cloud--2 " data-aos="slide-right">
                </div>
                <!-- Line up / Button -->
                <div class="l-cinnamoroll__btn-group">
                                        <a href="/sg/en/products?productLines[]=40" class="l-cinnamoroll__btn l-cinnamoroll__btn--common disabled view_timer" data-end-date="2021/10/15 11:00">ALL ITEMS</a>
                    <a href="/sg/en/products?productLines[]=40" class="l-cinnamoroll__btn l-cinnamoroll__btn--common view_timer" data-start-date="2021/10/15 11:00">ALL ITEMS</a>
                                        <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-cinnamoroll__btn l-cinnamoroll__btn--common font-tbudrgothic">SEARCH FOR NEARBY STORES</a>
                </div>
            </div>
        </div>
        <!-- Accessory -->
        <div class="accessory">
            <div class="l-cinnamoroll__container">
                <h2 class="l-cinnamoroll__title text-hide">ACCESSORY<img src="https://www.owndays.com/images/specials/products/cinnamoroll/accessory-title.svg" alt="accessory" class=""></h2>
                <div class="accessory__footer">
                    <div class="accessory__character-subtitle">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-7.png" alt="cinnamoroll" class="accessory__character">
                        <h5 class="accessory__subtitle">Receive an original spectacle cloth and case<br>with every frame purchased!</h5>
                    </div>
                    <p class="accessory__note">*Regular model comes with white spectacle case. <br class="u-sp-only">Kids model comes with green spectacle case.</p>
                </div>
                <div class="accessory__kids-model th-none au-none">
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/accessory-kids.webp" alt="Fluffy glasses case for kids models" class="">
                    <h5>KIDS<br>SIZE</h5>
                </div>
                <div class="accessory__set">
                    <img src="https://www.owndays.com/images/specials/products/cinnamoroll/accessory-set.webp" alt="Original limited set item" class="accessory__set__img ">
                    <p class="accessory__set__text accessory__set__text--1">
                        <span>Spectacle cloth with</span>
        <span>OWNDAYS-only character art</span>                    </p>
                    <p class="accessory__set__text accessory__set__text--2">
                        <span>Fluffy spectacle case</span>
        <span>inspired by clouds</span>                    </p>
                </div>
            </div>
        </div>
        <!-- Recommend -->
        <div class="recommend">
            <div class="l-cinnamoroll__container">
                <h2 class="l-cinnamoroll__title text-hide">MEET Cinnamoroll AND HIS FRIENDS<img src="https://www.owndays.com/images/specials/products/cinnamoroll/sg/recommend-title.svg" alt="meet Cinnamoroll and his friends" class=""></h2>
                <div class="recommend__content">
                    <div class="recommend__img">
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/character-8.svg" alt="meet Cinnamoroll and his friends" class="">
                        <ul class="recommend__img__stars">
                            <li><span>1</span></li>
                            <li><span>2</span></li>
                            <li><span>3</span></li>
                            <li><span>4</span></li>
                        </ul>
                    </div>
                    <ol class="recommend__list">
                        <li>
                            <h5>Cinnamoroll</h5>
                            <p>A white male puppy born on<br>the clouds high up in the sky</p>
                        </li>
                        <li>
                            <h5>Poron</h5>
                            <p>A mysterious girl who<br>travels with Corune</p>
                        </li>
                        <li>
                            <h5>Milk</h5>
                            <p>Longing to fly in the air some day
        <br>just like Cinnamoroll</p>
                        </li>
                        <li>
                            <h5>Corune</h5>
                            <p>A time traveller who can<br>journey into the fairy-tale world</p>
                        </li>
                    </ol>

                    <a href="https://www.sanrio.co.jp/character/cinnamon/" target="_blank" rel="noopener noreferrer" class="l-cinnamoroll__btn l-cinnamoroll__btn--common font-tbudrgothic">Cinnamoroll Official Site</a>
                </div>
            </div>
                        <img src="https://www.owndays.com/images/specials/products/cinnamoroll/recommend-cloud.svg" alt="" class="recommend__cloud ">
        </div>
        <!-- Share -->
        <div class="share">
            <div class="l-cinnamoroll__container">
                <h2 class="share__title">SHARE</h2>
                <ul class="share__list">
                    <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/cinnamoroll" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/cinnamoroll" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="http://line.me/R/msg/text/?https://www.owndays.com/sg/en/news/cinnamoroll" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-line"></i>
                        </a>
                    </li>
                </ul>
                <p class="share__copyright">©︎ 2021 SANRIO CO., LTD. APPROVAL NO. L624702</p>
            </div>
        </div>

        <!-- Button blob -->
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="d-none">
            <defs>
                <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                    <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                </filter>
            </defs>
        </svg>
</section>

        </main>
            </div>

    
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/rellax/1.12.1/rellax.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-cinnamoroll.js"></script>
       
</body>

</html>`;

const CinnamorollHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Discover the charm of Cinnamoroll x OWNDAYS eyewear collection, featuring frames inspired by Cinnamoroll, Milk, Corune, and Poron. Chic, versatile, and adorable!"
        title="Cinnamoroll x OWNDAYS | OWNDAYS Singapore Online Store"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default CinnamorollHTML;
