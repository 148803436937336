import React, { useState } from 'react';
import './CartAuthModalSignUp.scss';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import CustomDatePicker from '../../../../own-days/CommonComponents/CustomDatePicker/CustomDatePicker';
import OdEyeIcon from '../../../../../static/assets/svg/od-eye-icon.svg';
import OdCalendarLightIcon from '../../../../../static/assets/svg/od-calendar-light-icon.svg';
import OdChevronLeftLightIcon from '../../../../../static/assets/svg/od-chevron-left-light-icon.svg';

const CartAuthModalSignUp = ({ isFinishSignUp }) => {
  const dataLocale = useSelector(state => state.locale);

  const {
    CART_AUTH_SIGN_UP_TITLE,
    ADD_REFERRAL_CODE_HERE,
    SIGNUP,
    EXISTING_CUSTOMER_QUESTION,
    COMPLETE_SIGNUP,
    SIGN_IN,
    EMAIL_ID,
    PASSWORD,
    TOGGLE,
    FULL_NAME,
    PHONE_NO,
    DOB_WITH_FORMAT,
    CART_AUTH_SIGN_UP_FINISH_TITLE,
    FINISH_SIGNUP,
    BACK,
  } = dataLocale;

  const [fullName, setFullName] = useState('');
  const [phoneCode] = useState('+65');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [showUserPassword, setShowUserPassword] = useState('');

  const toggleCalendar = event => {
    event.stopPropagation();
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <div className="od-cart-auth-modal-sign-up">
      <div className="od-cart-auth-modal-sign-up__header od-font-reg">
        {isFinishSignUp ? CART_AUTH_SIGN_UP_FINISH_TITLE : CART_AUTH_SIGN_UP_TITLE}
      </div>

      <div className="od-cart-auth-modal-sign-up__form">
        {isFinishSignUp && (
          <img
            alt={BACK}
            className="od-cart-auth-modal-sign-up__back"
            src={OdChevronLeftLightIcon}
          />
        )}
        <div className="od-cart-auth-modal-sign-up__title od-font-bold">
          {isFinishSignUp ? FINISH_SIGNUP : SIGNUP}
        </div>

        <div className="od-cart-auth-modal-sign-up-field od-font-reg">
          <input
            autoComplete="fullName"
            className="od-cart-auth-modal-sign-up-field__input od-font-reg"
            name="fullName"
            placeholder={FULL_NAME}
            value={fullName}
            onInput={$event => setFullName($event.target.value)}
          />
        </div>

        <div className="od-cart-auth-modal-sign-up-field od-font-reg">
          <span className="od-cart-auth-modal-sign-up-field__phone-code od-font-regs">
            {phoneCode}
          </span>
          <input
            autoComplete="phone"
            className="od-cart-auth-modal-sign-up-field__input od-font-reg"
            name="phone"
            placeholder={PHONE_NO}
            value={phone}
            onInput={$event => setPhone($event.target.value)}
          />
        </div>

        <div className="od-cart-auth-modal-sign-up-field od-font-reg">
          <input
            autoComplete="email"
            className="od-cart-auth-modal-sign-up-field__input od-font-reg"
            name="email"
            placeholder={capitalizeFirstLetter(EMAIL_ID.toLowerCase())}
            value={email}
            onInput={$event => setEmail($event.target.value)}
          />
        </div>

        <div className="od-cart-auth-modal-sign-up-field od-font-reg">
          <CustomDatePicker
            containerClassName="od-cart-auth-modal-sign-up-field__date-container"
            icon={OdCalendarLightIcon}
            isCalendarOpen={isCalendarOpen}
            placeholderText={DOB_WITH_FORMAT}
            setIsCalendarOpen={setIsCalendarOpen}
            value={dob}
            onChange={setDob}
            onClick={toggleCalendar}
          />
        </div>

        <div className="od-cart-auth-modal-sign-up-field od-font-reg">
          <input
            autoComplete="password"
            className="od-cart-auth-modal-sign-up-field__input od-font-reg"
            name="password"
            placeholder={PASSWORD}
            type={showUserPassword ? 'text' : 'password'}
            value={password}
            onInput={$event => setPassword($event.target.value)}
          />
          <div
            className={`od-cart-auth-modal-sign-up-field__password-eye ${
              showUserPassword && 'od-cart-auth-modal-sign-up-field__password-eye--visible'
            }`}
            onClick={() => setShowUserPassword(!showUserPassword)}
          >
            <img
              alt={TOGGLE}
              className="od-cart-auth-modal-sign-up-field__password-eye-icon"
              src={OdEyeIcon}
            />
          </div>
        </div>

        <div className="od-cart-auth-modal-sign-up__referral od-font-bold">
          {ADD_REFERRAL_CODE_HERE}
        </div>

        <div className="od-cart-auth-modal-sign-up__submit-button od-font-reg">
          {COMPLETE_SIGNUP}
        </div>

        <div className="od-cart-auth-modal-sign-up__sign-in-wrapper od-font-reg">
          <span className="od-cart-auth-modal-sign-up__sign-in-question od-font-reg">
            {EXISTING_CUSTOMER_QUESTION}{' '}
          </span>
          <span className="od-cart-auth-modal-sign-up__sign-in od-font-bold">{SIGN_IN}</span>
        </div>
      </div>
    </div>
  );
};

export default CartAuthModalSignUp;
