import React, { useState, useEffect } from 'react';
import './FaqPage.scss';
import { useSelector } from 'react-redux';
import BlackPlus from '../../../../../static/assets/svg/od-plus-black.svg';
import { v4 } from 'uuid';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const FaqPage = () => {
  const dataLocale = useSelector(state => state.locale);
  const { FAQ_PAGE } = dataLocale;
  const { title, FAQ_LIST } = FAQ_PAGE;

  const [faqList, setFaqList] = useState(null);

  useEffect(() => {
    if (FAQ_LIST && FAQ_LIST.length > 0) {
      const faqDataWithId = FAQ_LIST.map(faq => {
        return {
          ...faq,
          id: v4(),
        };
      });
      setFaqList(faqDataWithId);
    }
  }, [FAQ_LIST]);

  const faqItemHandler = id => {
    setFaqList(prevState =>
      prevState.map(faq => {
        if (faq.id === id) {
          return {
            ...faq,
            handler: !faq.handler,
          };
        }
        return faq;
      })
    );
  };
  return (
    <>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Explore our Frequently Asked Questions (FAQ) to find answers about eyewear, services, and more at OWNDAYS Singapore. Get informed for a better eyewear experience.',
          },
          {
            name: 'twitter:description',
            content:
              'Explore our Frequently Asked Questions (FAQ) to find answers about eyewear, services, and more at OWNDAYS Singapore. Get informed for a better eyewear experience.',
          },
          {
            name: 'og:title',
            content: 'Frequently Asked Questions (FAQ) | OWNDAYS Singapore',
          },
          {
            name: 'twitter:title',
            content: 'Frequently Asked Questions (FAQ) | OWNDAYS Singapore',
          },
        ]}
        description="Explore our Frequently Asked Questions (FAQ) to find answers about eyewear, services, and more at OWNDAYS Singapore. Get informed for a better eyewear experience."
        title="Frequently Asked Questions (FAQ) | OWNDAYS Singapore"
      />
      <main>
        <section className="od-faq">
          <div className="od-faq__title od-font-bold">{title}</div>
          {faqList && faqList.length > 0 && (
            <div className="od-faq__content">
              <dl className="od-faq__content__list">
                {faqList.map(el => {
                  return (
                    <React.Fragment key={el.id}>
                      <dt onClick={() => faqItemHandler(el.id)}>
                        <div className="od-faq__content__list__item-title od-font-bold">
                          <span>{el.title}</span>
                          <img alt="black plus" height="16px" src={BlackPlus} width="16px" />
                        </div>
                      </dt>
                      {el.handler && (
                        <dd className="od-faq__content__list__item-description od-font-reg">
                          <span>{el.description}</span>
                          {el.answersList && el.answersList.length > 0 && (
                            <ul className="od-faq__content__list__item-description__answer-sheet">
                              {el.answersList.map(el => {
                                return <li key={v4()}>{el.title}</li>;
                              })}
                            </ul>
                          )}
                        </dd>
                      )}
                    </React.Fragment>
                  );
                })}
              </dl>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default FaqPage;
