import React, { useEffect } from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5WRT43B');</script>
<!-- End Google Tag Manager -->    
        <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
        <script>
    /*TAIWAN 20150119
     window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
     d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
     _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');
     $.src='//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';z.t=+new Date;$.
     type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script');
     */
    window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
        d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
    _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');
        $.src='//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';z.t=+new Date;$.
            type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script');
</script>
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    
    <!-- CSRF Token -->
    <meta name="csrf-token" content="wesZ8X3yPTPaS29Hgx1xYLxXziYMkLkfpisUiAU7">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="OWNDAYS+ | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="OWNDAYS aspires to present eyewear that is modest and a true reflection of the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+." />
<meta name="keywords" content="OWNDAYS+,minimalist,simple,exemplary quality,price-performance ratio,resin,Made-in-Japan,owndays" />
<meta name="og:description" content="OWNDAYS aspires to present eyewear that is modest and a true reflection of the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
<meta property="og:title" content="OWNDAYS+ | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta property="og:description" content="OWNDAYS aspires to present eyewear that is modest and a true reflection of the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+." />
<meta property="og:image" content="https://www.owndays.com/images/specials/products/owndays-plus/sg/ogp_1200x630.jpg">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/owndays-plus2023">
          <meta property="og:image" content="https://www.owndays.com/images/specials/ogp_1200x630.jpg?1553872731">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=660b37960541a180cc0c?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=660b37960541a180cc0c?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
<link rel="stylesheet" href="https://www.owndays.com/web/css/specials-owndays-plus.css">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">OWNDAYS+ | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
        <div id="top-information">
    
        </div>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en">Homepage</a>
                                                                                </li>
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            OWNDAYS+                    </li>
        
            </ul>
</div>


<div class="l-owndays-plus">
    <section class="main">
        <div class="main__img" data-aos="zoom-out">
                        <picture>
                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/owndays-plus/main_pc-b.webp">
                <img src="https://www.owndays.com/images/specials/products/owndays-plus/main_sp-b.webp" alt="OWNDAYS+ Main Visual">
            </picture>
                    </div>
        <div class="l-owndays-plus__container">
            <p class="main__text">
                <span class="main__text-bg">
                    <span class="main__text-inner">Modesty – a classic Japanese essence</span>
                </span>
                <span class="main__text-bg">
                    <span class="main__text-inner">Exemplified in its price and design</span>
                </span>
                <span class="main__text-bg">
                    <span class="main__text-inner"></span>
                </span>
            </p>
            <div class="main__heading" data-aos="fade-up">
                <h1 class="main__heading-title"><img src="https://www.owndays.com/images/specials/products/owndays-plus/OWNDAYS_PLUS.svg" alt="OWNDAYS+"></h1>
                <p class="main__heading-price"><span><strong>S$98</strong></span></p>
            </div>
        </div>
    </section>
    <section class="concept">
        <div class="l-owndays-plus__container">
            <h2 class="concept__title" data-aos="text-slide-up">
                <span class="concept__title-ja">
                    <span>日本製</span>
                </span>
                <span class="concept__title-en">
                    <span>MADE IN JAPAN</span>
                </span>
            </h2>
                        <div class="concept__grid">
                <div class="concept__img concept__img--1"><img src="https://www.owndays.com/images/specials/products/owndays-plus/concept-1-b.webp" alt="OWNDAYS+ Women・Men" data-aos="zoom-out"></div>
                <p class="concept__description" data-aos="fade-in" data-aos-delay="300">Subtle and modest, yet infused with the true spirit of Japanese craftsmanship.<br>Embracing techniques from Japan to create beautiful eyewear with simple design and price point.<br><br>Where minimalist aesthetic and sublime quality meet efficient pricing<br>That’s OWNDAYS+</p>
            </div>
            <div class="concept__grid concept__grid--img">
                <div class="concept__img concept__img--2"><img src="https://www.owndays.com/images/specials/products/owndays-plus/concept-2-b.webp" alt="OWNDAYS+ Men" data-aos="zoom-out"></div>
                <div class="concept__img concept__img--3"><img src="https://www.owndays.com/images/specials/products/owndays-plus/concept-3-b.webp" alt="OR2063L-2S C1/OR2064L-2S C3" data-aos="zoom-out"></div>
            </div>
                    </div>
    </section>
    <section class="movie">
        <div class="l-owndays-plus__container">
            <div class="movie__grid">
                <!-- <h2 class="movie__title" data-aos="fade-up"><span>MOVIE</span></h2> -->
                <div class="movie__video" data-aos="fade-up">
                    <a data-fancybox href="https://youtu.be/_H258iv-XRQ" class="movie__video-link">
                        <img src="https://www.owndays.com/images/specials/products/owndays-plus/sg/movie-cover.webp" alt="OWNDAYS+ CM" class="movie__video-cover">
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="feature">
        <div class="l-owndays-plus__container">
                        <ul class="feature__list">
                <li class="feature__item" data-aos="fade-up">
                    <h5 class="feature__item-title">
                        <span class="feature__item-title-number">01</span>
                        <span class="feature__item-title-text">
                            <span>Made in Japan</i></span>                            <span>with Exemplary Quality</span>                        </span>
                    </h5>
                    <div class="feature__item-img">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/owndays-plus/feature-1-b.webp">
                            <img src="https://www.owndays.com/images/specials/products/owndays-plus/feature-1_sp-b.webp" alt="Features①">
                        </picture>
                    </div>
                    <p class="feature__item-description">Balancing exemplary, Made-in-Japan quality at a valuable price point of S$98 to attain excellent price-performance ratio.</p>
                </li>
                <li class="feature__item" data-aos="fade-up" data-aos-delay="300">
                    <h5 class="feature__item-title">
                        <span class="feature__item-title-number">02</span>
                        <span class="feature__item-title-text">
                            <span>Extremely Light</span>                            <span>while Offering Impeccable Fit</span>                        </span>
                    </h5>
                    <div class="feature__item-img">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/owndays-plus/feature-2-b.webp">
                            <img src="https://www.owndays.com/images/specials/products/owndays-plus/feature-2_sp-b.webp" alt="Features②">
                        </picture>
                    </div>
                    <p class="feature__item-description">Made of lightweight resin that is extremely flexible; with elastic temples that wrap snuggly around the head for an impeccable fit.</p>
                </li>
                <li class="feature__item" data-aos="fade-up" data-aos-delay="600">
                    <h5 class="feature__item-title">
                        <span class="feature__item-title-number">03</span>
                        <span class="feature__item-title-text">
                            <span>A Minimalist Style</span>                            <span>Designed to Last</span>                        </span>
                    </h5>
                    <div class="feature__item-img">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/owndays-plus/feature-3-b.webp">
                            <img src="https://www.owndays.com/images/specials/products/owndays-plus/feature-3_sp-b.webp" alt="Features③">
                        </picture>
                    </div>
                    <p class="feature__item-description">A minimalist style that will always be adored; featuring a sleek silhouette that works for any occasion.</p>
                </li>
            </ul>
                    </div>
    </section>
    <section class="lineup">
        <div class="l-owndays-plus__container">
            <h2 class="lineup__title" data-aos="fade-up">LINE UP</h2>
            <p class="lineup__description" data-aos="fade-up">Minimalist eyewear made of Japanese resin, where resonances of classic,<br class="u-pc-only">modest Japanese design come to life.<br class="u-pc-only">Functional, versatile and perfect for any face shape.</p>
            <h3 class="lineup__price" data-aos="fade-up"><span><small>3 types / 16 variations in total</small><strong>ALL <b>S$98</b></strong></span></h3>
            <ul class="lineup__nav">
                <li class="lineup__nav-item">
                    <a data-scroll href="#wellington" class="lineup__nav-link">
                        <p class="lineup__nav-number"><small>TYPE</small>01</p>
                        <img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2063L-2S_C4.jpg" alt="OR2063L-2S C4" class="lineup__nav-img">
                        <h5 class="lineup__nav-title">Wellington</h5>
                    </a>
                </li>
                <li class="lineup__nav-item">
                    <a data-scroll href="#square" class="lineup__nav-link">
                        <p class="lineup__nav-number"><small>TYPE</small>02</p>
                        <img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2061L-2S_C1.jpg" alt="OR2061L-2S C1" class="lineup__nav-img">
                        <h5 class="lineup__nav-title">Rectangle</h5>
                    </a>
                </li>
                <li class="lineup__nav-item">
                    <a data-scroll href="#boston" class="lineup__nav-link">
                        <p class="lineup__nav-number"><small>TYPE</small>03</p>
                        <img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2064L-2S_C3.jpg" alt="OR2064L-2S C3" class="lineup__nav-img">
                        <h5 class="lineup__nav-title">Boston</h5>
                    </a>
                </li>
                            </ul>
            <div class="lineup__item" id="wellington">
                <div class="lineup__item-grid">
                    <div class="lineup__item-details">
                        <h4 class="lineup__item-title" data-aos="fade-up"><span>Wellington</span></h4>
                        <p class="lineup__item-description" data-aos="fade-up">A crossover between an inverted trapezium and a rectangle frame with curved edges, this is a versatile design that is both cool and smart.</p>
                        <p class="lineup__item-facetype" data-aos="fade-up"><span>Ideal Face Shape</span>Oblong</p>
                    </div>
                    <div class="lineup__item-cover" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/owndays-plus/Wellington.webp" alt="wellington"></div>
                </div>
                <ul class="lineup__products">
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2063L-2S?sku=6015" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2063L-2S_C1.jpg" alt="OR2063L-2S C1"></div>
                            <h5 class="lineup__products-sku"><b>OR2063L-2S C1</b>Matte Black</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2063L-2S?sku=6016" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2063L-2S_C2.jpg" alt="OR2063L-2S C2"></div>
                            <h5 class="lineup__products-sku"><b>OR2063L-2S C2</b>Brown Demi</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2063L-2S?sku=6017" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2063L-2S_C3.jpg" alt="OR2063L-2S C3"></div>
                            <h5 class="lineup__products-sku"><b>OR2063L-2S C3</b>Brown</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2063L-2S?sku=6018" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2063L-2S_C4.jpg" alt="OR2063L-2S C4"></div>
                            <h5 class="lineup__products-sku"><b>OR2063L-2S C4</b>Clear Gray</h5>
                        </a>
                    </li>
                </ul>
                <div class="lineup__btn" data-aos="fade-up">
                    <a href="/sg/en/products/OR2063L-2S" class="l-owndays-plus__btn">More details</a>
                </div>
            </div>
            <div class="lineup__item lineup__item--even" id="square">
                <div class="lineup__item-grid">
                    <div class="lineup__item-details">
                        <h4 class="lineup__item-title" data-aos="fade-up"><span>Rectangle</span></h4>
                        <p class="lineup__item-description" data-aos="fade-up">A rectangular style with slim lenses that gives the frame a linear structure on the whole, thereby creating a sharp impression.</p>
                        <p class="lineup__item-facetype" data-aos="fade-up"><span>Ideal Face Shape</span>Round</p>
                    </div>
                    <div class="lineup__item-cover" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/owndays-plus/Square.webp" alt="rectangle"></div>
                </div>
                <ul class="lineup__products">
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2061L-2S?sku=6007" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2061L-2S_C1.jpg" alt="OR2061L-2S C1"></div>
                            <h5 class="lineup__products-sku"><b>OR2061L-2S C1</b>Matte Black</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2061L-2S?sku=6008" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2061L-2S_C2.jpg" alt="OR2061L-2S C2"></div>
                            <h5 class="lineup__products-sku"><b>OR2061L-2S C2</b>Clear Gray</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2061L-2S?sku=6009" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2061L-2S_C3.jpg" alt="OR2061L-2S C3"></div>
                            <h5 class="lineup__products-sku"><b>OR2061L-2S C3</b>Navy</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2061L-2S?sku=6010" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2061L-2S_C4.jpg" alt="OR2061L-2S C4"></div>
                            <h5 class="lineup__products-sku"><b>OR2061L-2S C4</b>Khaki</h5>
                        </a>
                    </li>
                </ul>
                <div class="lineup__btn" data-aos="fade-up">
                    <a href="/sg/en/products/OR2061L-2S" class="l-owndays-plus__btn">More details</a>
                </div>
            </div>
            <div class="lineup__item" id="boston">
                <div class="lineup__item-grid">
                    <div class="lineup__item-details">
                        <h4 class="lineup__item-title" data-aos="fade-up"><span>Boston</span></h4>
                        <p class="lineup__item-description" data-aos="fade-up">A contemporary style that exudes intelligence and sensibility, in a round shape that bears resemblance to an inverted triangle.</p>
                        <p class="lineup__item-facetype" data-aos="fade-up"><span>Ideal Face Shape</span>Triangle</p>
                    </div>
                    <div class="lineup__item-cover" data-aos="fade-up"><img src="https://www.owndays.com/images/specials/products/owndays-plus/Boston.webp" alt="boston"></div>
                </div>
                <ul class="lineup__products">
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2064L-2S?sku=6019" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2064L-2S_C1.jpg" alt="OR2064L-2S C1"></div>
                            <h5 class="lineup__products-sku"><b>OR2064L-2S C1</b>Black</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2064L-2S?sku=6020" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2064L-2S_C2.jpg" alt="OR2064L-2S C2"></div>
                            <h5 class="lineup__products-sku"><b>OR2064L-2S C2</b>Brown Demi</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2064L-2S?sku=6021" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2064L-2S_C3.jpg" alt="OR2064L-2S C3"></div>
                            <h5 class="lineup__products-sku"><b>OR2064L-2S C3</b>Brown</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2064L-2S?sku=6022" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2064L-2S_C4.jpg" alt="OR2064L-2S C4"></div>
                            <h5 class="lineup__products-sku"><b>OR2064L-2S C4</b>Beige</h5>
                        </a>
                    </li>
                </ul>
                <div class="lineup__btn" data-aos="fade-up">
                    <a href="/sg/en/products/OR2064L-2S" class="l-owndays-plus__btn">More details</a>
                </div>
                <ul class="lineup__products">
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2065L-2S?sku=6023" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2065L-2S_C1.jpg" alt="OR2065L-2S C1"></div>
                            <h5 class="lineup__products-sku"><b>OR2065L-2S C1</b>Brown Demi</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2065L-2S?sku=6024" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2065L-2S_C2.jpg" alt="OR2065L-2S C2"></div>
                            <h5 class="lineup__products-sku"><b>OR2065L-2S C2</b>Light Brown</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2065L-2S?sku=6025" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2065L-2S_C3.jpg" alt="OR2065L-2S C3"></div>
                            <h5 class="lineup__products-sku"><b>OR2065L-2S C3</b>Wine</h5>
                        </a>
                    </li>
                    <li class="lineup__products-item">
                        <a href="/sg/en/products/OR2065L-2S?sku=6026" class="lineup__products-link">
                            <div class="lineup__products-img"><img src="https://www.owndays.com/images/specials/products/owndays-plus/products/OR2065L-2S_C4.jpg" alt="OR2065L-2S C4"></div>
                            <h5 class="lineup__products-sku"><b>OR2065L-2S C4</b>Khaki</h5>
                        </a>
                    </li>
                </ul>
                <div class="lineup__btn" data-aos="fade-up">
                    <a href="/sg/en/products/OR2065L-2S" class="l-owndays-plus__btn">More details</a>
                </div>
            </div>
                    </div>
    </section>
    <section class="bottom">
        <div class="l-owndays-plus__container">
            <div class="bottom__btn">
                <a href="/sg/en/products?productLines%5B0%5D=41" class="l-owndays-plus__btn l-owndays-plus__btn--beige" data-aos="fade-up">See all OWNDAYS+ frames</a>
                <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-owndays-plus__btn l-owndays-plus__btn--beige" data-aos="fade-up">Search For Nearby Stores</a>
            </div>
        </div>
    </section>
</div>

        </main>
            </div>

        <div class="whats-app noprint">
        <a href="https://wa.me/6598214268" target="_blank" rel="nofollow">
            <img src="https://www.owndays.com/images/common/logo-whatapp.png" alt="WhatsApp">
        </a>
    </div>
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>

<footer class="l-footer l-container-fluid">
        <!-- MENU -->
    <div class="l-footer__menu l-container">
        <div class="l-footer__menu__categories">
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>PRODUCTS<span class="c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li><a href="/sg/en/eyeglasses">Frames</a></li>
                    <li><a href="/sg/en/sunglasses">Sunglasses</a></li>
                                        <li><a href="/sg/en/news/owndays-contactlens-sg">Contact Lens</a></li>
                                        
                    
                    <li><a href="/sg/en/brands">All Collections</a></li>
                    <li><a href="/sg/en/news">Campaigns</a></li>
                                    </ul>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>PURCHASE<span class="c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    
                    
                    <li><a href="/sg/en/services/lens">Lens Guide</a></li>
                    <li><a href="/sg/en/services/warranty">OWNDAYS Customer Guarantees</a></li>
                </ul>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another"><a href="/sg/en/shops?autoSearchCurrentLocation=true">Shops<span class="c-menu--arrows c-menu--arrows-link u-sp-only"></span></a></div>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p> ABOUT<span class="c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li class="u-pc-only"><a href="/sg/en/shops?autoSearchCurrentLocation=true">Shops</a></li>
                    <li><a href="/sg/en/services">Services</a></li>
                    <li><a href="/sg/en/services/lens-replacement">Lens Replacement</a></li>
                                        <li class="sg-none"><a href="/sg/en/account">OWNDAYS MEMBERSHIP</a></li>
                                        <li><a href="/sg/en/account">Membership</a></li>
                                                            <li><a href="/sg/en/company">Corporate Information</a></li>
                                        <li><a href="https://www.odsaiyou.com/sg/" target="_blank">Recruitment </a></li>
                                        <li><a href="/sg/en/company/eyecamp">Eye Camp</a></li>
                </ul>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>SUPPORT<span class="c-menu--arrows c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li class="u-pc-only">
                                            </li>
                    <li>
                                                    <a href="/sg/en/faq">FAQs</a>
                                            </li>
                                        <li><a href="/sg/en/requirement">System Requirements</a></li>
                    <li><a href="/sg/en/sitemap">Site Map</a></li>
                                        <li class="u-pc-only u-mt__20"><a href="/sg/en/services/onlinestore">New Users</a></li>
                                        <li class="u-pc-only"><a href="/sg/en/register">Sign Up an Account</a></li>
                                                        </ul>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another">
                    <a href="/sg/en/contact">Contact Us<span class="c-menu--arrows c-menu--arrows-link u-sp-only"></span></a>
                </div>
            </div>

                        <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another"><a href="/sg/en/services/onlinestore">New Users</a></div>
            </div>
                        <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another"><a href="/sg/en/register">Sign Up an Account<span class="c-menu--arrows c-menu--arrows-link"></span></a></div>
            </div>
                                    <div class="l-footer__contact">
                <div class="l-footer__menu__category-list u-pc-only">CONTACT US</div>
                <div class="u-sp-only">Contact Us</div>
                <p class="l-footer__contact-tel">
                    <a id="footer_modal_contact" href="tel:62220588">6222-0588</a>
                    <span>Operating Hours <br>10:00 - 19:00</span>
                                    </p>
                            </div>
        </div>
        <!-- SNS -->
        <ul class="l-footer__sns-link">
                        <li><a href="https://www.facebook.com/owndays.sg/" target="_blank" rel="nofollow" title="Facebook" aria-label="Facebook"><i class="fab fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/owndays_sg/" target="_blank" rel="nofollow" title="instagram" aria-label="Instagram"><i class="fab fa-instagram"></i></a></li>
            <li><a href="https://www.youtube.com/channel/UCSu2yqzCpnTtGXi05WtsiZg" target="_blank" rel="nofollow" title="youtube" aria-label="Youtube"><i class="fab fa-youtube"></i></a></li>
                    </ul>
            </div>
    <div class="l-footer__bottom">
        <div class="l-footer__bottom-inner">
            <div class="l-footer__copyright">
                <p>COPYRIGHT (C) OWNDAYS co., ltd. ALL RIGHTS RESERVED.</p>
            </div>
            <div class="l-footer__bottom-grid">
                <div class="l-footer__language">
                    <button type="button" data-toggle="modal" data-target="#modalCountryLang" class="l-footer__country-lang border-0">
                        <span>
                            <picture>
                                <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                <source srcset="https://www.owndays.com/images/flags/sg.png" type="image/png">
                                <img class="lazyload" src="https://www.owndays.com/images/flags/sg.png" alt="sg" data-sizes="auto">
                            </picture> Singapore                        </span>
                    </button>
                </div>
                <ul class="l-footer__language-link">
                                                            <li>
                        <a href="https://www.owndays.com/sg/en/news/owndays-plus2023">English</a>
                    </li>
                                                                              
                                                        </ul>
                <ul class="l-footer__agreement">
                                        <li><a href="/sg/en/privacy">PRIVACY POLICY</a></li>
                    <li><a href="/sg/en/terms">TERMS & CONDITIONS</a></li>
                                    </ul>
            </div>
                    </div>
    </div>
</footer>

<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                                                                                                                                                                                                    </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                                                                                                                                                                                                    </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=63b50ca8cf73fb967649?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/scrollReveal.js/4.0.9/scrollreveal.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-owndays-plus.js"></script>
<script>
    (function(d) {
        var config = {
                kitId: 'hdk2aki',
                scriptTimeout: 3000,
                async: true
            },
            h = d.documentElement,
            t = setTimeout(function() {
                h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
            }, config.scriptTimeout),
            tk = d.createElement("script"),
            f = false,
            s = d.getElementsByTagName("script")[0],
            a;
        h.className += " wf-loading";
        tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
        tk.async = true;
        tk.onload = tk.onreadystatechange = function() {
            a = this.readyState;
            if (f || a && a != "complete" && a != "loaded") return;
            f = true;
            clearTimeout(t);
            try {
                Typekit.load(config)
            } catch (e) {}
        };
        s.parentNode.insertBefore(tk, s)
    })(document);
</script>
<script>
    (function(d) {
        var config = {
                kitId: 'unf7vgq',
                scriptTimeout: 3000,
                async: true
            },
            h = d.documentElement,
            t = setTimeout(function() {
                h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
            }, config.scriptTimeout),
            tk = d.createElement("script"),
            f = false,
            s = d.getElementsByTagName("script")[0],
            a;
        h.className += " wf-loading";
        tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
        tk.async = true;
        tk.onload = tk.onreadystatechange = function() {
            a = this.readyState;
            if (f || a && a != "complete" && a != "loaded") return;
            f = true;
            clearTimeout(t);
            try {
                Typekit.load(config)
            } catch (e) {}
        };
        s.parentNode.insertBefore(tk, s)
    })(document);
</script>
            <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5WRT43B');</script>
<!-- End Google Tag Manager -->

<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5WRT43B" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

<!-- Facebook Pixel Code -->
<script>
    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1510426245838375'); // Insert your pixel ID here.
    fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=1510426245838375&ev=PageView&noscript=1"
    /></noscript>
<!-- DO NOT MODIFY -->

<!-- User Insight PCDF Code Start : owndays.com -->
<script type="text/javascript">
    var _uic = _uic ||{}; var _uih = _uih ||{};_uih['id'] = 52715;
    _uih['lg_id'] = '';
    _uih['fb_id'] = '';
    _uih['tw_id'] = '';
    _uih['uigr_1'] = ''; _uih['uigr_2'] = ''; _uih['uigr_3'] = ''; _uih['uigr_4'] = ''; _uih['uigr_5'] = '';
    _uih['uigr_6'] = ''; _uih['uigr_7'] = ''; _uih['uigr_8'] = ''; _uih['uigr_9'] = ''; _uih['uigr_10'] = '';

    /* DO NOT ALTER BELOW THIS LINE */
    /* WITH FIRST PARTY COOKIE */
    (function() {
        var bi = document.createElement('script');bi.type = 'text/javascript'; bi.async = true;
        bi.src = '//cs.nakanohito.jp/b3/bi.js';
        var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(bi, s);
    })();
</script>
<!-- User Insight PCDF Code End : owndays.com -->

<script>
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

  ttq.load('CGHAVFBC77UA88R0IJ2G');
  ttq.page();
}(window, document, 'ttq');
</script>
</body>

</html>`;

const OwndaysPlus2023HTML = () => {
    window.location.href = '/sg/en/news/owndays-plus';
  useEffect(() => {
    const loadScripts = async () => {
      try {
        const externalScripts = [
          'https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap',
          'https://www.owndays.com/web/js/active-nav-menu.js?v=1.1',
          'https://www.owndays.com/web/js/lazysizes.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js',
          'https://www.owndays.com/web/js/app.js?id=63b50ca8cf73fb967649?20230222',
          'https://www.owndays.com/web/js/helper.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js',
          'https://cdn.xapping.com/libs.js',
          'https://www.owndays.com/web/js/aos.js',
          'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js',
          'https://cdnjs.cloudflare.com/ajax/libs/video.js/7.21.0/video.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/videojs-youtube/2.6.1/Youtube.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/scrollReveal.js/4.0.9/scrollreveal.min.js',
          'https://www.owndays.com/web/js/specials-owndays-plus.js',
        ];

        await Promise.all(
          externalScripts.map(src => {
            const script = document.createElement('script');
            script.src = src;
            script.defer = true;
            document.head.appendChild(script);
            return new Promise(resolve => {
              script.onload = resolve;
            });
          })
        );
        window.AOS.init();
      } catch (error) {
        console.error('Failed to load scripts:', error);
      }
    };

    loadScripts();
  }, []);
  return (
    <>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'OWNDAYS aspires to present eyewear that is modest and a true reflection of the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+.',
          },
          {
            name: 'twitter:description',
            content:
              'OWNDAYS aspires to present eyewear that is modest and a true reflection of the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+.',
          },
          {
            name: 'og:title',
            content: 'OWNDAYS+ | OWNDAYS ONLINE STORE - OPTICAL SHOP',
          },
          {
            name: 'twitter:title',
            content: 'OWNDAYS+ | OWNDAYS ONLINE STORE - OPTICAL SHOP',
          },
        ]}
        description="OWNDAYS aspires to present eyewear that is modest and a true reflection of the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+."
        title="OWNDAYS+ | OWNDAYS ONLINE STORE - OPTICAL SHOP"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default OwndaysPlus2023HTML;
