import React, { useEffect } from 'react';
import { html } from '../../../../own-days/html/News';
import { useSelector } from 'react-redux';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';
import { hideElementByIdUntilDate } from '../../../helper';

const NewsPage = () => {
  const dataLocale = useSelector(state => state.locale);
  const { NO_PAGE_FOUND } = dataLocale;

  useEffect(() => {
    const loadScript = async () => {
      const script = document.createElement('script');
      script.src = 'https://code.jquery.com/jquery-3.6.4.min.js';
      script.type = 'text/javascript';
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        const specialScript = document.createElement('script');
        specialScript.src = 'https://www.owndays.com/web/js/specials.js';
        specialScript.defer = true;
        document.head.appendChild(specialScript);
      };
    };

    loadScript();

    const hideElementId = 'enhanced-lens-offer';

    const hideElementStyles = `
  #${hideElementId}[data-hidden="true"] {
    display: none !important;
  }
  .l-specials__list > li#${hideElementId} {
    display: none !important;
  }
`;

    if (typeof window !== 'undefined') {
      if (!window?.location?.href?.includes('preproduction')) {
        hideElementByIdUntilDate(hideElementId, '2024-04-01', hideElementStyles);
      }
    }
  }, []);

  if (html) {
    return (
      <>
        <MetaTagsManager
          additionalMeta={[
            {
              name: 'og:description',
              content:
                'Stay up to date with the latest news, trends, and announcements from OWNDAYS. Discover the newest eyewear collections and industry insights. Explore our news page now.',
            },
            {
              name: 'twitter:description',
              content:
                'Stay up to date with the latest news, trends, and announcements from OWNDAYS. Discover the newest eyewear collections and industry insights. Explore our news page now.',
            },
            {
              name: 'og:title',
              content: 'Latest News & Updates | OWNDAYS Singapore Online Store',
            },
            {
              name: 'twitter:title',
              content: 'Latest News & Updates | OWNDAYS Singapore Online Store',
            },
          ]}
          canonical="/news"
          description="Stay up to date with the latest news, trends, and announcements from OWNDAYS. Discover the newest eyewear collections and industry insights. Explore our news page now."
          title="Latest News & Updates | OWNDAYS Singapore Online Store"
        />
        <div dangerouslySetInnerHTML={{ __html: html }} className="od_external_brand_pages"></div>
      </>
    );
  }
  return <div>{NO_PAGE_FOUND}</div>;
};

export default NewsPage;
