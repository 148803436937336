import React from 'react';
import CheckedIcon from '../../../../static/assets/svg/od-checked.svg';
import UnCheckedIcon from '../../../../static/assets/svg/od-unchecked.svg';
import './SelectPrescription.scss';
import { useSelector } from 'react-redux';

const SelectPrescription = ({ hasPrescription, setHasPrescription, isDesktop = false }) => {
  const { SELECT_TYPE, WITH_PRESCRIPTION, WITHOUT_PRESCRIPTION } = useSelector(
    state => state.locale
  );
  return (
    <div
      className={`od-select-prescription ${
        isDesktop ? 'od-select-prescription--desktop' : 'od-select-prescription--mobile'
      }`}
    >
      <h2 className="od-select-prescription__title">{SELECT_TYPE}</h2>
      <div className={`${isDesktop && 'od-select-prescription-flex'}`}>
        <div
          className={`od-select-prescription__item ${
            hasPrescription && 'od-select-prescription__item-active'
          }`}
          onClick={() => setHasPrescription(true)}
        >
          <span>{WITH_PRESCRIPTION}</span>{' '}
          <img alt={WITH_PRESCRIPTION} src={hasPrescription ? CheckedIcon : UnCheckedIcon} />
        </div>
        <div
          className={`od-select-prescription__item ${
            !hasPrescription && 'od-select-prescription__item-active'
          }`}
          onClick={() => setHasPrescription(false)}
        >
          <span>{WITHOUT_PRESCRIPTION}</span>{' '}
          <img alt={WITHOUT_PRESCRIPTION} src={!hasPrescription ? CheckedIcon : UnCheckedIcon} />
        </div>
      </div>
    </div>
  );
};

export default SelectPrescription;
