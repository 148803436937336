import {
  triggerDataLayer,
  triggerDataLayerOnPageView,
  triggerPurchaseEcomEvent,
} from './googleAnalytics';
import {
  addLoyaltyData,
  discountCalculator,
  getMembershipDiscount,
  getOrderContent,
  getProductCategories,
  getProductType,
  getCategoryType,
} from './helper';

const transformUserInfo = data => {
  const userInfo = data.result ?? data;

  if (!userInfo) return userInfo;

  return {
    login: userInfo.login,
    tierLabel: userInfo.tierLabel ?? userInfo.tier_label,
  };
};

const transformCartData = data => {
  const cartData = data.result ?? data;

  if (!cartData) return cartData;

  return {
    totals: cartData.totals && {
      total: cartData.totals.total,
      discounts:
        cartData.totals.discounts &&
        cartData.totals.discounts.map(discount => ({
          type: discount.type,
          code: discount.code,
        })),
    },
    currencyCode: cartData.currencyCode ?? cartData.currency_code,
    items:
      cartData.items &&
      cartData.items.map(item => ({
        id: item.id,
        modelName: item.modelName ?? item.model_name,
        brandName: item.brandName ?? item.brand_name,
        sku: item.sku,
        frameColour: item.frameColour ?? item.frame_colour,
        price: item.price && {
          currencyCode: item.price.currencyCode ?? item.price.currency_code,
          value: item.price.value,
        },
        quantity: item.quantity,
      })),
  };
};

const transformProductData = data => {
  console.log('transform product data ===>', data);
  const productData = data.result ?? data;

  if (!productData) return productData;

  return {
    id: productData.id,
    modelName: productData.modelName ?? productData.model_name,
    brandName: productData.brandName ?? productData.brand_name,
    sku: productData.sku,
    selectedCoating: productData?.selectedCoating || null,
    selectedPackage: productData?.selectedPackage || null,
    selectedPrescriptionType: productData?.selectedPrescriptionType || null,
    frameColour: productData.frameColour ?? productData.frame_colour,
    prices:
      productData.prices &&
      productData.prices.map(price => ({
        name: price.name,
        currencyCode: price.currencyCode ?? price.currency_code,
        price: price.price,
      })),
    qty: productData.qty,
  };
};

const transformPromotionData = data => {
  const promotionData = data.result ?? data;

  if (!promotionData) return promotionData;

  return {
    title: promotionData.title,
  };
};

const transformCategoryData = data => {
  const categoryData = data.result ?? data;

  if (!categoryData) return categoryData;

  return {
    categoryId: categoryData.categoryId ?? categoryData.category_id,
    categoryName: categoryData.categoryName ?? categoryData.category_name,
    productList:
      (categoryData.productList ?? categoryData.product_list) &&
      (categoryData.productList ?? categoryData.product_list).map(product => ({
        id: product?.id,
        modelName: product?.modelName ?? product?.model_name,
        brandName: product?.brandName ?? product?.brand_name,
        color: product?.color,
        classification: product?.classification,
        prices:
          product?.prices &&
          product.prices.map(price => ({
            name: price.name,
            currencyCode: price.currencyCode ?? price.currency_code,
            price: price.price,
          })),
        qty: product?.qty,
      })),
  };
};

const transformOrder = data => {
  const order = data.result ?? data;
  if (!order) return order;

  return {
    id: order.id,
    amount: order.amount && {
      currencyCode: order.amount.currencyCode ?? order.amount.currency_code,
      total: order.amount.total ?? order.amount.total,
      totalTax: order.amount.totalTax ?? order.amount.total_tax,
      shipping: order.amount.shipping ?? order.amount.shipping,
      discounts:
        order.discounts &&
        order.discounts.map(discount => ({
          type: discount.type,
          amount: discount.amount,
        })),
    },
    deliveryOption: order.deliveryOption ?? order.delivery_option,
    gokwikRtoDetails: (order.gokwikRtoDetails ?? order.gokwik_rto_details) && {
      riskFlag:
        (order.gokwikRtoDetails ?? order.gokwik_rto_details).riskFlag ??
        (order.gokwikRtoDetails ?? order.gokwik_rto_details).risk_flag,
    },
    payments: order?.payments,
    // payments:
    //   order.payments &&
    //   order.payments.map(payment => ({
    //     paymentList:
    //       (payment.paymentList ?? payment.payment_list) &&
    //       (payment.paymentList ?? payment.payment_list).map(paymentItem => ({
    //         method: paymentItem.method,
    //       })),
    //   })),

    shippingAddress: (order.shippingAddress ?? order.shipping_address) && {
      city: (order.shippingAddress ?? order.shipping_address).city,
    },
    createdAt: order.createdAt ?? order.created_at,
    items:
      order.items &&
      order.items.map(item => ({
        id: item.id,
        modelName: item.modelName ?? item.model_name,
        brandName: item.brandName ?? item.brand_name,
        sku: item.sku,
        frameColour: item.frameColour ?? item.frame_colour,
        price: item.price && {
          currencyCode: item.price.currencyCode ?? item.price.currency_code,
          value: item.price.value,
        },
        quantity: item.quantity,
      })),
  };
};

const transformGA4EventData = params => ({
  ...params,
  ...(params.userInfo ? { userInfo: transformUserInfo(params.userInfo) } : {}),
  ...(params.cartData ? { cartData: transformCartData(params.cartData) } : {}),
  ...(params.productData ? { productData: transformProductData(params.productData) } : {}),
  ...(params.promotionData ? { promotionData: transformPromotionData(params.promotionData) } : {}),
  ...(params.categoryData ? { categoryData: transformCategoryData(params.categoryData) } : {}),
  ...(params.order ? { order: transformOrder(params.order) } : {}),
});

const getPayloadCommon = ({ userInfo }) => ({
  login_status: userInfo?.login ? 'logged-in' : 'guest',
});

const getPayloadPageView = ({ userInfo, screenName }) => ({
  ...getPayloadCommon({ userInfo }),
  screenName,
});

const getPayloadHomePage = ({ userInfo }) => getPayloadCommon({ userInfo });

const getPayloadAudienceAnalysis = ({ userInfo, audienceBreakup }) => ({
  ...getPayloadCommon({ userInfo }),
  audience_breakup: audienceBreakup,
});

const getPayloadViewPromotion = ({ userInfo, promotionData, categoryData }) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    creative_name: promotionData?.title,
    promotion_id: promotionData?.title,
    promotion_name: promotionData?.title,
    item_list_id: categoryData?.categoryId,
    item_list_name: categoryData?.categoryName,
  },
});

const getPayloadSelectPromotion = ({ userInfo, promotionData, categoryData }) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    creative_name: promotionData?.title,
    promotion_id: promotionData?.title,
    promotion_name: promotionData?.title,
    item_list_id: categoryData?.categoryId,
    item_list_name: categoryData?.categoryName,
  },
});

const getPayloadViewItemList = ({ userInfo, categoryData }) => {
  console.log('@GA4 ViewItemList', userInfo, categoryData);
  return {
    ...getPayloadCommon({ userInfo }),
    ecommerce: {
      item_list_id: categoryData?.categoryId,
      item_list_name: categoryData?.categoryName,
      items: categoryData?.productList?.map((product, index) => ({
        item_id: product.id,
        item_name: product?.modelName,
        item_brand: product?.brandName,
        item_category: getCategoryType(product?.classification),
        item_list_id: categoryData?.categoryId,
        item_list_name: categoryData?.categoryName,
        item_variant: product?.color,
        currency: product?.prices?.[0]?.currencyCode,
        index,
        price: product?.prices?.find(price => price.name === 'Lenskart Price')?.price,
        quantity: product?.qty,
        in_stock: true,
      })),
    },
  }
};

const getPayloadSelectItem = ({ userInfo, categoryData, index }) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    item_list_id: categoryData?.categoryId,
    item_list_name: categoryData?.categoryName,
    items: [
      {
        item_id: categoryData?.productList?.[index]?.id,
        item_name: categoryData?.productList?.[index]?.modelName,
        item_brand: categoryData?.productList?.[index]?.brandName,
        item_category: getCategoryType(categoryData?.categoryName),
        item_list_id: categoryData?.categoryId,
        item_list_name: categoryData?.categoryName,
        item_category6: '',
        item_variant: categoryData?.productList?.[index]?.color,
        currency: categoryData?.productList?.[index]?.prices?.[0]?.currencyCode,
        index,
        price: categoryData?.productList?.[index]?.prices?.find(
          price => price.name === 'Lenskart Price'
        )?.price,
        quantity: categoryData?.productList?.[index]?.qty,
        in_stock: !!categoryData?.productList?.[index]?.qty,
      },
    ],
  },
});

const getPayloadPersonalizedSorting = ({ userInfo, categoryData }) => ({
  ...getPayloadCommon({ userInfo }),
  category_id: categoryData?.categoryId,
  algo_config: 'fm',
  algo_applied: 'none',
});

const getPayloadCTAClick = ({ userInfo, ctaName, ctaFlowAndPage }) => ({
  ...getPayloadCommon({ userInfo }),
  cta_name: ctaName,
  cta_flow_and_page: ctaFlowAndPage,
});

const getPayloadViewItem = ({ userInfo, productData, gaPLPSelectItemData }) => {
  console.log('@GA4 ViewItem', userInfo, productData, gaPLPSelectItemData);
  return {
    ...getPayloadCommon({ userInfo }),
    ecommerce: {
      currency: productData?.prices?.[0]?.currencyCode,
      value: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
      items: [
        {
          item_id: productData?.id,
          item_name: productData?.modelName,
          item_brand: productData?.brandName,
          item_category: getProductType(productData?.sku),
          item_variant: productData?.frameColour,
          currency: productData?.prices?.[0]?.currencyCode,
          price: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
          quantity: productData?.qty,
          in_stock: !!productData?.qty,
          ...(gaPLPSelectItemData?.[productData?.id]
            ? {
                item_list_id: gaPLPSelectItemData[productData.id].item_list_id,
                item_list_name: gaPLPSelectItemData[productData.id].item_list_name,
                index: gaPLPSelectItemData[productData.id].selectedProductIndex,
              }
            : {}),
        },
      ],
    },
  }
};

const getPayloadTimeToLoad = ({ userInfo, loadedParam, pidCheck, timeToLoad }) => ({
  ...getPayloadCommon({ userInfo }),
  loaded_param: loadedParam,
  pid_check: pidCheck,
  time_to_load: timeToLoad,
});

const getPayloadAddToCart = ({ userInfo, productData, gaPLPSelectItemData, gaAddToCartData }) => {
  return {
    ...getPayloadCommon({ userInfo }),
    ecommerce: {
      currency: productData?.prices?.[0]?.currencyCode,
      value: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
      items: [
        {
          item_id: productData?.id,
          item_name: productData?.modelName,
          item_brand: productData?.brandName,
          item_category: getProductType(productData?.sku),
          item_category2: productData?.selectedPrescriptionType,
          item_variant: productData?.frameColour,
          currency: productData?.prices?.[0]?.currencyCode,
          price: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
          coupon: '',
          discount: '',
          quantity: productData?.qty,
          ...(gaPLPSelectItemData?.[productData?.id]
            ? {
                item_list_id: gaPLPSelectItemData[productData.id]?.item_list_id,
                item_list_name: gaPLPSelectItemData[productData.id]?.item_list_name,
                index: gaPLPSelectItemData[productData.id]?.selectedProductIndex,
              }
            : {}),
          ...(gaAddToCartData?.[productData?.id]
            ? {
                item_category3: gaAddToCartData[productData.id]?.item_category3,
                item_category4: gaAddToCartData[productData.id]?.item_category4,
              }
            : {})
        },
      ],
    },
  }
};

const getPayloadAddToWishlist = ({
  userInfo,
  productData,
  categoryData,
  gaPLPSelectItemData,
  gaAddToCartData,
  index,
}) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    ...(productData
      ? {
          currency: productData?.prices?.[0]?.currencyCode,
          value: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
          items: [
            {
              item_id: productData?.id,
              item_name: productData?.modelName,
              item_brand: productData?.brandName,
              item_category: getProductType(productData?.sku),
              item_category2: '',
              item_variant: productData?.frameColour,
              item_category6: '',
              currency: productData?.prices?.[0]?.currencyCode,
              price: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
              coupon: '',
              discount: '',
              quantity: productData?.qty,
              ...(gaPLPSelectItemData?.[productData?.id]
                ? {
                    item_list_id: gaPLPSelectItemData[productData.id]?.item_list_id,
                    item_list_name: gaPLPSelectItemData[productData.id]?.item_list_name,
                    index: gaPLPSelectItemData[productData.id]?.selectedProductIndex,
                  }
                : {}),
              ...(gaAddToCartData?.[productData?.id]
                ? {
                    item_category3: gaAddToCartData[productData.id]?.item_category3,
                    item_category4: gaAddToCartData[productData.id]?.item_category4,
                  }
                : {}),
            },
          ],
        }
      : {}),
    ...(categoryData?.productList?.[index]
      ? {
          currency: categoryData.productList[index].prices?.[0]?.currencyCode,
          value: categoryData.productList[index].prices?.find(
            price => price.name === 'Lenskart Price'
          )?.price,
          items: [
            {
              item_id: categoryData.productList[index].id,
              item_name: categoryData.productList[index].modelName,
              item_brand: categoryData.productList[index].brandName,
              item_category: getCategoryType(categoryData?.categoryName),
              item_category2: '',
              item_list_id: categoryData?.categoryId,
              item_list_name: categoryData?.categoryName,
              item_category6: '',
              item_variant: categoryData.productList[index].color,
              currency: categoryData.productList[index].prices?.[0]?.currencyCode,
              index,
              price: categoryData.productList[index].prices?.find(
                price => price.name === 'Lenskart Price'
              )?.price,
              coupon: '',
              discount: '',
              quantity: categoryData.productList[index].qty,
            },
          ],
        }
      : {}),
  },
});

const getPayloadViewCart = ({ userInfo, cartData, gaPLPSelectItemData, gaAddToCartData }) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    value: cartData?.totals?.total,
    currency: cartData?.currencyCode,
    items: cartData?.items?.map(item => ({
      item_id: item.id,
      item_name: item.modelName,
      item_brand: item.brandName,
      item_category: getProductType(item.sku),
      item_category2: '',
      item_variant: item.frameColour,
      item_category6: '',
      currency: item.price?.currencyCode,
      price: item.price?.value,
      coupon: '',
      discount: '',
      quantity: item.quantity,
      ...(gaPLPSelectItemData?.[item.id]
        ? {
            item_list_id: gaPLPSelectItemData[item.id].item_list_id,
            item_list_name: gaPLPSelectItemData[item.id].item_list_name,
            index: gaPLPSelectItemData[item.id].selectedProductIndex,
          }
        : {}),
      ...(gaAddToCartData?.[item.id]
        ? {
            item_category3: gaAddToCartData[item.id].item_category3,
            item_category4: gaAddToCartData[item.id].item_category4,
          }
        : {}),
    })),
  },
});

const getPayloadRemoveFromCart = ({
  userInfo,
  cartData,
  gaPLPSelectItemData,
  gaAddToCartData,
  index,
}) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    value: cartData?.totals?.total,
    currency: cartData?.currencyCode,
    items: [
      {
        item_id: cartData?.items?.[index]?.id,
        item_name: cartData?.items?.[index]?.modelName,
        item_brand: cartData?.items?.[index]?.brandName,
        item_category: getProductType(cartData?.items?.[index]?.sku),
        item_category2: '',
        item_variant: cartData?.items?.[index]?.frameColour,
        item_category6: '',
        currency: cartData?.items?.[index]?.price?.currencyCode,
        price: cartData?.items?.[index]?.price?.value,
        coupon: '',
        discount: '',
        quantity: cartData?.items?.[index]?.quantity,
        ...(gaPLPSelectItemData?.[cartData?.items?.[index]?.id]
          ? {
              item_list_id: gaPLPSelectItemData[cartData.items[index].id].item_list_id,
              item_list_name: gaPLPSelectItemData[cartData.items[index].id].item_list_name,
              index: gaPLPSelectItemData[cartData.items[index].id].selectedProductIndex,
            }
          : {}),
        ...(gaAddToCartData?.[cartData?.items?.[index]?.id]
          ? {
              item_category3: gaAddToCartData[cartData.items[index].id].item_category3,
              item_category4: gaAddToCartData[cartData.items[index].id].item_category4,
            }
          : {}),
      },
    ],
  },
});

const getPayloadBeginCheckout = ({ userInfo, cartData, gaPLPSelectItemData, gaAddToCartData }) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    value: cartData?.totals?.total,
    currency: cartData?.currencyCode,
    coupon:
      cartData?.totals?.discounts?.[0]?.type === 'gv' ? cartData?.totals?.discounts?.[0]?.code : '',
    items: cartData?.items?.map(item => ({
      item_id: item.id,
      item_name: item.modelName,
      item_brand: item.brandName,
      item_category: getProductType(item.sku),
      item_category2: '',
      item_variant: item.frameColour,
      item_category6: '',
      currency: item.price?.currencyCode,
      price: item.price?.value,
      coupon: '',
      discount: '',
      quantity: item.quantity,
      ...(gaPLPSelectItemData?.[item.id]
        ? {
            item_list_id: gaPLPSelectItemData[item.id].item_list_id,
            item_list_name: gaPLPSelectItemData[item.id].item_list_name,
            index: gaPLPSelectItemData[item.id].selectedProductIndex,
          }
        : {}),
      ...(gaAddToCartData?.[item.id]
        ? {
            item_category3: gaAddToCartData[item.id].item_category3,
            item_category4: gaAddToCartData[item.id].item_category4,
          }
        : {}),
    })),
  },
});

const getPayloadAddShippingInfo = ({
  userInfo,
  cartData,
  gaPLPSelectItemData,
  gaAddToCartData,
}) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    value: cartData?.totals?.total,
    currency: cartData?.currencyCode,
    coupon:
      cartData?.totals?.discounts?.[0]?.type === 'gv' ? cartData?.totals?.discounts?.[0]?.code : '',
    items: cartData?.items?.map(item => ({
      item_id: item.id,
      item_name: item.modelName,
      item_brand: item.brandName,
      item_category: getProductType(item.sku),
      item_category2: '',
      item_variant: item.frameColour,
      item_category6: '',
      currency: item.price?.currencyCode,
      price: item.price?.value,
      coupon: '',
      discount: '',
      quantity: item.quantity,
      ...(gaPLPSelectItemData?.[item.id]
        ? {
            item_list_id: gaPLPSelectItemData[item.id].item_list_id,
            item_list_name: gaPLPSelectItemData[item.id].item_list_name,
            index: gaPLPSelectItemData[item.id].selectedProductIndex,
          }
        : {}),
      ...(gaAddToCartData?.[item.id]
        ? {
            item_category3: gaAddToCartData[item.id].item_category3,
            item_category4: gaAddToCartData[item.id].item_category4,
          }
        : {}),
    })),
  },
});

const getPayloadAddPaymentInfo = ({
  userInfo,
  cartData,
  gaPLPSelectItemData,
  gaAddToCartData,
}) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    value: cartData?.totals?.total,
    currency: cartData?.currencyCode,
    coupon:
      cartData?.totals?.discounts?.[0]?.type === 'gv' ? cartData?.totals?.discounts?.[0]?.code : '',
    membership_discount: getMembershipDiscount(cartData),
    payment_type: 'PAYMENT_CARD',
    items: cartData?.items?.map(item => ({
      item_id: item.id,
      item_name: item.modelName,
      item_brand: item.brandName,
      item_category: getProductType(item.sku),
      item_category2: '',
      item_variant: item.frameColour,
      item_category6: '',
      currency: item.price?.currencyCode,
      price: item.price?.value,
      coupon: '',
      discount: '',
      quantity: item.quantity,
      ...(gaPLPSelectItemData?.[item.id]
        ? {
            item_list_id: gaPLPSelectItemData[item.id].item_list_id,
            item_list_name: gaPLPSelectItemData[item.id].item_list_name,
            index: gaPLPSelectItemData[item.id].selectedProductIndex,
          }
        : {}),
      ...(gaAddToCartData?.[item.id]
        ? {
            item_category3: gaAddToCartData[item.id].item_category3,
            item_category4: gaAddToCartData[item.id].item_category4,
          }
        : {}),
    })),
  },
});

const getPayloadPurchase = ({ userInfo, order, gaPLPSelectItemData, gaAddToCartData }) => {
  return {
    ...getPayloadCommon({ userInfo }),
    order_content: getOrderContent(order),
    delivery_option: order?.deliveryOption,
    gokwik_risk_flag: order?.gokwikRtoDetails?.riskFlag?.split(' ')[0]?.toLowerCase() || '',
    gokwik_order_type: order?.payments?.paymentList?.[0]?.method,
    last_order_city: order?.shippingAddress?.city,
    last_transaction_date: new Date(order?.createdAt)
      ? new Date(order?.createdAt).toLocaleString('sv').split(' ')[0]
      : '',
    loyalty_start_date: '',
    loyalty_end_date: '',
    user_loyalty: userInfo?.tierLabel ? `${userInfo?.tierLabel} membership`?.toLowerCase() : 'na',
    number_of_items: order?.items?.length,
    ecommerce: {
      currency: order?.amount?.currencyCode,
      transaction_id: order?.id,
      value: order?.amount?.total,
      tax: order?.amount?.totalTax,
      shipping: order?.amount?.shipping,
      membership_discount:
        order?.amount?.discounts?.[0]?.type === 'implicit'
          ? order?.amount?.discounts?.[0]?.amount
          : 0,
      coupon_discount:
        order?.amount?.discounts?.[0]?.type === 'gv' ? order?.amount?.discounts?.[0]?.amount : 0,
      payment_mode: order?.payments?.paymentList?.[0]?.method,
      items: order?.items?.map(item => ({
        item_id: item?.id,
        item_name: item?.modelName,
        item_brand: item?.brandName,
        item_category: getProductType(item?.sku),
        item_category2: '',
        item_variant: item?.frameColour,
        currency: item?.price?.currencyCode,
        price: item?.price?.value,
        coupon: '',
        discount: '',
        quantity: item?.quantity,
        ...(gaPLPSelectItemData?.[item?.id]
          ? {
              item_list_id: gaPLPSelectItemData[item.id].item_list_id,
              item_list_name: gaPLPSelectItemData[item.id].item_list_name,
              index: gaPLPSelectItemData[item.id].selectedProductIndex,
            }
          : {}),
        ...(gaAddToCartData?.[item?.id]
          ? {
              item_category3: gaAddToCartData[item.id].item_category3,
              item_category4: gaAddToCartData[item.id].item_category4,
            }
          : {}),
      })),
    },
  }
};

const getPayloadRefund = ({ userInfo, order, gaPLPSelectItemData }) => ({
  ...getPayloadCommon({ userInfo }),
  ecommerce: {
    currency: order?.amount?.currencyCode,
    transaction_id: order?.id,
    value: order?.amount?.total,
    tax: order?.amount?.totalTax,
    shipping: order?.amount?.shipping,
    membership_discount:
      order?.amount?.discounts?.[0]?.type === 'implicit'
        ? order?.amount?.discounts?.[0]?.amount
        : 0,
    coupon_discount:
      order?.amount?.discounts?.[0]?.type === 'gv' ? order?.amount?.discounts?.[0]?.amount : 0,
    payment_mode: order?.payments?.paymentList?.[0]?.method,
    items: order?.items?.map(item => ({
      item_id: item?.id,
      item_name: item?.modelName,
      item_brand: item?.brandName,
      item_category: getProductType(item?.sku),
      item_category2: '',
      item_variant: item?.frameColour,
      item_category6: '',
      currency: item?.price?.currencyCode,
      price: item?.price?.value,
      coupon: '',
      discount: '',
      quantity: item?.quantity,
      ...(gaPLPSelectItemData?.[item?.id]
        ? {
            item_list_id: gaPLPSelectItemData[item.id].item_list_id,
            item_list_name: gaPLPSelectItemData[item.id].item_list_name,
            index: gaPLPSelectItemData[item.id].selectedProductIndex,
          }
        : {}),
    })),
  },
});

const payloadGetters = {
  view_promotion: getPayloadViewPromotion,
  select_promotion: getPayloadSelectPromotion,
  view_item_list: getPayloadViewItemList,
  select_item: getPayloadSelectItem,
  view_item: getPayloadViewItem,
  add_to_cart: getPayloadAddToCart,
  add_to_wishlist: getPayloadAddToWishlist,
  view_cart: getPayloadViewCart,
  remove_from_cart: getPayloadRemoveFromCart,
  begin_checkout: getPayloadBeginCheckout,
  add_shipping_info: getPayloadAddShippingInfo,
  add_payment_info: getPayloadAddPaymentInfo,
  purchase: getPayloadPurchase,
  refund: getPayloadRefund,
  page_view: getPayloadPageView,
  home_page: getPayloadHomePage,
  audience_analysis: getPayloadAudienceAnalysis,
  personalized_sorting: getPayloadPersonalizedSorting,
  cta_click: getPayloadCTAClick,
  time_to_load: getPayloadTimeToLoad,
};

let PENDING_EVENTS = [];
let GA4Interval;

export const odPushGA4Event = (event, data = {}, overrides = {}) => {
  console.log('odPushGA4Event event data overrides', event, data, overrides);

  const transformedData = transformGA4EventData(data);

  console.log('odPushGA4Event transformedData', transformedData);

  console.log('odPushGA4Event payload', payloadGetters[event]?.(transformedData) || {});

  const payload = {
    event,
    ...(payloadGetters[event]?.(transformedData) || {}),
    ...overrides,
  };

  PENDING_EVENTS.push(payload);

  if (window.dataLayer) {
    if (GA4Interval) {
      clearInterval(GA4Interval);

      GA4Interval = null;
    }

    PENDING_EVENTS.forEach(eventPayload => {
      window.dataLayer.push({ ecommerce: null });

      window.dataLayer.push(eventPayload);
    });

    PENDING_EVENTS = [];
  } else if (!GA4Interval) {
    GA4Interval = setInterval(() => {
      if (window.dataLayer) {
        clearInterval(GA4Interval);

        GA4Interval = null;

        PENDING_EVENTS.forEach(eventPayload => {
          window.dataLayer.push({ ecommerce: null });

          window.dataLayer.push(eventPayload);
        });

        PENDING_EVENTS = [];
      }
    }, 10);
  } else {
    console.log('NO_DATA_LAYER, sent to interval');
  }
};

// ====================================================================================================
// Lenskart legacy
// ====================================================================================================

export const viewItemListGA4 = (categoryData, login) => {
  const ecommerce = { items: [] };
  if (categoryData?.result) {
    categoryData?.result?.product_list?.map((product, idx) =>
      ecommerce.items.push({
        item_id: product.id,
        item_name: product.model_name,
        coupon: '',
        affiliation: '',
        currency: product?.prices?.[0]?.currency_code,
        discount: discountCalculator(product?.prices),
        index: idx + 1,
        item_brand: product?.brand_name,
        item_category: getProductType(product.sku),
        item_categoy2: '',
        item_categoy3: '',
        item_categoy4: '',
        item_categoy5: '',
        item_list_id: categoryData?.result?.categoryId,
        item_list_name: categoryData?.result?.category_name,
        item_variant: product.color,
        price: product?.prices?.filter(priceObj => priceObj.name === 'Lenskart Price')[0]?.price,
        quantity: 1,
      })
    );
  } else {
    categoryData?.product_list?.map((product, idx) =>
      ecommerce.items.push({
        item_id: product.id,
        item_name: product.model_name,
        coupon: '',
        affiliation: '',
        currency: product?.prices?.[0]?.currency_code,
        discount: discountCalculator(product?.prices),
        index: idx,
        item_brand: product?.brand_name,
        item_category: getProductType(product.sku),
        item_categoy2: '',
        item_categoy3: '',
        item_categoy4: '',
        item_categoy5: '',
        item_list_id: categoryData?.categoryId,
        item_list_name: categoryData?.category_name,
        item_variant: product.color || '',
        price: product?.prices?.filter(priceObj => priceObj.name === 'Lenskart Price')[0]?.price,
        quantity: 1,
      })
    );
  }

  triggerDataLayerOnPageView({ loginStatus: login, ecommerce });
};

export const viewItemGA4 = (productData, login) => {
  let isPlano;
  if (productData?.type === 'Contact Lens') {
    if (typeof productData?.isPlano !== 'undefined') {
      isPlano = true;
    } else if (!productData?.isPlano && productData?.jit) {
      isPlano = false;
    } else isPlano = !!productData?.isPlano;
  } else {
    isPlano = 'na';
  }
  const ecommerce = { items: [] };
  const product = {
    item_id: productData.id,
    item_name: productData.modelName,
    coupon: '',
    alliliation: '',
    currency: productData?.prices?.[0]?.currencyCode,
    discount: discountCalculator(productData?.prices),
    index: 0,
    item_brand: productData.brandName,
    item_category: getProductType(productData.sku),
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    item_list_id: '',
    item_list_name: '',
    item_variant: productData?.frameColour || productData?.frameColor || '',
    price: productData?.prices?.filter(priceObj => priceObj.name === 'Lenskart Price')[0]?.price,
    quantity: 1,
  };
  ecommerce.items.push(product);
  triggerDataLayerOnPageView({
    loginStatus: login,
    ecommerce,
    event: 'view_item',
    isPlano,
  });
};

export const addToWishListGA4 = (item, login) => {
  const ecommerce = {
    currency: item?.prices?.[0]?.currencyCode || item?.prices?.[0]?.currency_code,
    value: '', // to be discussed
    items: [],
  };
  ecommerce.items.push({
    item_id: item?.id,
    item_name: item?.name || item?.fullName || item?.model_name,
    affiliation: '',
    coupon: '',
    currency: item?.prices?.[0]?.currencyCode || item?.prices?.[0]?.currency_code,
    discount: item?.prices ? discountCalculator(item.prices) : '',
    index: 0,
    item_brand: item?.brandName || item?.brand_name,
    item_category: getProductType(item?.sku || ''),
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    item_list_id: '',
    item_list_name: '',
    item_variant: item?.frameColour || item?.frameColor || '',
    price:
      item?.amount?.total ||
      item?.prices?.filter(priceObj => priceObj.name === 'Lenskart Price')[0].price ||
      '',
    quantity: 1,
  });
  triggerDataLayerOnPageView({ loginStatus: login, ecommerce, event: 'add_to_wishlist' });
};

export const addToCartGA4 = (cartItems, loginStatus, prevCartItems) => {
  const idx = cartItems?.length - prevCartItems?.length || 1;
  let clPowerSubmission;
  if (cartItems[cartItems.length - idx]?.sku?.indexOf('contact:') !== -1) {
    if (
      cartItems[cartItems.length - idx]?.prescriptionView?.left?.sph !==
        'Call Me/Email Me for Power' &&
      cartItems[cartItems.length - idx]?.prescriptionView?.left?.sph !== ''
    ) {
      clPowerSubmission = 'self-from-pdp';
    } else if (
      cartItems[cartItems.length - idx]?.prescriptionView?.left?.sph ===
      'Call Me/Email Me for Power'
    ) {
      clPowerSubmission = 'later-on-checkout-or-call';
    } else if (cartItems[cartItems.length - idx]?.jit) {
      clPowerSubmission = 'jit-usecase';
    } else if (
      cartItems[cartItems.length - idx]?.isPlano ||
      cartItems[cartItems.length - idx]?.powerRequired === 'POWER_NOT_REQUIRED' ||
      cartItems[cartItems - idx]?.prescriptionView?.left?.sph === ''
    ) {
      clPowerSubmission = 'power-not-required';
    } else {
      clPowerSubmission = 'na';
    }
  } else {
    clPowerSubmission = 'na';
  }
  if (cartItems.length > 0) {
    const ecommerce = { items: [] };
    cartItems.map((item, idx) => {
      ecommerce.items.push({
        item_id: item.productId,
        item_name: item.name || item.fullName || item.modelName,
        affiliation: '',
        coupon: '',
        currency: item?.amount?.currencyCode,
        discount: discountCalculator(item.catalogPrices),
        index: idx,
        item_category: getProductType(item.sku),
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: item.frameColor || item.frameColour || '',
        location_id: '',
        price: item?.amount?.total,
        quantity: item.quantity,
      });
      getProductCategories(item, ecommerce.items[ecommerce.items.length - 1]);
      return null;
    });
    triggerDataLayerOnPageView({
      loginStatus,
      ecommerce,
      event: 'add_to_cart',
      clPowerSubmission,
    });
  }
};

export const removeFromCartGA4 = (item, loginStatus) => {
  // const ecommerce = { items: [] };
  // ecommerce.items.push({
  //   item_id: item.productId,
  //   item_name: item.name || item.fullName || item.modelName,
  //   affiliation: '',
  //   coupon: '',
  //   currency: item?.amount?.currencyCode,
  //   discount: discountCalculator(item.catalogPrices),
  //   index: 0,
  //   item_category: getProductType(item.sku),
  //   item_category5: '',
  //   item_list_id: '',
  //   item_list_name: '',
  //   item_variant: item.frameColor || item.frameColour || '',
  //   location_id: '',
  //   price: item?.amount?.total,
  //   quantity: item.quantity,
  // });
  // getProductCategories(item, ecommerce.items[ecommerce.items.length - 1]);
  // triggerDataLayerOnPageView({ loginStatus, ecommerce, event: 'remove_from_cart' });
};

export const viewCartGA4 = (cartData, loginStatus) => {
  const ecommerce = {
    currency: cartData?.currencyCode,
    value: cartData?.totals?.total,
    items: [],
  };
  cartData.items.map((item, idx) => {
    const product = {
      item_id: item.productId,
      item_name: item.name,
      coupon: '',
      alliliation: '',
      currency: cartData?.currencyCode,
      discount: discountCalculator(item.catalogPrices),
      index: idx,
      item_brand: item.brandName,
      item_category: getProductType(item.sku),
      item_category5: '',
      item_list_id: '',
      item_list_name: '',
      item_variant: item.frameColor || item.frameColour || '',
      price: item?.amount?.total,
      quantity: item.quantity,
    };
    getProductCategories(item, product);
    ecommerce.items.push(product);
    return null;
  });
  triggerDataLayerOnPageView({ loginStatus, ecommerce, event: 'view_cart' });
};

export const beginCheckoutGA4 = (cartData, loginStatus) => {
  const ecommerce = {
    items: [],
  };
  if (cartData?.result) {
    cartData?.result?.items?.map((item, idx) => {
      const product = {
        item_id: item.productId,
        item_name: item.name,
        coupon: '',
        alliliation: '',
        currency: cartData?.result?.currencyCode,
        discount: discountCalculator(item.catalogPrices),
        index: idx,
        item_brand: item.brandName,
        item_category: getProductType(item.sku),
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: item.frameColor || item.frameColour || '',
        price: item?.amount?.total,
        quantity: item.quantity,
      };
      getProductCategories(item, product);
      ecommerce.items.push(product);
      return null;
    });
  } else {
    cartData?.items?.map(item => {
      const product = {
        item_id: item.productId,
        item_name: item.name,
        coupon: '',
        alliliation: '',
        currency: cartData?.currencyCode,
        discount: discountCalculator(item.catalogPrices),
        index: 0,
        item_brand: item.brandName,
        item_category: getProductType(item.sku),
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: item.frameColor || item.frameColour || '',
        price: item?.amount?.total,
        quantity: item.quantity,
      };
      getProductCategories(item, product);
      ecommerce.items.push(product);
      return null;
    });
  }
  triggerDataLayerOnPageView({ loginStatus, ecommerce, event: 'begin_checkout' });
};

export const addShippingInfoGA4 = (cartData, loginStatus) => {
  const ecommerce = {
    currency: cartData.currencyCode,
    value: cartData?.totals?.total,
    coupon: '',
    shopping_tier: '',
    items: [],
  };
  cartData?.items?.map((item, idx) => {
    ecommerce.items.push({
      item_id: item.productId,
      item_name: item.name,
      coupon: '',
      alliliation: '',
      currency: cartData.currencyCode,
      discount: discountCalculator(item.catalogPrices),
      index: idx,
      item_brand: item.brandName,
      item_category: getProductType(item.sku),
      item_category5: '',
      item_list_id: '',
      item_list_name: '',
      item_variant: item?.frameColor || item?.frameColour || '',
      location_id: '',
      price: item?.amount?.total,
      quantity: item?.quantity,
    });
    getProductCategories(item, ecommerce.items[ecommerce.items.length - 1]);
    return null;
  });
  triggerDataLayerOnPageView({ loginStatus, ecommerce, event: 'add_shipping_info' });
};

export const addPaymentInfoGA4 = (cartData, paymentType, loginStatus) => {
  const ecommerce = {
    currency: cartData.currencyCode,
    value: cartData?.totals?.total,
    membership_discount: getMembershipDiscount(cartData),
    coupon_discount: 0,
    coupon: '',
    payment_type: paymentType,
    items: [],
  };
  cartData?.items?.map((item, idx) => {
    ecommerce.items.push({
      item_id: item.productId,
      item_name: item.name,
      coupon: '',
      currency: cartData.currencyCode,
      discount: discountCalculator(item.catalogPrices),
      index: idx,
      item_brand: item.brandName,
      item_category: getProductType(item.sku),
      item_variant: item?.frameColor || item?.frameColour || '',
      price: item?.amount?.total,
      quantity: item?.quantity,
    });
    getProductCategories(item, ecommerce.items[ecommerce.items.length - 1]);
    return null;
  });
  triggerDataLayerOnPageView({ loginStatus, ecommerce, event: 'add_payment_info' });
};

export const purchaseInfoGA4 = (order, userInfo) => {
  if (!sessionStorage.getItem('ga4PurchaseEventFired')) {
    const ecommerce = {
      currency: order?.amount?.currencyCode,
      transaction_id: order?.id,
      value: order?.amount?.total,
      tax: order?.amount?.totalTax,
      shipping: order?.amount?.shipping,
      membership_discount:
        order?.amount?.discounts[0]?.type === 'implicit' ? order?.amount?.discounts[0]?.amount : 0,
      coupon_discount:
        order?.amount?.discounts[0]?.type === 'gv' ? order?.amount?.discounts[0]?.amount : 0,
      coupon: order?.amount?.discounts[0]?.type === 'gv' ? order?.amount?.discounts[0]?.code : '',
      payment_mode: order?.payments?.paymentList[0]?.method,
      items: [],
    };
    order?.items?.map((item, idx) => {
      ecommerce.items.push({
        item_id: item?.productId,
        item_name: item?.name,
        currency: order?.amount?.currencyCode,
        discount: discountCalculator(item?.catalogPrices),
        index: idx,
        item_brand: item?.brandName,
        item_category: getProductType(item?.sku),
        item_variant: item?.frameColor || item?.frameColour || '',
        price: item?.amount?.total,
        quantity: item?.quantity,
      });
      getProductCategories(item, ecommerce.items[ecommerce.items.length - 1]);
      return null;
    });
    const eventData = {
      ecommerce,
      event: 'purchase',
      order_content: getOrderContent(order),
      delivery_option: order?.deliveryOption,
      gokwik_risk_flag: order?.gokwikRtoDetails?.riskFlag?.split(' ')[0]?.toLowerCase() || '',
      gokwik_order_type: order?.payments?.paymentList[0]?.method,
      last_order_city: order?.shippingAddress?.city,
      number_of_items: order?.items?.length,
      last_transaction_date: new Date(order?.createdAt)
        ? new Date(order?.createdAt).toLocaleString('sv').split(' ')[0]
        : '',
    };
    addLoyaltyData(order, userInfo, eventData);
    triggerPurchaseEcomEvent(eventData);
    sessionStorage.setItem('ga4PurchaseEventFired', 'Yes');
  }
};

export const testYourVisionEventGA4 = ({ category, action, label, name }) => {
  const details = {};
  if (category) details.category = category;
  if (action) details.action = action;
  if (label) details.label = label;
  if (name) details.name = name;
  triggerDataLayer(details);
};
