import React, { useEffect } from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>

    <!-- <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3"> -->

    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
    <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">
    <link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
    <link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
    <link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
    <link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
    <link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
    <link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
    <link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
    <link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
    <link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
    <link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
    <link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
    <link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
    <link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
    <link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
    <meta name="description" content="OWNDAYS PC - SAVE YOUR EYES, SHIELD YOUR EYES! Reduce up to 25% blue light!" />
    <meta name="keywords"
        content="owndayspc,spectacles,glasses,optical shop,eye glasses,sunglasses,eyewear,pc lenses,frame,bluelight,filter blue light,owndays" />
    <meta name="og:title" content="OWNDAYS PC | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
    <meta name="og:description" content="OWNDAYS PC - SAVE YOUR EYES, SHIELD YOUR EYES! Reduce up to 25% blue light!" />
    <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:image"
        content="https://www.owndays.com/images/specials/products/owndayspc2022/sg/ogp_1200x630.jpg">
    <meta property="og:type" content="website">
    <meta property="og:url" content="https://www.owndays.com/sg/en/news/owndayspc">
    <meta property="og:image"
        content="https://www.owndays.com/images/specials/products/owndayspc2022/sg/ogp_1200x630.jpg?1657882070">
    <meta property="fb:app_id" content="221140811388378">
    <meta name="twitter:card" content="summary_large_image">
    <!-- <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net"> -->
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://use.typekit.net/gbr6keb.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css">
    <link rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Kantumruy+Pro:wght@200;400;500&display=swap" rel="stylesheet">
    <link href="https://www.owndays.com/web/css/specials-owndayspc2022.css" rel="stylesheet">
    <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
    <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
    <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
    <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
    <!-- fontawesome 5.x -->
    <!-- <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script"> -->
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform">
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">OWNDAYS PC | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

    <div id="app" data-nosnippet>



        <!-- header - SP電話モーダル中身 -->
        <modal class="l-header__modal" id="contact" style="display: none;">
            <p class="l-header__modal-title">Contact Us</p>
            <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span
                        class="icon-tel"></span>6222-0588</a></div>
            <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
            <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
                <a href="/sg/en/faq">FAQs</a>
            </div>
            <p class="c-attention"></p>
        </modal>

        <main class="l-content
        
        
        
        
        
        ">
            <div class="c-breadcrumb">
                <ul id="breadcrumb-list">
                    <li>

                        <a href="/sg/en">Homepage</a>
                    </li>
                    <li>

                        <a href="/sg/en/news">News</a>
                    </li>
                    <li>
                        OWNDAYS PC </li>

                </ul>
            </div>


            <div class="l-owndayspc">

                <!-- Main -->
                <section class="main">
                    <div class="main__top">
                        <div class="l-owndayspc__container l-owndayspc__container--sm">
                            <h1 class="main__logo" data-aos="fade-right"><img
                                    src="https://www.owndays.com/images/specials/products/owndayspc2022/owndayspc-logo.svg"
                                    alt="OWNDAYS PC"></h1>
                            <p class="main__heading" data-aos="fade-right" data-aos-delay="300">SAVE YOUR EYES,
                                <span>SHIELD YOUR EYES</span></p>
                            <p class="main__tag"><img
                                    src="https://www.owndays.com/images/specials/products/owndayspc2022/en/main-tag.svg"
                                    alt="BLUE LIGHT 25% CUT" data-aos="zoom-in" data-aos-delay="600"></p>
                        </div>
                        <div class="main__product"><img
                                src="https://www.owndays.com/images/specials/products/owndayspc2022/main_visual_1_large.webp"
                                alt="OWNDAYS PC"></div>
                    </div>
                    <div class="main__bottom">
                        <div class="l-owndayspc__container">
                            <p class="main__text" style="--mb: 20px; --mb-lg: 30px;" data-aos="fade-down">Protect your
                                eyes from harmful BLUE LIGHT <br class="u-sp-only">emitted by digital
                                screens.<br>OWNDAYS PC reduces up to 25% blue light.</p>
                            <p class="main__text" style="--mb: 40px; --mb-lg: 30px;" data-aos="fade-down"><b>2 types / 4
                                    colours / S$60</b></p>
                            <ul class="main__products">
                                <li data-aos="fade-left" data-aos-delay="600">
                                    <div class="slider slider--for" data-slick='{"initialSlide": 2}'>
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C1/PC2007N-2S_C1_02_r2.webp"
                                                alt="PC2007N-2S C1"></div>
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C2/PC2007N-2S_C2_02_r2.webp"
                                                alt="PC2007N-2S C2"></div>
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C3/PC2007N-2S_C3_02_r2.webp"
                                                alt="PC2007N-2S C3"></div>
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C4/PC2007N-2S_C4_02_r2.webp"
                                                alt="PC2007N-2S C4"></div>
                                    </div>
                                    <div class="slider slider--nav" data-slick='{"initialSlide": 2}'>
                                        <div class="slider__item"><span style="--bg-color: #3E3A39;"></span></div>
                                        <div class="slider__item"><span style="--bg-color: #8A4B3C;"></span></div>
                                        <div class="slider__item"><span style="--bg-color: #1A406B;"></span></div>
                                        <div class="slider__item"><span style="--bg-color: #7E6839;"></span></div>
                                    </div>
                                    <div class="l-owndayspc__container l-owndayspc__container--sm">
                                        <a data-scroll href="#PC2007N-2S"
                                            class="l-owndayspc__btn l-owndayspc__btn--down"
                                            style="--max-w: 220px;">PC2007N-2S</a>
                                    </div>
                                </li>
                                <li data-aos="fade-left" data-aos-delay="900">
                                    <div class="slider slider--for">
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C1/PC2008N-2S_C1_02_r2.webp"
                                                alt="PC2008N-2S C1"></div>
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C2/PC2008N-2S_C2_02_r2.webp"
                                                alt="PC2008N-2S C2"></div>
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C3/PC2008N-2S_C3_02_r2.webp"
                                                alt="PC2008N-2S C3"></div>
                                        <div class="slider__item"><img
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C4/PC2008N-2S_C4_02_r2.webp"
                                                alt="PC2008N-2S C4"></div>
                                    </div>
                                    <div class="slider slider--nav">
                                        <div class="slider__item"><span style="--bg-color: #3E3A39;"></span></div>
                                        <div class="slider__item"><span
                                                style="background-image: url(https://www.owndays.com/images/specials/products/owndayspc2022/color.jpg)"></span>
                                        </div>
                                        <div class="slider__item"><span style="--bg-color: #8A4B3C;"></span></div>
                                        <div class="slider__item"><span style="--bg-color: #20565D;"></span></div>
                                    </div>
                                    <div class="l-owndayspc__container l-owndayspc__container--sm">
                                        <a data-scroll href="#PC2008N-2S"
                                            class="l-owndayspc__btn l-owndayspc__btn--down"
                                            style="--max-w: 220px;">PC2008N-2S</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div class="l-owndayspc__bg">

                    <!-- About -->
                    <section class="about" id="about">
                        <div class="about__top">
                            <div class="l-owndayspc__container l-owndayspc__container--sm">
                                <div class="l-owndayspc__title-grid">
                                    <div class="l-owndayspc__title-heading">
                                        <h2 class="l-owndayspc__title" data-aos="fade-down">
                                            <span class="l-owndayspc__title-no">01</span>
                                            <span class="l-owndayspc__title-name">ABOUT</span>
                                        </h2>
                                    </div>
                                    <div class="l-owndayspc__title-content">
                                        <p style="--mb: 20px;" data-aos="fade-left">OWNDAYS PC is developed for people
                                            in today’s digital world who tend to experience excessive screen time. It
                                            protects your eyes from blue light emitted by digital screens such as
                                            computers, TV, mobile phones and gaming devices. By reducing the brightness
                                            and glare of these screens, OWNDAYS PC helps to ease eyestrain, thereby
                                            allowing you to enjoy clear, comfortable vision.</p>
                                        <p style="--mb: 40px;" data-aos="fade-left">Lightweight and designed for
                                            comfort, OWNDAYS PC is also suitable for people who have never worn
                                            spectacles before.</p>
                                        <div data-aos="fade-left">
                                            <a href="/sg/en/search?q=PC"
                                                class="l-owndayspc__btn l-owndayspc__btn--right"
                                                style="--max-w: 275px;">See All Products</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="about__middle">
                            <div class="l-owndayspc__container l-owndayspc__container--sm">
                                <ul class="shuffle">
                                    <li>
                                        <h5>International Standard</h5>
                                        <p class="shuffle-letter shuffle-letter-1">ISO 12312-1:2013/A1:2015</p>
                                    </li>
                                    <li>
                                        <h5>Blue Light</h5>
                                        <p class="shuffle-letter shuffle-letter-2">385-485nm</p>
                                    </li>
                                    <li>
                                        <h5>Reduction</h5>
                                        <p class="shuffle-letter shuffle-letter-3">25%</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="about__bottom">
                            <div class="owl-carousel owl-carousel--about owl-theme owl-loading">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/about3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                            </div>
                            <div class="owl-buttons">
                                <button
                                    class="l-owndayspc__btn l-owndayspc__btn--left l-owndayspc__btn--circle owl-prev--about"></button>
                                <button
                                    class="l-owndayspc__btn l-owndayspc__btn--right l-owndayspc__btn--circle owl-next--about"></button>
                            </div>
                        </div>
                    </section>

                    <!-- Points -->
                    <section class="points">
                        <div class="l-owndayspc__container l-owndayspc__container--sm">
                            <h2 class="l-owndayspc__title" data-aos="fade-down">
                                <span class="l-owndayspc__title-no">02</span>
                                <span class="l-owndayspc__title-name">FEATURES</span>
                            </h2>
                            <ul class="points__list">
                                <li data-aos="fade-left">
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/pointA.webp"
                                        alt="Ultem Fram">
                                    <h5>Ultem Frame</h5>
                                    <p>Made of Ultem, a lightweight material that has superb elasticity and is also used
                                        for aircraft parts and medical devices</p>
                                </li>
                                <li data-aos="fade-left">
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/pointB.webp"
                                        alt="Adjustable Tips">
                                    <h5>Adjustable Tips</h5>
                                    <p>Rubber tips that can be adjusted for better fitting to enhance wearing comfort
                                        and prevent the frame from slipping down the face</p>
                                </li>
                                <li data-aos="fade-left">
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/pointC.webp"
                                        alt="Nose Pad-Less Design">
                                    <h5>Nose Pad-Less Design</h5>
                                    <p>A saddle bridge design with no nose pads to offer better comfort and fit and does
                                        not leave marks on the nose</p>
                                </li>
                            </ul>
                        </div>
                        <div class="points__bottom">
                            <div class="owl-carousel owl-carousel--point owl-theme owl-loading">
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point1.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point2.webp"
                                        alt="OWNDAYS PC">
                                </div>
                                <div>
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/point3.webp"
                                        alt="OWNDAYS PC">
                                </div>
                            </div>
                            <div class="owl-buttons">
                                <button
                                    class="l-owndayspc__btn l-owndayspc__btn--left l-owndayspc__btn--circle owl-prev--point"></button>
                                <button
                                    class="l-owndayspc__btn l-owndayspc__btn--right l-owndayspc__btn--circle owl-next--point"></button>
                            </div>
                        </div>
                    </section>

                    <!-- Where -->
                    <section class="where">
                        <div class="where__top">
                            <div class="l-owndayspc__container l-owndayspc__container--sm">
                                <div class="l-owndayspc__title-grid">
                                    <div class="l-owndayspc__title-heading">
                                        <h2 class="l-owndayspc__title" data-aos="fade-down">
                                            <span class="l-owndayspc__title-no">03</span>
                                            <span class="l-owndayspc__title-name">WHAT</span>
                                        </h2>
                                    </div>
                                    <div class="l-owndayspc__title-content">
                                        <p data-aos="fade-left" style="--mb:20px;">Staring at computers, mobile phones
                                            or video game consoles for a long period of time may strain your eyes
                                            without you realising it. OWNDAYS PC can reduce this blue light from digital
                                            devices, thereby easing eyestrain, headache and neck pain.</p>
                                        <p data-aos="fade-left" data-aos-delay="100" style="--mb:60px; --mb-lg:100px;">
                                            Blue light is a visible light with wavelength of 385-485nm which is the
                                            closest to that of harmful ultraviolet (UV) light. It is able to reach the
                                            human retina, causing eyestrain and eye diseases.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="l-owndayspc__container">
                            <figure data-aos="fade-up">
                                <img src="https://www.owndays.com/images/specials/products/owndayspc2022/sg/bluelight.png"
                                    alt="bluelight">
                            </figure>
                        </div>
                        <div class="where__slider">
                            <div data-aos="fade-up">
                                <picture>
                                    <source media="(min-width:768px)"
                                        srcset="https://www.owndays.com/images/specials/products/owndayspc2022/where1-pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/where1-sp.webp"
                                        alt="mobilephone">
                                </picture>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="200">
                                <picture>
                                    <source media="(min-width:768px)"
                                        srcset="https://www.owndays.com/images/specials/products/owndayspc2022/where2-pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/where2-sp.webp"
                                        alt="tablet">
                                </picture>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="400">
                                <picture>
                                    <source media="(min-width:768px)"
                                        srcset="https://www.owndays.com/images/specials/products/owndayspc2022/where3-pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/where3-sp.webp"
                                        alt="tv">
                                </picture>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <picture>
                                    <source media="(min-width:768px)"
                                        srcset="https://www.owndays.com/images/specials/products/owndayspc2022/where4-pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/owndayspc2022/where4-sp.webp"
                                        alt="laptop">
                                </picture>
                            </div>
                        </div>

                    </section>

                    <!-- Line up -->
                    <section class="lineup">
                        <div class="lineup__top">
                            <div class="l-owndayspc__container l-owndayspc__container--sm">
                                <div class="l-owndayspc__title-grid">
                                    <div class="l-owndayspc__title-heading">
                                        <h2 class="l-owndayspc__title" data-aos="fade-down">
                                            <span class="l-owndayspc__title-no">04</span>
                                            <span class="l-owndayspc__title-name">LINEUP</span>
                                        </h2>
                                    </div>
                                    <div class="l-owndayspc__title-content">
                                        <p data-aos="fade-left">Made of Ultem, a lightweight and highly elastic
                                            material, OWNDAYS PC is durable and can be worn comfortably even over a long
                                            period of time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="l-owndayspc__container">
                            <ul class="lineup__product lineup__product--first" id="PC2007N-2S">
                                <li data-aos="fade-up">
                                    <a href="/sg/en/products/PC2007N-2S">
                                        <figure>
                                            <p class="lineup__product-color">matte black</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C1/PC2007N-2S_C1_04_r2.webp"
                                                alt="PC2007N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2007N-2S C1</p>
                                    </a>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="50">
                                    <a href="/sg/en/products/PC2007N-2S?sku=6266">
                                        <figure>
                                            <p class="lineup__product-color">light brown</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C2/PC2007N-2S_C2_04_r2.webp"
                                                alt="PC2007N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2007N-2S C2</p>
                                    </a>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="100">
                                    <a href="/sg/en/products/PC2007N-2S?sku=6267">
                                        <figure>
                                            <p class="lineup__product-color">navy</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C3/PC2007N-2S_C3_04_r2.webp"
                                                alt="PC2007N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2007N-2S C3</p>
                                    </a>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="150">
                                    <a href="/sg/en/products/PC2007N-2S?sku=6268">
                                        <figure>
                                            <p class="lineup__product-color">khaki</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2007N-2S/C4/PC2007N-2S_C4_04_r2.webp"
                                                alt="PC2007N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2007N-2S C4</p>
                                    </a>
                                </li>
                            </ul>
                            <div class="l-owndayspc__container l-owndayspc__container--sm">
                                <div class="lineup__more">
                                    <a href="/sg/en/search?q=PC2007N"
                                        class="l-owndayspc__btn l-owndayspc__btn--right" style="--max-w: 275px;">More
                                        Details</a>
                                </div>
                            </div>
                            <ul class="lineup__product" id="PC2008N-2S">
                                <li data-aos="fade-up">
                                    <a href="/sg/en/products/PC2008N-2S">
                                        <figure>
                                            <p class="lineup__product-color">matte black</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C1/PC2008N-2S_C1_04_r2.webp"
                                                alt="PC2008N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2008N-2S C1</p>
                                    </a>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="50">
                                    <a href="/sg/en/products/PC2008N-2S?sku=6270">
                                        <figure>
                                            <p class="lineup__product-color">matte brown demi</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C2/PC2008N-2S_C2_04_r2.webp"
                                                alt="PC2008N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2008N-2S C2</p>
                                    </a>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="100">
                                    <a href="/sg/en/products/PC2008N-2S?sku=6271">
                                        <figure>
                                            <p class="lineup__product-color">light brown</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C3/PC2008N-2S_C3_04_r2.webp"
                                                alt="PC2008N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2008N-2S C3</p>
                                    </a>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="150">
                                    <a href="/sg/en/products/PC2008N-2S?sku=6272">
                                        <figure>
                                            <p class="lineup__product-color">green</p>
                                            <img class="lineup__product-img"
                                                src="https://www.owndays.com/images/specials/products/owndayspc2022/PC2008N-2S/C4/PC2008N-2S_C4_04_r2.webp"
                                                alt="PC2008N-2S">
                                        </figure>
                                        <p class="lineup__product-sku">PC2008N-2S C4</p>
                                    </a>
                                </li>
                            </ul>
                            <div class="l-owndayspc__container l-owndayspc__container--sm">
                                <div class="lineup__more">
                                    <a href="/sg/en/search?q=PC2008N"
                                        class="l-owndayspc__btn l-owndayspc__btn--right" style="--max-w: 275px;">More
                                        Details</a>
                                </div>
                            </div>
                            <div class="lineup__footer">
                                <a href="/sg/en/search?q=PC"
                                    class="l-owndayspc__btn l-owndayspc__btn--right l-owndayspc__btn--black"
                                    style="--max-w: 450px;">See All OWNDAYS PC Products</a>
                                <a href="/stores/sg"
                                    class="l-owndayspc__btn l-owndayspc__btn--right l-owndayspc__btn--black"
                                    style="--max-w: 450px;">Search For Nearest OWNDAYS Stores</a>
                            </div>
                        </div>
                    </section>
                </div>

            </div>

        </main>
    </div>


    <!-- /top-return -->
    <div id="top-btn" class="c-top-btn">
        <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
        <p>TOP</p>
    </div>



    <!-- Modal / Country & Language -->
    <div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                    </button>
                    <p class="modal-title">Choose your country</p>
                    <div id="accordionCountryLang" class="modal-accordion accordion">
                        <!-- For Mobile -->
                        <ul class="modal-list u-sp-only">
                            <li class="modal-list-item">
                                <button type="button" data-target="#countryjp" class="modal-list-country "
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                    </picture>
                                    日本
                                </button>
                                <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <button type="button" data-target="#countrysg" class="modal-list-country active"
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                    </picture>
                                    SINGAPORE
                                </button>
                                <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                   
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                    </picture>
                                    台灣
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <button type="button" data-target="#countryth" class="modal-list-country "
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                    </picture>
                                    ไทย
                                </button>
                                <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                    </picture>
                                    ព្រះរាជាណាចក្រ
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                    </picture>
                                    PHILIPPINES
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                    </picture>
                                    AUSTRALIA
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                    </picture>
                                    MALAYSIA
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <button type="button" data-target="#countryvn" class="modal-list-country "
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                    </picture>
                                    Việt Nam
                                </button>
                                <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                    </picture>
                                    INDONESIA
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                    </picture>
                                    香港
                                </a>
                            </li>
                        </ul>

                        <!-- For Desktop -->
                        <ul class="modal-list u-pc-only">
                            <li class="modal-list-item">
                                <button type="button" data-target="#countryjp" class="modal-list-country "
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                    </picture>
                                    日本
                                </button>
                                <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                    </picture>
                                    台灣
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                    </picture>
                                    ព្រះរាជាណាចក្រ
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                    </picture>
                                    AUSTRALIA
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <button type="button" data-target="#countryvn" class="modal-list-country "
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                    </picture>
                                    Việt Nam
                                </button>
                                <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                    </picture>
                                    香港
                                </a>
                            </li>
                        </ul>
                        <ul class="modal-list u-pc-only">
                            <li class="modal-list-item">
                                <button type="button" data-target="#countrysg" class="modal-list-country active"
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                    </picture>
                                    SINGAPORE
                                </button>
                                <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                   
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <button type="button" data-target="#countryth" class="modal-list-country "
                                    data-toggle="collapse" aria-expanded="false">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                    </picture>
                                    ไทย
                                </button>
                                <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                    <li>
                                        <a href="https://www.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                    </picture>
                                    PHILIPPINES
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                    </picture>
                                    MALAYSIA
                                </a>
                            </li>
                            <li class="modal-list-item">
                                <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                    <picture>
                                        <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                        <img class="lazyload" data-sizes="auto"
                                            data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                    </picture>
                                    INDONESIA
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</body>

</html>`;

const initPageScripts = () => {
  AOS.init({
    duration: 1000,
    once: true,
  });

  const scroll = new SmoothScroll('a[href*="#"]', {
    header: '.l-header',
  });

  const list = document.querySelector('ul.shuffle');
  const listItems = list.querySelectorAll('p');

  const waypoint = new Waypoint({
    element: document.getElementById('about'),
    handler() {
      Array.prototype.forEach.call(listItems, (element, index) => {
        shuffleLetters(element, {
          fps: 30,
        });
      });
      this.destroy();
    },
  });

  // Main slider
  let numSlick = 0;
  $('.slider--for').each(function () {
    numSlick++;
    $(this)
      .addClass('slider-' + numSlick)
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        asNavFor: '.slider--nav.slider-' + numSlick,
      });
  });
  numSlick = 0;
  $('.slider--nav').each(function () {
    numSlick++;
    $(this)
      .addClass('slider-' + numSlick)
      .slick({
        asNavFor: '.slider--for.slider-' + numSlick,
        arrows: false,
        dots: false,
        focusOnSelect: true,
        infinite: false,
        swipe: false,
        swipeToSlide: false,
        draggable: false,
        variableWidth: true,
      });
  });
  $('.slider--for').on('setPosition', function () {
    AOS.refresh();
  });

  $('.slider--for').on('setPosition', function () {
    AOS.refresh();
  });

  // Where slider
  $('.where__slider').slick({
    centerMode: true,
    centerPadding: '45px',
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 4,
        },
      },
    ],
  });

  const owlAbout = $('.owl-carousel--about');
  owlAbout.owlCarousel({
    loop: true,
    center: true,
    nav: false,
    dots: false,
    navSpeed: 500,
    responsiveClass: true,
    autoplayTimeout: 4000,
    smartSpeed: 500,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1.5,
        margin: 10,
      },
      768: {
        autoWidth: true,
        margin: 20,
      },
      1024: {
        autoWidth: true,
        margin: 30,
      },
    },
  });
  $('.owl-next--about').click(function () {
    owlAbout.trigger('next.owl.carousel');
  });
  $('.owl-prev--about').click(function () {
    owlAbout.trigger('prev.owl.carousel', [300]);
  });

  const owlPoint = $('.owl-carousel--point');
  owlPoint.owlCarousel({
    loop: true,
    center: true,
    nav: false,
    dots: false,
    lazyLoad: true,
    navSpeed: 500,
    responsiveClass: true,
    autoplayTimeout: 4000,
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1.5,
        margin: 10,
      },
      768: {
        autoWidth: true,
        margin: 20,
      },
      1024: {
        autoWidth: true,
        margin: 30,
      },
    },
  });
  $('.owl-next--point').click(function () {
    owlPoint.trigger('next.owl.carousel');
  });
  $('.owl-prev--point').click(function () {
    owlPoint.trigger('prev.owl.carousel', [300]);
  });

  owlAbout.trigger('refresh.owl.carousel');
  owlPoint.trigger('refresh.owl.carousel');
  owlAbout.trigger('refresh.owl.carousel');
  owlPoint.trigger('refresh.owl.carousel');
  owlAbout.trigger('refresh.owl.carousel');
  owlPoint.trigger('refresh.owl.carousel');
};

const loadScripts = async () => {
  try {
    const externalScripts = [
      'https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3',
      'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js',
      'https://kit.fontawesome.com/2f45761fc6.js',
      'https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap',
      'https://www.owndays.com/web/js/jquery.easing.1.3.js',
      'https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js',
      'https://cdn.xapping.com/libs.js',
      'https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/waypoints/4.0.1/noframework.waypoints.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js',
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js',
      'https://unpkg.com/shuffle-letters',
      'https://www.owndays.com/web/js/lazysizes.min.js',
      'https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3',
      'https://www.owndays.com/web/js/apis.google-platform.js',
      'https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2',
      'https://www.owndays.com/web/js/helper.js',
      'https://www.owndays.com/web/js/active-nav-menu.js?v=1.1',
      'https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222',
    ];

    await Promise.all(
      externalScripts.map(src => {
        return new Promise(resolve => {
          const script = document.createElement('script');
          script.src = src;
          script.defer = true;
          script.onload = resolve;
          document.head.appendChild(script);
        });
      })
    );

    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js';
    script.defer = true;
    script.onload = initPageScripts;
    document.body.appendChild(script);
  } catch (error) {
    console.error('Failed to load scripts:', error);
  }
};

const OwndaysPcHTML = () => {
  useEffect(() => {
    loadScripts();
  }, []);

  return (
    <>
      <MetaTagsManager
        canonical="/news/owndayspc"
        description="Protect your vision with OWNDAYS PC glasses! Specifically designed to shield your eyes by reducing up to 25% of harmful blue light emitted by screens."
        title="OWNDAYS PC | Blue Light Filter Glasses | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default OwndaysPcHTML;
