import React, { useEffect } from 'react';
import AOS from 'aos';

import { Breadcrumbs } from './Breadcrumbs';

import './HeaderAiruitem.scss';

export const HeaderAiruitem = ({ baseImgUrl }) => {
  useEffect(() => {
    const loadTypekit = () => {
      const config = {
        kitId: 'clr2low',
        scriptTimeout: 3000,
        async: true,
      };
      const h = document.documentElement;
      const timeout = setTimeout(() => {
        h.className = h.className.replace(/\bwf-loading\b/g, '') + ' wf-inactive';
      }, config.scriptTimeout);

      const script = document.createElement('script');
      let loaded = false;
      const firstScript = document.getElementsByTagName('script')[0];

      h.className += ' wf-loading';
      script.src = `https://use.typekit.net/${config.kitId}.js`;
      script.async = true;

      script.onload = script.onreadystatechange = function () {
        const readyState = this.readyState;
        if (loaded || (readyState && readyState !== 'complete' && readyState !== 'loaded')) return;
        loaded = true;
        clearTimeout(timeout);
        try {
          Typekit.load(config);
        } catch (e) {
          console.error('Typekit failed to load', e);
        }
      };

      firstScript.parentNode.insertBefore(script, firstScript);
    };

    const addCharacterSpans = () => {
      const blurAntElements = document.querySelectorAll('.blur-ant');

      blurAntElements.forEach(element => {
        const characters = element.textContent.split('');
        element.innerHTML = ''; // Clear the element content
        characters.forEach((char, i) => {
          const span = document.createElement('span');
          span.textContent = char;
          span.style.animationDelay = `${i * 50}ms`;
          element.appendChild(span);
        });
      });
    };

    const initializeAOS = () => {
      AOS.init({
        duration: 800,
        once: true,
        offset: 50,
      });
    };

    // Load Typekit and initialize the animations
    loadTypekit();
    addCharacterSpans();
    initializeAOS();
  }, []);

  return (
    <div className="header-airuitem">
      <Breadcrumbs />
      <section className="main-airuitem">
        <div className="main-airuitem__top">
          <div className="base-airuitem__container">
            <div className="main-airuitem__heading">
              <h1>
                <img alt="AIR Ultem" src={`${baseImgUrl}/logo.svg`} />
              </h1>
              <p className="blur-ant" data-aos="blur-ant">
                Soft as a Feather, Light as the Air
              </p>
            </div>
            <div className="main-airuitem__img">
              <picture>
                <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/main-pc.webp`} />
                <img alt="AIR Ultem" src={`${baseImgUrl}/main-sp.webp`} />
              </picture>
            </div>
          </div>
        </div>
        <div className="main-airuitem__bottom">
          <div className="base-airuitem__container">
            <div className="main-airuitem__bottom-preview main-airuitem__bottom-preview--1">
              <div className="main-airuitem__bottom-img">
                <img
                  alt="AIR Ultem"
                  className="main-airuitem__bottom-img-left"
                  data-aos="fade-up"
                  src={`${baseImgUrl}/glasses1.webp`}
                />
                <img
                  alt="AIR Ultem"
                  className="main-airuitem__bottom-img-right"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  src={`${baseImgUrl}/glasses2.webp`}
                />
              </div>
              <p className="main-airuitem__bottom-text blur-ant" data-aos="blur-ant">
                Lightweight, flexible and durable because it’s ultem
              </p>
            </div>
            <div className="main-airuitem__bottom-preview main-airuitem__bottom-preview--2">
              <div className="main-airuitem__bottom-img">
                <img alt="AIR Ultem" data-aos="fade-up" src={`${baseImgUrl}/glasses3.webp`} />
              </div>
              <p className="main-airuitem__bottom-text blur-ant" data-aos="blur-ant">
                Unlikely to break even when bent
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
