import React, { Fragment } from 'react';

import { onImageLoadError } from '../../../utils/helper';

import { Breadcrumbs } from './Breadcrumbs';

import './HeaderOwndaysProgressiveCP.scss';

export const HeaderOwndaysProgressiveCP = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <Breadcrumbs />
      <div className="od-header-owndays-progressive-cp">
        <img
          alt="OWNDAYS Free Enhanced Progressive Lenses"
          className="od-header-owndays-progressive-cp__container"
          src={`${baseImgUrl}/main-pc-08-2024.webp`}
          onError={e => onImageLoadError(e)}
        />

        <div className="od-header-owndays-progressive-cp__container__desc">
          <div className="od-header-owndays-progressive-cp__header">
            <h1>FREE</h1>
            <span>
              {`PROGRESSIVE `} <br />
              LENSES
            </span>
          </div>

          <div className="od-header-owndays-progressive-cp__body">
            <h3>For First-Time Wearers With Spectacle Purchase*</h3>
            <h2>1 - 31 AUG</h2>
          </div>

          <div className="od-header-owndays-progressive-cp__footer">
            <span>
              Progressive glasses from<strong>$98</strong>only!
            </span>
          </div>
        </div>

        <div className="">
          <span className="od-header-owndays-progressive-cp__container__remark od-header-owndays-progressive-cp__remark-text">
            * Free Enhanced Progressive Lens only, other T&Cs apply
          </span>
        </div>
      </div>
    </Fragment>
  );
};
