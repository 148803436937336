import React, { useMemo } from 'react';
import './LensOptions.scss';
import LensIcon from '../../../../static/assets/svg/od-lens.svg';
import CheckedIcon from '../../../../static/assets/svg/od-checked.svg';
import UnCheckedIcon from '../../../../static/assets/svg/od-unchecked.svg';
import { useSelector } from 'react-redux';
import Coatings from './Coatings';

const LensOptions = ({
  packages,
  setSelectedPackage,
  selectedPackage,
  isDesktop = false,
  coating = null,
  selectedCoating,
  setSelectedCoating,
}) => {
  const { SELECT_LENS } = useSelector(state => state.locale);

  const handleSelection = type => {
    setSelectedPackage(type);
    setSelectedCoating(null);
  };

  const lensOptionTitle = useMemo(() => {
    const title = SELECT_LENS.toLowerCase();
    return title
      .split(/\s+/)
      .map(word => word[0].toUpperCase() + word.substring(1))
      .join(' ');
  }, [SELECT_LENS]);

  if (packages && packages.length > 0) {
    return (
      <div
        className={`od-lens-options ${
          isDesktop ? 'od-lens-options-desktop' : 'od-lens-options-mobile'
        }`}
      >
        <h4 className="od-lens-options-title">{lensOptionTitle}</h4>
        <div className="od-lens-options-items">
          {packages.map(pack => (
            <div
              key={pack.id}
              className={`od-lens-options-item-panel ${
                selectedPackage?.id === pack.id && 'od-lens-options-item-active'
              }`}
            >
              <div className="od-lens-options-item" onClick={() => handleSelection(pack)}>
                <div className="od-lens-options-item-left">
                  {/* <img alt={lens.title} className="od-lens-options-item-lens-img" src={LensIcon} /> */}
                  <div className="od-lens-options-item-info">
                    <h4 className="od-lens-options-item-info-title">{pack?.label}</h4>
                    {/* <p className="od-lens-options-item-info-description">{lens?.description}</p> */}
                  </div>
                </div>
                <img
                  alt={pack?.label}
                  className="od-lens-options-item-lens-checkbox"
                  src={selectedPackage?.id === pack.id ? CheckedIcon : UnCheckedIcon}
                />
              </div>
              {selectedPackage?.id === pack?.id ? (
                <Coatings
                  coatings={pack?.addons}
                  selectedCoating={selectedCoating}
                  setSelectedCoating={setSelectedCoating}
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default LensOptions;
