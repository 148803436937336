import React, { useEffect, useState } from 'react';
import './GiftCards.scss';
import { useDispatch, useSelector } from 'react-redux';
import GiftCardsSection from '../../../CommonComponents/GiftCards/GiftCardsSection/GiftCardsSection';
import SelectCardDesign from './SelectCardDesign/SelectCardDesign';
import DenominationSection from './DenominationSection/DenominationSection';
// import HowToUse from './HowToUse/HowToUse';
// import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import GiftCardsPriceSection from './GiftCardsPriceSection/GiftCardsPriceSection';
import ProductPurchaseSection from '../../../CommonComponents/GiftCards/ProductPurchaseSection/ProductPurchaseSection';
import GiftCardPreview from './GiftCardPreview/GiftCardPreview';
import apiClient from '../../../../helpers/apiClient';
import { apiPath } from '../../../../config';
import { getProductPrice } from '../../../helper';
import { addToCart } from '../../../../actionCreators/cart';
import { withRouter } from 'react-router';
import GiftCardsHeader from '../../../CommonComponents/GiftCards/GiftCardsHeader/GiftCardsHeader';
import WriteYourMessage from '../../../CommonComponents/WriteYourMessage/WriteYourMessage';
import YourName from '../../../CommonComponents/YourName/YourName';
// import config from 'config';
import { setToastMessage } from '../../../../actionCreators/common';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const writeMessageTextMaxLength = 260;

// const giftCardImages = [
//   'https://static5.lenskart.com/media/catalog/product/pre/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//o/i/owndays-gc000001-c3-gift-cards_od_gc_one.png',
//   'https://static5.lenskart.com/media/catalog/product/pre/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//o/i/owndays-gc000001-c3-gift-cards_od_gc_two.png',
//   'https://static5.lenskart.com/media/catalog/product/pre/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//o/i/owndays-gc000001-c3-gift-cards_od_gc_three.png',
//   'https://static5.lenskart.com/media/catalog/product/pre/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//o/i/owndays-gc000001-c3-gift-cards_od_gc_four.png',
// ];

const GiftCards = ({ history }) => {
  const [writeMessageText, setWriteMessageText] = useState('');
  const [giftCardImages, setGiftCardImages] = useState([]);
  const [selectCardType, setSelectCardType] = useState();
  const [giftCardCatId, setGiftCardCatId] = useState(null);
  const [previewType, setPreviewType] = useState(0);
  const [yourName, setYourName] = useState('');
  const [selectedDenomination, setSelectedDenomination] = useState(null);
  const [denominations, setDenominations] = useState([]);
  // const [disablePurchase, setDisablePurchase] = useState(false);

  const dataLocale = useSelector(state => state.locale);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('this is GIFT CARD ENV ===>', process.env.ENVIRONMENT);
    // OD__CLEVERTAP__EVENT (Page View)
    if (typeof window.clevertap !== 'undefined') {
      window.clevertap.event.push('Page View', {
        Page: 'Gift Card Page',
        Title: 'Gift Cards｜OWNDAYS ONLINE STORE - OPTICAL SHOP',
        URL: window.location.href,
      });

      // OD__CLEVERTAP__EVENT (Clicked on a Product Category)
      window.clevertap.event.push('Clicked on a Product Category', {
        Page: 'Gift Card Page',
        Title: 'Gift Cards｜OWNDAYS ONLINE STORE - OPTICAL SHOP',
        URL: window.location.href,
      });
    }

    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .message-toast {
        text-align: center !important;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        window?.location?.href?.includes('preproduction') ||
        window?.location?.href?.includes('localhost')
      ) {
        setGiftCardCatId(91351);
      } else {
        setGiftCardCatId(17131);
      }
    }
  }, []);

  useEffect(() => {
    console.log('this is gifcatiddd ==>', giftCardCatId);
    if (giftCardCatId) {
      const client = apiClient();
      client.setHeader();
      client
        .get(`${apiPath.dataCategory}${giftCardCatId}?page-size=500&include-images=true`)
        .then(res => {
          const giftCards = res?.result?.product_list;
          setGiftCardImages(() => giftCards[0]?.imageUrls);
          setSelectCardType(giftCards[0]?.imageUrls[0]);
          let denominationsAndId = [];
          if (Array.isArray(giftCards) && giftCards.length > 0) {
            denominationsAndId = giftCards.map(gc => ({ id: gc?.id, price: getProductPrice(gc) }));
          }
          if (denominationsAndId.length > 0) {
            const result = denominationsAndId.sort((a, b) => a.price - b.price);
            setDenominations(result);
            setSelectedDenomination(result[0]);
          }
        })
        .catch(() => {});
    }
  }, [giftCardCatId]);

  const {
    SELECT_CARD_DESIGN,
    SELECT_CARD_DENOMINATION,
    WRITE_YOUR_MESSAGE,
    YOUR_NAME,
    // HOW_TO_USE,
    // TERMS_AND_CONDITIONS,
  } = dataLocale;

  const handleWriteMessageText = event => {
    const newText = event.target.value;
    if (newText.length <= writeMessageTextMaxLength) {
      setWriteMessageText(newText);
    }
  };

  // useEffect(() => {
  //   if (
  //     selectedDenomination &&
  //     selectCardType &&
  //     writeMessageText.trim()?.length &&
  //     yourName.trim()?.length
  //   )
  //     setDisablePurchase(true);
  // }, [selectCardType, selectedDenomination, writeMessageText, yourName]);

  const purchaseAction = () => {
    // purcahse action

    if (!yourName.trim()?.length) {
      return dispatch(
        setToastMessage({ message: 'please write your name to continue', timeout: 3000 })
      );
    }

    if (!writeMessageText.trim()?.length) {
      return dispatch(
        setToastMessage({ message: 'please write your message to continue', timeout: 3000 })
      );
    }

    // if (!selectedDenomination) {
    //   return dispatch(
    //     setToastMessage({ message: 'please select a card denomination to continue', timeout: 3000 })
    //   );
    // }

    // if (!selectCardType) {
    //   return dispatch(
    //     setToastMessage({ message: 'Please select your card design', timeout: 2000 })
    //   );
    // }

    dispatch(
      addToCart({
        productId: selectedDenomination.id,
        giftCardDetails: {
          message: writeMessageText,
          imageName: selectCardType,
          senderName: yourName,
        },
      })
    );

    return history.push('/cart');
  };

  const handleDenominationSelection = denomination => {
    setSelectedDenomination(denomination);
  };

  if (denominations.length > 0) {
    return (
      <main className="od-gift-cards">
        <MetaTagsManager
          canonical="/gift-cards"
          description="Gift Cards｜OWNDAYS ONLINE STORE - OPTICAL SHOP"
          title="Gift Cards｜OWNDAYS ONLINE STORE - OPTICAL SHOP"
        />
        <GiftCardsHeader device="desktop" />
        <div className="main-content-wrapper">
          <div className="od-gift-cards__main">
            <section className="od-gift-cards__main__left">
              <GiftCardsSection device="desktop" title={SELECT_CARD_DESIGN}>
                <SelectCardDesign
                  giftCardImages={giftCardImages}
                  handleSelectCardType={setSelectCardType}
                  selectCardType={selectCardType}
                  setPreviewType={setPreviewType}
                />
              </GiftCardsSection>
              <GiftCardsSection device="desktop" title={SELECT_CARD_DENOMINATION}>
                <DenominationSection
                  denominations={denominations}
                  handleDenominationSelection={handleDenominationSelection}
                  selectedDenomination={selectedDenomination}
                />
              </GiftCardsSection>
              <GiftCardsSection
                classes="od-gift-cards__main__left__write-message-title"
                device="desktop"
                html={`<h2>${WRITE_YOUR_MESSAGE}</h2> <span>(${
                  writeMessageText.length || 0
                }/${writeMessageTextMaxLength})</span>`}
              >
                <WriteYourMessage
                  device="desktop"
                  handleWriteMessageText={handleWriteMessageText}
                  text={writeMessageText}
                />
              </GiftCardsSection>
              <GiftCardsSection device="desktop" title={YOUR_NAME}>
                <YourName device="desktop" setYourName={setYourName} yourName={yourName} />
              </GiftCardsSection>
              {/* <GiftCardsSection
                classes="od-gift-cards__main__left__how-to-use-title"
                device="desktop"
                html={`<h3>${HOW_TO_USE}</h3>`}
              >
                <HowToUse />
              </GiftCardsSection>
              <GiftCardsSection
                classes="od-gift-cards__main__left__terms-conditions-title"
                device="desktop"
                html={`<h3>${TERMS_AND_CONDITIONS}</h3>`}
              >
                <TermsAndConditions />
              </GiftCardsSection> */}
            </section>
            <section className="od-gift-cards__main__right">
              {selectedDenomination && (
                <GiftCardsPriceSection price={selectedDenomination?.price} />
              )}
              <ProductPurchaseSection device="desktop" purchaseAction={purchaseAction} />
              <GiftCardPreview
                previewType={previewType}
                url={selectCardType}
                writeMessageText={writeMessageText}
                yourName={yourName}
              />
            </section>
          </div>
        </div>
      </main>
    );
  }

  return null;
};

export default withRouter(GiftCards);
