import React, { useEffect, useState } from 'react';
import apiClient from '../../../helpers/apiClient';
import { apiPath } from '../../../config';
import { odPushGA4Event } from '../../../utils/gaFour';
import { useSelector } from 'react-redux';

const useGetOrderById = (id, uuid, grabPayId = null) => {
  const userInfo = useSelector(state => state.common.userInfo);

  const [orderDetail, setOrderDetail] = useState(null);
  const [fetchingOrderDetails, setFetchingOrderDetails] = useState(false);

  const fetchOrderDetails = orderId => {
    const client = apiClient();
    client.setHeader();

    client
      .get(`${apiPath.getOrders}/${orderId}`)
      .then(res => {
        if (Number(res?.status) === 200) {
          console.log('this is order details ===>>>>', res.result);

          const gaPLPSelectItemData =
            JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};

          const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

          odPushGA4Event('purchase', {
            userInfo,
            order: res.result,
            gaPLPSelectItemData,
            gaAddToCartData,
          });
          setOrderDetail(res?.result);
          setFetchingOrderDetails(false);
        }
      })
      .catch(() => {
        setOrderDetail(null);
        setFetchingOrderDetails(false);
      });
  };

  useEffect(() => {
    const client = apiClient();
    client.setHeader();

    if (grabPayId) {
      client
        .get(`${apiPath.juspayOrderStatus}/${grabPayId}/juspay/status`)
        .then(res => {
          fetchOrderDetails(res?.result?.orderId);
          console.log('juspay order status res ===>', res);
        })
        .catch(() => {});
    } else {
      fetchOrderDetails(id);
    }
  }, [uuid]);

  return { orderDetail, fetchingOrderDetails };
};

export default useGetOrderById;
