import React, { useState } from 'react';
import RightIcon from '../../../../../static/assets/svg/icon-right.svg';
import './Collapse.scss';

const Collapse = ({
  children,
  title,
  icon,
  description,
  titleIcon,
  onSelect,
  isToggleOnHeaderClick,
}) => {
  const [isOpen, setIsOpen] = useState(!onSelect);
  return (
    <div className="odslv-pt__item" onClick={() => onSelect && onSelect()}>
      <div
        className="odslv-pt__item__title"
        onClick={() => isToggleOnHeaderClick && setIsOpen(prevState => !prevState)}
      >
        <div className="odslv-pt__item__name">
          {icon && <img alt="collapse" className="odslv-pt__item__name_img" src={icon} />}
          <div className="odslv-pt__item__name__with-icon">
            {titleIcon && <img alt="prescription" src={titleIcon} />}
            {title}
            {description && <div className="odslv-pt__item__name_des">{description}</div>}
          </div>
        </div>
        <div className="odslv-pt__item__icon">
          {onSelect ? (
            <img
              alt="arrow"
              className={isOpen ? 'odslv-pt__item__icon-rotate-90' : ''}
              src={RightIcon}
            />
          ) : (
            <img
              alt="arrow"
              className={isOpen ? 'odslv-pt__item__icon-rotate' : ''}
              src={RightIcon}
              onClick={() => setIsOpen(prevState => !prevState)}
            />
          )}
        </div>
      </div>
      {isOpen ? <div className="odslv-pt__item__body">{children}</div> : null}
    </div>
  );
};

export default Collapse;
