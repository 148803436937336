import React, { useRef, useState } from 'react';
import './Gundam.scss';
import ProductSlider from './ProductSlider/ProductSlider';
import ColorOption from './ColorOption/ColorOption';
import { gundamCase, gundamEyeglasses, gundamSunglasses } from './gundamData';
import GundamDescription from '../../../CommonComponents/GundamDescription/GundamDescription';

const Gundam = () => {
  const [selectedEyeglass, setSelectedEyeglass] = useState(gundamEyeglasses.colors[0]);
  const [selectedSunglass, setSelectedSunglass] = useState(gundamSunglasses.colors[0]);

  const gundamDescriptionRef = useRef(null);
  const lineupOneRef = useRef(null);

  const scrollToNextBlock = ref => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="od-catalog">
      <div className="od-catalog__banner">
        <img
          alt="gundam logo"
          className="od-catalog__banner-logo"
          src="https://static.lenskart.com/media/owndays/desktop/img/gundum/gundam-logo.png"
        />
        <img
          alt="scroll"
          className="od-catalog__banner-scroll"
          src="https://static.lenskart.com/media/owndays/desktop/img/gundum/scroll-down-desktop.svg"
          onClick={() => scrollToNextBlock(gundamDescriptionRef)}
        />
      </div>
      <GundamDescription
        gundamDescriptionRef={gundamDescriptionRef}
        onClick={() => scrollToNextBlock(lineupOneRef)}
      />
      <div className="od-catalog__products">
        <div className="container">
          <div ref={lineupOneRef} className="catalog-card-lineup">
            Line up
            <img
              alt="lineup"
              src="https://static.lenskart.com/media/owndays/desktop/img/gundum/01.png"
            />
          </div>
          <div className="catalog-card">
            <div className="catalog-card__left">
              <ProductSlider
                images={selectedSunglass.images}
                otherImages={selectedSunglass.altImages}
                selectedOption={selectedSunglass}
              />
              <div className="catalog-card__left-content">
                <h4>{gundamSunglasses.modelNumber}</h4>
                <div className="od-catalog-white-heading">{gundamSunglasses.name}</div>
                <ColorOption
                  colors={gundamSunglasses.colors}
                  selectedOption={selectedSunglass}
                  onSelect={setSelectedSunglass}
                />
                <button
                  className="od-catalog-button"
                  onClick={() => {
                    window.location.href = `/sg/en/${selectedSunglass.url}`;
                  }}
                >
                  View details
                </button>
              </div>
            </div>
            <div className="catalog-card__right">
              <img
                alt="char 1"
                src="https://static.lenskart.com/media/owndays/mobile/img/gundum/lineup_char-1-pc.webp"
              />
            </div>
          </div>
          <div className="catalog-card-lineup__right">
            <img
              alt="lineup"
              src="https://static.lenskart.com/media/owndays/desktop/img/gundum/02.png"
            />
          </div>
          <div className="catalog-card catalog-card-lr">
            <div className="catalog-card__left">
              <div className="catalog-card__left-content">
                <h4>{gundamEyeglasses.modelNumber}</h4>
                <div className="od-catalog-white-heading">{gundamEyeglasses.name}</div>
                <ColorOption
                  colors={gundamEyeglasses.colors}
                  selectedOption={selectedEyeglass}
                  onSelect={setSelectedEyeglass}
                />
                <button
                  className="od-catalog-button"
                  onClick={() => {
                    window.location.href = `/sg/en/${selectedEyeglass.url}`;
                  }}
                >
                  View details
                </button>
              </div>
              <ProductSlider
                images={selectedEyeglass.images}
                otherImages={selectedEyeglass.altImages}
                selectedOption={selectedEyeglass}
              />
            </div>
            <div className="catalog-card__right">
              <img
                alt="char 2"
                src="https://static.lenskart.com/media/owndays/mobile/img/gundum/lineup_char-2-pc.webp"
              />
            </div>
          </div>
          <div className="catalog-card-lineup__left">
            <img
              alt="lineup"
              src="https://static.lenskart.com/media/owndays/desktop/img/gundum/03.png"
            />
          </div>
          <div className="catalog-card">
            <div className="catalog-card__left">
              <ProductSlider images={gundamCase.images} otherImages={gundamCase.altImages} />
              <div className="catalog-card__left-content">
                <h4>{gundamCase.modelNumber}</h4>
                <div className="od-catalog-white-heading">{gundamCase.name}</div>
                {/* <ColorOption /> */}
                <button
                  className="od-catalog-button"
                  onClick={() => {
                    window.location.href = `/sg/en/${gundamCase.url}`;
                  }}
                >
                  View details
                </button>
              </div>
            </div>
            <div className="catalog-card__right">
              <img
                alt="char 3"
                src="https://static.lenskart.com/media/owndays/desktop/img/gundum/concept-img-pc.webp"
              />
            </div>
          </div>
          <img
            alt="zeon logo"
            className="zeon-logo-lg"
            src="https://static.lenskart.com/media/owndays/desktop/img/gundum/zeon-logo-lg.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Gundam;
