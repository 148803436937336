import React, { useState } from 'react';
import './Coatings.scss';
import CheckedIcon from '../../../../static/assets/svg/od-checked.svg';
import UnCheckedIcon from '../../../../static/assets/svg/od-unchecked.svg';

const Coatings = ({ coatings, selectedCoating, setSelectedCoating }) => {
  const [hoverImg, setHoverImg] = useState('');
  if (Array.isArray(coatings) && coatings.length > 0) {
    return (
      <>
        {/* <div className="od-product-coatings__lens_guide">See Lens Guide</div> */}
        <div className="od-product-coatings">
          {coatings.map(coating => (
            <div
              key={coating.id}
              className="od-product-coating"
              onClick={() => setSelectedCoating(coating)}
            >
              <img
                alt={coating?.title}
                className="od-product-coating__radiocheck"
                src={selectedCoating?.id === coating?.id ? CheckedIcon : UnCheckedIcon}
              />
              <img
                alt={coating?.title}
                className="od-product-coating__img"
                src={hoverImg || coating?.imageUrl}
                onMouseEnter={() => setHoverImg(coating?.hoverImageUrl)}
                onMouseLeave={() => setHoverImg('')}
              />
              <div className="od-product-coating__label">{coating?.title}</div>
            </div>
          ))}
        </div>
      </>
    );
  }

  return null;
};

export default Coatings;
