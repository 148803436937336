import React, { useState } from 'react';
import './Membership.scss';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import MembershipCircle from './MembershipCircle/MembershipCircle';
import MembershipSection from './MembershipPoints/MembershipSection';
import MembershipHeading from './MembershipHeading/MembershipHeading';
import MembershipStatuses from './MembershipStatuses/MembershipStatuses';
import MembershipFunction from './MembershipFunction/MembershipFunction';
import BannerOwndays from '../../../CommonComponents/BannerOwndays/BannerOwndays';
import AuthModalNewLoadable from '../../../../desktop/components/AuthModalNew/AuthModalNewLoadable';
import { useDispatch, useSelector } from 'react-redux';
import { setToastMessage } from '../../../../actionCreators/common';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const bannerData = {
  title: 'OWNDAYS MEMBERSHIP',
  img: {
    src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/od-membership-banner.webp',
  },
};

const Membership = () => {
  const [showAuthPopup, setShowAuthPopup] = useState(false);

  const login = useSelector(state => state.common.login);

  const dispatch = useDispatch();

  return (
    <main className="od-membership">
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Join the OWNDAYS membership and earn points on all purchases. Enjoy exclusive perks and hassle-free shopping with no membership fee.',
          },
          {
            name: 'twitter:description',
            content:
              'Join the OWNDAYS membership and earn points on all purchases. Enjoy exclusive perks and hassle-free shopping with no membership fee.',
          },
          {
            name: 'og:title',
            content: 'OWNDAYS Membership Programme | Exclusive Benefits & Offers',
          },
          {
            name: 'twitter:title',
            content: 'OWNDAYS Membership Programme | Exclusive Benefits & Offers',
          },
        ]}
        description="Join the OWNDAYS membership and earn points on all purchases. Enjoy exclusive perks and hassle-free shopping with no membership fee."
        title="OWNDAYS Membership Programme | Exclusive Benefits & Offers"
      />
      <section className="od-membership-banner">
        <BannerOwndays data={bannerData} />
      </section>
      <section className="od-membership-perks">
        <div className="od-membership-perks__title od-font-bold">
          Exclusive Perks, More Convenience!
        </div>
        <div className="od-membership-perks__description od-font-reg">
          Unlock exclusive rewards with the OWNDAYS membership programme! You can now earn
          membership points for your in-store and online purchases, receive members-only perks and
          enjoy added convenience while shopping at OWNDAYS. No membership fee required.
        </div>
        <div className="od-membership-perks__btns">
          <ButtonOwnDays
            fontClass="od-font-bold"
            fontSize="20px"
            mode="grey"
            px="0"
            py="30px"
            text="Sign up as member"
            width="405px"
            onClick={() => {
              if (!login) {
                window.location.href = '/sg/en/customer/account/signup'
              } else {
                dispatch(
                  setToastMessage({
                    message: 'You already have an account',
                    timeout: 3000,
                  })
                );
              }
            }}
          />
          <ButtonOwnDays
            fontClass="od-font-bold"
            fontSize="20px"
            px="0"
            py="30px"
            text="Manage my account"
            width="415px"
            onClick={() => {
              if (login) {
                window.location.assign('/sg/en/customer/account/edit');
              } else {
                dispatch(
                  setToastMessage({
                    message: 'Please create an account first',
                    timeout: 3000,
                  })
                );
              }
            }}
          />
        </div>
      </section>
      <section className="od-membership-features">
        <div className="od-membership__title--features od-font-bold">Membership Features</div>
        <div className="od-membership-features__table">
          <div className="od-membership-features__table-item">
            <MembershipCircle variant="earn points" />
            <div className="od-membership-features__table-item__title od-font-bold">
              Earn rebate points to offset next purchase
            </div>
          </div>
          <div className="od-membership-features__table-item">
            <MembershipCircle variant="refer friend" />
            <div className="od-membership-features__table-item__title od-font-bold">
              Refer a friend to enjoy exclusive privileges for both you and your friend!
            </div>
          </div>
          <div className="od-membership-features__table-item">
            <MembershipCircle variant="exclusive perks" />
            <div className="od-membership-features__table-item__title od-font-bold">
              Enjoy exclusive perks at different membership levels
            </div>
          </div>
          <div className="od-membership-features__table-item">
            <MembershipCircle variant="benefits" />
            <div className="od-membership-features__table-item__title od-font-bold">
              Members-Only Range of benefits
            </div>
          </div>
        </div>
      </section>
      <MembershipSection
        isPoints
        dataHeading={{
          title: 'Points',
          subTitle: 'OWNDAYS Membership',
          description: 'Earn membership points to offset your next in-store and online purchase.',
        }}
      />
      <MembershipSection
        isRefFriend
        dataHeading={{
          title: 'Refer a friend',
          subTitle: 'OWNDAYS Membership',
          description:
            'Refer a friend to enjoy exclusive privileges for both you and your friend! Your friend gets a S$20 discount coupon while you earn 4% points rebate.',
        }}
      />
      <section className="od-membership-ranks">
        <MembershipHeading
          {...{
            title: 'Ranks',
            subTitle: 'OWNDAYS Membership',
            description:
              'Earn points to move up the levels. The higher you go, the more points rebates you earn! Plus, you get to enjoy exclusive benefits at different membership levels.',
          }}
        />
        <MembershipStatuses />
      </section>
      <section className="od-membership-function">
        <MembershipHeading
          {...{
            title: 'Function',
            subTitle: 'OWNDAYS Membership',
            description: 'Sign up as member to enjoy exclusive perks and added convenience',
          }}
        />
        <MembershipFunction />
      </section>
      <section className="od-membership-account-reg">
        <div className="od-membership-account-reg__title od-dont-bold">
          Quick and easy account registration!
        </div>
        <div className="od-membership-account-reg__btn">
          <ButtonOwnDays
            fontClass="od-font-bold"
            fontSize="24px"
            mode="grey"
            text="Sign up as member"
            onClick={() => {
              if (!login) {
                window.location.href = '/sg/en/customer/account/signup'
              } else {
                dispatch(
                  setToastMessage({
                    message: 'You already have an account',
                    timeout: 3000,
                  })
                );
              }
            }}
          />
        </div>
        <div className="od-membership-account-reg__description od-dont-reg">
          Quick and easy account registration!
        </div>
      </section>
      {showAuthPopup && (
        <AuthModalNewLoadable
          allowSignUp
          handleModalClose={() => setShowAuthPopup(false)}
          hideLogin={() => setShowAuthPopup(false)}
          login={login}
          openTab="signin"
        />
      )}
    </main>
  );
};

export default Membership;
