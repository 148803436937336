// Breadcrumbs.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss'; // optional, for custom styling

const Breadcrumbs = ({ crumbs }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb breadcrumb--2">
        {crumbs.map((crumb, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${index === crumbs.length - 1 ? 'active' : ''}`}
            aria-current={index === crumbs.length - 1 ? 'page' : undefined}
          >
            {index < crumbs.length - 1 ? (
              <Link to={crumb.path}>{crumb.label}</Link>
            ) : (
              crumb.label
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;