import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from './../../../../static/assets/svg/od-calendar-icon.svg';

const parseDateStringToDate = dateString => {
  const dateComponents = dateString.split('/');
  if (dateComponents.length === 3) {
    const [day, month, year] = dateComponents;
    const dateObject = new Date(`${year}-${month}-${day}`);
    return dateObject;
  }
  return null;
};

const formatDate = dateString => {
  const parsedDate = Date.parse(dateString);
  if (!isNaN(parsedDate)) {
    const dateObject = new Date(parsedDate);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return '';
};

const CustomDatePicker = ({
  value,
  onChange,
  onBlur,
  containerClassName,
  inputClass,
  placeholderText = 'Date of birth (dd/mm/yyyy)',
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value ? formatDate(value) : '');
  const dateOfBirthRef = useRef(null);

  useEffect(() => {
    const handleClickOutsideDOB = event => {
      const calendarElement = document.querySelector('.react-datepicker');
      const isMonthOptionClicked = event.target.classList.contains(
        'react-datepicker__month-option'
      );
      const isYearOptionClicked = event.target.classList.contains('react-datepicker__year-option');

      if (
        !dateOfBirthRef.current.contains(event.target) &&
        calendarElement &&
        !calendarElement.contains(event.target) &&
        !isMonthOptionClicked &&
        !isYearOptionClicked
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutsideDOB);

    return () => {
      document.removeEventListener('click', handleClickOutsideDOB);
    };
  }, [setIsCalendarOpen]);

  const handleInputChange = e => {
    let eValue = e.target.value.replace(/\D/g, '');

    if (eValue.length > 2) {
      eValue = `${eValue.slice(0, 2)}/${eValue.slice(2)}`;
    }
    if (eValue.length > 5) {
      eValue = `${eValue.slice(0, 5)}/${eValue.slice(5, 9)}`;
    }

    setInputValue(eValue);
    onChange(parseDateStringToDate(eValue));
  };

  return (
    <div ref={dateOfBirthRef} className={containerClassName}>
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.14.1/react-datepicker.min.css"
        rel="stylesheet"
      />
      {!isCalendarOpen ? (
        <input
          className={inputClass && inputClass}
          inputMode="numeric"
          placeholder={placeholderText}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
        />
      ) : (
        <DatePicker
          fixedHeight
          scrollableYearDropdown
          showMonthDropdown
          showYearDropdown
          className={inputClass}
          dateFormat="dd/MM/yyyy"
          inputMode="numeric"
          maxDate={new Date()}
          open={isCalendarOpen}
          placeholderText={placeholderText}
          selected={value}
          yearDropdownItemNumber={100}
          onBlur={onBlur || null}
          onChange={date => {
            onChange(date);
            setInputValue(formatDate(date));
            setIsCalendarOpen(false);
          }}
          onClick={e => e.stopPropagation()}
        />
      )}
      <img
        alt="calendar"
        id="calendar-icon"
        src={CalendarIcon}
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
      />
    </div>
  );
};

export default CustomDatePicker;
