import React, { useEffect, useState } from 'react';
import './ProductSlider.scss';

const ProductSlider = ({ images, otherImages, selectedOption }) => {
  const [currentImage, setCurrentImage] = useState(images?.[0]);

  useEffect(() => {
    if(selectedOption) {
      setCurrentImage(selectedOption?.images[0])
    }
  }, [selectedOption]);

  if (images && images.length > 0 && otherImages?.length > 0) {
    return (
      <div className="od-catalog-slider">
        <div className="od-catalog-slider__current">
          <img src={currentImage} alt="current image" />
        </div>
        <div className="od-catalog-slider__other">
          {otherImages.map(url => (
            <div className="od-catalog-slider__other_col">
              <img onClick={() => setCurrentImage(url)} src={url} alt="" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default ProductSlider;
