import React from 'react';
import './PrescriptionType.scss';
import Collapse from './Collapse';
import FrameOnly from '../../../../../static/assets/img/select-lenses/frame-only.png';
import SingleVision from '../../../../../static/assets/img/select-lenses/single-vision.png';
import ZeroPower from '../../../../../static/assets/img/select-lenses/zero-power.png';

const prescriptions = [
  {
    id: 'single_vision',
    title: 'Single Vision (With Prescription)',
    description: 'For distance or near vision',
    titleIcon: SingleVision,
  },
  {
    id: 'frame_only',
    title: 'Lens Replacement Voucher (Eye Test at Store)',
    description: 'You can replaces lenses at store using our Lens Replacement Voucher',
    titleIcon: FrameOnly,
  },
  {
    id: 'zero_power',
    title: 'Zero Power',
    description: 'Blocks 98% of harmful rays',
    titleIcon: ZeroPower,
  },
];

const PrescriptionType = ({ setSelectedPrescriptionType }) => {
  const handleCheck = id => {
    setSelectedPrescriptionType(id);
  };

  return (
    <div className="odslv-pt">
      <h1 className="odslv-pt__heading">Select Lens Type</h1>
      {prescriptions.map(prescription => (
        <Collapse
          key={prescription.id}
          description={prescription.description}
          id={prescription.id}
          title={prescription.title}
          titleIcon={prescription.titleIcon}
          onSelect={() => handleCheck(prescription.id)}
        />
      ))}
    </div>
  );
};

export default PrescriptionType;
