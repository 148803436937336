import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import Navigation from '../CommonComponents/Services/Navigation/Navigation';
import Header from '../CommonComponents/Services/Header/Header';
import LensCategories from '../CommonComponents/Services/LensCategories/LensCategories';
import LensDetails from '../CommonComponents/Services/LensDetails/LensDetails';
import './css/Services.scss';

const LensGuideHTML = () => {
  const crumbs = [
    { path: '/', label: 'Home' },
    { path: '/services', label: 'Services' },
    { path: '/services/lens', label: 'Lens Guide' },
  ];
  const currentPath = '/services/lens';
  const headerContent = {
    title: 'LENS GUIDE',
    description:
      'We offer regular high index aspheric lenses at no additional charge and a wide range of <br/>optional lenses that cater to different needs.',
    bgSp: 'https://static.lenskart.com/media/owndays/desktop/img/services-lens/main-768x500.webp',
    bgPc: 'https://static.lenskart.com/media/owndays/desktop/img/services-lens/main-1536x480.webp',
    bgAlt: 'LENS GUIDE',
    align: 'center',
    theme: 'light',
  };

  return (
    <>
      <Helmet>
        <title>LENS GUIDE | OWNDAYS ONLINE STORE - OPTICAL SHOP</title>
      </Helmet>
      <Breadcrumbs crumbs={crumbs} />
      <div className="services">
        <Navigation currentPath={currentPath} size="desktop" />
        <Header headerContent={headerContent} />
        <div className="services__container">
          <h2 className="services__subtitle">Options Available</h2>
          <LensCategories />
          <h2 className="services__subtitle">Lens Details</h2>
          <LensDetails />
          <Link className="services__lens-replacement" to="/services/lens-replacement">
            <img
              className="services__lens-replacement-img"
              src="https://static.lenskart.com/media/owndays/img/services-lens/banner-lens-replacement.webp"
              alt="Lens Replacement"
            />
            <div className="services__lens-replacement-details">
              <h2 className="services__lens-replacement-title">Lens Replacement</h2>
              <p className="services__lens-replacement-subtitle">
                Replace lenses at simple price <br class="u-sp-only" />
                Even for spectacles bought at other shops
              </p>
              <p className="services__lens-replacement-des">
                Bring us the spectacles that you can no longer use due to changes in your
                prescription or damage to the lenses and let us help you breathe new life to them.
              </p>
              <p className="services__lens-replacement-price">From S$98〜</p>
              <p className="services__lens-replacement-more">VIEW MORE</p>
            </div>
          </Link>
          <Navigation currentPath={currentPath} size="mobile" />
        </div>
      </div>
    </>
  );
};

export default LensGuideHTML;
