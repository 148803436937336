import React, { useEffect } from 'react';
const scenesSliders = [
  {
    pcImg: 'scene1-pc.webp',
    spImg: 'scene1-sp.webp',
    title: 'Relaxing at home',
    description:
      'Perfect for leisure time at home that you can still have conversations with your family members while listening to music or watching movies. And, never miss the doorbell again.',
  },
  {
    pcImg: 'scene2-pc.webp',
    spImg: 'scene2-sp.webp',
    title: 'Go hands-free the smart way',
    description:
      'Every time your phone rings, all you need to do is double tapping the side of your smart audio glasses to answer the call. You do not need to touch the phone at all and it can be left where it is.',
  },
  {
    pcImg: 'scene3-pc.webp',
    spImg: 'scene3-sp.webp',
    title: 'Keep the ears open for safety',
    description:
      'Stay aware of your surroundings while listening to music so you can detect dangers and avoid accidents.',
  },
];

const Scene = ({ baseImgUrl, isSP }) => {
  useEffect(() => {
    const handleLoad = () => {
      const swiperScene = new Swiper(".l-huawei2-scene__swiper", {
        slidesPerView: 1,
        spaceBetween: 15,
        loop: true,
        speed: 300,
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination--custom",
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
            allowTouchMove: false,
          },
        },
      });
      swiperScene;
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);
  return (
    <section className="l-huawei2-scene" data-aos="fade-up" data-aos-offset="300">
      <div className="l-huawei2__container">
        <h2 className="l-huawei2__heading l-huawei2__heading--lg">SCENARIOS</h2>
      </div>
      <div className="swiper l-huawei2-scene__swiper">
        <ul className="swiper-wrapper">
          {scenesSliders.map((item, index) => (
            <li key={index} className="swiper-slide">
              <div className="l-huawei2-scene__img-container">
                <picture>
                  {!isSP && (
                    <>
                      <source
                        media="(min-width:768px)"
                        srcSet={`${baseImgUrl}${item.pcImg}`}
                        type="image/webp"
                      />
                    </>
                  )}
                  <img src={`${baseImgUrl}${item.spImg}`} alt={item.title} />
                </picture>
              </div>
              <h3 className="l-huawei2-scene__title">{item.title}</h3>
              <p className="l-huawei2-scene__desc">{item.description}</p>
            </li>
          ))}
        </ul>
        <div className="swiper-pagination--custom"></div>
      </div>
    </section>
  );
};

export default Scene;
