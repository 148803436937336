import React, { useState, useEffect } from 'react';
import './ChristmasHappeningsHTML.scss';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>
   
        <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">
    <link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
    <link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
    <link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
    <link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
    <link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
    <link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
    <link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
    <link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
    <link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
    <link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
    <link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
    <link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
    <link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
    <link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="LZFZxqlKtqoBgb9ttUMuKwhrhNaNZqJ8GC1Jhegt">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
            <meta name="og:title" content="OWNDAYS Christmas Happenings | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
    <meta name="description" content="Get merry with exclusive deal and treats this festive season!" />
    <meta name="keywords" content="Waterway Point Store,re-opening,OWNDAYS" />
    <meta name="og:description" content="Get merry with exclusive deal and treats this festive season!" />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/christmas-happenings">
          <meta property="og:image" content="https://www.owndays.com/images/specials/campaign/christmas-happenings/ogp_1200x630.jpg?1702264071">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=0afd20bca87e9d583acc?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=0afd20bca87e9d583acc?20191018" rel="stylesheet">
    <link href="https://www.owndays.com/web/css/information.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/specials-ortho-k-lens.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">OWNDAYS Christmas Happenings | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>
</html>`;

const ChristmasHappeningsHTML = () => {
  window.location.href = '/sg/en';
  const [openModal, setOpenModal] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);

  const termsAndConditions = {
    one: [
      'Items are available while stocks lastItems sold are not refundable nor exchangeable',
      'Offer is limited to three redemptions per receipt and must be completed within the same transaction made on the same day',
      'Offer is only valid with the purchase of prescription glasses or lens replacement service at OWNDAYS retail stores in Singapore',
      'Offer cannot be used in conjunction with other discounts, vouchers and/or promotions except the 2nd-pair-50%-off promotion for frames',
      'The management reserves the right to amend the offer and the terms and conditions without prior notice',
    ],
    two: [
      '1 redemption per customer, while stocks last',
      'The management reserves the right to amend the offer and the terms and conditions without prior notice',
    ],
    three: [
      '1 redemption per customer, while stocks lastSweets are not exchangeable once redeemed',
      'The management reserves the right to amend the offer and the terms and conditions without prior notice',
    ],
  };

  return (
    <>
      {openModal ? (
        <div className="christmas-page-modal">
          <div className="christmas-page-modal__content">
            <div className="christmas-page-modal__content__header">
              <img
                alt="cross"
                src="https://static1.lenskart.com/media/desktop/img/DesignStudioIcons/CrossBlue.svg"
                onClick={() => {
                  setOpenModal(false);
                  setCurrentModal(null);
                }}
              />
            </div>
            <h4 className="christmas-page-modal__heading">Terms & Conditions</h4>
            {termsAndConditions[currentModal]?.map(terms => (
              <ul key={terms} className="christmas-page-modal__ul">
                <li className="christmas-page-modal__li">{terms}</li>
              </ul>
            ))}
          </div>
        </div>
      ) : null}
      {/* <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" /> */}
      <div id="external-pages">
        <div className="od-c-breadcrumb">
          <ul id="breadcrumb-list">
            <li>
              <a href="/sg/en/news">News</a>
            </li>
            <li>OWNDAYS Christmas Happenings </li>
          </ul>
        </div>
      </div>
      <MetaTagsManager
        description="OWNDAYS Christmas Happenings | OWNDAYS ONLINE STORE - OPTICAL SHOP"
        title="OWNDAYS Christmas Happenings | OWNDAYS Singapore"
      />

      <div className="od-christmas-page">
        <div className="od-christmas-page__banner">
          <h1 className="od-christmas-page__heading">OWNDAYS Christmas Happenings</h1>
          <img
            alt="christmas happening banner"
            src="https://www.owndays.com/images/specials/campaign/christmas-happenings/thumbnail.webp"
          />
        </div>

        <div className="od-christmas-page__content">
          <p className="od-christmas-page__duration">
            <strong>Duration:</strong> 11 to 25 December 2023{' '}
          </p>
          <h2 className="od-christmas-page__highlight">Highlight</h2>
          <div className="od-christmas-page__card">
            <img
              alt="Exclusive xmas deal"
              src="https://www.owndays.com/images/specials/campaign/christmas-happenings/event-1.webp"
            />
            <div>
              <div className="od-christmas-page__card__description">
                Get a pair of OWNDAYS PC Glasses for just $30 (U.P. $60) with any purchase of
                prescription glasses or lens replacement service, while stocks last!
              </div>
              <div
                className="od-christmas-page__terms"
                onClick={() => {
                  setCurrentModal('one');
                  setOpenModal(true);
                }}
              >
                Terms & Conditions
              </div>
            </div>
          </div>
          <div className="od-christmas-page__card">
            <img
              alt="Exclusive xmas deal"
              src="https://www.owndays.com/images/specials/campaign/christmas-happenings/event-1.webp"
            />
            <div>
              <div className="od-christmas-page__card__description">
                Bring home a free Christmas Balloon from us at selected stores, no purchase needed!
              </div>
              <ul>
                Join us at the following stores on 16 – 17 Dec, from 1pm to 4pm daily:
                <li>OWNDAYS IMM #01-11/12</li>
                <li>OWNDAYS Plaza Singapura #03-04</li>
                <li>OWNDAYS Bedok Mall #01-60/61</li>
                <li>OWNDAYS Waterway Point #01-58/59/60</li>
              </ul>
              <div
                className="od-christmas-page__terms"
                onClick={() => {
                  setCurrentModal('two');
                  setOpenModal(true);
                }}
              >
                Terms & Conditions
              </div>
            </div>
          </div>
          <div className="od-christmas-page__card">
            <img
              alt="Exclusive xmas deal"
              src="https://www.owndays.com/images/specials/campaign/christmas-happenings/event-1.webp"
            />
            <div>
              <div className="od-christmas-page__card__description">
                Indulge in delightful Christmas treats at selected stores! Follow @owndays_sg on{' '}
                <a
                  href="https://www.instagram.com/owndays_sg/"
                  rel="noreferrer"
                  style={{ display: 'inline', color: '#0094c8' }}
                  target="_blank"
                >
                  Instagram
                </a>{' '}
                /{' '}
                <a
                  href="https://www.tiktok.com/@owndays_sg"
                  rel="noreferrer"
                  style={{ display: 'inline', color: '#0094c8' }}
                  target="_blank"
                >
                  TikTok{' '}
                </a>{' '}
                for a chance to scoop as many candies as you can with one hand – no purchase needed!
              </div>
              <ul>
                Join us at the following stores on 23 – 24 Dec, from 1pm to 4pm daily:
                <li>OWNDAYS Compass One #01-21</li>
                <li>OWNDAYS Tiong Bahru Plaza #01-117/118</li>
                <li>OWNDAYS Suntec City #02-465/469/481</li>
                <li>OWNDAYS Jurong Point 01-24/25/26/27</li>
              </ul>
              <div
                className="od-christmas-page__terms"
                onClick={() => {
                  setCurrentModal('three');
                  setOpenModal(true);
                }}
              >
                Terms & Conditions
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChristmasHappeningsHTML;
