import React from 'react';
const stores = [
  'OWNDAYS Plaza Singapura',
  'OWNDAYS nex',
  'OWNDAYS Suntec City',
  'OWNDAYS Jurong Point',
  'OWNDAYS Waterway Point',
  'OWNDAYS Tiong Bahru Plaza',
  'OWNDAYS City Square Mall',
  'OWNDAYS Westgate',
  'OWNDAYS AMK Hub',
  'OWNDAYS PLQ Mall',
  'OWNDAYS Causeway Point',
  'OWNDAYS Parkway Parade',
  'OWNDAYS MBS Premium Concept Store',
  'OWNDAYS Takashimaya S.C Premium Concept Store',
];
const Stores = () => {
  return (
    <section className="l-huawei2-stores" data-aos="fade-up" data-aos-offset="300">
      <div className="l-huawei2__container">
        <h2 className="l-huawei2__heading l-huawei2__heading--center">
          STORES
        </h2>
        <ul className="c-list-decoration c-list-decoration--dot">
          {stores.map((store, index) => (
            <li key={index}>{store}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Stores;
