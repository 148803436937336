import React from 'react';

export const Breadcrumbs = () => {
  return (
    <div className="od-c-breadcrumb">
      <ul id="breadcrumb-list">
        <li>
          <a href="/sg/en">Homepage</a>
        </li>

        <li>
          <a href="/sg/en/news#all">News</a>
        </li>

        <li>
          <a href="#">Free Enhanced Progressive Lenses</a>
        </li>
      </ul>
    </div>
  );
};
