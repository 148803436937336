import React from 'react';
import './ODHomePageButton.scss';

const ODHomePageButton = ({ link, label, padding = {}, theme = 'light', children }) => {
  return (
    <a className={`od-home-page-btn od-home-page-btn__${theme}`} href={link} style={{ padding }}>
      {children} {label}
    </a>
  );
};

export default ODHomePageButton;
