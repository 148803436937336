import React, { useEffect, useState } from 'react';
import AOS from 'aos';

import {
  HeaderMemoryMetal,
  BodyMemoryMetal,
  FooterMemoryMetal,
} from '../CommonComponents/MemoryMetal';
import 'aos/dist/aos.css';

import '../CommonComponents/MemoryMetal/BaseMemoryMetal.scss';

const MemoryMetalHTML = () => {
  const [isLoading, setIsLoading] = useState(true);

  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/memory-metal';

  useEffect(() => {
    if (!isLoading) {
      AOS.init({ duration: 2000, once: true });
    }
  }, [isLoading]);

  return (
    <div className="base-memory-metal">
      <HeaderMemoryMetal
        baseImgUrl={baseImgUrl}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <BodyMemoryMetal baseImgUrl={baseImgUrl} />
      <FooterMemoryMetal baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default MemoryMetalHTML;
