import React from 'react';

const Main = ({ baseImgUrl, isSP }) => {
  return (
    <section className="main">
      <div className="main__bg">
        <picture data-aos="main-bg" data-aos-duration="3000">
          {!isSP && <source
            media="(min-width:768px)"
            srcset={`${baseImgUrl}main-pc.webp`}
            type="image/webp"
          />}
          <img
            className="main__img"
            src={`${baseImgUrl}main-sp.webp`}
            alt="Harry Potter × OWNDAYS"
          />
        </picture>
      </div>
      <div className="main__detail">
        <picture className="main__logo" data-aos="fade-up" data-aos-delay="1000">
          {!isSP && <source
            media="(min-width:768px)"
            srcset={`${baseImgUrl}logo-pc.webp`}
            type="image/webp"
          />}
          <img src={`${baseImgUrl}logo-sp.webp`} alt="Harry Potter × OWNDAYS" />
        </picture>
      </div>
    </section>
  );
};

export default Main;