import React, { useMemo, useState } from 'react';
import './LangPopup.scss';
import SideBarPopup from '../../../desktop/components/SideBarPopup/SideBarPopup';
import { chunkArray } from '../../helper';
import { v4 } from 'uuid';
import { useSelector } from 'react-redux';

const LangPopup = ({ showModal, setShowModal, device, countries }) => {
  const dataLocale = useSelector(state => state.locale);
  const { CHOOSE_YOUR_COUNTRY = 'Choose your country' } = dataLocale;

  const [openLanguages, setOpenLanguages] = useState({});

  const hideCountryLanguageModal = () => {
    setShowModal(false);
  };

  const chunkedCountries = useMemo(() => {
    return chunkArray(countries, 6);
  }, []);

  const handleCountryClick = country => {
    setOpenLanguages(prevOpenLanguages => ({
      ...Object.keys(prevOpenLanguages).reduce(
        (acc, key) => ({
          ...acc,
          [key]: key === country.name ? !prevOpenLanguages[key] : false,
        }),
        {}
      ),
      [country.name]: !prevOpenLanguages[country.name],
    }));
  };

  return (
    <div className="lang-popup-wrapper">
      <SideBarPopup
        crossIconStyle="lang-popup__cross"
        dialogClassName={`lang-popup ${device === 'mobile' && 'lang-popup-mweb'}`}
        open={showModal}
        width={device === 'desktop' ? '790px' : '100%'}
        onClose={hideCountryLanguageModal}
      >
        <div className="lang-popup__title">{CHOOSE_YOUR_COUNTRY}</div>
        <div className="lang-popup__columns">
          {chunkedCountries.map(chunk => (
            <div key={v4()} className="lang-popup__column">
              {chunk.map(country => {
                return (
                  <div key={v4()} className="country-item">
                    <div
                      className={`country-item__content ${
                        openLanguages[country.name] ? 'open' : 'lang-close'
                      }`}
                      onClick={() => handleCountryClick(country)}
                    >
                      <button className="country-item__content__btn">
                        <img alt="country" src={country.img} />
                        {country.name}
                      </button>
                      {country.languages.length > 1 && (
                        <>
                          {openLanguages[country.name] ? (
                            <img
                              alt="minus"
                              className="country-item__content__toggle-img"
                              src="https://www.owndays.com/images/icons/minus.svg"
                            />
                          ) : (
                            <img
                              alt="plus"
                              className="country-item__content__toggle-img"
                              src="https://www.owndays.com/images/icons/plus.svg"
                            />
                          )}
                        </>
                      )}
                    </div>
                    {openLanguages[country.name] && (
                      <div
                        className={`country-item__language-list ${
                          openLanguages[country.name] ? 'open' : 'lang-close'
                        }`}
                      >
                        <ul>
                          {country.languages.map(language => (
                            <li key={v4()} onClick={() => window.location.assign(language.link)}>
                              {language.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </SideBarPopup>
    </div>
  );
};

export default LangPopup;
