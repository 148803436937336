import React from 'react';
import LeftIcon from '../../../../../static/assets/svg/od-arrow-left-grey-icon.svg';
import CrossIcon from '../../../../../static/assets/svg/Cross.svg';
import './SelectLensesHeader.scss';

const SelectLensesHeader = ({ onBack, onCross, steps, currentStep }) => {
  return (
    <div className="odslv-h">
      <div className="odslv-h__left" onClick={() => onBack && onBack()}>
        <img alt="back" src={LeftIcon} /> {steps?.[currentStep]?.label}
      </div>
      <div className="odslv-h__right" onClick={() => onCross && onCross()}>
        <img alt="back" src={CrossIcon} />
      </div>
    </div>
  );
};

export default SelectLensesHeader;
