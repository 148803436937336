import { isEmptyObject } from '../../../utils/helper';
import { getLocaleData } from '../../../actionCreators/locale';
import getSetCookie from './../../../cookie';
import sessionStorageHelper from '../../../utils/sessionStorageHelper';
import { odPushClevertapEvent } from '../../../utils/clevertap';

export const provideHooksFetchHelper = async (
  location,
  dispatch,
  showGlobalHeaderFooter,
  commonCallsActionCreators,
  state
) => {
  // get locale config data
  if (isEmptyObject(state.locale)) {
    dispatch(getLocaleData());
  }
  if (state.common.wishlistTrue === true) {
    dispatch(commonCallsActionCreators.showWishlist(false));
  }
  if (location.pathname && location.pathname?.indexOf('/checkout/onepage') !== -1) {
    dispatch(showGlobalHeaderFooter({ header: false, footer: false }));
    if (__SERVER__) {
      await dispatch(commonCallsActionCreators.validateSession(location.pathname));
    }
  } else if (location?.pathname?.includes('wishlist')) {
    dispatch(showGlobalHeaderFooter({ header: true, footer: false }));
  } else {
    dispatch(showGlobalHeaderFooter({ header: true, footer: true }));
  }

  if (__CLIENT__) {
    if (state.common.login !== Boolean(parseInt(getSetCookie.getCookie('isLogined'), 10))) {
      dispatch(commonCallsActionCreators.getUserInfo());
    }
  }
};

const desktopGTMEventHelper = () => {
  // desktopGTM event for omni and gtm
  if (typeof window.desktopGTM === 'undefined') {
    window.desktopGTM = document.createEvent('Event');
    window.desktopGTM.initEvent('desktopGTM', true, true);
    window.setTimeout(() => {
      window.dispatchEvent(window.desktopGTM);
      if (
        typeof window.FMApi === 'object' &&
        typeof window.FMApi.manualTriggerExperiment === 'function'
      ) {
        window.FMApi = window.FMApi || [];
        window.FMApi.push('fm_activate_adobe_integration');
      }
    }, 800);
  }
};

const dtmHelper = loginStatus => {
  if (window.dtm === undefined) {
    window.dtm = {};
    window.dtm.pageName = 'baseAbort';
  }
  if (window.dtm) {
    window.dtm.site = 'webDesktop';
    window.dtm.sessionID = getSetCookie.getCookie('omniSession');
    window.dtm.loginStatus = loginStatus ? 'registered' : 'guest';
  }
};

export const initializeComponentLogicHelper = ({
  location,
  path,
  homeCallsActionCreators,
  facebookPixel,
  loginStatus,
  commonCallsActionCreators,
}) => {
  setTimeout(() => {
    if (
      [
        'eyeglasses.html',
        'sunglasses.html',
        'premium-eyeglasses',
        'premium-sunglasses',
        '/',
      ].includes(path) ||
      path?.indexOf('promo/') > -1
    ) {
      homeCallsActionCreators.loadCarousalsData();
    }
  }, 50);
  window.ReactPixel = require('react-facebook-pixel');
  const { init, pageView } = window.ReactPixel.default;
  init(facebookPixel);
  pageView();

  if (!getSetCookie.getCookie('session')) {
    getSetCookie.setCookie('session', 'sticky', 14);
  }
  if (!loginStatus && (getSetCookie.getCookie('frontend') || getSetCookie.getCookie('clientV1'))) {
    if (!getSetCookie.getCookie('clientV1')) {
      const cookieVal = getSetCookie.getCookie('frontend');
      getSetCookie.deleteCookie('frontend');
      getSetCookie.setCookie('clientV1', cookieVal, 30);
    }
    let i = 0;
    while (getSetCookie.getCookie('frontend') && i < 5) {
      getSetCookie.deleteCookie('frontend');
      i++;
    }
    setTimeout(() => {
      commonCallsActionCreators.validateSession(location.pathname);
    }, 500);
  }
  dtmHelper(loginStatus);
  window.localStorage.removeItem('showEnterAddress');
  desktopGTMEventHelper();
};

export const dtmLoginStatusHelper = (loginStatus, userInfo, prevProps) => {
  if (loginStatus && userInfo) {
    const {
      result: { phoneCode, telephone, lastName, firstName, gender, dob, email },
    } = userInfo;

    window.dtm.loginStatus = 'registered';
    window.dtm.customerID = userInfo.result.id;
    const gaData = {
      Name: `${firstName} ${lastName}`,
      Identity: telephone,
      Phone: `${phoneCode}${telephone}`,
      'MSG-push': false,
    };
    if (gender && gender.toLowerCase()?.indexOf('male') > -1) {
      const genderType = gender.toLowerCase();
      gaData.Gender = genderType === 'male' ? 'M' : 'F';
    }
    if (dob) {
      gaData.DOB = new Date(Number(dob));
    }
    if (email) {
      gaData.Email = email;
      gaData['MSG-email'] = true;
    }
    if (telephone) {
      gaData['MSG-sms'] = true;
      gaData['MSG-whatsapp'] = true;
    }
    console.log('this is dtmLogin ===> profile', userInfo, gaData);
    // if (loginStatus !== prevProps.loginStatus) {
    // }

    odPushClevertapEvent('', { Site: gaData }, { Site: gaData }, 'onUserLogin');
    // odPushClevertapEvent('', { Site: gaData }, { Site: gaData }, 'onUserLogin');
  } else {
    window.dtm.loginStatus = 'guest';
    delete window.dtm.customerID;
  }
};

export const passingUtmDataHelper = (
  location,
  userInfo,
  prevProps,
  loginStatus,
  getCart,
  passingUtmData,
  commonCallsActionCreators
) => {
  if (
    userInfo &&
    prevProps.userInfo !== userInfo &&
    prevProps.userInfo?.result?.id !== userInfo?.result?.id &&
    loginStatus
  ) {
    // shipping address component for exchange
    // if (!(exchangeFlow && returnAction === 'exchange')) {
    //   getCart();
    // }
    // shipping address component for exchange
    const { phoneCode, telephone } = userInfo.result;
    if (location.pathname?.indexOf('/checkout') > -1) {
      passingUtmData('ADD_TO_CART_LOGIN', phoneCode, telephone, commonCallsActionCreators);
    }
    if (location.pathname === '/') {
      passingUtmData('LOGGED_IN', phoneCode, telephone, commonCallsActionCreators);
    }
  }
};

export const handleUpdateLogicHelper = (
  location,
  prevProps,
  isValidSession,
  commonCallsActionCreators,
  isWishlistCount
) => {
  if (prevProps.isValidSession !== isValidSession && isValidSession === 401) {
    commonCallsActionCreators.setJunoSession();
  }
  if (getSetCookie.getCookie('frontend') && !getSetCookie.getCookie('clientV1')) {
    const cookieVal = getSetCookie.getCookie('frontend');
    getSetCookie.setCookie('clientV1', cookieVal, 30);
    let i = 0;
    while (getSetCookie.getCookie('frontend') && i < 5) {
      getSetCookie.deleteCookie('frontend');
      i++;
    }
  }
  if (
    !prevProps.isWishlistCount &&
    isWishlistCount &&
    getSetCookie.getCookie('clientV1') !== '' &&
    location.pathname &&
    location.pathname?.indexOf('/checkout/onepage/') === -1
  ) {
    commonCallsActionCreators.shortlist('', 'get', '?attributes=true');
  }
};
