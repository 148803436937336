import React from 'react';

const Main = ({ baseImgUrl, isSP }) => {
  return (
    <section className="l-huawei2-main">
      <div className="l-huawei2-main__bg" data-aos="fade-up">
        <picture>
          {!isSP && (
            <>
              <source
                media="(min-width:1921px)"
                srcSet={`${baseImgUrl}main_huawei2_4k.webp`}
              />
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}main_huawei2_pc.webp`}
              />
            </>
          )}
          <img
            className="l-huawei2-main__img"
            src={`${baseImgUrl}main_huawei2_sp.webp`}
            alt="OWNDAYS × HUAWEI Eyewear"
          />
        </picture>
      </div>
    </section>
  );
};

export default Main;
