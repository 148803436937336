import axios from 'axios';
import config from 'config';
import getSetCookie from '../cookie';
// List of allowed lang for locale config
import { langCodeAllowed } from '../locales';
const __IS_LOCAL__ = process.env.IS_LOCAL_DEV;
let xApiClient = 'desktop';
let appXApiClients = [];
let userAgent = 'axios/0.18.1-desktop';
let defaultLanguage = 'en';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
appXApiClients = config.msiteGlobals.appXApiClients;
userAgent = 'axios/0.18.1-mobilesite';
('#endif'); // eslint-disable-line

const getCountryCode = () => {
  global.__COUNTRY_CODE__ = process.env.COUNTRY ? process.env.COUNTRY.toLowerCase() : 'in';
  // global.__COUNTRY_CODE__ = __SERVER__ ? req.cookies.countryCode : getSetCookie.getCookie('countryCode');
  // if (!global.__COUNTRY_CODE__) global.__COUNTRY_CODE__ = 'IN';
};

// set user lang for locale specific data
const setUserLang = (_request, _response) => {
  /** default country locale */
  let locale = 'en_in';

  /** set Country code in global scope */
  getCountryCode();
  // console.log(global.__COUNTRY_CODE__);
  let localeDomainLink = 'www.lenskart.com';
  if (global.__COUNTRY_CODE__) {
    const getLangObj = langCodeAllowed[global.__COUNTRY_CODE__];
    const currentLocale = getLangObj
      ? ((defaultLanguage = getLangObj?.defaultLanguage), getLangObj?.defaultLocale)
      : 'en_in';
    // user selected locale
    locale = __SERVER__ ? _request.cookies.locale : getSetCookie.getCookie('locale');
    let isValidLocale = [];
    if (getLangObj) {
      isValidLocale = getLangObj.localeList.filter(x => x.code === locale);
    }
    if (isValidLocale.length === 0 || locale?.indexOf(global.__COUNTRY_CODE__) === -1) {
      locale = currentLocale;
    }
    localeDomainLink = getLangObj.cookieDomain;
  }

  if (__SERVER__) {
    _response.cookie('locale', locale, {
      domain: localeDomainLink,
      path: '/',
      maxAge: 2592000000,
    });
  }
  /** SET LOCALE AS GLOBAL VARIABLE */
  global.__LOCALE__ = locale;
};

const getCommonHeader = ({ customHeader, req }) => {
  /** set Country code in global scope */
  getCountryCode();

  const headers = {
    'X-Accept-Language': defaultLanguage || '',
    'X-Country-Code': global.__COUNTRY_CODE__,
    'X-Api-Client': xApiClient,
    'Cache-Control': 'no-cache, no-store',
    'X-B3-TraceId': `99${Date.now()}`,
    'X-Client-Org': 'owndays',
  };
  if ((__SERVER__ && req.cookies.clientV1) || (__CLIENT__ && getSetCookie.getCookie('clientV1'))) {
    headers['X-Session-Token'] = __SERVER__
      ? req.cookies.clientV1
      : getSetCookie.getCookie('clientV1');
  }

  // if ((__SERVER__ && req.cookies.countryCode) || (__CLIENT__ && getSetCookie.getCookie('countryCode'))) {
  //   headers['X-Country-Code'] = global.__COUNTRY_CODE__;
  // }

  ('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
  if (req && req.header('x-api-client') && appXApiClients.includes(req.header('x-api-client'))) {
    // did not use loop as iterators/generators require regenerator-runtime
    if (req.header('x-app-version')) {
      headers['x-app-version'] = req.header('x-app-version');
    }
    if (req.header('x-session-token')) {
      headers['X-Session-Token'] = req.header('x-session-token');
    }
    if (req.header('udid')) {
      headers.udid = req.header('udid');
    }
    if (req.header('device-id')) {
      headers['device-id'] = req.header('device-id');
    }
  }
  ('#endif'); // eslint-disable-line
  if (
    __CLIENT__ &&
    typeof window !== 'undefined' &&
    (window.location.href?.indexOf('/v2/carts') !== -1 ||
      window.location.href?.indexOf('campaign') !== -1) &&
    window.sessionStorage.getItem('userEmail') &&
    window.sessionStorage.getItem('userPh')
  ) {
    headers.email = window.sessionStorage.getItem('userEmail');
    headers.phone = window.sessionStorage.getItem('userPh');
  }
  return customHeader ? Object.assign({}, headers, customHeader) : headers;
};

export default function apiClient(apiObject) {
  const instance = axios.create();
  const _response = apiObject ? apiObject.res : null;
  const _request = apiObject ? apiObject.req : null;

  let localeDomainLink = 'www.lenskart.com';
  if (global.__COUNTRY_CODE__) {
    const getLangObj = langCodeAllowed[global.__COUNTRY_CODE__];
    localeDomainLink = getLangObj.cookieDomain;
  }

  instance.interceptors.request.use(
    async request => {
      // Should only be enabled for dev environments
      // const reqObj = { Url: request.url, Method: request.method, Data: request.data || '' };
      // if (__SERVER__) {
      //   console.log(reqObj);
      // }

      // set user lang for locale specific data
      setUserLang(_request, _response);
      if (__CLIENT__) {
        if (request.url?.indexOf('v2/sessions') > -1 && request.method === 'post') {
          return request;
        }
        if (!getSetCookie.getCookie('clientV1')) {
          const frontendVal = getSetCookie.getCookie('frontend');
          if (frontendVal) {
            getSetCookie.deleteCookie('frontend');
            // console.log('clientV1 apiClient.js apiClient client cookie not exist, frontend exists')
            getSetCookie.setCookie('clientV1', frontendVal, 30);
            let i = 0;
            while (getSetCookie.getCookie('frontend') && i < 5) {
              getSetCookie.deleteCookie('frontend');
              i++;
            }
            request.headers['X-Session-Token'] = frontendVal;
          } else {
            const response = await axios.post(config.apiPath.userSession, null, {
              headers: {
                'X-Api-Client': xApiClient,
                'X-Accept-Language': defaultLanguage || '',
                'X-Country-Code': global.__COUNTRY_CODE__,
                'X-B3-TraceId': `99${Date.now()}`,
                'X-Client-Org': 'owndays',
              },
            });
            getSetCookie.deleteCookie('frontend');
            // console.log('clientV1 apiClient.js apiClient client cookie not exist, frontend also not exists so new api call')
            getSetCookie.setCookie('clientV1', response.data.result.id, 30);
            let i = 0;
            while (getSetCookie.getCookie('frontend') && i < 5) {
              getSetCookie.deleteCookie('frontend');
              i++;
            }
            request.headers['X-Session-Token'] = response.data.result.id;
          }
        }
      }
      if (request.url?.indexOf(':locale') !== -1) {
        if (__SERVER__ && __IS_LOCAL__ === 'ON') {
          request.url = 'http://localhost:8081' + request.url;
        }
        request.url = request.url.replace(':locale', global.__LOCALE__);
      }
      if (__CLIENT__ && request.url?.indexOf('HTO') > -1) {
        delete request.headers['X-Country-Code'];
      }
      if (__CLIENT__ && request.url?.indexOf('static.lenskart') > -1) {
        delete request.headers['X-B3-TraceId'];
        delete request.headers['X-Api-Client'];
        delete request.headers['X-Session-Token'];
        delete request.headers['X-Accept-Language'];
        delete request.withCredentials;
      }
      if (
        request.url?.indexOf('stage.lenskart.io') > -1 ||
        request.url?.indexOf('area51.lenskart.io') > -1
      ) {
        // console.log('global.__COUNTRY_CODE__', global.__COUNTRY_CODE__);
        request.headers['X-Api-Client'] = 'android';
        request.headers['x-country-code-override'] = global.__COUNTRY_CODE__;
      }
      // console.log(_request);
      try {
        if (request.url?.indexOf('/v2/products') > -1) {
          const hasPlacedOrderCookieValue =
            (__SERVER__ && _request.cookies.hasPlacedOrder) ||
            (__CLIENT__ && getSetCookie.getCookie('hasPlacedOrder'));
          if (hasPlacedOrderCookieValue && hasPlacedOrderCookieValue !== 'null') {
            request.headers['X-Customer-Type'] =
              hasPlacedOrderCookieValue === 'true' ? 'REPEAT' : 'NEW';
          }
        }
      } catch (e) {
        // console.log(e);
      }
      return request;
    },
    error => Promise.reject(error)
  );
  /** ENDS HERE */

  instance.interceptors.request.use(
    conf => {
      // Hack for PI-7430
      // if (conf.url?.indexOf('filters') > -1 && conf.headers['X-Api-Client']) {
      //   delete conf.headers['X-Api-Client'];
      // }
      if (__SERVER__) {
        conf.headers['User-Agent'] = `${userAgent}${conf.headers.isBot ? '-seoBot' : ''}`;
      }
      delete conf.headers.isBot;
      return conf;
    },
    error => Promise.reject(error)
  );

  instance.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error.response ? error.response.data : error)
  );
  instance.setHeader = function (customHeader) {
    this.defaults.headers = getCommonHeader({ req: _request, customHeader });
  };
  instance.withCredentials = function () {
    this.defaults.withCredentials = true;
  };
  instance.getServerRequestHeader = function () {
    return _request;
  };
  instance.getServerResponse = function () {
    return _response;
  };

  instance.validateSession = async function () {
    const requestUrl = __SERVER__
      ? config.apiPath.albValidateSession
      : config.apiPath.validateSession;

    const options = {
      headers: getCommonHeader({ req: _request }),
    };

    try {
      const response = await this.get(requestUrl, options);
      if (__SERVER__) {
        _response.cookie('isLogined', '0', {
          domain: localeDomainLink,
          path: '/',
          maxAge: 2592000000,
        });
        _response.cookie('log_in_status', 'loggedout', {
          domain: localeDomainLink,
          path: '/',
          maxAge: 2592000000,
        });
        _response.cookie('hasPlacedOrder', 'null', {
          domain: localeDomainLink,
          path: '/',
        });
      } else if (__CLIENT__) {
        getSetCookie.setCookie('isLogined', '0', 30);
        getSetCookie.setCookie('log_in_status', 'loggedout', 30);
        getSetCookie.deleteCookie('hasPlacedOrder');
      }

      let isCartCount = 0;
      let isWishlistCount = 0;
      if (response.result.attrs.hasOwnProperty('cartIds') && response.result.attrs.itemsCount > 0) {
        isCartCount = response.result.attrs.itemsCount;
      }
      if (
        response.result.attrs.hasOwnProperty('wishlist') &&
        response.result.attrs.wishlist.length > 0
      ) {
        isWishlistCount = response.result.attrs.wishlist.length;
      }
      if (
        typeof response.result.attrs.isLoggedIn !== 'undefined' &&
        response.result.attrs.isLoggedIn
      ) {
        const res = await this.customerMe();
        if (__SERVER__) {
          _response.cookie('isLogined', '1', {
            domain: localeDomainLink,
            path: '/',
          });
          _response.cookie('log_in_status', 'loggedin', {
            domain: localeDomainLink,
            path: '/',
          });
          _response.cookie('hasPlacedOrder', res.result.hasPlacedOrder, {
            domain: localeDomainLink,
            path: '/',
          });
        } else if (__CLIENT__) {
          getSetCookie.setCookie('isLogined', '1', 30);
          getSetCookie.setCookie('log_in_status', 'loggedin', 30);
          getSetCookie.setCookie('hasPlacedOrder', res.result.hasPlacedOrder, 30);
        }
        res.result.isCartCount = isCartCount;
        res.result.isWishlistCount = isWishlistCount;
        res.result.login = true;
        return res;
      }
      response.result.isCartCount = isCartCount;
      response.result.isWishlistCount = isWishlistCount;
      response.result.login = false;
      return response;
    } catch (er) {
      throw er;
    }
  };

  // get category data
  /* eslint comma-spacing: 0 */
  instance.getCategoryData = async function (catID, params, pageSize, pageCounter, isExchange) {
    const requestUrl = __SERVER__ ? config.apiPath.albDataCategory : config.apiPath.dataCategory;
    const options = {
      headers: {
        'X-Api-Client': xApiClient,
        email: __CLIENT__ ? window.sessionStorage.getItem('userEmail') : '',
        phone: __CLIENT__ ? window.sessionStorage.getItem('userPh') : '',
      },
    };
    if (isExchange) {
      options.headers['X-Service-Type'] = 'exchange';
    }
    const getParams = params ? `&${params.replace('#', '')}` : '';
    const urlOption = `${catID}?page-size=${pageSize}&page=${pageCounter}${getParams}&withMainProductColorOption=true`;
    try {
      const response = await this.get(requestUrl + urlOption, options);
      return response;
    } catch (er) {
      throw er;
    }
  };
  // get product data
  instance.getProductData = async function (pID, params, isExchange) {
    const requestUrl = __SERVER__ ? config.apiPath.albDataProduct : config.apiPath.dataProduct;
    const options = {
      headers: {
        'X-Api-Client': xApiClient,
        email: __CLIENT__ ? window.sessionStorage.getItem('userEmail') : '',
        phone: __CLIENT__ ? window.sessionStorage.getItem('userPh') : '',
      },
    };
    if (isExchange) {
      options.headers['X-Service-Type'] = 'exchange';
    }
    const getParams = pID + (params ? `?${params}` : '');
    try {
      const response = await this.get(requestUrl + getParams, options);
      return response;
    } catch (er) {
      throw er;
    }
  };

  instance.getColoredLensData = async function (pID, params, isExchange) {
    const requestUrl = __SERVER__ ? config.apiPath.albDataProduct : config.apiPath.dataProduct;
    const options = {
      headers: {
        'X-Api-Client': xApiClient,
        email: __CLIENT__ ? window.sessionStorage.getItem('userEmail') : '',
        phone: __CLIENT__ ? window.sessionStorage.getItem('userPh') : '',
      },
    };
    if (isExchange) {
      options.headers['X-Service-Type'] = 'exchange';
    }
    const getParams = pID + (params ? `?${params}` : '');
    try {
      const response = await this.get(requestUrl + getParams, options);
      return response;
    } catch (er) {
      throw er;
    }
  };

  instance.customerMe = async function () {
    const requestUrl = __SERVER__ ? config.apiPath.albGetUserInfo : config.apiPath.getUserInfo;
    const options = {
      headers: getCommonHeader({ req: _request }),
    };
    try {
      return await this.get(requestUrl, options);
    } catch (er) {
      throw er;
    }
  };

  instance.customPost = async function (url, data, sessionKey, apiClient, cache, isExchange) {
    try {
      const header = {
        'X-Accept-Language': defaultLanguage || '',
        'X-Country-Code': global.__COUNTRY_CODE__,
        'X-Client-Org': 'owndays',
      };
      if (apiClient !== null) header['X-Api-Client'] = apiClient;
      if (sessionKey !== null) {
        header[sessionKey] = getSetCookie.getCookie('clientV1');
      }
      if (isExchange) {
        header['X-Service-Type'] = 'exchange';
      }
      if (cache !== null) header['Cache-Control'] = 'no-cache, no-store';
      if (url?.indexOf('/v2/') !== -1) {
        header['X-B3-TraceId'] = `99${Date.now()}`;
      }
      if (
        __CLIENT__ &&
        (url?.indexOf('/v2/carts') !== -1 || url?.indexOf('campaign') !== -1) &&
        typeof window !== 'undefined'
      ) {
        header.email = __CLIENT__ ? window.sessionStorage.getItem('userEmail') : '';
        header.phone = __CLIENT__ ? window.sessionStorage.getItem('userPh') : '';
      }

      const extras = { headers: header };
      if (url?.indexOf('/v2/orderpayment') !== -1) {
        extras.withCredentials = true;
      }
      const response = await this.post(url, data, extras);
      return response;
    } catch (er) {
      throw er;
    }
  };
  instance.getCmsData = async function (cmdId) {
    let requestUrl = config.apiPath.cmsPages;
    // const { domain } = langCodeAllowed[global.__COUNTRY_CODE__];
    // not able to debug from infra side so for server cms page call keeping www.lenskart.com as hardcoded
    // requestUrl = __SERVER__ ? `${domain}${requestUrl}` : requestUrl;
    requestUrl = __SERVER__ ? `https://www.lenskart.com${requestUrl}` : requestUrl;
    const options = {
      headers: {
        'X-Api-Client': xApiClient,
        email: __CLIENT__ ? window.sessionStorage.getItem('userEmail') : '',
        phone: __CLIENT__ ? window.sessionStorage.getItem('userPh') : '',
      },
    };
    const urlOption = `${cmdId}`;
    try {
      const response = await this.get(requestUrl + urlOption, options);
      return response;
    } catch (er) {
      console.log('cms data api error', er);
      throw er;
    }
  };
  return instance;
}
