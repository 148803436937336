import React from 'react';
import './ColorOption.scss';

const ColorOption = ({ colors, onSelect, selectedOption, setPrimaryImage }) => {
  if (Array.isArray(colors) && colors.length > 0) {
    return (
      <div className="od-catalog-color-options ">
        {colors.map(color => {
          return (
            <div onClick={() => {
              onSelect && onSelect(color);
              setPrimaryImage && setPrimaryImage(color.images[0])
            }} className="od-catalog-color-option__outer" style={selectedOption.id === color.id ? { borderColor: color.color } : {borderColor: 'transparent'}}>
              <div className="od-catalog-color-option__inner" style={{ backgroundColor: color.color }} />
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default ColorOption;
