import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://callback.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://callback.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>
    
    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5WRT43B');</script>
<!-- End Google Tag Manager -->    
        
    
        
        
    
        
    
        <script>
    /*TAIWAN 20150119
     window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
     d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
     _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');
     $.src='//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';z.t=+new Date;$.
     type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script');
     */
    window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
        d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
    _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');
        $.src='//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';z.t=+new Date;$.
            type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script');
</script>
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://callback.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://callback.owndays.com/images/favicon.ico">
    
    
    <meta name="csrf-token" content="bVZMAN1f8t1A9vsh03nqMox2Si6SNHFQeIB9egEh">
    
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
                                                    <meta property="og:title" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                                                                        <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/information">
                <meta property="og:image" content="https://callback.owndays.com/images/common/ogp_1200x630.jpg?1690859228">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://callback.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    
    
    <link rel="preload" as="image" href="https://callback.owndays.com/images/svg/owndays.svg">
    
    
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=eb789a641c2e5c845351?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=eb789a641c2e5c845351?20191018" rel="stylesheet">
        <link href="https://callback.owndays.com/web/css/information.css" rel="stylesheet">
        <link rel="preload" href="https://callback.owndays.com/web/css/sg/en.css?v=1709751732" as="style">
        <link href="https://callback.owndays.com/web/css/sg/en.css?v=1709751732" rel="stylesheet">
        
        
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
    
        <link rel="preload" as="script" href="https://callback.owndays.com/web/js/apis.google-platform.js">
    <script src="https://callback.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://callback.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://callback.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        
    <title id="title">    OWNDAYS ONLINE STORE - OPTICAL SHOP
</title>
</head>



<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
        <div id="top-information">
    
        </div>

<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content">
    <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
            <p>
                                                 
                                                    <a href="/sg/en">Homepage</a>
                                                                                    </p>
        </li>
                <li>
            <p>
                                                 
                                                    <a href="/sg/en/information">See all the Information</a>
                                                                                    </p>
        </li>
        
            </ul>
</div>
<div class="container">

</div>
    <section class="p-information p-information-list l-content--inner">
        <h1 class="c-title--en">Information</h1>
        <section class="p-information-list__categories">
            <ul>
                <li id="all__l-category"  class="is-current" >
                    <a href="/sg/en/information">
                        ALL                    </a>
                </li>
                <li id="newshop__l-category" >
                    <a href="/sg/en/information?category=new_shop">
                        Latest information                    </a>
                </li>
                <li id="news__l-category" >
                    <a  href="/sg/en/information?category=information_article">
                        Notice                    </a>
                </li>
                <li id="press__l-category" >
                    <a  href="/sg/en/information?category=press">
                        Press Release                    </a>
                </li>
            </ul>
        </section>
        <section class="p-information-list__main">
            <ul class="p-information-list__block">

                <li data-category="new_shop">
                    <a href="/sg/en/information/shops/1043" target="_self" >
                        <img src="https://static.lenskart.com/media/owndays/img/information/1043/main.webp" alt="" onerror="this.src='https://static.lenskart.com/media/owndays/img/no-image.jpg';">
                    </a>
                    <div>
                        <p class="days">2024.08.14 ｜ Latest information</p>
                        <a href="/sg/en/information/shops/1043" target="_self" >
                            <h3>Thu. 15 Aug. 2024 OWNDAYS Toa Payoh Store Now Open!</h3>
                        </a>
                    </div>
                </li>

                    <li data-category="new_shop">
                        <a href="/sg/en/information/5001" target="_self" >
                            <img src="https://www.owndays.com/images/information/20240711/676.webp" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 2024.07.11 | Press Release</p>
                            <a href="/sg/en/information/5001" target="_self" >
                                <h3>OWNDAYS REVAMPS BRAND IDENTITY, DEBUTING NEW RETAIL DESIGN IN JAPAN AND SINGAPORE THIS JULY</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="new_shop">
                        <a href="/sg/en/information/432" target="_self" >
                            <img src="https://callback.owndays.com/storage/information/thumbnail/517374f2-5674-4015-b836-f911e9aea672.webp" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2023.05.08 | Latest information                                                                      
                                                            </p>
                            <a href="/sg/en/information/432" target="_self" >
                                <h3>Tue. 9 May 2023 OWNDAYS Woodleigh Mall Store Now Open!</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="news">
                        <a href="/sg/en/information/369" target="_self" >
                            <img src="https://callback.owndays.com/storage" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2023.01.06 | Notice                                                                                                </p>
                            <a href="/sg/en/information/369" target="_self" >
                                <h3>2023 Lunar New Year Operating Hours</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="new_shop">
                        <a href="/sg/en/information/287" target="_self" >
                            <img src="https://callback.owndays.com/storage/information/thumbnail/614bb860-685a-4dd0-a105-db3ad9679e9d.jpeg" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2022.05.17 | Latest information                                                                      
                                                            </p>
                            <a href="/sg/en/information/287" target="_self" >
                                <h3>Fri. 20 May 2022 OWNDAYS Takashimaya S.C. Premium Concept Store Now Open!</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="news">
                        <a href="/sg/en/information/244" target="_self" >
                            <img src="https://callback.owndays.com/storage" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2022.01.14 | Notice                                                                                                </p>
                            <a href="/sg/en/information/244" target="_self" >
                                <h3>2022 Lunar New Year Operating Hours</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="news">
                        <a href="/sg/en/information/217" target="_self" >
                            <img src="https://callback.owndays.com/storage" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2021.11.11 | Notice                                                                                                </p>
                            <a href="/sg/en/information/217" target="_self" >
                                <h3>Email Server Failure Notice</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="news">
                        <a href="/sg/en/information/193" target="_self" >
                            <img src="https://callback.owndays.com/storage" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2021.10.11 | Notice                                                                                                </p>
                            <a href="/sg/en/information/193" target="_self" >
                                <h3>Urgent System Maintenance Notification</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="new_shop">
                        <a href="/sg/en/information/184" target="_self" >
                            <img src="https://callback.owndays.com/storage/information/thumbnail/6ac21965-0024-4eb3-95de-0c8081834517.png" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2021.09.22 | Latest information                                                                      
                                                            </p>
                            <a href="/sg/en/information/184" target="_self" >
                                <h3>Wed. 29 Sep. 2021 OWNDAYS BUGIS JUNCTION Store Now Open!</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="news">
                        <a href="/sg/en/information/162" target="_self" >
                            <img src="https://callback.owndays.com/storage" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2021.08.03 | Notice                                                                                                </p>
                            <a href="/sg/en/information/162" target="_self" >
                                <h3>Urgent System Maintenance Notification</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="news">
                        <a href="/sg/en/information/113" target="_self" >
                            <img src="https://callback.owndays.com/storage" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2021.02.05 | Notice                                                                                                </p>
                            <a href="/sg/en/information/113" target="_self" >
                                <h3>2021 Lunar New Year Operating Hours</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="new_shop">
                        <a href="/sg/en/information/112" target="_self" >
                            <img src="https://callback.owndays.com/storage/information/thumbnail/1ec5a483-53b6-46c7-9371-716db8d8abe4.png" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2021.02.04 | Latest information                                                                      
                                                            </p>
                            <a href="/sg/en/information/112" target="_self" >
                                <h3>Fri. 5 Feb. 2021 OWNDAYS MBS Premium Concept Store Now Open</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="news">
                        <a href="/sg/en/information/111" target="_self" >
                            <img src="https://callback.owndays.com/storage" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2021.02.01 | Notice                                                                                                </p>
                            <a href="/sg/en/information/111" target="_self" >
                                <h3>Urgent System Maintenance Notification</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="new_shop">
                        <a href="/sg/en/information/97" target="_self" >
                            <img src="https://callback.owndays.com/storage/information/thumbnail/c05a18af-d383-443b-b5de-bde7d308d056.png" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2020.12.17 | Latest information                                                                      
                                                            </p>
                            <a href="/sg/en/information/97" target="_self" >
                                <h3>Wed. 23 Dec. 2020 OWNDAYS Parkway Parade Now Open</h3>
                            </a>
                        </div>
                    </li>
                                    <li data-category="new_shop">
                        <a href="/sg/en/information/94" target="_self" >
                            <img src="https://callback.owndays.com/storage/information/thumbnail/1fa3ef2f-cd6f-45ec-8384-8579937fc7f7.png" alt="" onerror="this.src='https://www.owndays.com/images/information/no-image.jpg';">
                        </a>
                        <div>
                            <p class="days"> 
                                                                                                            2020.09.28 | Latest information                                                                      
                                                            </p>
                            <a href="/sg/en/information/94" target="_self" >
                                <h3>Mon. 28 Sep. 2020 OWNDAYS Causeway Point Now Open</h3>
                            </a>
                        </div>
                    </li>
                            </ul>
                        <ul class="pagination justify-content-center">
                <li class="page-item pagination-page-nav active"><a href="#" class="page-link">1</a></li>
            </ul>
                       
        </section>
    </section>

        </main>
            </div>

        <div class="whats-app noprint">
        <a href="https://wa.me/6598214268" target="_blank" rel="nofollow">
            <img src="https://callback.owndays.com/images/common/logo-whatapp.png" alt="WhatsApp">
        </a>
    </div>
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://callback.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>

<footer class="l-footer l-container-fluid">
        <!-- MENU -->
    <div class="l-footer__menu l-container">
        <div class="l-footer__menu__categories">
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>PRODUCTS<span class="c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li><a href="/sg/en/eyeglasses">Frames</a></li>
                    <li><a href="/sg/en/sunglasses">Sunglasses</a></li>
                                        <li><a href="/sg/en/news/owndays-contactlens-sg">Contact Lens</a></li>
                                        
                    
                    <li><a href="/sg/en/brands">All Collections</a></li>
                                        <li><a href="/sg/en/news">Campaigns</a></li>
                                    </ul>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>PURCHASE<span class="c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    
                    
                    <li><a href="/sg/en/services/lens">Lens Guide</a></li>
                    <li><a href="/sg/en/services/warranty">OWNDAYS Customer Guarantees</a></li>
                </ul>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another"><a href="/sg/en/shops?autoSearchCurrentLocation=true">Shops<span class="c-menu--arrows c-menu--arrows-link u-sp-only"></span></a></div>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p> ABOUT<span class="c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li class="u-pc-only"><a href="/sg/en/shops?autoSearchCurrentLocation=true">Shops</a></li>
                    <li><a href="/sg/en/services">Services</a></li>
                    <li><a href="/sg/en/services/lens-replacement">Lens Replacement</a></li>
                                        <li class="sg-none"><a href="/sg/en/account">OWNDAYS MEMBERSHIP</a></li>
                                        <li><a href="/sg/en/account">Membership</a></li>
                                                            <li><a href="/sg/en/company">Corporate Information</a></li>
                                        <li><a href="https://www.odsaiyou.com/sg/" target="_blank">Recruitment </a></li>
                                        <li><a href="/sg/en/company/eyecamp">Eye Camp</a></li>
                </ul>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>SUPPORT<span class="c-menu--arrows c-menu--arrows u-sp-only"></span></p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li class="u-pc-only">
                                            </li>
                    <li>
                                                    <a href="/sg/en/faq">FAQs</a>
                                            </li>
                                        <li><a href="/sg/en/requirement">System Requirements</a></li>
                    <li><a href="/sg/en/sitemap">Site Map</a></li>
                                        <li class="u-pc-only u-mt__20"><a href="/sg/en/services/onlinestore">New Users</a></li>
                                        <li class="u-pc-only"><a href="/sg/en/register">Sign Up an Account</a></li>
                                                        </ul>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another">
                    <a href="/sg/en/contact">Contact Us<span class="c-menu--arrows c-menu--arrows-link u-sp-only"></span></a>
                </div>
            </div>

                        <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another"><a href="/sg/en/services/onlinestore">New Users</a></div>
            </div>
                        <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another"><a href="/sg/en/register">Sign Up an Account<span class="c-menu--arrows c-menu--arrows-link"></span></a></div>
            </div>
                                    <div class="l-footer__contact">
                <div class="l-footer__menu__category-list u-pc-only">CONTACT US</div>
                <div class="u-sp-only">Contact Us</div>
                <p class="l-footer__contact-tel">
                    <a id="footer_modal_contact" href="tel:62220588">6222-0588</a>
                    <span>Operating Hours <br>10:00 - 19:00</span>
                                                    </p>
                            </div>
        </div>
        <!-- SNS -->
        <ul class="l-footer__sns-link">
                        <li><a href="https://www.facebook.com/owndays.sg/" target="_blank" rel="nofollow" title="Facebook" aria-label="Facebook"><i class="fab fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/owndays_sg/" target="_blank" rel="nofollow" title="instagram" aria-label="Instagram"><i class="fab fa-instagram"></i></a></li>
            <li><a href="https://www.youtube.com/channel/UCSu2yqzCpnTtGXi05WtsiZg" target="_blank" rel="nofollow" title="youtube" aria-label="Youtube"><i class="fab fa-youtube"></i></a></li>
                    </ul>
            </div>
    <div class="l-footer__bottom">
        <div class="l-footer__bottom-inner">
            <div class="l-footer__copyright">
                <p>COPYRIGHT (C) OWNDAYS co., ltd. ALL RIGHTS RESERVED.</p>
            </div>
            <div class="l-footer__bottom-grid">
                <div class="l-footer__language">
                    <button type="button" data-toggle="modal" data-target="#modalCountryLang" class="l-footer__country-lang border-0">
                        <span>
                            <picture>
                                <source srcset="https://callback.owndays.com/images/flags/sg.webp" type="image/webp">
                                <source srcset="https://callback.owndays.com/images/flags/sg.png" type="image/png">
                                <img class="lazyload" data-src="https://callback.owndays.com/images/flags/sg.png" alt="sg" data-sizes="auto">
                            </picture> Singapore                        </span>
                    </button>
                </div>
                <ul class="l-footer__language-link">
                                                            <li>
                        <a href="https://callback.owndays.com/sg/en/information">English</a>
                    </li>
                                                                                
                                                        </ul>
                <ul class="l-footer__agreement">
                                        <li><a href="/sg/en/privacy">PRIVACY POLICY</a></li>
                    <li><a href="/sg/en/terms">TERMS & CONDITIONS</a></li>
                                    </ul>
            </div>
                    </div>
    </div>
</footer>

<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://callback.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                                                                                                                                                                                                    </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                                                                                                                                                                                                    </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://callback.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://callback.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://callback.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://callback.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://callback.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js" integrity="sha512-q583ppKrCRc7N5O0n2nzUiJ+suUv7Et1JGels4bXOaMFQcamPk9HjdUknZuuFjBNs7tsMuadge5k9RzdmO+1GQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://callback.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=5fc0d3feebe59b7c2ac4?20230222"></script>
    
    <script src="https://callback.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });
    </script>
        <script src="https://callback.owndays.com/web/js/information.js" defer></script>
    <script src="https://callback.owndays.com/web/js/faq.js" defer></script>
    <script src="https://callback.owndays.com/web/js/scroll_internal-link.js" defer></script>

    <script>
        $(function () {
            console.log("test");
        });
    </script>
            <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5WRT43B');</script>
<!-- End Google Tag Manager -->

<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5WRT43B" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

<!-- Facebook Pixel Code -->
<script>
    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1510426245838375'); // Insert your pixel ID here.
    fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=1510426245838375&ev=PageView&noscript=1"
    /></noscript>
<!-- DO NOT MODIFY -->

<!-- User Insight PCDF Code Start : owndays.com -->
<script type="text/javascript">
    var _uic = _uic ||{}; var _uih = _uih ||{};_uih['id'] = 52715;
    _uih['lg_id'] = '';
    _uih['fb_id'] = '';
    _uih['tw_id'] = '';
    _uih['uigr_1'] = ''; _uih['uigr_2'] = ''; _uih['uigr_3'] = ''; _uih['uigr_4'] = ''; _uih['uigr_5'] = '';
    _uih['uigr_6'] = ''; _uih['uigr_7'] = ''; _uih['uigr_8'] = ''; _uih['uigr_9'] = ''; _uih['uigr_10'] = '';

    /* DO NOT ALTER BELOW THIS LINE */
    /* WITH FIRST PARTY COOKIE */
    (function() {
        var bi = document.createElement('script');bi.type = 'text/javascript'; bi.async = true;
        bi.src = '//cs.nakanohito.jp/b3/bi.js';
        var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(bi, s);
    })();
</script>
<!-- User Insight PCDF Code End : owndays.com -->

<script>
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

  ttq.load('CGHAVFBC77UA88R0IJ2G');
  ttq.page();
}(window, document, 'ttq');
</script>     <!-- Site 24/7 monitoring service -->
        
        
    <script src="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.js" defer="defer"></script>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.css">
    <script src="https://callback.owndays.com/web/js/cookieconsent-init.js?v=2.5.1" defer="defer"></script>

    <!-- Rtoaster Popup -->
                 <!-- /Rtoaster Popup -->
    </body>

</html>`;

const InformationHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="OWNDAYS ONLINE STORE - OPTICAL SHOP | OWNDAYS Singapore"
        title="OWNDAYS ONLINE STORE - OPTICAL SHOP | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default InformationHTML;
