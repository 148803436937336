import React from 'react';

const Concept = ({ baseImgUrl, onSmoothScroll }) => {
  return (
    <section className="l-huawei2-concept">
      <div className="l-huawei2__container">
        <h2 className="l-huawei2-concept__title" data-aos="fade-up">
          All-New Upgraded{ ' ' }
          <br/>
          Smart Audio Glasses{ ' ' }
        </h2>
        <a className="l-huawei2-concept__movie" href="https://www.youtube.com/watch?v=ftelhfUkgq0" data-fancybox>
          <picture>
            <img
              src={`${baseImgUrl}banner-default-youtube.webp`}
              alt="All-New Smart Audio Glasses｜OWNDAYS × HUAWEI Eyewear 2"
            />
          </picture>
          <div className="l-huawei2-concept__movie-play">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
              <circle
                cx="25"
                cy="25"
                r="24"
                fill="black"
                fillOpacity="0.5"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M30.5312 24.4548C31.1979 24.8397 31.1979 25.8019 30.5312 26.1868L22.334 30.9195C21.6673 31.3044 20.834 30.8233 20.834 30.0535L20.834 20.5881C20.834 19.8183 21.6673 19.3372 22.334 19.7221L30.5312 24.4548Z"
                fill="white"
              />
            </svg>
            <span>PLAY VIDEO</span>
          </div>
        </a>
        <div className="l-huawei2-concept__details" data-aos="fade-up">
          <p className="l-huawei2-concept__des">
            The wildly successful OWNDAYS x HUAWEI Eyewear makes a return with significant improvements. Coming in 4 models with 2 colour variations each, the new smart audio glasses promise an elevated auditory and visual experience with improved sound quality while weighing lighter than the earlier models. Similar to the Generation 1 models, a designated SNAP LENS that allows the smart audio glasses to be converted into sunglasses in an instant is available for purchase separately.
          </p>
          <a href="/sg/en/news/huawei2#lineup" className="l-huawei2__btn" onClick={onSmoothScroll}>
            Product Lineup
          </a>
        </div>
      </div>
    </section>
  );
};

export default Concept;
