import React, { useState, useMemo, useEffect } from 'react';
import './CookiesPopup.scss';

import OdCookiesIcon from '../../../../static/assets/svg/od-cookies-icon.svg';

import { useSelector } from 'react-redux';
import { capitalizeLetters } from '../../helper';

const DEFAULT_COOKIES_SETTINGS = {
  necessaryServices: true,
  functionalSettings: true,
  marketingSettings: true,
};

const PaymentMethods = () => {
  const {
    COOKIES_POPUP_TITLE,
    COOKIES_POPUP_DESCRIPTION,
    ACCEPT,
    SETTINGS,
    COOKIES_SETTINGS,
    NECESSARY,
    FUNCTIONAL,
    MARKETING,
    SAVE_SETTINGS,
    ACCEPT_ALL,
  } = useSelector(state => state.locale);

  const [showPopup, setShowPopup] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState(DEFAULT_COOKIES_SETTINGS);

  const settingsList = useMemo(
    () => [
      {
        name: 'necessaryServices',
        title: NECESSARY,
        value: settings.necessaryServices,
      },
      {
        name: 'functionalSettings',
        title: FUNCTIONAL,
        value: settings.functionalSettings,
      },
      {
        name: 'marketingSettings',
        title: MARKETING,
        value: settings.marketingSettings,
      },
    ],
    [NECESSARY, FUNCTIONAL, MARKETING, settings]
  );

  const toggleSetting = settingName =>
    setSettings(state => ({ ...state, [settingName]: !state[settingName] }));

  const submit = () => {
    localStorage.setItem('cookies-user-settings', JSON.stringify(settings));

    setShowPopup(false);
  };

  useEffect(() => {
    if (!localStorage.getItem('cookies-user-settings')) setShowPopup(true);
  }, []);

  const handleAcceptAll = () => {
    const updatedSettings = {
      necessaryServices: true,
      functionalSettings: true,
      marketingSettings: true,
    };

    setSettings(updatedSettings);
    localStorage.setItem('cookies-user-settings', JSON.stringify(updatedSettings));

    setShowPopup(false);
  };

  return (
    showPopup && (
      <>
        {!showSettings && (
          <div className="od-cookies-popup">
            <div className="od-cookies-popup__text">
              <div className="od-cookies-popup__title od-font-bold">
                <img alt="Cookies" className="od-cookies-popup__image" src={OdCookiesIcon} />
                <span>{COOKIES_POPUP_TITLE}</span>
              </div>
              <div className="od-cookies-popup__description od-font-reg">
                {COOKIES_POPUP_DESCRIPTION}
              </div>
            </div>
            <div className="od-cookies-popup__actions">
              <button
                className="od-cookies-popup__actions__action od-cookies-popup__actions__action--accept od-font-bold"
                onClick={submit}
              >
                {ACCEPT_ALL}
              </button>
              <button
                className="od-cookies-popup__actions__action od-cookies-popup__actions__action--settings od-font-reg"
                onClick={() => setShowSettings(true)}
              >
                {SETTINGS}
              </button>
            </div>
          </div>
        )}

        {showSettings && (
          <div className="od-cookies-popup-settings">
            <div className="od-cookies-popup-settings__content">
              <div className="od-cookies-popup-settings__title od-font-bold">
                {COOKIES_SETTINGS}
              </div>
              {settingsList.map(({ name, title, value }) => (
                <div key={name} className="od-cookies-popup-setting">
                  <div className="od-cookies-popup-setting__title od-font-bold">{title}</div>
                  <label className={`od-cookies-popup-switch ${title === NECESSARY ? 'disabled' : ''}`} htmlFor={name}>
                    <input
                      checked={!!value}
                      id={name}
                      name={name}
                      type="checkbox"
                      onChange={() => toggleSetting(name)}
                    />
                    <span className="od-cookies-popup-switch__slider"></span>
                  </label>
                </div>
              ))}
              <div className="od-cookies-popup__actions">
                <button
                  className="od-cookies-popup__actions__action od-cookies-popup__actions__action--accept od-font-bold"
                  onClick={handleAcceptAll}
                >
                  {ACCEPT_ALL}
                </button>
                <button
                  className="od-cookies-popup__actions__action od-cookies-popup__actions__action--settings od-font-reg"
                  onClick={submit}
                >
                  {capitalizeLetters(SAVE_SETTINGS.toLowerCase())}
                </button>
              </div>
              {/* <button className="od-cookies-popup-settings__submit" onClick={submit}>
                Accept All
              </button> */}
            </div>
          </div>
        )}
      </>
    )
  );
};

export default PaymentMethods;
