import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="og:title" content="OWNDAYS SUNGLASSES COLLECTION 2022 | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="2022 OWNDAYS Sunglasses Collection, featuring an assortment of designs available at S$78 per pair. All sunglasses block over 99% UV, even for those with tinted lenses. Protect your eyes from UV rays. Convert to prescription sunglasses at a top-up fee." />
<meta name="keywords" content="Sunglasses, Collection,2022,UV rays,Anti-UV,Trend,Warranty Spectacles,Glasses,Online Store " />
<meta name="og:description" content="2022 OWNDAYS Sunglasses Collection, featuring an assortment of designs available at S$78 per pair. All sunglasses block over 99% UV, even for those with tinted lenses. Protect your eyes from UV rays. Convert to prescription sunglasses at a top-up fee." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
<meta property="og:title" content="OWNDAYS SUNGLASSES COLLECTION 2022" />
<meta property="og:description" content="2022 OWNDAYS Sunglasses Collection, featuring an assortment of designs available at S$78 per pair. All sunglasses block over 99% UV, even for those with tinted lenses. Protect your eyes from UV rays. Convert to prescription sunglasses at a top-up fee." />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/sunglasses">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/sunglasses2022/ogp_1200x630.jpg?1669271843">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://use.typekit.net/qnd5nxq.css">
<link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css" />
<link rel="stylesheet" type="text/css" href="https://www.owndays.com/web/css/slick-theme.css" />
<link href="https://www.owndays.com/web/css/bx-slider.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/jp/jquery.bxslider.css" rel="stylesheet">
<link rel="stylesheet" href="https://unpkg.com/swiper@8/swiper-bundle.min.css" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&family=Noto+Sans+Khmer:wght@300;400&display=swap" rel="stylesheet">
<link href="https://www.owndays.com/web/css/specials-sunglasses2022.css?v=1.5" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">OWNDAYS SUNGLASSES COLLECTION 2022 | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            OWNDAYS SUNGLASSES COLLECTION 2022                    </li>
        
            </ul>
</div>


<!-- contents_area -->
<div class="l-sunglasses">
    <!-- Hero -->
    <section class="hero">
        <div class="hero__bg">
            <video class="hero__bg-video" autoplay muted loop playsinline>
                <source src="https://www.owndays.com/images/specials/products/sunglasses2022/video/hero-bg-video.mp4" type="video/mp4">
            </video>
        </div>
        <h1 class="u-pc-only">OWNDAYS SUNGLASSES COLLECTION</h1>
        <div class="container">
            <div class="cd-slider-wrapper">
                <h1 class="u-sp-only">OWNDAYS<br>SUNGLASSES<br>COLLECTION</h1>
                <ul class="cd-slider" data-step1="M1402,800h-2V0h1c0.6,0,1,0.4,1,1V800z" data-step2="M1400,800H379L771.2,0H1399c0.6,0,1,0.4,1,1V800z" data-step3="M1400,800H0V0h1399c0.6,0,1,0.4,1,1V800z" data-step4="M-2,800h2V0h-1c-0.6,0-1,0.4-1,1V800z" data-step5="M0,800h1021L628.8,0L1,0C0.4,0,0,0.4,0,1L0,800z" data-step6="M0,800h1400V0L1,0C0.4,0,0,0.4,0,1L0,800z">
                    <li class="visible active">
                        <div class="cd-svg-wrapper">
                            <svg viewBox="0 0 520 723">
                                <defs>
                                    <clipPath id="cd-image-1">
                                        <path id="cd-changing-path-1" d="M1400,800H0V0h1399c0.6,0,1,0.4,1,1V800z" />
                                    </clipPath>
                                </defs>
                                <image clip-path="url(#cd-image-1)" xlink:href="https://www.owndays.com/images/specials/products/sunglasses2022/slide-1.webp"></image>
                            </svg>
                        </div>
                    </li>
                    <li>
                        <div class="cd-svg-wrapper">
                            <svg viewBox="0 0 520 723">
                                <defs>
                                    <clipPath id="cd-image-2">
                                        <path id="cd-changing-path-2" d="M1400,800H0V0h1399c0.6,0,1,0.4,1,1V800z" />
                                    </clipPath>
                                </defs>
                                <image clip-path="url(#cd-image-2)" xlink:href="https://www.owndays.com/images/specials/products/sunglasses2022/slide-2.webp"></image>
                            </svg>
                        </div>
                    </li>
                    <li>
                        <div class="cd-svg-wrapper">
                            <svg viewBox="0 0 520 723">
                                <defs>
                                    <clipPath id="cd-image-3">
                                        <path id="cd-changing-path-3" d="M1400,800H0V0h1399c0.6,0,1,0.4,1,1V800z" />
                                    </clipPath>
                                </defs>
                                <image clip-path="url(#cd-image-3)" xlink:href="https://www.owndays.com/images/specials/products/sunglasses2022/slide-3.webp"></image>
                            </svg>
                        </div>
                    </li>
                    <li>
                        <div class="cd-svg-wrapper">
                            <svg viewBox="0 0 520 723">
                                <defs>
                                    <clipPath id="cd-image-4">
                                        <path id="cd-changing-path-4" d="M1400,800H0V0h1399c0.6,0,1,0.4,1,1V800z" />
                                    </clipPath>
                                </defs>
                                <image clip-path="url(#cd-image-4)" xlink:href="https://www.owndays.com/images/specials/products/sunglasses2022/slide-4.webp"></image>
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
            <span class="hero__scroll-text">
                <span class="hero__scroll-line-wrapper">
                    <div class="hero__scroll-line"></div>
                </span>
                <a href="#cahnge">Scroll for contents</a>
            </span>
        </div>

                <img class="hero__uv-cut u-pc-only" src="https://www.owndays.com/images/specials/products/sunglasses2022/uv-cut-en.svg" alt="BLOCK 99% UV">
        <img class="hero__uv-cut u-sp-only" src="https://www.owndays.com/images/specials/products/sunglasses2022/uv-cut_sp-en.svg" alt="BLOCK 99% UV">
            </section>
    <!-- Change -->
    <section id="cahnge" class="cahnge">
        <div class="container">
            <h2>“ Put them on and feel the change ”</h2>
            <div class="cahnge__box-1">
                <div class="cahnge__box-1-img-wrapper">
                    <div data-aos="fade-up" data-aos-duration="800">
                        <img class="cahnge__box-1-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/change-1.webp" alt="SUN2100T-2S C3" data-aos="zoom-out">
                    </div>
                </div>
                <div class="cahnge__detail">
                    <h3 class="cahnge__detail-title">Step out<br>into the <span>Sun</span></h3>
                    <small>OWNDAYS SUNGLASSES COLLECTION 2022 | UV99%CUT</small>
                    <p class="random-text">From neutrals in earth tones to transparent frames, sunglasses this season are all about subtle nuances that rejuvenate your everyday look while protecting your eyes from UV rays. Put on a pair that resonates with your style and make it an extension of your personality.<br>Bonus: All OWNDAYS sunglasses block over 99% UV!</p>
                    <a class="l-sunglasses__btn l-sunglasses__btn--icon-sunglasses" href="/sg/en/products?productTypes[]=2&submitFilter=true"> See All Sunglasses</a>
                </div>
            </div>
            <div class="cahnge__group">
                <div class="cahnge__box-2">
                    <div class="cahnge__box-2-img-wrapper">
                        <div data-aos="fade-up" data-aos-duration="800">
                            <img class="cahnge__box-2-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/change-2.webp" alt="SUN2100T-2S C2" data-aos="zoom-out">
                        </div>
                    </div>
                </div>
                <div class="cahnge__box-3">
                    <h6 class="cahnge__box-3-title random-text">New <strong>me</strong></h6>
                    <div class="cahnge__box-3-img-wrapper">
                        <div data-aos="fade-up" data-aos-duration="800">
                            <picture>
                                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/sunglasses2022/change-3.webp">
                                <img class="cahnge__box-3-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/change-3-sp.webp" alt="SUN2098T-2S C2" data-aos="zoom-out">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Line up -->
    <section class="line-up">
        <div class="container">
            <h2>Line up</h2>
            <p class="line-up__sub-title line-up__sub-title--1">21<small> Designs / </small>81<small> Variations in Total</small></p>
            <p class="line-up__sub-title line-up__sub-title--2"> <small>All</small> S$78</p>
            <ul class="line-up__product-type">
                <li>
                    <div class="line-up__product-type-inner">
                        <a href="#standard">
                            <h3>Essential</h3>
                            <img class="line-up__product-type-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/SUN2095T-2S-C3.jpg" alt="UN2095T-2S C3">
                            <span class="line-up__product-type-view-all">VIEW ALL</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="line-up__product-type-inner">
                        <a href="#modern">
                            <h3>Modern</h3>
                            <img class="line-up__product-type-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/SUN2100T-2S-C3.jpg" alt="SUN2100T-2S C3">
                            <span class="line-up__product-type-view-all">VIEW ALL</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="line-up__product-type-inner">
                        <a href="#classic">
                            <h3>Classic</h3>
                            <img class="line-up__product-type-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/SUN1068T-2S-C1.jpg" alt="SUN1068T-2S C1">
                            <span class="line-up__product-type-view-all">VIEW ALL</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="line-up__product-type-inner">
                        <a href="#active">
                            <h3>Active</h3>
                            <img class="line-up__product-type-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/SUN2098T-2S-C2.jpg" alt="SUN2098T-2S C2">
                            <span class="line-up__product-type-view-all">VIEW ALL</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- no 1 Standard -->
        <section id="standard">
            <div class="line-up__hero-img-group">
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/standard-main-1.webp" alt="Sunglasses (Essential) / Men" data-aos="zoom-out">
                    </div>
                </div>
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/standard-main-2.webp" alt="Sunglasses (Essential) / Men" data-aos="zoom-out" data-aos-delay="100">
                    </div>
                </div>
                <div class="line-up__hero-img-title">
                    <div data-aos="open-right" data-aos-delay="100" data-aos-duration="1200">
                        <h4><span class="line-up__hero-img-title-num">No.</span><strong>01</strong></h4><br>
                        <h3>Essential</h3>
                    </div>
                </div>
                <p class="line-up__hero-img-sku"><a href="/sg/en/products/SUN2095T-2S?sku=6231">SUN2095T-2S C3</a></p>
            </div>
            <div class="container">
                <div class="line-up__info">
                    <div class="line-up__info-img" data-aos="fade-up">
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/SUN2095T-2S-C3_02.webp" alt="SUN2095T-2S C3">
                    </div>
                    <div class="line-up__info-detail" data-aos="fade-up">
                        <h2>Versatile and<br>great for all</h2>
                        <p>Everyday essential that everyone needs to own, featuring a selection of designs that are simple, yet perfectly solid and versatile. These are must-haves that will outlast any runway trend and always be in vogue.</p>
                    </div>
                </div>
                <!-- Slide -->
                <div class="line-up__product">
                    <!-- SUN2095T-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2095T-2S?sku=6229"><img id="line-up__product-img-ref" class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/SUN2095T-2S-C1.jpg" alt="SUN2095T-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN2095T-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2095T-2S?sku=6230"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/SUN2095T-2S-C2.jpg" alt="SUN2095T-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN2095T-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2095T-2S?sku=6231"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/SUN2095T-2S-C3.jpg" alt="SUN2095T-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN2095T-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/thumb-SUN2095T-2S-C1.jpg" alt="SUN2095T-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/thumb-SUN2095T-2S-C2.jpg" alt="SUN2095T-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2095T-2S/thumb-SUN2095T-2S-C3.jpg" alt="SUN2095T-2S C3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                                        <!-- SUN8004J-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="100">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8004J-2S?sku=6121"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/SUN8004J-2S-C1.jpg" alt="SUN8004J-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN8004J-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8004J-2S?sku=6122"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/SUN8004J-2S-C2.jpg" alt="SUN8004J-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN8004J-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8004J-2S?sku=6123"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/SUN8004J-2S-C3.jpg" alt="SUN8004J-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN8004J-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8004J-2S?sku=6124"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/SUN8004J-2S-C4.jpg" alt="SUN8004J-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN8004J-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/thumb-SUN8004J-2S-C1.jpg" alt="SUN8004J-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/thumb-SUN8004J-2S-C2.jpg" alt="SUN8004J-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/thumb-SUN8004J-2S-C3.jpg" alt="SUN8004J-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8004J-2S/thumb-SUN8004J-2S-C4.jpg" alt="SUN8004J-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN8006J-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="200">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8006J-2S?sku=6129"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/SUN8006J-2S-C1.jpg" alt="SUN8006J-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN8006J-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8006J-2S?sku=6130"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/SUN8006J-2S-C2.jpg" alt="SUN8006J-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN8006J-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8006J-2S?sku=6131"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/SUN8006J-2S-C3.jpg" alt="SUN8006J-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN8006J-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8006J-2S?sku=6132"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/SUN8006J-2S-C4.jpg" alt="SUN8006J-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN8006J-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/thumb-SUN8006J-2S-C1.jpg" alt="SUN8006J-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/thumb-SUN8006J-2S-C2.jpg" alt="SUN8006J-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/thumb-SUN8006J-2S-C3.jpg" alt="SUN8006J-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8006J-2S/thumb-SUN8006J-2S-C4.jpg" alt="SUN8006J-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <!-- SUN2090T-1S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="300">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2090T-1S?sku=5412"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/SUN2090T-1S-C1.jpg" alt="SUN2090T-1S C1"></a>
                                        <h4 class="line-up__product-sku">SUN2090T-1S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2090T-1S?sku=5414"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/SUN2090T-1S-C2.jpg" alt="SUN2090T-1S C2"></a>
                                        <h4 class="line-up__product-sku">SUN2090T-1S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2090T-1S?sku=5415"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/SUN2090T-1S-C3.jpg" alt="SUN2090T-1S C3"></a>
                                        <h4 class="line-up__product-sku">SUN2090T-1S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2090T-1S?sku=5416"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/SUN2090T-1S-C4.jpg" alt="SUN2090T-1S C4"></a>
                                        <h4 class="line-up__product-sku">SUN2090T-1S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/thumb-SUN2090T-1S-C1.jpg" alt="SUN2090T-1S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/thumb-SUN2090T-1S-C2.jpg" alt="SUN2090T-1S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/thumb-SUN2090T-1S-C3.jpg" alt="SUN2090T-1S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2090T-1S/thumb-SUN2090T-1S-C4.jpg" alt="SUN2090T-1S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN2091T-1S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="400">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2091T-1S?sku=5413"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/SUN2091T-1S-C1.jpg" alt="SUN2091T-1S C1"></a>
                                        <h4 class="line-up__product-sku">SUN2091T-1S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2091T-1S?sku=5417"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/SUN2091T-1S-C2.jpg" alt="SUN2091T-1S C2"></a>
                                        <h4 class="line-up__product-sku">SUN2091T-1S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2091T-1S?sku=5418"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/SUN2091T-1S-C3.jpg" alt="SUN2091T-1S C3"></a>
                                        <h4 class="line-up__product-sku">SUN2091T-1S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN2091T-1S?sku=5419"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/SUN2091T-1S-C4.jpg" alt="SUN2091T-1S C4"></a>
                                        <h4 class="line-up__product-sku">SUN2091T-1S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/thumb-SUN2091T-1S-C1.jpg" alt="SUN2091T-1S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/thumb-SUN2091T-1S-C2.jpg" alt="SUN2091T-1S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/thumb-SUN2091T-1S-C3.jpg" alt="SUN2091T-1S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2091T-1S/thumb-SUN2091T-1S-C4.jpg" alt="SUN2091T-1S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- no 2 Modern -->
        <section id="modern">
            <div class="line-up__hero-img-group">
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/modern-main-1.webp" alt="Sunglasses (Modern) / Women" data-aos="zoom-out">
                    </div>
                </div>
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/modern-main-2.webp" alt="Sunglasses (Modern) / Women" data-aos="zoom-out" data-aos-delay="100">
                    </div>
                </div>
                <div class="line-up__hero-img-title">
                    <div data-aos="open-right" data-aos-delay="100" data-aos-duration="1200">
                        <h4><span class="line-up__hero-img-title-num">No.</span><strong>02</strong></h4><br>
                        <h3>Modern</h3>
                    </div>
                </div>
                <p class="line-up__hero-img-sku"><a href="/sg/en/products/SUN2100T-2S?sku=6241">SUN2100T-2S C3</a></p>
            </div>
            <div class="container">
                <div class="line-up__info">
                    <div class="line-up__info-img" data-aos="fade-up">
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/SUN2100T-2S-C3_02.webp" alt="SUN2100T-2S C3">
                    </div>
                    <div class="line-up__info-detail" data-aos="fade-up">
                        <h2>Elevate your style and<br>unleash your personality</h2>
                        <p>These are not just sunglasses but fashion accessories that give you a sense of self-empowerment. The lineup consists of sunglasses in the currently trending earth colour palette such as brown, khaki, beige and dark grey. Options in colourful transparent frames and oversized metal frames are also available.</p>
                    </div>
                </div>
                <!-- Slide -->
                <div class="line-up__product">
                    <!-- SUN2100T-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2100T-2S?sku=6239"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/SUN2100T-2S-C1.jpg" alt="SUN2100T-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN2100T-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2100T-2S?sku=6240"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/SUN2100T-2S-C2.jpg" alt="SUN2100T-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN2100T-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2100T-2S?sku=6241"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/SUN2100T-2S-C3.jpg" alt="SUN2100T-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN2100T-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2100T-2S?sku=6242"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/SUN2100T-2S-C4.jpg" alt="SUN2100T-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN2100T-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/thumb-SUN2100T-2S-C1.jpg" alt="SUN2100T-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/thumb-SUN2100T-2S-C2.jpg" alt="SUN2100T-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/thumb-SUN2100T-2S-C3.jpg" alt="SUN2100T-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2100T-2S/thumb-SUN2100T-2S-C4.jpg" alt="SUN2100T-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                                        <!-- SUN7001B-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="100">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7001B-2S?sku=6097"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/SUN7001B-2S-C1.jpg" alt="SUN7001B-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN7001B-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7001B-2S?sku=6098"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/SUN7001B-2S-C2.jpg" alt="SUN7001B-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN7001B-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7001B-2S?sku=6099"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/SUN7001B-2S-C3.jpg" alt="SUN7001B-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN7001B-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7001B-2S?sku=6100"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/SUN7001B-2S-C4.jpg" alt="SUN7001B-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN7001B-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/thumb-SUN7001B-2S-C1.jpg" alt="SUN7001B-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/thumb-SUN7001B-2S-C2.jpg" alt="SUN7001B-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/thumb-SUN7001B-2S-C3.jpg" alt="SUN7001B-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7001B-2S/thumb-SUN7001B-2S-C4.jpg" alt="SUN7001B-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN8001B-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="200">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8001B-2S?sku=6109"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/SUN8001B-2S-C1.jpg" alt="SUN8001B-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN8001B-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8001B-2S?sku=6110"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/SUN8001B-2S-C2.jpg" alt="SUN8001B-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN8001B-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8001B-2S?sku=6111"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/SUN8001B-2S-C3.jpg" alt="SUN8001B-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN8001B-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8001B-2S?sku=6112"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/SUN8001B-2S-C4.jpg" alt="SUN8001B-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN8001B-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/thumb-SUN8001B-2S-C1.jpg" alt="SUN8001B-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/thumb-SUN8001B-2S-C2.jpg" alt="SUN8001B-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/thumb-SUN8001B-2S-C3.jpg" alt="SUN8001B-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8001B-2S/thumb-SUN8001B-2S-C4.jpg" alt="SUN8001B-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN8005J-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="300">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8005J-2S?sku=6125"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/SUN8005J-2S-C1.jpg" alt="SUN8005J-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN8005J-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8005J-2S?sku=6126"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/SUN8005J-2S-C2.jpg" alt="SUN8005J-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN8005J-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8005J-2S?sku=6127"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/SUN8005J-2S-C3.jpg" alt="SUN8005J-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN8005J-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8005J-2S?sku=6128"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/SUN8005J-2S-C4.jpg" alt="SUN8005J-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN8005J-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/thumb-SUN8005J-2S-C1.jpg" alt="SUN8005J-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/thumb-SUN8005J-2S-C2.jpg" alt="SUN8005J-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/thumb-SUN8005J-2S-C3.jpg" alt="SUN8005J-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8005J-2S/thumb-SUN8005J-2S-C4.jpg" alt="SUN8005J-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <!-- SUN1062T-1S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="400">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1062T-1S?sku=5404"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/SUN1062T-1S-C1.jpg" alt="SUN1062T-1S C1"></a>
                                        <h4 class="line-up__product-sku">SUN1062T-1S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1062T-1S?sku=5405"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/SUN1062T-1S-C2.jpg" alt="SUN1062T-1S C2"></a>
                                        <h4 class="line-up__product-sku">SUN1062T-1S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1062T-1S?sku=5406"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/SUN1062T-1S-C3.jpg" alt="SUN1062T-1S C3"></a>
                                        <h4 class="line-up__product-sku">SUN1062T-1S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1062T-1S?sku=5407"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/SUN1062T-1S-C4.jpg" alt="SUN1062T-1S C4"></a>
                                        <h4 class="line-up__product-sku">SUN1062T-1S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/thumb-SUN1062T-1S-C1.jpg" alt="SUN1062T-1S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/thumb-SUN1062T-1S-C2.jpg" alt="SUN1062T-1S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/thumb-SUN1062T-1S-C3.jpg" alt="SUN1062T-1S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1062T-1S/thumb-SUN1062T-1S-C4.jpg" alt="SUN1062T-1S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- SUN1063T-1S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="500">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1063T-1S?sku=5408"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/SUN1063T-1S-C1.jpg" alt="SUN1063T-1S C1"></a>
                                        <h4 class="line-up__product-sku">SUN1063T-1S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1063T-1S?sku=5409"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/SUN1063T-1S-C2.jpg" alt="SUN1063T-1S C2"></a>
                                        <h4 class="line-up__product-sku">SUN1063T-1S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1063T-1S?sku=5410"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/SUN1063T-1S-C3.jpg" alt="SUN1063T-1S C3"></a>
                                        <h4 class="line-up__product-sku">SUN1063T-1S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <a href="/sg/en/products/SUN1063T-1S?sku=5411"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/SUN1063T-1S-C4.jpg" alt="SUN1063T-1S C4"></a>
                                        <h4 class="line-up__product-sku">SUN1063T-1S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/thumb-SUN1063T-1S-C1.jpg" alt="SUN1063T-1S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/thumb-SUN1063T-1S-C2.jpg" alt="SUN1063T-1S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/thumb-SUN1063T-1S-C3.jpg" alt="SUN1063T-1S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1063T-1S/thumb-SUN1063T-1S-C4.jpg" alt="SUN1063T-1S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- no 3 Classic -->
        <section id="classic">
            <div class="line-up__hero-img-group">
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/classic-main-1.webp" alt="Sunglasses (Classic) / Women" data-aos="zoom-out">
                    </div>
                </div>
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/classic-main-2.webp" alt="Sunglasses (Classic) / Women" data-aos="zoom-out" data-aos-delay="100">
                    </div>
                </div>
                <div class="line-up__hero-img-title">
                    <div data-aos="open-right" data-aos-delay="100" data-aos-duration="1200">
                        <h4><span class="line-up__hero-img-title-num">No.</span><strong>03</strong></h4><br>
                        <h3>Classic</h3>
                    </div>
                </div>
                <p class="line-up__hero-img-sku"><a href="/sg/en/products/SUN1068T-2S?sku=6221">SUN1068T-2S C1</a></p>
            </div>
            <div class="container">
                <div class="line-up__info">
                    <div class="line-up__info-img" data-aos="fade-up">
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/SUN1068T-2S-C1_02.webp" alt="SUN1068T-2S C1">
                    </div>
                    <div class="line-up__info-detail" data-aos="fade-up">
                        <h2>An expression of modern sensibility<br>paired with timeless appeal</h2>
                        <p>Created for those who are eyeing sunglasses with a unique twist, these classic, timeless designs are the perfect fusion of the old and the new. Available in an assortment of styles and vibrant colours to give you a chic summer vibe.</p>
                    </div>
                </div>
                <!-- Slide -->
                <div class="line-up__product">
                    <!-- SUN1068T-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1068T-2S?sku=6221"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/SUN1068T-2S-C1.jpg" alt="SUN1068T-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN1068T-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1068T-2S?sku=6222"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/SUN1068T-2S-C2.jpg" alt="SUN1068T-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN1068T-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1068T-2S?sku=6223"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/SUN1068T-2S-C3.jpg" alt="SUN1068T-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN1068T-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1068T-2S?sku=6224"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/SUN1068T-2S-C4.jpg" alt="SUN1068T-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN1068T-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/thumb-SUN1068T-2S-C1.jpg" alt="SUN1068T-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/thumb-SUN1068T-2S-C2.jpg" alt="SUN1068T-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/thumb-SUN1068T-2S-C3.jpg" alt="SUN1068T-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1068T-2S/thumb-SUN1068T-2S-C4.jpg" alt="SUN1068T-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN1069T-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="100">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1069T-2S?sku=6225"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/SUN1069T-2S-C1.jpg" alt="SUN1069T-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN1069T-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1069T-2S?sku=6226"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/SUN1069T-2S-C2.jpg" alt="SUN1069T-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN1069T-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1069T-2S?sku=6227"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/SUN1069T-2S-C3.jpg" alt="SUN1069T-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN1069T-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN1069T-2S?sku=6228"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/SUN1069T-2S-C4.jpg" alt="SUN1069T-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN1069T-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/thumb-SUN1069T-2S-C1.jpg" alt="SUN1069T-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/thumb-SUN1069T-2S-C2.jpg" alt="SUN1069T-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/thumb-SUN1069T-2S-C3.jpg" alt="SUN1069T-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN1069T-2S/thumb-SUN1069T-2S-C4.jpg" alt="SUN1069T-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                                        <!-- SUN7002B-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="200">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7002B-2S?sku=6101"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/SUN7002B-2S-C1.jpg" alt="SUN7002B-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN7002B-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7002B-2S?sku=6102"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/SUN7002B-2S-C2.jpg" alt="SUN7002B-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN7002B-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7002B-2S?sku=6103"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/SUN7002B-2S-C3.jpg" alt="SUN7002B-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN7002B-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7002B-2S?sku=6104"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/SUN7002B-2S-C4.jpg" alt="SUN7002B-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN7002B-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/thumb-SUN7002B-2S-C1.jpg" alt="SUN7002B-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/thumb-SUN7002B-2S-C2.jpg" alt="SUN7002B-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/thumb-SUN7002B-2S-C3.jpg" alt="SUN7002B-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7002B-2S/thumb-SUN7002B-2S-C4.jpg" alt="SUN7002B-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN7003B-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="300">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7003B-2S?sku=6105"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/SUN7003B-2S-C1.jpg" alt="SUN7003B-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN7003B-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7003B-2S?sku=6106"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/SUN7003B-2S-C2.jpg" alt="SUN7003B-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN7003B-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7003B-2S?sku=6107"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/SUN7003B-2S-C3.jpg" alt="SUN7003B-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN7003B-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN7003B-2S?sku=6108"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/SUN7003B-2S-C4.jpg" alt="SUN7003B-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN7003B-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/thumb-SUN7003B-2S-C1.jpg" alt="SUN7003B-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/thumb-SUN7003B-2S-C2.jpg" alt="SUN7003B-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/thumb-SUN7003B-2S-C3.jpg" alt="SUN7003B-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN7003B-2S/thumb-SUN7003B-2S-C4.jpg" alt="SUN7003B-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN8002B-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="400">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8002B-2S?sku=6113"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/SUN8002B-2S-C1.jpg" alt="SUN8002B-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN8002B-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8002B-2S?sku=6114"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/SUN8002B-2S-C2.jpg" alt="SUN8002B-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN8002B-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8002B-2S?sku=6115"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/SUN8002B-2S-C3.jpg" alt="SUN8002B-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN8002B-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8002B-2S?sku=6116"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/SUN8002B-2S-C4.jpg" alt="SUN8002B-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN8002B-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/thumb-SUN8002B-2S-C1.jpg" alt="SUN8002B-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/thumb-SUN8002B-2S-C2.jpg" alt="SUN8002B-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/thumb-SUN8002B-2S-C3.jpg" alt="SUN8002B-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8002B-2S/thumb-SUN8002B-2S-C4.jpg" alt="SUN8002B-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SUN8007J-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="500">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8007J-2S?sku=6133"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/SUN8007J-2S-C1.jpg" alt="SUN8007J-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN8007J-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8007J-2S?sku=6134"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/SUN8007J-2S-C2.jpg" alt="SUN8007J-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN8007J-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8007J-2S?sku=6135"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/SUN8007J-2S-C3.jpg" alt="SUN8007J-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN8007J-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8007J-2S?sku=6136"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/SUN8007J-2S-C4.jpg" alt="SUN8007J-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN8007J-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/thumb-SUN8007J-2S-C1.jpg" alt="SUN8007J-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/thumb-SUN8007J-2S-C2.jpg" alt="SUN8007J-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/thumb-SUN8007J-2S-C3.jpg" alt="SUN8007J-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8007J-2S/thumb-SUN8007J-2S-C4.jpg" alt="SUN8007J-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>
        <!-- no 4 Active -->
        <section id="active">
            <div class="line-up__hero-img-group">
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/active-main-1.webp" alt="Sunglasses (Active) / Women" data-aos="zoom-out">
                    </div>
                </div>
                <div class="line-up__hero-img-wrapper">
                    <div>
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/active-main-2.webp" alt="Sunglasses (Active) / Women" data-aos="zoom-out" data-aos-delay="100">
                    </div>
                </div>
                <div class="line-up__hero-img-title">
                    <div data-aos="open-right" data-aos-delay="100" data-aos-duration="1200">
                        <h4><span class="line-up__hero-img-title-num">No.</span><strong>04</strong></h4><br>
                        <h3>Active</h3>
                    </div>
                </div>
                <p class="line-up__hero-img-sku"><a href="/sg/en/products/SUN2098T-2S?sku=6233">SUN2098T-2S C2</a></p>
            </div>
            <div class="container">
                <div class="line-up__info">
                    <div class="line-up__info-img" data-aos="fade-up">
                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/SUN2098T-2S-C2_02.webp" alt="SUN2098T-2S C2">
                    </div>
                    <div class="line-up__info-detail" data-aos="fade-up">
                        <h2>A practical approach<br>ideal for daily and active use</h2>
                        <p>A functional option that is lightweight and great for multiple occasions be it day-to-day use or active outdoor use. These trendy sunglasses will instantly amp up your style even when you are out and about for your errand runs.</p>
                    </div>
                </div>
                <!-- Slide -->
                <div class="line-up__product">
                    <!-- SUN2098T-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2098T-2S?sku=6232"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/SUN2098T-2S-C1.jpg" alt="SUN2098T-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN2098T-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2098T-2S?sku=6233"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/SUN2098T-2S-C2.jpg" alt="SUN2098T-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN2098T-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2098T-2S?sku=6234"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/SUN2098T-2S-C3.jpg" alt="SUN2098T-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN2098T-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2098T-2S?sku=6235"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/SUN2098T-2S-C4.jpg" alt="SUN2098T-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN2098T-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/thumb-SUN2098T-2S-C1.jpg" alt="SUN2098T-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/thumb-SUN2098T-2S-C2.jpg" alt="SUN2098T-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/thumb-SUN2098T-2S-C3.jpg" alt="SUN2098T-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2098T-2S/thumb-SUN2098T-2S-C4.jpg" alt="SUN2098T-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- SUN2093T-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="100">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2093T-2S?sku=6236"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2093T-2S/SUN2093T-2S-C1.jpg" alt="SUN2093T-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN2093T-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2093T-2S?sku=6237"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2093T-2S/SUN2093T-2S-C2.jpg" alt="SUN2093T-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN2093T-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2093T-2S?sku=6238"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2093T-2S/SUN2093T-2S-C3.jpg" alt="SUN2093T-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN2093T-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2093T-2S/thumb-SUN2093T-2S-C1.jpg" alt="SUN2093T-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2093T-2S/thumb-SUN2093T-2S-C2.jpg" alt="SUN2093T-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2093T-2S/thumb-SUN2093T-2S-C3.jpg" alt="SUN2093T-2S C3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                                        <!-- SUN8003B-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="200">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8003B-2S?sku=6117"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/SUN8003B-2S-C1.jpg" alt="SUN8003B-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN8003B-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8003B-2S?sku=6118"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/SUN8003B-2S-C2.jpg" alt="SUN8003B-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN8003B-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8003B-2S?sku=6119"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/SUN8003B-2S-C3.jpg" alt="SUN8003B-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN8003B-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C4 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN8003B-2S?sku=6120"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/SUN8003B-2S-C4.jpg" alt="SUN8003B-2S C4"></a>
                                        <h4 class="line-up__product-sku">SUN8003B-2S C4</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/thumb-SUN8003B-2S-C1.jpg" alt="SUN8003B-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/thumb-SUN8003B-2S-C2.jpg" alt="SUN8003B-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/thumb-SUN8003B-2S-C3.jpg" alt="SUN8003B-2S C3">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN8003B-2S/thumb-SUN8003B-2S-C4.jpg" alt="SUN8003B-2S C4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <!-- SUN2101T-2S -->
                    <div class="swiper-group" data-aos="fade-up-small" data-aos-duration="250" data-aos-delay="300">
                        <div class="swiper line-up__product-slide">
                            <div class="swiper-wrapper">
                                <!-- C1 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2101T-2S?sku=6243"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2101T-2S/SUN2101T-2S-C1.jpg" alt="SUN2101T-2S C1"></a>
                                        <h4 class="line-up__product-sku">SUN2101T-2S C1</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C2 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2101T-2S?sku=6244"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2101T-2S/SUN2101T-2S-C2.jpg" alt="SUN2101T-2S C2"></a>
                                        <h4 class="line-up__product-sku">SUN2101T-2S C2</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                                <!-- C3 -->
                                <div class="swiper-slide">
                                    <div class="line-up__product-slide-inner">
                                        <span class="line-up__product-label">NEW</span>
                                        <a href="/sg/en/products/SUN2101T-2S?sku=6245"><img class="line-up__product-img" src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2101T-2S/SUN2101T-2S-C3.jpg" alt="SUN2101T-2S C3"></a>
                                        <h4 class="line-up__product-sku">SUN2101T-2S C3</h4>
                                        <p class="line-up__product-price">S$78</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumb -->
                        <div class="line-up__product-thumb-wrapper">
                            <div class="line-up__product-thumb-label">
                                <span>COLOUR</span>
                            </div>
                            <div class="swiper swiper-thumbs line-up__product-thumb" thumbsSlider>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2101T-2S/thumb-SUN2101T-2S-C1.jpg" alt="SUN2101T-2S C1">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2101T-2S/thumb-SUN2101T-2S-C2.jpg" alt="SUN2101T-2S C2">
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="https://www.owndays.com/images/specials/products/sunglasses2022/products/SUN2101T-2S/thumb-SUN2101T-2S-C3.jpg" alt="SUN2101T-2S C3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </section>
        <div class="container">
            <div class="line-up__button-wrapper">
                <a class="l-sunglasses__btn l-sunglasses__btn--icon-sunglasses" href="/sg/en/products?productTypes[]=2&submitFilter=true"> See All Sunglasses</a>
                <a class="l-sunglasses__btn" href="/sg/en/shops?autoSearchCurrentLocation=true">Search for nearest OWNDAYS stores</a>
            </div>
        </div>
    </section>
    <hr>
    <!-- Ranking -->
    <section class="ranking">
        <div class="l-product-weekly l-content--inner">
            <h2 class="c-title--heading"><span class="sub-title sub-title--top">Weekly Bestsellers</span><span>Weekly</span> Ranking</h2>
            <div class="l-product__inner">
                                    <div class="l-product__panel"> 
  <div
    id="weekly_all_slider"
    class="
      l-product__details p-slider__product
      all
      active
    "
  >
    <div class="search-list-slickslide">
        <div class="p-product">
        <p class=" first ">1</p>
        <a href="/sg/en/products/HWF2001Le-N?sku=6183" target="_self">
          <img src="https://www.owndays.com/storage/products/72289070-ab41-4031-b988-83214ea1ddda.webp" alt="HWF2001Le-N" class="l-product__details-img">
          <p class="l-product__details-code">HWF2001Le-N C1</p>
          <p class="l-product__details-name">
                          OWNDAYS × HUAWEI Eyewear
                      </p>
          <p class="l-product__details-price">
            S$20.00 
                      </p>
        </a>
      </div>
        <div class="p-product">
        <p class=" second ">2</p>
        <a href="/sg/en/products/CSU2002B-2S?sku=6151" target="_self">
          <img src="https://www.owndays.com/storage/products/b7cac438-5fb1-4ea0-a5d1-7fd4183a81aa.webp" alt="CSU2002B-2S" class="l-product__details-img">
          <p class="l-product__details-code">CSU2002B-2S C3</p>
          <p class="l-product__details-name">
                          OWNDAYS
                      </p>
          <p class="l-product__details-price">
            S$78.00 
                      </p>
        </a>
      </div>
        <div class="p-product">
        <p class=" third ">3</p>
        <a href="/sg/en/products/HWF2002Le-N?sku=6184" target="_self">
          <img src="https://www.owndays.com/storage/products/d1b46063-e035-445f-86b5-ac1575605c8d.webp" alt="HWF2002Le-N" class="l-product__details-img">
          <p class="l-product__details-code">HWF2002Le-N C1</p>
          <p class="l-product__details-name">
                          OWNDAYS × HUAWEI Eyewear
                      </p>
          <p class="l-product__details-price">
            S$20.00 
                      </p>
        </a>
      </div>
        <div class="p-product">
        <p class=" ">4</p>
        <a href="/sg/en/products/SUN7003B-2S?sku=6105" target="_self">
          <img src="https://www.owndays.com/storage/products/7e0f4dbe-c7dc-487f-aea1-495dbccbe71d.webp" alt="SUN7003B-2S" class="l-product__details-img">
          <p class="l-product__details-code">SUN7003B-2S C1</p>
          <p class="l-product__details-name">
                          OWNDAYS
                      </p>
          <p class="l-product__details-price">
            S$78.00 
                      </p>
        </a>
      </div>
        <div class="p-product">
        <p class=" ">5</p>
        <a href="/sg/en/products/SUN1062T-1S?sku=5405" target="_self">
          <img src="https://www.owndays.com/storage/products/e29c9751-7987-4b1c-9c29-61bb58f405df.webp" alt="SUN1062T-1S" class="l-product__details-img">
          <p class="l-product__details-code">SUN1062T-1S C2</p>
          <p class="l-product__details-name">
                          OWNDAYS
                      </p>
          <p class="l-product__details-price">
            S$78.00 
                      </p>
        </a>
      </div>
        <div class="p-product">
        <p class=" ">6</p>
        <a href="/sg/en/products/CSU1001B-2S?sku=6137" target="_self">
          <img src="https://www.owndays.com/storage/products/93beb0a1-e5d0-4920-9461-f02abccab544.webp" alt="CSU1001B-2S" class="l-product__details-img">
          <p class="l-product__details-code">CSU1001B-2S C1</p>
          <p class="l-product__details-name">
                          OWNDAYS
                      </p>
          <p class="l-product__details-price">
            S$78.00 
                      </p>
        </a>
      </div>
        </div>
  </div>
  <div
    id="weekly_men_slider"
    class="
      l-product__details p-slider__product
      men
      1
    "
  >
    <div class="search-list-slickslide">
        </div>
  </div>
  <div
    id="weekly_women_slider"
    class="
      l-product__details p-slider__product
      women
      1
    "
  >
    <div class="search-list-slickslide">
        </div>
  </div>
</div>
                            </div>
        </div>
    </section>
    <!-- About -->
    <section class="about">
        <div class="container container--sub">
            <h2>About</h2>
            <p class="about__sub-title">Features of OWNDAYS sunglasses</p>
            <p class="about__des">OWNDAYS sunglasses come with lenses that can block over 99% UV rays. <br class="u-pc-only">With OWNDAYS Customer Guarantees, you get to enjoy 1-year warranty on the sunglasses frame that covers against manufacturing defects. 
        Depending on the shape and size of the sunglasses, you may even choose to add prescription at a top-up fee of <strong>S$198</strong>.</p>
            <span class="about__note"><i>Adding of prescription to sunglasses is not available at the online store.</i></span>
            <ul class="about__list">
                <li>
                    <div class="about__list-inner" data-aos="fade-right" data-aos-delay="50">
                        <div>
                            <span class="about__list-badge">01</span>
                            <div class="about__list-icon-wrapper">
                                <img class="about__list-icon" src="https://www.owndays.com/images/specials/products/sunglasses2022/about-icon-uv-cut.svg" alt="Over 99% UV Protection">
                            </div>
                            <h3>Over 99% UV Protection</h3>
                            <p>UV light entering the eyes may cause freckles and spots on your skin. Offering over 99% UV protection, OWNDAYS sunglasses not only reduce glare but also safeguard your eyes from harmful UV light.</p>
                        </div>
                    </div>
                </li>
                                <li>
                    <div class="about__list-inner" data-aos="fade-right" data-aos-delay="150">
                        <div>
                            <span class="about__list-badge">02</span>
                            <div class="about__list-icon-wrapper">
                                <img class="about__list-icon" src="https://www.owndays.com/images/specials/products/sunglasses2022/about-icon-frame-quality.svg" alt="Frame Quality">
                            </div>
                            <h3>Frame Quality</h3>
                            <p>We provide complimentary repair or exchange of your frames should there be any manufacturing defects within one year of purchase.</p>
                        </div>
                        <span class="about__note"><i>For more details, please visit</i><a href="/sg/en/services/warranty" class="link-color-blue u-dib">OWNDAYS Customer Guarantees</a> page</span>
                    </div>
                </li>
                                <li>
                    <div class="about__list-inner" data-aos="fade-right" data-aos-delay="250">
                        <div>
                                                        <span class="about__list-badge">03</span>
                                                        <div class="about__list-icon-wrapper">
                                <img class="about__list-icon" src="https://www.owndays.com/images/specials/products/sunglasses2022/about-icon-change-prescription.svg" alt="Adding Prescription">
                            </div>
                            <h3>Adding Prescription</h3>
                            <p>Customise your favourite sunglasses to suit your needs by upgrading them to prescription sunglasses at a top up fee of <strong>S$198</strong>.</p>
                        </div>
                        <span class="about__note"><i>Subject to frame shape and lens power</i></span>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- Search Sunglasses -->
    <section class="p-search">
        <div class="container container--sub">
            <h2 class="u-mb__10">Search Sunglasses</h2>
            <div class="p-search__inner">
                <input id="p-search__radio--frames" type="radio" name="tab-btn" checked>
                <input id="p-search__radio--users" type="radio" name="tab-btn">
                <input id="p-search__radio--materials" type="radio" name="tab-btn">
                <input id="p-search__radio--colors" type="radio" name="tab-btn">
                <div class="p-search__tab u-row u-mx__auto">
                    <label id="tab_search_frames" class="p-search__label--frames" for="p-search__radio--frames">Type</label>
                    <label id="tab_search_users" class="p-search__label--users" for="p-search__radio--users">Gender</label>
                    <label id="tab_search_materials" class="p-search__label--materials" for="p-search__radio--materials">Material</label>
                    <label id="tab_search_colors" class="p-search__label--colors" for="p-search__radio--colors">Colour</label>
                </div>
                <div class="p-search__item u-mx__auto">
                    <div id="p-search__frames" class="p-block p-search__block">
                        <ul class="u-row no-gutters">
                                                        <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=1&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/square.svg" alt="Rectangle">
                                    </div>
                                    <p class="u-text-left">Rectangle
                                        <br><span>For a smart look</span>
                                    </p>
                                </a>
                            </li>
                                                        <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=2&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/oval.svg" alt="Oval">
                                    </div>
                                    <p class="u-text-left">Oval
                                        <br><span>For a gentle look</span>
                                    </p>
                                </a>
                            </li>
                                                        <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=3&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/round.svg" alt="Round">
                                    </div>
                                    <p class="u-text-left">Round
                                        <br><span>For a gentle look</span>
                                    </p>
                                </a>
                            </li>
                                                        <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=11&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/wellington.svg" alt="Wellington">
                                    </div>
                                    <p class="u-text-left">Wellington
                                        <br><span>For a versatile style</span>
                                    </p>
                                </a>
                            </li>
                                                        <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=12&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/boston.svg" alt="Boston">
                                    </div>
                                    <p class="u-text-left">Boston
                                        <br><span>For a casual look</span>
                                    </p>
                                </a>
                            </li>
                                                        <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=14&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/blow.svg" alt="Browline">
                                    </div>
                                    <p class="u-text-left">Browline
                                        <br><span>For a classic style</span>
                                    </p>
                                </a>
                            </li>
                                                        <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=16&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/two-point.svg" alt="Rimless">
                                    </div>
                                    <p class="u-text-left">Rimless
                                        <br><span>For a clean-cut look </span>
                                    </p>
                                </a>
                            </li>
                                                                                    <li class="u-col-md-3 u-col-6">
                                <a href="/sg/en/products?frameTypes[]=18&amp;frameTypes[]=19&amp;frameTypes[]=20&amp;frameTypes[]=21&productTypes[]=2&submitFilter=true">
                                    <div class="frames-icon">
                                        <img src="https://www.owndays.com/images/frame-types/other.svg">
                                    </div>
                                    <p class="u-text-left">Other                                        <br><span>For a bold, unique style</span>
                                    </p>
                                </a>
                            </li>
                                                    </ul>
                    </div>
                    <div id="p-search__users" class="p-search__block">
                        <ul>
                            <li>
                                <a href="/sg/en/products?targetMarkets[]=1&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/svg/icon-men.svg" alt="男性">
                                    <p>MEN</p>
                                </a>
                            </li>
                            <li>
                                <a href="/sg/en/products?targetMarkets[]=2&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/svg/icon-women.svg" alt="女性">
                                    <p>WOMEN</p>
                                </a>
                            </li>
                            
                        </ul>
                    </div>
                    <div id="p-search__materials" class="p-search__block">
                        <ul>
                                                        <li>
                                <a href="/sg/en/products?materials%5B%5D=1&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/common/materials/plastic.jpg" alt="Plastic">
                                    <p>Plastic
                                        <span>Available in a wide range of colour options</span>
                                    </p>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?materials%5B%5D=2&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/common/materials/metal.jpg" alt="Metal">
                                    <p>Metal
                                        <span>Rust-resistant and give a metallic shine</span>
                                    </p>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?materials%5B%5D=3&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/common/materials/resin.jpg" alt="Resin">
                                    <p>Resin
                                        <span>Lightweight and durable</span>
                                    </p>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?materials%5B%5D=4&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/common/materials/celluloid.jpg" alt="Celluloid">
                                    <p>Celluloid
                                        <span>Premium and all-natural</span>
                                    </p>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?materials%5B%5D=5&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/common/materials/titanium.jpg" alt="Titanium">
                                    <p>Titanium
                                        <span>Super lightweight and allergy safe</span>
                                    </p>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?materials%5B%5D=6&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/common/materials/stainless.jpg" alt="Stainless">
                                    <p>Stainless
                                        <span>Elastic and corrosion resistant</span>
                                    </p>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?materials%5B%5D=11&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/images/common/materials/other.jpg" alt="Other">
                                    <p>Other
                                        <span>common.material-description.other</span>
                                    </p>
                                </a>
                            </li>
                                                    </ul>
                    </div>
                    <div id="p-search__colors" class="p-search__block">
                        <ul>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=1&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/M0frtYc5dUA7fONY3umEDTV8ZABdO83ip5yRS8tL.png" alt="透明・クリア">
                                    <span>Clear</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=2&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/AC7ZtcemOue4RP0COioCfx1HsLM9KrgxyFKTsJbo.png" alt="白縁・ホワイト">
                                    <span>White</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=3&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/a3ZxD5AQ9SdMJGGVXoTrrpfF7VDqwDp0A7oxlyFn.png" alt="灰色・グレー">
                                    <span>Grey</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=4&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/QrcBz9R2rypF7uD4g3yqs10XEoi3HvxSMiR9C4TN.png" alt="黒縁・ブラック">
                                    <span>Black</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=5&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/8gBjL7omqIUvZcbIKEwPaJJfv0sLGfQrqnpcb5Pu.png" alt="橙色・オレンジ">
                                    <span>Orange</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=6&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/7MFgToOLizzyx2aCbfR9cEyupjgDqwFtqrV8A5qJ.png" alt="黄色・イエロー">
                                    <span>Yellow</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=7&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/fujiPjUhuTtTyBTDS9ZmUFKKIcvwFPyWjgaKInt0.png" alt="緑・グリーン">
                                    <span>Green</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=8&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/5T0QljYMW5mPY11sPfpjFt29Tx8KF88AEvWj2h6b.png" alt="青・ブルー">
                                    <span>Blue</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=9&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/tLr5P6tqYKDy3q9qBspsVZS8XgJ2NLSfaaFoft84.png" alt="茶色・ブラウン">
                                    <span>Brown</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=10&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/qHdMDgaDR39oU8kPJgrqTCCHndwRbFPEjNShhHCL.png" alt="金縁・ゴールド">
                                    <span>Gold</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=11&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/X6qoO4ZkP5OimwjyucnfhDcokvxBBYPH0SYXibE5.png" alt="銀縁・シルバー">
                                    <span>Silver</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=12&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/22UTmmVr8d6fwxHnG6tFTbXeNAdfn2JhzYSLBwB3.png" alt="赤・レッド">
                                    <span>Red</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=13&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/NXUEmL6dExtpDPUUzsHHUQMspfET6jumG76GqEVs.png" alt="紫・パープル">
                                    <span>Purple</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=14&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/ulYXyn92DH92xaQQepitqJA3Qp0XqqbtW4jW0WjI.png" alt="桃色・ピンク">
                                    <span>Pink</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=15&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/C07Wj5lchlQxYrngUntDdABhnUtlqY40AE7341jy.png" alt="灰黄色・ベージュ">
                                    <span>Beige</span>
                                </a>
                            </li>
                                                        <li>
                                <a href="/sg/en/products?colorAttributes[]=16&productTypes[]=2&submitFilter=true">
                                    <img src="https://www.owndays.com/storage/color-attributes/ZEbb3GuWqnHm0s5Pvbb6QgCtndHcqL6aEM2u090B.png" alt="柄">
                                    <span>Pattern</span>
                                </a>
                            </li>
                                                    </ul>
                    </div>
                </div>
            </div>
            <div class="p-search__button-wrapper">
                <a class="l-sunglasses__btn l-sunglasses__btn--icon-sunglasses" href="/sg/en/products?productTypes[]=2&submitFilter=true"> See All Sunglasses</a>
            </div>
        </div>
    </section>
        </div>
        </main>
            </div>

 
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script>
  (function(d) {
    var config = {
      kitId: 'hdk2aki',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);
</script>
<script src="https://code.jquery.com/jquery-2.1.4.min.js" integrity="sha256-8WqyJLuWKRBVhxXIL1jBDD7SDxU936oZkCnxQbWwJVw=" crossorigin="anonymous"></script>
<script src="https://www.owndays.com/web/js/jquery.mobile.custom.min.js"></script>
<script src="https://www.owndays.com/web/js/snap.svg-min.js"></script>
<script src="https://unpkg.com/swiper@8/swiper-bundle.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<script type="text/javascript" src="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js" defer></script>
<script src="https://www.owndays.com/web/js/slick.js"></script>
<script src="https://www.owndays.com/web/js/jp/jquery.bxslider.min.js" defer></script>
<script src="https://cdn.jsdelivr.net/gh/cferdinandi/smooth-scroll@15.0.0/dist/smooth-scroll.polyfills.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-sunglasses2022.js?v=1.6"></script>
<script src="https://www.owndays.com/web/js/specials-sunglasses2022-text.js?v=1.6"></script>
 
</body>

</html>`;

const SunglassesCollectionsHTML = () => {
  return (
    <>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Explore a vast range of stylish OWNDAYS sunglasses offering 100% UV protection. Find the perfect frame for any face shape and lens color to suit your style.',
          },
          {
            name: 'twitter:description',
            content:
              'Explore a vast range of stylish OWNDAYS sunglasses offering 100% UV protection. Find the perfect frame for any face shape and lens color to suit your style.',
          },
          {
            name: 'og:title',
            content: 'Sunglasses Collections | 100% UV Protection & Style | OWNDAYS',
          },
          {
            name: 'twitter:title',
            content: 'Sunglasses Collections | 100% UV Protection & Style | OWNDAYS',
          },
        ]}
        canonical="/news/sunglasses"
        description="Explore a vast range of stylish OWNDAYS sunglasses offering 100% UV protection. Find the perfect frame for any face shape and lens color to suit your style."
        title="Sunglasses Collections | 100% UV Protection & Style | OWNDAYS"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default SunglassesCollectionsHTML;
