import React from 'react';

import './BodyAiruitem.scss';

export const BodyAiruitem = ({ baseImgUrl }) => {
  return (
    <div className="body-airuitem">
      <section className="concept">
        <div className="concept__top">
          <div className="base-airuitem__container">
            <div className="concept__top-overlay">
              <h2 className="base-airuitem__title" data-aos="fade-up">
                CONCEPT
              </h2>
              <p className="concept__top-text" data-aos="fade-up">
                A collection of frames made of ultem, an ultra-flexible material that is amazingly
                light and durable.
                <br />
                AIR Ultem has passed inspections of endurance and bridge distortion based on
                Japanese Industrial Standards (JIS B 7285:2008). In addition, a test conducted by
                the Japan Spectacles and Popular Optics Inspection Institute has shown that the
                temples are able to retain their shape without distortion despite being twisted over
                10,000 times.
                <br />
                These unique properties make AIR Ultem a highly functional collection that is
                perfect for day-to-day use.
              </p>
            </div>
            <div className="concept__top-img">
              <img alt="AIR Ultem" data-aos="fade-up" src={`${baseImgUrl}/concept.webp`} />
            </div>
          </div>
        </div>

        <div className="concept__bottom">
          <div className="base-airuitem__container">
            <h3 className="concept__bottom-title" data-aos="fade-up">
              What is Ultem
            </h3>
            <div className="concept__bottom-img">
              <img alt="Ultem樹脂" data-aos="fade-up" src={`${baseImgUrl}/ultem.webp`} />
            </div>
            <p className="concept__bottom-text" data-aos="fade-up">
              Ultem is a safe and enduring material recognised by the United States Food and Drug
              Administration (FDA). Being lightweight, flexible, durable, non-combustible and
              heat-resistant, it is also used in aircraft parts and medical devices.
            </p>
            <ul className="concept__bottom-list">
              <li className="concept__bottom-item" data-aos="fade-up" data-aos-delay="300">
                <img alt="AIR Ultem" src={`${baseImgUrl}/feature-1.svg`} />
                <p>Lightweight, flexible and unlikely to break even when stepped on</p>
              </li>
              <li className="concept__bottom-item" data-aos="fade-up" data-aos-delay="300">
                <img alt="AIR Ultem" src={`${baseImgUrl}/feature-2.svg`} />
                <p>Non-combustible and heat-resistant</p>
              </li>
              <li className="concept__bottom-item" data-aos="fade-up" data-aos-delay="300">
                <img alt="AIR Ultem" src={`${baseImgUrl}/feature-3.svg`} />
                <p>Eco-friendly material recognised by FDA </p>
              </li>
            </ul>
            <a
              className="base-airuitem__btn"
              data-aos="fade-up"
              href="/sg/en/eyeglasses/brands/AIRUltem.html"
              style={{ maxWidth: '500px' }}
            >
              See All AIR Ultem Products
            </a>
          </div>
        </div>
      </section>

      <section className="point">
        <div className="base-airuitem__container">
          <h2 className="base-airuitem__title base-airuitem__title--center" data-aos="fade-up">
            FEATURES<small>AIR Ultem</small>
          </h2>
          <ul className="point__list">
            <li className="point__item" data-aos="fade-up" data-aos-delay="300">
              <img alt="AIR Utem features" src={`${baseImgUrl}/point1.webp`} />
              <p>Surprisingly elastic and unlikely to break even when bent</p>
            </li>
            <li className="point__item" data-aos="fade-up" data-aos-delay="600">
              <img alt="AIR Utem features" src={`${baseImgUrl}/point2.webp`} />
              <p>Metal hinges that allow better adjustment of frame fitting</p>
            </li>
            <li className="point__item" data-aos="fade-up" data-aos-delay="900">
              <img alt="AIR Utem features" src={`${baseImgUrl}/point3.webp`} />
              <p>
                Eco-friendly silicone nose pads that are transparent, anti-slip and can be replaced
                when needed
              </p>
            </li>
            <li className="point__item" data-aos="fade-up" data-aos-delay="1200">
              <img alt="AIR Utem features" src={`${baseImgUrl}/point4.webp`} />
              <p>Rubber temple tips to minimise pressure on the ears for a more comfortable wear</p>
            </li>
          </ul>
        </div>
      </section>

      <section className="lineup">
        <div className="base-airuitem__container">
          <h2 className="base-airuitem__title base-airuitem__title--center" data-aos="fade-up">
            LINEUP<small>All AIR Ultem Frames</small>
          </h2>
          <ul className="lineup__list">
            <li className="lineup__item">
              <h3 className="lineup__item-title" data-aos="fade-up">
                <span>ESSENTIAL</span>
              </h3>
              <div className="lineup__grid">
                <div className="lineup__item-cover lineup__item-cover--1">
                  <div className="lineup__item-link">
                    <div className="lineup__item-img" data-aos="fade-up">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={`${baseImgUrl}/AU2093T-2A_C3.webp`}
                          type="image/webp"
                        />
                        <source srcSet={`${baseImgUrl}/AU2093T-2A_C3-SP.webp`} type="image/webp" />
                        <img alt="AU2093T-2A C3" src={`${baseImgUrl}/AU2093T-2A_C3-SP.webp`} />
                      </picture>
                    </div>
                    <a
                      className="lineup__item-text"
                      href="/sg/en/air-ultem-au2093t-2a-c3-eyeglasses.html"
                    >
                      <span>AU2093T-2A C3 Brown</span>
                    </a>
                  </div>
                </div>
                <div className="lineup__item-products">
                  <ul className="lineup__item-list">
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2093t-2a-c1-eyeglasses.html">
                        <img alt="AU2093T-2A C1" src={`${baseImgUrl}/AU2093T-2A-C1.webp`} />
                        <p>
                          Black<span style={{ backgroundColor: '#000000' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2093t-2a-c2-eyeglasses.html">
                        <img alt="AU2093T-2A C2" src={`${baseImgUrl}/AU2093T-2A-C2.webp`} />
                        <p>
                          Brown Demi
                          <span
                            style={{
                              backgroundImage: `url(${baseImgUrl}/brown-demi-color.jpeg)`,
                            }}
                          ></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2093t-2a-c3-eyeglasses.html">
                        <img alt="AU2093T-2A C3" src={`${baseImgUrl}/AU2093T-2A-C3.webp`} />
                        <p>
                          Brown<span style={{ backgroundColor: '#795732' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2093t-2a-c4-eyeglasses.html">
                        <img alt="AU2093T-2A C4" src={`${baseImgUrl}/AU2093T-2A-C4.webp`} />
                        <p>
                          Light Purple<span style={{ backgroundColor: '#B34D62' }}></span>
                        </p>
                      </a>
                    </li>
                  </ul>
                  <p className="lineup__item-sku" data-aos="fade-up">
                    AU2093T-2A
                  </p>
                  <p className="lineup__item-price" data-aos="fade-up">
                    S$198
                  </p>
                  <a
                    className="base-airuitem__btn"
                    data-aos="fade-up"
                    href="/sg/en/sg/en/air-ultem-au2093t-2a-c1-eyeglasses.html"
                    style={{ maxWidth: '300px' }}
                  >
                    More Details
                  </a>
                </div>
              </div>
            </li>

            <li className="lineup__item">
              <h3 className="lineup__item-title" data-aos="fade-up">
                <span>FORMAL</span>
              </h3>
              <div className="lineup__grid lineup__grid--even">
                <div className="lineup__item-cover lineup__item-cover--2">
                  <div className="lineup__item-link">
                    <div className="lineup__item-img" data-aos="fade-up">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={`${baseImgUrl}/AU2092T-2A_C3.webp`}
                          type="image/webp"
                        />
                        <source srcSet={`${baseImgUrl}/AU2092T-2A_C3-SP.webp`} type="image/webp" />
                        <img alt="AU2092T-2A C3" src={`${baseImgUrl}/AU2092T-2A_C3-SP.webp`} />
                      </picture>
                    </div>
                    <a
                      className="lineup__item-text"
                      href="/sg/en/air-ultem-au2092t-2a-c3-eyeglasses.html"
                    >
                      <span>AU2092T-2A C3 Khaki</span>
                    </a>
                  </div>
                </div>
                <div className="lineup__item-products">
                  <ul className="lineup__item-list">
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2092t-2a-c1-eyeglasses.html">
                        <img alt="AU2092T-2A C1" src={`${baseImgUrl}/AU2092T-2A-C1.webp`} />
                        <p>
                          Matte Black<span style={{ backgroundColor: '#424146' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2092t-2a-c2-eyeglasses.html">
                        <img alt="AU2092T-2A C2" src={`${baseImgUrl}/AU2092T-2A-C2.webp`} />
                        <p>
                          Grey<span style={{ backgroundColor: '#676570' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2092t-2a-c3-eyeglasses.html">
                        <img alt="AU2092T-2A C3" src={`${baseImgUrl}/AU2092T-2A-C3.webp`} />
                        <p>
                          Khaki<span style={{ backgroundColor: '#6A5C39' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2092t-2a-c4-eyeglasses.html">
                        <img alt="AU2092T-2A C4" src={`${baseImgUrl}/AU2092T-2A-C4.webp`} />
                        <p>
                          Matte Navy<span style={{ backgroundColor: '#29395B' }}></span>
                        </p>
                      </a>
                    </li>
                  </ul>
                  <p className="lineup__item-sku" data-aos="fade-up">
                    AU2092T-2A
                  </p>
                  <p className="lineup__item-price" data-aos="fade-up">
                    S$198
                  </p>
                  <a
                    className="base-airuitem__btn"
                    data-aos="fade-up"
                    href="/sg/en/air-ultem-au2092t-2a-c1-eyeglasses.html"
                    style={{ maxWidth: '300px' }}
                  >
                    More Details
                  </a>
                </div>
              </div>
            </li>

            <li className="lineup__item">
              <h3 className="lineup__item-title" data-aos="fade-up">
                <span>CLASSY</span>
              </h3>
              <div className="lineup__grid">
                <div className="lineup__item-cover lineup__item-cover--3">
                  <div className="lineup__item-link">
                    <div className="lineup__item-img" data-aos="fade-up">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={`${baseImgUrl}/AU2094T-2A_C2.webp`}
                          type="image/webp"
                        />
                        <source srcSet={`${baseImgUrl}/AU2094T-2A_C2-SP.webp`} type="image/webp" />
                        <img alt="AU2094T-2A C2" src={`${baseImgUrl}/AU2094T-2A_C2-SP.webp`} />
                      </picture>
                    </div>
                    <a
                      className="lineup__item-text"
                      href="/sg/en/air-ultem-au2095t-2a-c2-eyeglasses.html"
                    >
                      <span>AU2095T-2A C2 Brown Demi</span>
                    </a>
                  </div>
                </div>
                <div className="lineup__item-products">
                  <ul className="lineup__item-list">
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2095t-2a-c1-eyeglasses.html">
                        <img alt="AU2095T-2A C1" src={`${baseImgUrl}/AU2095T-2A-C1.webp`} />
                        <p>
                          Black<span style={{ backgroundColor: '#000000' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2095t-2a-c2-eyeglasses.html">
                        <img alt="AU2095T-2A C2" src={`${baseImgUrl}/AU2095T-2A-C2.webp`} />
                        <p>
                          Brown Demi
                          <span
                            style={{
                              backgroundImage: `url(${baseImgUrl}/brown-demi-color.jpeg)`,
                            }}
                          ></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2095t-2a-c3-eyeglasses.html">
                        <img alt="AU2095T-2A C3" src={`${baseImgUrl}/AU2095T-2A-C3.webp`} />
                        <p>
                          Light Brown<span style={{ backgroundColor: '#BFAB9D' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2095t-2a-c4-eyeglasses.html">
                        <img alt="AU2095T-2A C4" src={`${baseImgUrl}/AU2095T-2A-C4.webp`} />
                        <p>
                          Light Purple<span style={{ backgroundColor: '#B34D62' }}></span>
                        </p>
                      </a>
                    </li>
                  </ul>
                  <p className="lineup__item-sku" data-aos="fade-up">
                    AU2095T-2A
                  </p>
                  <p className="lineup__item-price" data-aos="fade-up">
                    S$198
                  </p>
                  <a
                    className="base-airuitem__btn"
                    data-aos="fade-up"
                    href="/sg/en/air-ultem-au2095t-2a-c1-eyeglasses.html"
                    style={{ maxWidth: '300px' }}
                  >
                    More Details
                  </a>
                </div>
              </div>
              <div className="lineup__grid lineup__grid--even">
                <div className="lineup__item-cover lineup__item-cover--4">
                  <div className="lineup__item-link">
                    <div className="lineup__item-img" data-aos="fade-up">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={`${baseImgUrl}/AU2095T-2A_C4.webp`}
                          type="image/webp"
                        />
                        <source srcSet={`${baseImgUrl}/AU2095T-2A_C4-SP.webp`} type="image/webp" />
                        <img alt="AU2095T-2A C4" src={`${baseImgUrl}/AU2095T-2A_C4-SP.webp`} />
                      </picture>
                    </div>
                    <a
                      className="lineup__item-text"
                      href="/sg/en/air-ultem-au2094t-2a-c4-eyeglasses.html"
                    >
                      <span>AU2094T-2A C4 Violet</span>
                    </a>
                  </div>
                </div>
                <div className="lineup__item-products">
                  <ul className="lineup__item-list">
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2094t-2a-c1-eyeglasses.html">
                        <img alt="AU2094T-2A C1" src={`${baseImgUrl}/AU2094T-2A-C1.webp`} />
                        <p>
                          Black<span style={{ backgroundColor: '#000000' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2094t-2a-c2-eyeglasses.html">
                        <img alt="AU2094T-2A C2" src={`${baseImgUrl}/AU2094T-2A-C2.webp`} />
                        <p>
                          Light Grey<span style={{ backgroundColor: '#989793' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="300">
                      <a href="/sg/en/air-ultem-au2094t-2a-c3-eyeglasses.html">
                        <img alt="AU2094T-2A C3" src={`${baseImgUrl}/AU2094T-2A-C3.webp`} />
                        <p>
                          Brown<span style={{ backgroundColor: '#795732' }}></span>
                        </p>
                      </a>
                    </li>
                    <li className="lineup__item-product" data-aos="fade-up" data-aos-delay="600">
                      <a href="/sg/en/air-ultem-au2094t-2a-c4-eyeglasses.html">
                        <img alt="AU2094T-2A C4" src={`${baseImgUrl}/AU2094T-2A-C4.webp`} />
                        <p>
                          Violet<span style={{ backgroundColor: '#747697' }}></span>
                        </p>
                      </a>
                    </li>
                  </ul>
                  <p className="lineup__item-sku" data-aos="fade-up">
                    AU2094T-2A
                  </p>
                  <p className="lineup__item-price" data-aos="fade-up">
                    S$198
                  </p>
                  <a
                    className="base-airuitem__btn"
                    data-aos="fade-up"
                    href="/sg/en/air-ultem-au2094t-2a-c1-eyeglasses.html"
                    style={{ maxWidth: '300px' }}
                  >
                    More Details
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
