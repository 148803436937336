import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">
    <link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
    <link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
    <link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
    <link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
    <link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
    <link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
    <link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
    <link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
    <link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
    <link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
    <link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
    <link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
    <link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
    <link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="keywords" content="Sunglasses with transparent lenses that offer 100% UV protection while promising you extra style factor." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta property="og:image" content="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/sg/ogp_1200x630.jpg">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/clear-sunglasses">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/clear-sunglasses/ogp_1200x630.jpg?1654183480">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <!-- Font Tannakone
font-family: tannakone-condensed, sans-serif; -->
<link rel="stylesheet" href="https://use.typekit.net/vhm4kyl.css">
<!-- Slick -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css">
<!-- Animation -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css">
<!-- AOS -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
<!-- CLEAR SUNGLASSES -->
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Kantumruy:wght@300;400&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://www.owndays.com/web/css/specials-clear-sunglasses-2022.css">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">CLEAR SUNGLASSES COLLECTION | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
 


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            CLEAR SUNGLASSES COLLECTION                    </li>
        
            </ul>
</div>


<div class="l-clear-sunglasses">

    <!-- Main -->
    <section class="main">
        <div class="l-clear-sunglasses__container">
            <p class="main__heading" data-aos="fade-up" data-aos-duration="9000"><span>UV protection starts from the eyes</span><br><span>Wear Clear Sunglasses to keep UV at bay</span></p>
            <div data-aos="fade-in">
                <div class="main__slider u-sp-only">
                    <div class="main__slider-item">
                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/main-1_sp.webp" alt="Clear Sunglasses / UV Block">
                    </div>
                    <div class="main__slider-item">
                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/main-2_sp.webp" alt="Clear Sunglasses / UV Block">
                    </div>
                    <div class="main__slider-item">
                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/main-3_sp.webp" alt="Clear Sunglasses / UV Block">
                    </div>
                </div>
            </div>
            <div class="main__img u-pc-only">
                <div class="main__img-item main__img-item--1"><img data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="4000" src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/main-1_pc.webp" alt="Clear Sunglasses / UV Block"></div>
                <div class="main__img-item main__img-item--2"><img data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="7000" data-aos-delay="300" src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/main-3_pc.webp" alt="Clear Sunglasses / UV Protection"></div>
            </div>
            <h1 class="main__logo"><img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/main-logo.svg" alt="CLEAR SUNGLASSES COLLECTION"></h1>
            <p class="main__sticker">
                <span class="main__sticker-text main__sticker-text--title">Block</span>
                <span class="main__sticker-text main__sticker-text--subtitle"><strong><mark>100<small>%</small></mark></strong>UV</span>
            </p>
        </div>
    </section>

    <!-- Concept -->
    <section class="concept">
        <div class="l-clear-sunglasses__container l-clear-sunglasses__container--md">
            <div class="concept__img"><img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/concept-img.svg" alt=""></div>
            <h3 class="concept__heading" data-aos="blur-up">Sunglasses with transparent lenses that offer <br class ="u-pc-only"><mark>100%<small>UV</small></mark> protection while <br class ="u-sp-only">promising you <br class ="u-sp-only">extra style factor</h3>
            <div class="l-clear-sunglasses__relative">
                <h2 class="l-clear-sunglasses__title l-clear-sunglasses__title--left"><span>Concept</span></h2>
                <p class="concept__text" data-aos="blur-up">Did you know that you can get suntanned through the eyes? When UV rays enter the eyes, the brain sends a signal to the body to produce melanin which causes the skin to turn dark and freckles to form. Hats and sun umbrellas are only able to block between 20% to 50% UV rays from entering the eyes.<br> Fitted with anti-UV blue light lenses, Clear Sunglasses offer you double protection from both UV light and harmful blue light! They are the ideal eyewear for all occasions, be it your daily commute, day out or holiday trips.<br>Find your perfect pair and get started on all-round UV protection now!</p>
            </div>
                                    <a href="/sg/en/products?keyword=clear%20sunglasses" class="l-clear-sunglasses__btn">See All Products</a>
                    </div>
    </section>

    <!-- Feature -->
    <section class="feature">
        <div class="l-clear-sunglasses__container l-clear-sunglasses__container--sm">
            <h2 class="l-clear-sunglasses__title l-clear-sunglasses__title--right"><span>Features</span></h2>
            <ul class="feature__list">
                <li class="feature__item">
                    <div class="feature__item-top">
                        <img class="feature__item-img" src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/feature-img-1.svg" alt="Hassle-free UV Protection">
                        <h5 class="feature__item-title">
                            <small>01</small>
                            <span>Hassle-free<br>UV Protection</span>
                        </h5>
                    </div>
                    <p class="feature__item-text">Clear Sunglasses offer 100% UV protection for your eyes. They are the ideal anti-UV solution for those who are constantly on the go!</p>
                </li>
                <li class="feature__item">
                    <div class="feature__item-top">
                        <img class="feature__item-img" src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/feature-img-2.svg" alt="UV Care on Cloudy Days">
                        <h5 class="feature__item-title">
                            <small>02</small>
                            <span>UV Care<br>On Cloudy Days</span>
                        </h5>
                    </div>
                    <p class="feature__item-text">At times, UV intensity may be higher on cloudy days compared to days with clear skies. Clear Sunglasses are thus perfect for people who prefer not to wear regular sunglasses when it is not glaring outside.</p>
                </li>
                <li class="feature__item">
                    <div class="feature__item-top">
                        <img class="feature__item-img" src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/feature-img-3.svg" alt="Suitable For All Occasions">
                        <h5 class="feature__item-title">
                            <small>03</small>
                            <span>Suitable For<br>All Occasions</span>
                        </h5>
                    </div>
                    <p class="feature__item-text">With transparent lenses, there is no need to switch out your Clear Sunglasses when you head indoors or outdoors. Just one pair of eyewear and you are all set! You get a handy storage case too.</p>
                </li>
            </ul>
            <div class="feature__what">
                <h3 class="feature__what-title"><span>WHAT ARE CLEAR SUNGLASSES</span></h3>
                <div class="feature__what-details">
                    <div>
                        <h4 class="feature__what-heading">All New Anti-UV Protection<span>With Transparent Lenses</span></mark></span></h4>
                        <p class="feature__what-text">Harmful lights entering the eyes can cause skin issues such as tanning of the skin, freckles and spots. They can also lead to eye diseases and eye fatigue. Clear Sunglasses are fitted with non-tinted, transparent lenses that offer double protection against UV rays and blue light. The lenses are able to block 100% UV-A and UV-B which affect both the eyes and skin as well as reduce the amount of HEV light (high-energy visible light) that may cause aging of the eyes.</p>
                    </div>
                    <img class="feature__what-img" src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/sg/what-is-clear-sunglasses-img.svg" alt="Block 100% UV Rays Reduce Harmful HEV Light">
                </div>
            </div>
        </div>
    </section>

    <!-- Line up -->
    <section class="lineup">
        <div class="l-clear-sunglasses__container">
            <h2 class="l-clear-sunglasses__title l-clear-sunglasses__title--top"><span>Lineup</span></h2>
            <ul class="lineup__list">

                <!-- Item 1 -->
                <li class="lineup__list-item">
                    <div class="lineup__top">
                        <div class="lineup__top-border">
                            <h3 class="lineup__title" data-aos="blur-up">Item 1<small>Metal Frame</small></h3>
                            <div class="lineup__cover" data-aos="blur-in">
                                <picture>
                                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-1_pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-1_sp.webp" alt="CSU1001B-2S">
                                </picture>
                            </div>
                        </div>
                        <div class="lineup__details">
                            <h4 class="lineup__sku" data-aos="blur-up">CSU1001B-2S</h4>
                            <p class="lineup__price" data-aos="blur-up">S$78</p>
                            <p class="lineup__heading" data-aos="blur-up">Boston style<br>with a Crown Panto touch</p>
                            <p class="lineup__text" data-aos="blur-up">A small, form-fitting design that is recommended for people with small face. The frame features dotted-line engravings on the nose bridge and temples that exude a charming retro vibe.</p>
                            <a href="/sg/en/products/CSU1001B-2S" class="l-clear-sunglasses__btn">View more</a>
                        </div>
                    </div>
                    <div class="product__list product__list--for">
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6137" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C1/CSU1001B-2S_C1_01.jpg" alt="CSU1001B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6137" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C1/CSU1001B-2S_C1_02.jpg" alt="CSU1001B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6137" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C1/CSU1001B-2S_C1_03.jpg" alt="CSU1001B-2S C1">
                                    </a>
                                </div>
                                <p class="product__details">CSU1001B-2S C1 | <b>Matte Black</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6138" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C2/CSU1001B-2S_C2_01.jpg" alt="CSU1001B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6138" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C2/CSU1001B-2S_C2_02.jpg" alt="CSU1001B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6138" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C2/CSU1001B-2S_C2_03.jpg" alt="CSU1001B-2S C2">
                                    </a>
                                </div>
                                <p class="product__details">CSU1001B-2S C2 | <b>Gold</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6139" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C3/CSU1001B-2S_C3_01.jpg" alt="CSU1001B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6139" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C3/CSU1001B-2S_C3_02.jpg" alt="CSU1001B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU1001B-2S?sku=6139" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1001B-2S/C3/CSU1001B-2S_C3_03.jpg" alt="CSU1001B-2S C3">
                                    </a>
                                </div>
                                <p class="product__details">CSU1001B-2S C3 | <b>Silver</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="product__list product__list--nav">
                        <div class="product__list-item"><span style="background-color: #000000;"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/gold.png)"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/silver.png)"></span></div>
                    </div>
                </li>

                <!-- Item 2 -->
                <li class="lineup__list-item">
                    <div class="lineup__top">
                        <div class="lineup__top-border">
                            <h3 class="lineup__title" data-aos="blur-up">Item 2<small>Combination Frame</small></h3>
                            <div class="lineup__cover" data-aos="blur-in">
                                <picture>
                                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-2_pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-2_sp.webp" alt="CSU1002B-2S">
                                </picture>
                            </div>
                        </div>
                        <div class="lineup__details">
                            <h4 class="lineup__sku" data-aos="blur-up">CSU1002B-2S</h4>
                            <p class="lineup__price" data-aos="blur-up">S$78</p>
                            <p class="lineup__heading" data-aos="blur-up">Half-rim design<br>in Crown Panto style</p>
                            <p class="lineup__text" data-aos="blur-up">An acetate feature in complementing colour is layered over the top half of the rim to add a touch of fun to the sophisticated frame design. This is a frame that combines classic elegance and contemporary chic in one.</p>
                            <a href="/sg/en/products/CSU1002B-2S" class="l-clear-sunglasses__btn">View more</a>
                        </div>
                    </div>
                    <div class="product__list product__list--for">
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6140" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C1/CSU1002B-2S_C1_01.jpg" alt="CSU1002B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6140" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C1/CSU1002B-2S_C1_02.jpg" alt="CSU1002B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6140" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C1/CSU1002B-2S_C1_03.jpg" alt="CSU1002B-2S C1">
                                    </a>
                                </div>
                                <p class="product__details">CSU1002B-2S C1 | <b>Black / Silver</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6141" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C2/CSU1002B-2S_C2_01.jpg" alt="CSU1002B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6141" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C2/CSU1002B-2S_C2_02.jpg" alt="CSU1002B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6141" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C2/CSU1002B-2S_C2_03.jpg" alt="CSU1002B-2S C2">
                                    </a>
                                </div>
                                <p class="product__details">CSU1002B-2S C2 | <b>Gold / Brown Demi</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6142" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C3/CSU1002B-2S_C3_01.jpg" alt="CSU1002B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6142" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C3/CSU1002B-2S_C3_02.jpg" alt="CSU1002B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU1002B-2S?sku=6142" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1002B-2S/C3/CSU1002B-2S_C3_03.jpg" alt="CSU1002B-2S C3">
                                    </a>
                                </div>
                                <p class="product__details">CSU1002B-2S C3 | <b>Gold / Matte Light Brown Demi</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="product__list product__list--nav">
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/black-silver.png)"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/gold-brown-demi.png)"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/gold-brown-demi.png)"></span></div>
                    </div>
                </li>

                <!-- Item 3 -->
                <li class="lineup__list-item">
                    <div class="lineup__top">
                        <div class="lineup__top-border">
                            <h3 class="lineup__title" data-aos="blur-up">Item 3<small>Metal Frame</small></h3>
                            <div class="lineup__cover" data-aos="blur-in">
                                <picture>
                                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-3_pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-3_sp.webp" alt="CSU1003B-2S">
                                </picture>
                            </div>
                        </div>
                        <div class="lineup__details">
                            <h4 class="lineup__sku" data-aos="blur-up">CSU1003B-2S</h4>
                            <p class="lineup__price" data-aos="blur-up">S$78</p>
                            <p class="lineup__heading" data-aos="blur-up">Currently trending<br>polygonal frame</p>
                            <p class="lineup__text" data-aos="blur-up">Switch things up with this polygonal frame, featuring dotted-line engravings on the nose bridge and temples that exude a charming retro vibe.</p>
                            <a href="/sg/en/products/CSU1003B-2S" class="l-clear-sunglasses__btn">View more</a>
                        </div>
                    </div>
                    <div class="product__list product__list--for">
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6143" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C1/CSU1003B-2S_C1_01.jpg" alt="CSU1003B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6143" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C1/CSU1003B-2S_C1_02.jpg" alt="CSU1003B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6143" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C1/CSU1003B-2S_C1_03.jpg" alt="CSU1003B-2S C1">
                                    </a>
                                </div>
                                <p class="product__details">CSU1003B-2S C1 | <b>Matte Black</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6144" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C2/CSU1003B-2S_C2_01.jpg" alt="CSU1003B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6144" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C2/CSU1003B-2S_C2_02.jpg" alt="CSU1003B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6144" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C2/CSU1003B-2S_C2_03.jpg" alt="CSU1003B-2S C2">
                                    </a>
                                </div>
                                <p class="product__details">CSU1003B-2S C2 | <b>Gold</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6145" v class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C3/CSU1003B-2S_C3_01.jpg" alt="CSU1003B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6145" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C3/CSU1003B-2S_C3_02.jpg" alt="CSU1003B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU1003B-2S?sku=6145" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU1003B-2S/C3/CSU1003B-2S_C3_03.jpg" alt="CSU1003B-2S C3">
                                    </a>
                                </div>
                                <p class="product__details">CSU1003B-2S C3 | <b>Silver</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="product__list product__list--nav">
                        <div class="product__list-item"><span style="background-color: #000000;"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/gold.png)"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/silver.png)"></span></div>
                    </div>
                </li>

                <!-- Item 4 -->
                <li class="lineup__list-item">
                    <div class="lineup__top">
                        <div class="lineup__top-border">
                            <h3 class="lineup__title" data-aos="blur-up">Item 4<small>Plastic Frame</small></h3>
                            <div class="lineup__cover" data-aos="blur-in">
                                <picture>
                                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-4_pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-4_sp.webp" alt="CSU2001B-2S">
                                </picture>
                            </div>
                        </div>
                        <div class="lineup__details">
                            <h4 class="lineup__sku" data-aos="blur-up">CSU2001B-2S</h4>
                            <p class="lineup__price" data-aos="blur-up">S$78</p>
                            <p class="lineup__heading" data-aos="blur-up">A stylish yet relaxed<br>Crown Panto frame</p>
                            <p class="lineup__text" data-aos="blur-up">The plastic frame body is given a metal nose bridge to create a point of difference. Available in black, clear and clear pink, this is a versatile style that can be worn at both casual and formal settings.</p>
                            <a href="/sg/en/products/CSU2001B-2S" class="l-clear-sunglasses__btn">View more</a>
                        </div>
                    </div>
                    <div class="product__list product__list--for">
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6146" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C1/CSU2001B-2S_C1_01.jpg" alt="CSU2001B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6146" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C1/CSU2001B-2S_C1_02.jpg" alt="CSU2001B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6146" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C1/CSU2001B-2S_C1_03.jpg" alt="CSU2001B-2S C1">
                                    </a>
                                </div>
                                <p class="product__details">CSU2001B-2S C1 | <b>Black</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6147" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C2/CSU2001B-2S_C2_01.jpg" alt="CSU2001B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6147" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C2/CSU2001B-2S_C2_02.jpg" alt="CSU2001B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6147" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C2/CSU2001B-2S_C2_03.jpg" alt="CSU2001B-2S C2">
                                    </a>
                                </div>
                                <p class="product__details">CSU2001B-2S C2 | <b>Clear</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6148" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C3/CSU2001B-2S_C3_01.jpg" alt="CSU2001B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6148" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C3/CSU2001B-2S_C3_02.jpg" aalt="CSU2001B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU2001B-2S?sku=6148" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2001B-2S/C3/CSU2001B-2S_C3_03.jpg" aalt="CSU2001B-2S C3">
                                    </a>
                                </div>
                                <p class="product__details">CSU2001B-2S C3 | <b>Clear Pink</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="product__list product__list--nav">
                        <div class="product__list-item"><span style="background-color: #000000;"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/clear-1.png)"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/clear-2.png)"></span></div>
                    </div>
                </li>

                <!-- Item 5 -->
                <li class="lineup__list-item">
                    <div class="lineup__top">
                        <div class="lineup__top-border">
                            <h3 class="lineup__title" data-aos="blur-up">Item 5<small>Plastic Frame</small></h3>
                            <div class="lineup__cover" data-aos="blur-in">
                                <picture>
                                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-5_pc.webp">
                                    <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/item-5_sp.webp" alt="CSU2002B-2S">
                                </picture>
                            </div>
                        </div>
                        <div class="lineup__details">
                            <h4 class="lineup__sku" data-aos="blur-up">CSU2002B-2S</h4>
                            <p class="lineup__price" data-aos="blur-up">S$78</p>
                            <p class="lineup__heading" data-aos="blur-up">Sophisticated<br>Wellington style</p>
                            <p class="lineup__text" data-aos="blur-up">A minimalist design that is brimming with character and vintage charm. The frame features a keyhole nose bridge design with delicate engravings on the end pieces and temples.</p>
                            <a href="/sg/en/products/CSU2002B-2S" class="l-clear-sunglasses__btn">View more</a>
                        </div>
                    </div>
                    <div class="product__list product__list--for">
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6149" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C1/CSU2002B-2S_C1_01.jpg" alt="CSU2002B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6149" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C1/CSU2002B-2S_C1_02.jpg" alt="CSU2002B-2S C1">
                                    </a>
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6149" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C1/CSU2002B-2S_C1_03.jpg" alt="CSU2002B-2S C1">
                                    </a>
                                </div>
                                <p class="product__details">CSU2002B-2S C1 | <b>Black</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6150" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C2/CSU2002B-2S_C2_01.jpg" alt="CSU2002B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6150" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C2/CSU2002B-2S_C2_02.jpg" alt="CSU2002B-2S C2">
                                    </a>
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6150" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C2/CSU2002B-2S_C2_03.jpg" alt="CSU2002B-2S C2">
                                    </a>
                                </div>
                                <p class="product__details">CSU2002B-2S C2 | <b>Brown Demi</b></p>
                            </div>
                        </div>
                        <div class="product__list-item">
                            <div class="product">
                                <div class="product__slider">
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6151" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C3/CSU2002B-2S_C3_01.jpg" aalt="CSU2002B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6151" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C3/CSU2002B-2S_C3_02.jpg" alt="CSU2002B-2S C3">
                                    </a>
                                    <a href="/sg/en/products/CSU2002B-2S?sku=6151" class="product__slider-item">
                                        <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/CSU2002B-2S/C3/CSU2002B-2S_C3_03.jpg" alt="CSU2002B-2S C3">
                                    </a>
                                </div>
                                <p class="product__details">CSU2002B-2S C3 | <b>Clear</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="product__list product__list--nav">
                        <div class="product__list-item"><span style="background-color: #000000;"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/brown.png)"></span></div>
                        <div class="product__list-item"><span style="background-image: url(https://www.owndays.com/images/specials/products/clear-sunglasses-2022/clear-1.png)"></span></div>
                    </div>
                </li>

            </ul>
        </div>
    </section>

    <!-- Original case -->
    <section class="case">
        <div class="l-clear-sunglasses__container l-clear-sunglasses__container--md">
            <div class="case__top">
                <h2 class="case__title"><small>Handy 2-Way Original Design</small>Eyewear Case</h2>
                <div class="case__img">
                    <div class="case__img-item case__img-item--1"><img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/original-case-1.webp" alt="Original Spectacle Case"></div>
                    <div class="case__img-item case__img-item--2"><img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/original-case-2.webp" alt="Mini Tray"></div>
                    <p class="case__img-badge"><span>2-WAY</span></p>
                </div>
                <p class="case__text">A convenient, 2-way design that can be used as an eyewear case when folded in half with the buttons fastened. Simply unfold and fasten the snap closure at the four corners to transform it into a multi-purpose tray to hold small items.</p>
            </div>
            <div class="l-clear-sunglasses__btn-group">
                                                <a href="/sg/en/products?keyword=clear%20sunglasses" class="l-clear-sunglasses__btn l-clear-sunglasses__btn--primary"><span>See All Clear Sunglasses Products</span></a>
                                <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-clear-sunglasses__btn l-clear-sunglasses__btn--primary"><span>Search For Nearest OWNDAYS Stores</span></a>
            </div>
        </div>
    </section>

    <!-- Options Lens -->
    <section class="option">
        <div class="l-clear-sunglasses__container l-clear-sunglasses__container--sm">
            <div class="option__top">
                <h2 class="option__title">Option Lens</h2>
                <p class="option__text">オプションレンズとして「UV・PCダブルカットレンズ」をお選びいただくと、<strong>追加料金 ¥10,400 (税込)</strong>で度付きにも変更が可能です。</p>
            </div>
            <div class="option__table">
                <div class="option__table-price">
                    <ul class="option__table-price-el">
                        <li>
                            <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/frame.svg" alt="Clear Sunglasses">
                            <p>クリア<br>サングラス</p>
                        </li>
                        <li>
                            <img src="https://www.owndays.com/images/specials/products/clear-sunglasses-2022/lens.svg" alt="Block UV・PC lens">
                            <p>UV・PCダブルカット<br>レンズ（度付き）</p>
                        </li>
                    </ul>
                    <p class="option__table-price-total"><span>¥16,400 <small>税込</small></span></p>
                </div>
                <dl class="option__table-details">
                    <dt>スペック</dt>
                    <dd>
                        <ul class="option__list option__list--checkbox">
                            <li>薄型非球面レンズ</li>
                            <li>UVカット率 100%</li>
                            <li>キズつきを防止</li>
                            <li>レンズの反射を防止</li>
                            <li>防汚レンズ加工</li>
                            <li>ブルーライトカット</li>
                        </ul>
                    </dd>

                    <dt>屈折率</dt>
                    <dd><i>1.60 / 1.67 / 1.74</i></dd>

                    <dt>価格</dt>
                    <dd><i>¥6,000</i> <small>(税込)</small></dd>
                </dl>
            </div>
            <ul class="option__list option__list--star option__list--sm">
                <li>通常の度付きレンズ、またはPCレンズに変更した場合はUVカット効果はなくなります。</li>
                <li>度数によりお作りいただけない場合がございます。ご了承ください。</li>
                <li>一部お取り扱いのない店舗がございます。</li>
                <li>オンラインストアでは、UV・PCダブルカットレンズへの変更は対応しておりません。</li>
            </ul>
        </div>
    </section>

    
    <!-- SNS Share -->
    <section class="sns">
        <div class="l-clear-sunglasses__container">
            <h2 class="sns__title">SNS Share</h2>
            <ul class="sns__list">
                <li>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/clear-sunglasses" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/clear-sunglasses" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="http://line.me/R/msg/text/?https://www.owndays.com/sg/en/news/clear-sunglasses" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-line"></i>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</div>

        </main>
            </div>

   
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                               
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script>
  (function(d) {
    var config = {
      kitId: 'hdk2aki',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);
</script>
<!-- Slick -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
<!-- AOS -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<!-- CLEAR SUNGLASSES -->
<script src="https://www.owndays.com/web/js/specials-clear-sunglasses-2022.js"></script>
    
</body>

</html>`;

const ClearSunglassesHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Check out OWNDAYS' collection of sunglasses with transparent lenses that offer 100% UV protection while promising you extra style factor."
        title="Clear Sunglasses Collection | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default ClearSunglassesHTML;
