import React from 'react';

const BackIcon = ({stroke ='#546073', strokeWidth = 2}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18L9 12L15 6" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="square" />
    </svg>
  );
};

export default BackIcon;
